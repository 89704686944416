<div class="container" *ngIf="usuario_sesion.rol==1">
    <h1 class="h1">Log <img class="btn_informacion" src="../../assets/info.svg"(click)="abrirInfo()"></h1>
    <div class="row">
        <div class="col-lg-6 col-12">   
            <button class="w-100 btn btn btn-outline-info textoblanco" (click)="mostrar_filtro(filtro)">Filtros</button>
        </div>
        <div class="col-lg-6 col-12" *ngIf="usuario_sesion.rol == 1">
            <button class="w-100 btn btn-outline-primary mt-3 mt-lg-0" (click)="mostrar_conf(config)"> Configuración</button>
        </div>
    </div>

    <div #filtro class="row | bg-white border rounded mt-3 | animate__animated ">
        <div class="row col-12">          
            <div class="col-lg-6 col-12">
                <label>Desde</label>
                <input type="date" placeholder="Fecha de publicación..." class="form-control" [(ngModel)]="desde">
            </div>
  
            <div class="col-lg-6 col-12">
                <label>Hasta</label>
                <input type="date" placeholder="Fecha del publicación..." class="form-control" [(ngModel)]="hasta">                
            </div>                
        </div>

        <div class="row col-12">          
            <div class="col-lg-4 col-6">
                <label>Error</label>
                <div class="">
                    <input type="number" (keyup.enter)="aplicarFiltros()" placeholder="Código de error" class="form-control" [(ngModel)]="filtro_error">
                </div>
            </div>
 
            <div class="col-lg-4 col-6">
                <label>Query</label>
                <div class="">
                    <input type="text" (keyup.enter)="aplicarFiltros()" placeholder="Query del log" class="form-control" [(ngModel)]="filtro_query">
                </div>
            </div>

            <div class="col-lg-4 col-12">
                <label>Usuario</label>
                <div class="">
                    <input type="text" (keyup.enter)="aplicarFiltros()" placeholder="Nombre del usuario" class="form-control" [(ngModel)]="filtro_usuario">
                </div>
            </div>
        </div>

        <div class="row | offset-lg-1 | col-lg-10 col-12 | justify-content-center align-items-center ">
            <div class="offset-1 offset-md-0 | col-6 col-md-5 | ms-lg-0">
                <label class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch"  [(ngModel)]="quitar_ok">
                    <h6 class="form-check-label">Quitar logs correctos </h6>
                </label>
            </div>
            <div class="col-12 col-md-5 | mb-2 text-center text-md-start">
                <button class="boton secondary" (click)="aplicarFiltros()">Aplicar filtros</button>
            </div>
        </div>
    </div>
    <div *ngIf="this.numeroResultado > 0" class="row overflow-x-auto d-flex flex-nowrap">
        <table class="col-12 mt-3 | rounded table table-hover ">
            <thead>
                <tr>
                    <th scope="col">Error</th>
                    <th scope="col">Mensaje</th>
                    <th scope="col">Query</th>
                    <th scope="col">Usuario</th>
                    <th *ngIf="this.ordenAscendente" class="clicable" scope="col" (click)="ordenar()">Fecha <img src="../../assets/arrow-up.svg"> </th>
                    <th *ngIf="!this.ordenAscendente" class="clicable" scope="col" (click)="ordenar()">Fecha <img src="../../assets/arrow-down.svg"> </th>
                </tr>
            </thead>
            <tbody>
                <tr class="clicable" *ngFor="let log of lista_logs" (click)="verLog(log)">
                    <td>{{log.error}}</td>
                    <td>{{log.res}}</td>
                    <td>{{log.query}}</td>
                    <td>
                        <div class="usuario" *ngIf="log.id_usuario?.nombre!=null">
                            <div class="foto">
                                <img class="img-fluid" width="50" src="{{url_imagenes}}{{log.id_usuario.imagen}}">
                            </div>
                            <div class="nombre">
                                <span>{{log.id_usuario.nombre}} {{log.id_usuario.apellidos}}</span>
                            </div>
                        </div>
                    </td>
                    <td>{{pipe.transform(log.cdate, 'dd/MM/yyyy HH:mm:ss')}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="noResultados" *ngIf="numeroResultado == 0">
        <h4>SIN RESULTADOS</h4>
   </div>

    <div *ngIf="this.numeroResultado > 0" class="div-paginacion">
        <div class="atras">
            <button *ngIf="pagina>1"  (click)="anteriorPagina()" class="boton secondary"><img src="../../assets/arrow-left.svg">Anterior</button>
        </div>
        <div class="paginas ">{{pagina}}/{{paginas}}</div>
        <div class="siguiente ">
            <button class="boton secondary" *ngIf="pagina<paginas" (click)="siguientePagina()"><img src="../../assets/arrow-right.svg">Siguiente</button>
        </div>
    </div>

    <div #config class="row | mt-3 justify-content-evenly | animate__animated " >        
        <div class="col-lg-4 col-12 | mt-3 mb-3">
            <!-- Boton en HTML para activar webhook de firmafy -->
            <button  class="boton medium w-100" (click)="confirmarCambios()">Activar Webhook Firmafy</button>
        </div>
        <div class="col-lg-4 col-12 | mt-3 mb-3">
            <!-- Boton para obtener datos de la licencia de firmafy-->
           <button  class="boton medium w-100" (click)="consultaConsumos()">Ver consumos Firmafy</button>
        </div>
        <div class="col-lg-4 col-12 | mt-3 mb-3">
            <!-- Boton en HTML para activar webhook de firmafy -->
            <button  class="boton medium w-100" (click)="actualizarPresiFGCSIC()">Actualizar presidente FGCSIC</button>
        </div>
    </div>
</div>



<!--Modal para mostrar datos del log-->
<ng-template #modal_log let-modal >
    <div class="modal-content">
         <div class="modal-content">
           <div class="modal-header">
               <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
           </div>
              <div class="modal-body">
                    <p><b>Error: </b>{{ver_log.error}}</p>
                    <p><b>Respuesta: </b>{{ver_log.res}}</p>
                    <p><b>Query: </b>{{ver_log.query}}</p>
                    <p><b>Usuario: </b>{{ver_log.id_usuario.nombre}} {{ver_log.id_usuario.apellidos}} ({{ver_log.id_usuario._id}})</p>
                    <p><b>Parametros: </b></p>
                    <p>
                       {{ver_log.model}}
                   </p>				
              </div> 
              <div class="modal-footer">
                   <button class="boton medium"(click)="modal.dismiss()">Aceptar</button>
              </div>            
         </div>
    </div>
</ng-template>

<!--Modal para pregunta confirmación-->
<ng-template #modal_confirmacion let-modal >
    <div class="modal-content">
         <div class="modal-content">
           <div class="modal-header">
           </div>
              <div class="modal-body">
               <p>
                    {{informacion}}
                    <input type="text" class="form-control mt-2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder="https://nexofy.acercasolutions.com:5959/api/updatefirmafy" [(ngModel)]="variablesDinamicas.urlwebhook">
                </p>
              </div> 
              <div class="modal-footer">
                   <button class="boton medium"(click)="activarNotificaciones()">Aceptar</button>
                   <button class="boton medium"(click)="modal.dismiss()">Cancelar</button>
              </div>
         </div>
    </div>
</ng-template>
<!--Modal avisos-->
<ng-template #modal_aviso let-modal>
    <div class="modal-content">
         <div class="modal-content">
              <div class="modal-header">
                   <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
              </div>

           <div class="modal-body aviso">
               <div class="icono-aviso">
                   <img src="../../assets/x (1).svg" *ngIf="!aviso_error" width="100%">
                   <img src="../../assets/correcto.svg" *ngIf="aviso_error" width="100%">
               </div>
               <div class="titulo">
                   <h4>{{this.mensaje_error}}</h4>
               </div>
               <div class="modal-footer">
                   <button class="boton medium"(click)="modal.dismiss()">Aceptar</button>
              </div>
           </div>
         </div>
    </div>
</ng-template>
<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal >
    <div class="modal-content">
         <div class="modal-content">
           <div class="modal-header">
               <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
           </div>
              <div class="modal-body">
               <p [innerHTML]="informacion"> </p>				
              </div> 
              <div class="modal-footer">
                   <button class="boton medium"(click)="modal.dismiss()">Aceptar</button>
              </div>            
         </div>
    </div>
</ng-template> 
<!--Modal para pregunta confirmación-->
<ng-template #modal_cambioPresidente let-modal >
    <div class="modal-content">
         <div class="modal-content">
           <div class="modal-header">
           </div>
              <div class="modal-body">
               <p>{{informacion}}</p>
               <div class="entradaModal">
                    <label for="nombreFGCISC">Nombre presidente FGCSIC:</label>
                    <input id="nombreFGCISC" type="text" class="form-control mt-2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" [(ngModel)]="variablesDinamicas.nombrePresidenteFGCSIC">
               </div>
               <div class="entradaModal">
                    <label for="dniFGCISC">NIF/DNI/NIE:</label>
                    <input id="dniFGCISC" type="text" class="form-control mt-2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" [(ngModel)]="variablesDinamicas.dniPresidenteFGCSIC">
                </div>
              </div> 
              <div class="modal-footer">
                   <button class="boton medium"(click)="actualizarConfiguracionDinamica()">Aceptar</button>
                   <button class="boton medium"(click)="modal.dismiss()">Cancelar</button>
              </div>
         </div>
    </div>
</ng-template>