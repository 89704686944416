<div class="cabecera-componente">
     <a class="boton-atras clicable" (click)="this.location.back()">
          <img src="../../assets/Icon feather-arrow-left-circle.svg">
     </a>
     <h1 class="titulo-componente">Ver perfil<img class="btn_informacion" src="../../assets/info.svg"
               (click)="abrirInfo()"></h1>
</div>
<div class="div-perfil" *ngIf="usuario_sesion.rol>2">
     <div class="cabecera">
          <div class="columna-usuario">
               <div class="foto">
                    <img src={{url_imagenes}}{{usuarioModelo.imagen}}>
               </div>
               <div class="nombre-perfil">
                    {{usuarioModelo.nombre}}
               </div>
          </div>
          <div class="label_tabla">{{usuarioModelo.cargo}}</div>
     </div>
     <div class="cuerpo">
          <div class="fila-botones ">
               <div class="botones-conectar ">
                    <button *ngIf="conectar || !existeConexionUsuario" class="boton secondary"
                         (click)="conectarConUsuario()">+ Conectar</button>
                    <!--Estos dos botones solo deben aparecer si tiene peticiones-->
                    <button class="boton noclicable"
                         *ngIf="existeConexionUsuario && peticionAmistadModelo?.id_receptor._id == usuarioModelo?._id && peticionAmistadModelo?.estado == 0"
                         disabled>Petición enviada...</button>
                    <button class="boton secondary"
                         *ngIf="existeConexionUsuario && peticionAmistadModelo?.id_emisor._id == usuarioModelo?._id && peticionAmistadModelo?.estado == 0"
                         (click)="aceptaPeticion()">+ Aceptar petición</button>
                    <button class="boton danger"
                         *ngIf="existeConexionUsuario && peticionAmistadModelo?.id_emisor._id == usuarioModelo._id && peticionAmistadModelo?.estado == 0"
                         (click)="rechazaPeticion()">X Rechazar</button>
                    <!--Estos dos botones solo deben aparecer si ya está en tu red de contactos-->
                    <div class="d-flex justify-content-between">
                         <button class="boton danger"
                              *ngIf="existeConexionUsuario && peticionAmistadModelo?.estado == 1"
                              (click)="eliminarContacto()">X Eliminar de mi red</button>
                         <button class="boton secondary"
                              *ngIf="existeConexionUsuario && peticionAmistadModelo?.estado == 1"
                              (click)="enviarMensaje()">Enviar un mensaje</button>
                    </div>
               </div>
               <div class="botones-redes">
                    <a class="boton secondary icono" *ngIf="usuarioModelo.linkedin!=''"
                         href="{{usuarioModelo.linkedin}}" target="_blank">
                         <img src="../../assets/linkedin.svg">
                    </a>
                    <a class="boton secondary icono" *ngIf="usuarioModelo.twitter!=''" href="{{usuarioModelo.twitter}}"
                         target="_blank">
                         <img src="../../assets/twitter.svg">
                    </a>
                    <a class="boton secondary icono" *ngIf="usuarioModelo.facebook!=''"
                         href="{{usuarioModelo.facebook}}" target="_blank">
                         <img src="../../assets/facebook.svg">
                    </a>
                    <a class="boton secondary icono" *ngIf="usuarioModelo.orcid!=''" href="{{usuarioModelo.orcid}}"
                         target="_blank">
                         <img src="../../assets/orcid.svg">
                    </a>
                    <a class="boton secondary icono" *ngIf="usuarioModelo.telefono!=''"
                         href="tel:{{usuarioModelo.telefono}}">
                         <img src="../../assets/phone-azul.svg">
                    </a>

               </div>
          </div>
          <p><b>Centro de investigación: </b><span>{{usuarioModelo.centro_investigacion}}</span></p>
          <p><b>Área científico-técnica: </b><span>{{usuarioModelo.area}}</span></p>
          <div class="titulo">Biografía</div>
          <div [innerHTML]="this.usuarioModelo.biografia"></div>
     </div>
</div>

<div class="div-perfil" *ngIf="usuario_sesion.rol<=2">
     <div class="cabecera">
          <div class="columna-usuario">
               <div class="foto">
                    <img src={{url_imagenes}}{{usuarioModelo.imagen}}>
               </div>
               <div class="nombre-perfil">
                    {{usuarioModelo.nombre}} {{usuarioModelo.apellidos}}
               </div>
          </div>
          <div class="label_tabla px-4">{{usuarioModelo.cargo}}</div>
     </div>
     <div class="cuerpo row ">
          <div class="fila-botones ">
               <div class="botones-conectar">
                    <button class="boton medium secondary" *ngIf="usuario_sesion.rol<=2"
                         (click)="editar()">Editar</button>

                    <button class="boton medium secondary" *ngIf="usuario_sesion.rol<=2 && usuarioModelo.validado == 0"
                         (click)="aprobar()">Aprobar</button>

                    <button class="boton medium danger secondary"
                         *ngIf="usuario_sesion.rol<=2 && usuarioModelo.validado == 1 && usuarioModelo.baneado==0"
                         (click)="banear()">Banear</button>
                    <button class="boton medium secondary" *ngIf="usuario_sesion.rol<=2 && usuarioModelo.baneado == 1"
                         (click)="desbanear()">Desbanear</button>

                    <button class="boton medium danger" *ngIf="usuario_sesion.rol<=2 && usuarioModelo.borrado==0"
                         (click)="borrar()">Eliminar</button>
                    <button class="boton medium secondary" *ngIf="usuario_sesion.rol<=2 && usuarioModelo.borrado==1"
                         (click)="reactivar()">Reactivar</button>
               </div>
          </div>
          <div class="row">
               <div class="col-xxl-6 col-10 offset-1 offset-xxl-0">
                    <div class="row">
                         <h4>Datos personales:</h4>

                         <div class="miniatura text-center mt-3">
                              <img src={{url_imagenes}}{{usuarioModelo.imagen}} class="alt-min">
                         </div>

                         <div class="col-lg-4 col-sm-6">
                              <label>Miembro del CSIC: </label>
                              <span *ngIf="usuarioModelo.miembro_csic==0 || usuarioModelo.miembro_csic==null"
                                   class="input-group-text border-0 bg-body-secondary">No</span>
                              <span *ngIf="usuarioModelo.miembro_csic==1"
                                   class="input-group-text border-0 bg-body-secondary">Sí</span>
                         </div>

                         <div *ngIf="usuarioModelo.miembro_csic==1" class="col-lg-8 col-sm-6">
                              <label>ID de CSIC: </label>
                              <span class="input-group-text bg-body-secondary">{{usuarioModelo.id_csic}}</span>
                         </div>

                         <div class="col-lg-4 col-sm-6">
                              <label>Rol: </label>
                              <span *ngIf="usuarioModelo.rol == 1"
                                   class="input-group-text border-0 bg-body-secondary ">Superadministrador</span>
                              <span *ngIf="usuarioModelo.rol == 2"
                                   class="input-group-text border-0 bg-body-secondary">Administrador</span>
                              <span *ngIf="usuarioModelo.rol == 3"
                                   class="input-group-text border-0 bg-body-secondary">Empresa</span>
                              <span *ngIf="usuarioModelo.rol == 4"
                                   class="input-group-text border-0 bg-body-secondary">Investigador</span>
                         </div>

                         <div class="col-lg-4 col-sm-6">
                              <label>NIF/NIE: </label>
                              <span class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.nif}}</span>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                              <label>Nombre:</label>
                              <span class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.nombre}}</span>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                              <label>Apellidos: </label>
                              <span
                                   class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.apellidos}}</span>
                         </div>

                         <div class="col-lg-9 col-sm-6">
                              <label>Email: </label>
                              <span class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.email}}</span>
                         </div>

                         <div class="col-lg-3 col-sm-6">
                              <label>Sexo: </label>
                              <span *ngIf="usuarioModelo.sexo == 0"
                                   class="input-group-text border-0 bg-body-secondary">Prefieron no decirlo</span>
                              <span *ngIf="usuarioModelo.sexo == 1"
                                   class="input-group-text border-0 bg-body-secondary">Hombre</span>
                              <span *ngIf="usuarioModelo.sexo == 2"
                                   class="input-group-text border-0 bg-body-secondary">Mujer</span>
                         </div>

                         <div class="col-lg-5 col-sm-6">
                              <label>Fecha nacimiento: </label>
                              <span class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.fecha_nacimiento
                                   | date: 'dd/MM/yyyy'}}</span>
                         </div>

                         <div class="col-lg-3 col-sm-6">
                              <label>Pais:</label>
                              <span class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.pais}}</span>
                         </div>

                         <div class="col-lg-4 col-sm-6">
                              <label>Provincia: </label>
                              <span
                                   class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.provincia}}</span>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                              <label>Población: </label>
                              <span
                                   class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.poblacion}}</span>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                              <label>Dirección: </label>
                              <span
                                   class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.direccion}}</span>
                         </div>
                         <div class="col-lg-3 col-sm-6">
                              <label>CP: </label>
                              <span class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.cp}}</span>
                         </div>

                         <div class="col-lg-4 col-sm-6">
                              <label>Tlf: </label>
                              <span
                                   class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.telefono}}</span>
                         </div>

                         <div class="col-lg-4 col-sm-6" *ngIf="usuarioModelo.centro_investigacion != ''">
                              <label>Centro de investigación: </label>
                              <span
                                   class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.centro_investigacion}}</span>
                         </div>

                         <div class="col-lg-7 col-sm-6" *ngIf="usuarioModelo.area != ''">
                              <label>Área científico-técnica: </label>
                              <span class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.area}}</span>
                         </div>

                         <div class="col-lg-7 col-sm-6" *ngIf="usuarioModelo.orcid != ''">
                              <label>ORCID: </label>
                              <span class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.orcid}}</span>
                         </div>

                         <div class="col-12" *ngIf="usuarioModelo.linkedin != ''">
                              <label>Linkedin: </label>
                              <span
                                   class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.linkedin}}</span>
                         </div>

                         <div class="col-lg-5 col-sm-6" *ngIf="usuarioModelo.facebook != ''">
                              <label>Facebook: </label>
                              <span
                                   class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.facebook}}</span>
                         </div>

                         <div class="col-lg-12 col-sm-6" *ngIf="usuarioModelo.twitter != ''">
                              <label>Twitter: </label>
                              <span class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.twitter}}</span>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                              <label>Fecha de registro: </label>
                              <span class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.cdate | date:
                                   'dd/MM/yyyy'}}</span>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                              <label>Última modificación: </label>
                              <span class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.mdate | date:
                                   'dd/MM/yyyy'}}</span>
                         </div>

                    </div>

               </div>
               <div class="col-xxl-6 col-10 offset-1 offset-xxl-0" *ngIf="empresaModelo">
                    <div class="row">
                         <h4>Datos de la empresa</h4>
                         <div class="miniatura text-center mt-3">
                              <img src="{{url_imagenes}}{{empresaModelo.imagen}}" class="alt-min">
                         </div>
                         <div class="col-lg-6 col-sm-6">
                              <label>Nombre:
                              </label><span
                                   class="input-group-text border-0 bg-body-secondary">{{empresaModelo.nombre}}</span>
                         </div>
                         <div class="col-lg-6 col-sm-6">
                              <label>CIF:
                              </label><span
                                   class="input-group-text border-0 bg-body-secondary">{{empresaModelo.cif}}</span>
                         </div>
                         <div class="col-lg-4 col-sm-6">
                              <label>Facturación anual:
                              </label><span
                                   class="input-group-text border-0 bg-body-secondary">{{empresaModelo.facturacion_anual}}</span>
                         </div>
                         <div class="col-lg-3 col-sm-6">
                              <label>Empleados:
                              </label><span
                                   class="input-group-text border-0 bg-body-secondary">{{empresaModelo.numero_empleados}}</span>
                         </div>
                         <div class="col-lg-5 col-sm-6">
                              <label>Pais:
                              </label><span
                                   class="input-group-text border-0 bg-body-secondary">{{empresaModelo.pais}}</span>
                         </div>
                         <div class="col-lg-3 col-sm-6">
                              <label>Provincia:
                              </label><span
                                   class="input-group-text border-0 bg-body-secondary">{{empresaModelo.provincia}}</span>
                         </div>
                         <div class="col-lg-4 col-sm-6">
                              <label>Población:
                              </label><span
                                   class="input-group-text border-0 bg-body-secondary">{{empresaModelo.poblacion}}</span>
                         </div>
                         <div class="col-lg-5 col-sm-6">
                              <label>Dirección:</label>
                              <span
                                   class="input-group-text border-0 bg-body-secondary">{{usuarioModelo.direccion}}</span>
                         </div>
                         <div class="col-lg-3 col-sm-6">
                              <label>CP:</label>
                              <span class="input-group-text border-0 bg-body-secondary">{{empresaModelo.cp}}</span>
                         </div>
                         <div class="col-lg-9 col-sm-6">
                              <label>Email:</label>
                              <span class="input-group-text border-0 bg-body-secondary">{{empresaModelo.email}}</span>
                         </div>
                         <div class="col-sm-12">
                              <label>Web:</label>
                              <span class="input-group-text border-0 bg-body-secondary">{{empresaModelo.web}}</span>
                         </div>
                         <div class="col-sm-12">
                              <label>Linkedin:</label>
                              <span
                                   class="input-group-text border-0 bg-body-secondary">{{empresaModelo.linkedin}}</span>
                         </div>
                         <div class="col-lg-12 col-sm-6">
                              <label>Facebook:</label>
                              <span
                                   class="input-group-text border-0 bg-body-secondary">{{empresaModelo.facebook}}</span>
                         </div>

                         <div class="col-lg-12 col-sm-6">
                              <label>Twitter:</label>
                              <span class="input-group-text border-0 bg-body-secondary">{{empresaModelo.twitter}}</span>
                         </div>
                         <div class="col-lg-6 col-sm-6">
                              <label>Fecha de registro:</label>
                              <span class="input-group-text border-0 bg-body-secondary">{{empresaModelo.cdate | date:
                                   'dd/MM/yyyy'}}</span>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                              <label>Última modificación:</label>
                              <span class="input-group-text border-0 bg-body-secondary">{{empresaModelo.mdate | date:
                                   'dd/MM/yyyy'}}</span>
                         </div>

                    </div>
               </div>
          </div>
          <div class="botones-redes">
               <a class="boton secondary icono" *ngIf="usuarioModelo.linkedin!=''" href="{{usuarioModelo.linkedin}}"
                    target="_blank">
                    <img src="../../assets/linkedin.svg">
               </a>
               <a class="boton secondary icono" *ngIf="usuarioModelo.twitter!=''" href="{{usuarioModelo.twitter}}"
                    target="_blank">
                    <img src="../../assets/twitter.svg">
               </a>
               <a class="boton secondary icono" *ngIf="usuarioModelo.facebook!=''" href="{{usuarioModelo.facebook}}"
                    target="_blank">
                    <img src="../../assets/facebook.svg">
               </a>
               <a class="boton secondary icono" *ngIf="usuarioModelo.orcid!=''" href="{{usuarioModelo.orcid}}"
                    target="_blank">
                    <img src="../../assets/orcid.svg">
               </a>
               <a class="boton secondary icono" *ngIf="usuarioModelo.telefono!=''"
                    href="tel:{{usuarioModelo.telefono}}">
                    <img src="../../assets/phone-azul.svg">
               </a>

          </div>
          <div class="titulo">Biografía</div>
          <div [innerHTML]="this.usuarioModelo.biografia"></div>
     </div>
</div>

<!--Modal para avisos-->
<ng-template #modal_aviso let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>

               <div class="modal-body aviso">
                    <div class="icono-aviso">
                         <img src="../../../assets/x (1).svg" *ngIf="aviso_error" width="100%">
                         <img src="../../../assets/correcto.svg" *ngIf="!aviso_error" width="100%">
                    </div>
                    <div class="titulo">
                         <h2>{{mensaje_error}}</h2>
                    </div>
               </div>
          </div>
     </div>
</ng-template>
<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
               <div class="modal-body">
                    <p>
                         {{informacion}}
                    </p>
               </div>
               <div class="modal-footer">
                    <button class="boton medium" (click)="modal.dismiss()">Aceptar</button>
               </div>
          </div>
     </div>
</ng-template>