import { Component, OnInit, } from '@angular/core';
import { RetosService } from '../retos/retos.service';
import { environment } from 'src/environments/environment';
import {  Router } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {


  public estado_boton: boolean = false;
	public lista_retos:any;
	public url_imagenes:string=environment.url_imagenes
	public year: number = new Date().getFullYear();
	public cookieAceptadas = false;

	constructor(
		public appComponentes: AppComponent,
		private router:Router,
		private _retosService:RetosService,

	) {

	 }

	ngOnInit(): void {
		let aletaPoliticas = localStorage.getItem('aletaPoliticas');
		if(aletaPoliticas == "true"){
			this.cookieAceptadas = true;
		}
		this.descargarRetos();

	}


	descargarRetos(){
		this._retosService.retosLanding().subscribe({
			next: (respuesta:any)=>{
				if(respuesta.error_code==1){
					this.lista_retos = respuesta.response.listado
				}
			}
		})
	}

	registro(){
		window.location.href='/?r=1';
	}
	inicio(){
		window.location.href='/login';
	}

	registro_empresa(){
		window.location.href='/?r=e';
	}

	registro_investigador(){
		window.location.href='/?r=i';
	}
	abrirPoliticas(){
		this.appComponentes.ver_politicas = true;
	}
	aceptarcookies(){
		console.log("Cookies Aceptadas");
		this.cookieAceptadas = true;
		localStorage.setItem('aletaPoliticas', 'true');
	}
	descargarInfo(ruta:string){
		const downloadLink = document.createElement("a");
		downloadLink.href = ruta;
		downloadLink.download = "Guia_basica_v5.pdf";
		downloadLink.click();
	}

	irAnuevaWeb(url: string){
		window.open(url, "_blank");
	}

}
