import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SolucionesService {
	public token:any;
	public url:any;

	constructor(
		public _http:HttpClient,
	) {
		this.url = environment.url_api;
	}

	listSoluciones(token:any){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*");
		return this._http.get(this.url+'listSoluciones', {headers: headers});
	}


	createSoluciones(soluciones:any, token:any){
		let params = JSON.stringify(soluciones);  
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url+'createSoluciones', params,{headers: headers});
	}
	
	readSoluciones(token:any, id:any){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.get(this.url+'readSoluciones/'+id, {headers: headers});
	}

	updateSoluciones(solucion: any, token: any) {
		let params = JSON.stringify(solucion);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'updateSoluciones', params, { headers: headers });
	}

	aprobarSoluciones(retos: any, token: any) {
		let params = JSON.stringify(retos);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'aprobarSoluciones', params, { headers: headers });
	}
	// Rechaza la solucion mediante el id de la solucion.
	rechazarSoluciones(retos: any, token: any) {
		let params = JSON.stringify(retos);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'rechazarSoluciones', params, { headers: headers });
	}
	//El siguiente metodo rechaza todas las soluciones que estan en estado 0 (Presentadas) de un reto
	rechazarTodasSoluciones(idRetos: string, token: any){
		let params = {"id_reto":idRetos};
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'rechazarTodasSoluciones', params, { headers: headers });
	}
	
	//subir ficheros
	subir_fichero(file:any,token:any) {
		let url_api = this.url+'uploadsolucion';
		//Hace la llamada AJAX para subir los archivos
		return new Promise(function(resolve,reject){
			if (file) {
				var formData:any = new FormData();
				var xhr = new XMLHttpRequest();
				formData.append('fichero',file);
				xhr.onreadystatechange = function(){
					if(xhr.readyState == 4){
						if(xhr.status == 200){
								resolve(JSON.parse(xhr.response));	
						}else{
								reject(xhr.response);
						}
					}
				}
				xhr.open('POST', url_api ,true);
				xhr.setRequestHeader('Authorization',token);
				xhr.send(formData);
			}else{
				reject("No hay fichero");
			}
		});
	}

	descargarSolucion(token:any, fichero:string){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*");
		this._http.get(this.url+'downloadsolucion/'+fichero, {headers: headers, responseType: 'blob' }).subscribe(data => {
			const a = document.createElement('a');
			a.href = URL.createObjectURL(data);
			a.download = fichero;
			a.click();
		});
	}

	presentarSoluciones(solucion: any, token: any) {
		let params = JSON.stringify(solucion);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'presentarSoluciones', params, { headers: headers });
	}
	//Registrar pago del premio.
	registrarPagoRegalo(solucion: any, token: any) {
		let params = JSON.stringify(solucion);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'registrarPagoRegalo', params, { headers: headers });
	}
}
