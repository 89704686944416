import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-condiciones',
  templateUrl: './condiciones.component.html',
  styleUrls: ['./condiciones.component.css']
})
export class CondicionesComponent implements OnInit {
  public url = environment.url;
  public verIconos: boolean = true;
  constructor(
    public appComponentes: AppComponent,
    private _router:ActivatedRoute,
    private router: Router,

  ) {
   }

  ngOnInit(): void {
    const currentRoute = this.router.url;
    if(currentRoute == "/" || currentRoute == "/condiciones"){
      this.verIconos = true;
    }else{
      this.verIconos = false;
    }
  }

  cerrar(){
    this.appComponentes.ver_condiciones = false;
  }
  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  irAnuevaWeb(url: string){
    window.open(url, "_blank");
  }
}
