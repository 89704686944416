import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { Empresas } from 'src/app/models/empresas';
import { Retos } from 'src/app/models/retos';
import { GlobalService } from 'src/app/services/global.services';
import { RetosService } from '../retos.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { htmlToText } from 'html-to-text';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { Opciones } from 'src/app/models/opciones'; 

@Component({
	selector: 'app-editar-reto',
	templateUrl: './editar-reto.component.html',
	styleUrls: ['./editar-reto.component.css']
})
export class EditarRetoComponent implements OnInit {
	public imageChangedEvent: any = '';
  	public croppedImage: any = '';
  	public retoModelo:any;
	public empresa_modelo:any;
	public usuario_sesion:any;
	public id:string="";
	public titulo:string="Crear reto";
	public informacion:string= "";
	public imagen:any;
	public imgActualizada:boolean = false;
	public opciones: any;
	
	public mensaje_error = "";
	public aviso_error=false;
	// Obtenemos fecha actual para evitar poner fecha inferior
	public fechaTmp =new Date(Date.now()); 
	public fechaHoy=new Date(new Date(this.fechaTmp.getTime() + (1000 * 60 * 60 * 24))); 

	public dia = ('0' + this.fechaHoy.getDate()).slice(-2);
	public mes = ('0' + (this.fechaHoy.getMonth() + 1)).slice(-2);
	public year = this.fechaHoy.getFullYear();
	public fechaActual = this.year+"-"+this.mes+"-"+this.dia;

	public caracteres_criterios_presupuesto:number=0;
	public max_caracteres_criterios_presupuesto:number=1000;

	public caracteres_descripcion:number=0;
	public max_caracteres_descripcion:number=500;

	public caracteres_detalles:number=0;
	public max_caracteres_detalles:number=2000;

	public url_imagenes:string=environment.url_imagenes
	public subiendo_imagen:boolean=false;

	public simboloPresupuesto = "+"

  	@ViewChild('modal_aviso') modal_aviso:any;
	@ViewChild('modal_informacion') modal_informacion:any;
	@ViewChild('modal_imagen') modal_imagen:any;
	@ViewChild('cropper', { static: true }) cropper:any;
	

	public files: NgxFileDropEntry[] = [];

	public imagenReto:string = "../../../assets/logo.png";

	//Control de errores de los formularios
	public camposObligatorios={
		'nombre':false,
		'sector':false,
		'area':false,
		'password':false,
		'tipologia':false,
		'fecha_fin':false,
		'descripcion':false,
		'criterios':false,
	};

	constructor(
		private _router:ActivatedRoute,
		private modalService: NgbModal,
		private _retosService:RetosService,
		private _empresasService:EmpresasService,
		private _globalService:GlobalService,
		public location:Location,
		private router:Router,
	) { 
		this.usuario_sesion = this._globalService.DatosSesion();
		this.retoModelo = new Retos('', '', '','', '', 0, '', '', new Date, 0, '', '', new Date, new Date, '', 0, 1, 0,'','Desafíos-Retos de innovación empresarial', 0, 1,'','',[],'');
		this.opciones = new Opciones();
	}

	ngOnInit(): void {
		this._router.params.subscribe( paramMap => {
			if(paramMap['id']!="create"){
				this.id = paramMap['id'];
				this.leer_reto();
			}else{
				this.retoModelo.fecha_fin = this.fechaActual;
				if (this.usuario_sesion.rol == 3){
					let id = this.usuario_sesion.id_empresa;
					this._empresasService.readEmpresas(this._globalService.getToken(), id).subscribe({
						next: (respuesta)=>{
							let res:any = respuesta;
							if(res.error_code == 1){
								if(res.response.listado.contrato != 1){
									this.location.back()
								}
							}
						}
					})
				}
			}			
		});	
	}

	muestraInfoPresupuesto(){
		if(this.simboloPresupuesto == "+"){
			this.simboloPresupuesto = "-"
		}else{
			this.simboloPresupuesto = "+"
		}
	}

	actualizarCaracteres(){
		let criterios = htmlToText(this.retoModelo.criterios_presupuesto, {
			singleNewLineParagraphs: true,
			ignoreImage: true,
		});
		this.caracteres_criterios_presupuesto = criterios.length;

		let descripcion = htmlToText(this.retoModelo.descripcion, {
			singleNewLineParagraphs: true,
			ignoreImage: true,
		});
		this.caracteres_descripcion = descripcion.length;

		let detalles = htmlToText(this.retoModelo.detalles, {
			singleNewLineParagraphs: true,
			ignoreImage: true,
		});
		this.caracteres_detalles = detalles.length;
	}

	leer_reto(){
		this._retosService.readRetos(this._globalService.getToken(), this.id).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				this.retoModelo = res.response.listado;
				this.titulo = this.retoModelo.nombre
				if(this.retoModelo.imagen_destacada != ""){
					this.imagenReto = this.url_imagenes+this.retoModelo.imagen_destacada
					if(this.retoModelo.criterios_presupuesto.length > 1){
						this.simboloPresupuesto = '-'
					}
					this.actualizarCaracteres();
				}
			}
		})
	} 

	guardar(){	
		let guardar = true;
		this.actualizarCaracteres();
		if(this.caracteres_detalles>this.max_caracteres_detalles
			|| this.caracteres_criterios_presupuesto>this.max_caracteres_criterios_presupuesto
			|| this.caracteres_descripcion>this.max_caracteres_descripcion){
				guardar=false;
		}

		if(this.retoModelo.nombre.length<3){
			this.camposObligatorios.nombre=true;
			guardar=false;
		}else{
			this.camposObligatorios.nombre=false;
		}

		if(this.retoModelo.sector.length<3){
			this.camposObligatorios.sector=true;
			guardar=false;
		}else{
			this.camposObligatorios.sector=false;
		}

		if(this.retoModelo.area.length<3){
			this.camposObligatorios.area=true;
			guardar=false;
		}else{
			this.camposObligatorios.area=false;
		}

		if(this.retoModelo.tipologia.length<3){
			this.camposObligatorios.tipologia=true;
			guardar=false;
		}else{
			this.camposObligatorios.tipologia=false;
		}

		if(this.retoModelo.fecha_fin=="" || this.retoModelo.fecha_fin==null || this.retoModelo.fecha_fin < this.fechaActual){
			this.camposObligatorios.fecha_fin=true;
			guardar=false;
		}else{
			this.camposObligatorios.fecha_fin=false;
		}

		if(this.retoModelo.descripcion.length<3){
			this.camposObligatorios.descripcion=true;
			guardar=false;
		}else{
			this.camposObligatorios.descripcion=false;
		}
		
		if(guardar){
			this.retoModelo.documentos = this.retoModelo.documentos.filter((documento:any)=> !documento.fichero.includes('error.error')) //quitamos los documentos que no se han subido correctamente
			if(this.id.length==0){
				this._retosService.createRetos(this.retoModelo, this._globalService.getToken()).subscribe({ 
					next: (respuesta) => {
						let res:any = respuesta;
						if(res.error_code==1){
							//Solo un usuario empresa puede crear un reto
							//Redireccionamos al componente retos
							this.informacionInicial();
							this.router.navigate(['/retos'])
						}else{  
							this.modalService.dismissAll(); //cerrar todos los popups
							this.aviso_error=false;
							this.mensaje_error=res.message;
							this.modalService.open(this.modal_aviso,{centered:true});
						}  
					},
					error: (e) => {
						console.log(e)
					}
				})
			}else{
				this._retosService.updateRetos(this.retoModelo, this._globalService.getToken()).subscribe({ 
					next: (respuesta) => {
						let res:any = respuesta;
						if(res.error_code==1){
							//Solo un Admin puede editar un reto
							//Redireccionamos a la pantalla anterior
							this.informacionInicial();
							this.location.back();					
						}else{  
							this.modalService.dismissAll(); //cerrar todos los popups
							this.aviso_error=false;
							this.mensaje_error=res.message;
							this.modalService.open(this.modal_aviso,{centered:true});
						}   
					},
					error: (e) => {
					}
				})
			}
		}else{
			//mostramos los errores
		}
				
	}
	
	cerrarModal(){
		//Si se guarda correctamente los cambios volvemosa mis retos, sino manetenemos en la ventana de crear reto.
		if (this.aviso_error){
			this.modalService.dismissAll()
			this.location.back()
		}else{
			this.modalService.dismissAll()
		}
	}

	buscarFichero(){
		document.getElementById("inputFichero")?.click();
	}

	subir_fichero(event:any){
		if(event.target.files.length>0){
			let documentos:Array<any> = this.retoModelo.documentos; 
			documentos.push({'nombre':event.target.files[0].name, fichero:''});
			this.retoModelo.documentos = documentos;

			this._globalService.subir_documento(event.target.files[0]).then((res:any)=> {
					//funcion promesa cumplida
					let respuesta;
					if(res.error_code==1){
						respuesta = res.response.fichero;
					}else{
						respuesta = 'error.error'; //añadimos este nombre de fichero para cambiar el icono que aparece
						this.modalService.dismissAll(); //cerrar todos los popups
						this.aviso_error=false;
						this.mensaje_error=res.message;
						this.modalService.open(this.modal_aviso,{centered:true});
					}
					for(let d=0;d<this.retoModelo.documentos.length;d++){
						if(this.retoModelo.documentos[d].nombre==event.target.files[0].name && this.retoModelo.documentos[d].fichero==""){
							this.retoModelo.documentos[d].fichero = respuesta
							break;
						}
					}
					
				},
				(e) => {}
			);
		}
	}

	borrar_fichero(fichero:string){
		this.retoModelo.documentos = this.retoModelo.documentos.filter((documento:any)=>documento.fichero!=fichero);
	}

	public dropped(files: NgxFileDropEntry[]) {
		this.files = files;
		if(files.length<=10){
			for (const droppedFile of files) {
				// Is it a file?
				if (droppedFile.fileEntry.isFile) {
					const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
					fileEntry.file((file: File) => {
	
						// Here you can access the real file
						/* console.log(droppedFile.relativePath, file); */
						let documentos:Array<any> = this.retoModelo.documentos; 
						documentos.push({'nombre':file.name, fichero:''});
						this.retoModelo.documentos = documentos;
	
						this._globalService.subir_documento(file).then((res:any)=> {
							//funcion promesa cumplida
							let respuesta;
							if(res.error_code==1){
								respuesta = res.response.fichero;
							}else{
								respuesta = 'error.error'; //añadimos este nombre de fichero para cambiar el icono que aparece
								this.modalService.dismissAll(); //cerrar todos los popups
								this.aviso_error=false;
								this.mensaje_error=res.message;
								this.modalService.open(this.modal_aviso,{centered:true});
							}
							for(let d=0;d<this.retoModelo.documentos.length;d++){
								if(this.retoModelo.documentos[d].nombre==file.name && this.retoModelo.documentos[d].fichero==""){
									this.retoModelo.documentos[d].fichero = respuesta;
									break;
								}
							}
							
						},
						(e) => {}
					);
	
	
					});
				} else {
					// It was a directory (empty directories are added, otherwise only files)
					const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
					/* console.log(droppedFile.relativePath, fileEntry); */
				}
			}
		}else{
			this.modalService.dismissAll(); //cerrar todos los popups
			this.aviso_error=false;
			this.mensaje_error="No se pueden subir más de 10 ficheros simultáneos";
			this.modalService.open(this.modal_aviso,{centered:true});
		}
	}

	buscar_imagen(){
		const inputElement = document.getElementById('inputImagen') as HTMLInputElement;
  		inputElement.value = '';
  		inputElement.click(); 
	}

	subir_imagen(event:any){
		if(event){
			this.subiendo_imagen=true;
			this._globalService.subir_imagen(event).then((res:any)=> {
					this.subiendo_imagen=false;
					//funcion promesa cumplida
					if(res.error_code==1){
						if(this.retoModelo.imagen_destacada != "" && this.retoModelo.imagen_destacada != null && this.retoModelo.imagen_destacada != undefined){
							this._globalService.eliminar_imagen(this.retoModelo.imagen_destacada).subscribe({ 
								next: (respuesta) => {
									let res = respuesta;
								}
							})
						}
						this.retoModelo.imagen_destacada=res.response.imagen;
						this.imagenReto = this.url_imagenes+this.retoModelo.imagen_destacada;
						this.guardar();
					}else{
						this.modalService.dismissAll(); //cerrar todos los popups
						this.aviso_error=false;
						this.mensaje_error=res.message;
						this.modalService.open(this.modal_aviso,{centered:true});
					}
				},
				(e) => {}
			);
		}
	}
	abrirInfo(op:number){
		if (op==1){
			this.informacion = "En la ventana crear retos puedes, editar y crear retos, pudiendo seleccionar el número de premio, importe de premio, los detalles, presupuesto, anonimizar datos de la empresa etc.";
		}else if (op == 2){
			this.informacion = "<p>En este apartado, seleccionando +,  podrá incluir los detalles y los distintos apartados del presupuesto, por ejemplo:</p><ul><li> Personal de Plantilla</li>"+
			"<li> Contratos laborales </li>"+ 
			"<li> Becas </li>"+
			"<li> Inventariable </li>"+
			"<li> Fungible </li>"+
			"<li> Viajes y dietas </li>"+
			"<li> Otros gastos </li>"+
			"<li> Colaboradores externos </li>"+
			"<li> Servicios externos (subcontrataciones) </li></ul>"+
			"<u><b>*NO SON OBLIGATORIOS</b> Los campos: presupuesto y detalles del presupuesto para publicar el reto.</u>"
		}else{
			this.informacion = "<p>En el caso de estar activa la opción podemos dar visibilidad o Anonimizar a la empresa:</p><ul>"+
			"<li> ANÓNIMA: se ocultar el logo y nombre a los investigadores mientras no tengan una solución aprobada. </li>"+ 
			"<li> VISIBLE: Muestra el logo y el nombre de la empresa durante todo el periodo de tiempo del reto. </li> </ul>";
		}
		
		this.modalService.open(this.modal_informacion,{centered:true});
	}
	informacionInicial(){
		if(this.usuario_sesion.rol == 3){
			this.informacion = "<h3>¡Enhorabuena por crear tu reto!</h3> Recuerda, para que tu reto se publique, es importante que selecciones la opción <strong><em>Publicar</em></strong>, Al hacerlo, confirmarás que tu reto está listo para compartir con la comunidad.";
		}else{
			this.informacion = "<h2>¡El reto ha sido actualizado correctamente!</h2>";
		}
		this.modalService.open(this.modal_informacion,{centered:true});
	}
	//Ponemos que como maximo sea la imagen de 800 X 400 y siempre de manera proporcionada:
	fileChangeEvent(event:any) {
		const file = event.target.files[0];
		const reader = new FileReader();
	  
		reader.onload = (e) => {
		  if (e.target && e.target.result) {
			const img = new Image();
	  
			img.onload = () => {
			  const canvas = document.createElement('canvas');
			  const MAX_WIDTH = 622;
			  const MAX_HEIGHT = 351;
			  let width = img.width;
			  let height = img.height;
			  
			  // Verificar si es necesario redimensionar el ancho o la altura
			  if (width > MAX_WIDTH || height > MAX_HEIGHT) {
				const scaleFactor = Math.min(MAX_WIDTH / width, MAX_HEIGHT / height);
				width *= scaleFactor;
				height *= scaleFactor;
			  } else {
				const scaleFactor = Math.max(MAX_WIDTH / width, MAX_HEIGHT / height);
				width *= scaleFactor;
				height *= scaleFactor;
			  }
			  
			  // Crear un nuevo canvas con las dimensiones redimensionadas
			  canvas.width = width;
			  canvas.height = height;
	
	  
			  const ctx = canvas.getContext('2d');
			  ctx!.drawImage(img, 0, 0, width, height);
	  
			  const dataUrl = canvas.toDataURL('image/png');
	  
			  // Asignar la imagen redimensionada a imageChangedEvent
			  this.imageChangedEvent = dataUrl;
	  
			  // Cerrar todos los popups
			  this.modalService.dismissAll();
	  
			  // Abrir el modal con la imagen redimensionada
			  this.modalService.open(this.modal_imagen, { size: 'lg', backdrop: 'static' });
			};
	  
			img.src = e.target.result as string; // Convertir a tipo 'string' utilizando 'as'
	  
		  }
		};
	  
		reader.readAsDataURL(file);
	  }
	  
	imageCropped(event: any) {
		this.imagen = event;
	}
	
	cargarImagen(){
		this.modalService.dismissAll();
		this.imagenReto = this.imagen.base64;
		this.imgActualizada = true;
	}

	async prepararImagenParaSubir() {
		if (this.imgActualizada) {
			const imageFile = this.dataURLtoFile(this.imagen.base64, 'imagen_recortada.png');
			this.subir_imagen(imageFile);
		}else{
			this.guardar();
		}
	}


	dataURLtoFile(dataURL: string, filename: string): File {
		const arr = dataURL.split(',');
		const mimeMatch = arr[0].match(/:(.*?);/);
		const mime = (mimeMatch && mimeMatch[1]) as string; // Utilizamos 'as string' para asegurarnos de que mime sea tratado como string
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
		  u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}
	//Cancelamos seleccion de imagen
	cancelarImagen(){
		this.modalService.dismissAll();
	}
	// Cambiar Anonimo
	cambiarAnonimo(){
		if(this.retoModelo.mostrarAnonimo){
			this.retoModelo.mostrarAnonimo = '0';
		}else{
			this.retoModelo.mostrarAnonimo = '1';
		}
	}

}
