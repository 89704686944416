import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../services/global.services';

@Injectable({
	providedIn: 'root'
})
export class LogService {
	public usuario_sesion: any;
	public token: any;
	public url: any;


	constructor(
		public _http: HttpClient,
        private _globalService:GlobalService
	) {
		this.url = environment.url_api;
        this.token = this._globalService.getToken();
	}

	listLogs(pagina:number=1, desde:any=null, hasta:any=null, error:number=0, query:string="", usuario:string="", quitar_ok:boolean=false,ascendete:boolean=false){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', this.token)
		.set("Accept", "*/*");
	  	return this._http.get(this.url+'listLogs?p='+pagina+'&e='+error+'&q='+query+'&u='+usuario+'&fi='+desde+'&ff='+hasta+'&ok='+quitar_ok+'&o='+ascendete, {headers: headers});
	}
	leerConfigDinamicas(){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', this.token)
		.set("Accept", "*/*");
		return this._http.get(this.url+'leerConfigDinamicas',{headers: headers});
	}
	actualizarConfiguracionDinamica(params:any){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', this.token)
		.set("Accept", "*/*");
		return this._http.post(this.url+'actualizarConfiguracionDinamica',params,{headers: headers});
	}
}
