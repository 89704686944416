
<div class="overlay" *ngIf="estado_sidebar"></div>

<div class="cabecera_movil">
     <div class="boton_sidebar clicable" (click)="cambiarSidebar()">
          <img src="../../assets/chevron-right-blanco.svg" *ngIf="estado_sidebar">
          <img src="../../assets/chevron-left-blanco.svg" *ngIf="!estado_sidebar">
          <span class="circulo-notificacion" *ngIf="notificaciones_totales > 0">{{notificaciones_totales}}</span>
     </div>
     <div class="logo">
          <img src="../../assets/logo.png" width="100px">
     </div>
     
</div>
<div class="sidebar" [ngClass]="{'active': estado_sidebar}">
     <div class="logo">
          <img src="../../assets/logo.png" width="70%">
     </div>
     
     <div class="boton_sidebar clicable" (click)="cambiarSidebar()">
          <img  *ngIf="estado_sidebar" src="../../assets/chevron-right-blanco.svg">
          <img  *ngIf="!estado_sidebar" src="../../assets/chevron-left-blanco.svg">
          <span class="circulo-notificacion" *ngIf="notificaciones_totales > 0 && !estado_sidebar">{{notificaciones_totales}}</span>
     </div>
     
     <div #bodyBar class="cuerpo-menu animate__animated">
          <div class="items">
               <a class="menu-item" [routerLink]="['/administracion']" routerLinkActive="active" *ngIf="usuario_sesion.rol<=2">
                    <img src="../../assets/folder-blanco.svg" class="icono">
                    <span class="txt">Administración</span>
                    <span class="circulo" *ngIf="notificaciones_administracion ==0"></span>
                    <span class="circulo-notificacion" *ngIf="notificaciones_administracion > 0">{{notificaciones_administracion}}</span>
               </a>
               <a class="menu-item" [routerLink]="['/home']" routerLinkActive="active" *ngIf="usuario_sesion.rol!=3">
                    <img src="../../assets/reto-blanco.svg" class="icono">
                    <span class="txt">Retos</span><span class="circulo"></span>
               </a>
               <a class="menu-item" [routerLink]="['/retos']" routerLinkActive="active" *ngIf="usuario_sesion.rol==3">
                    <!-- <img src="../../assets/home-blanco.svg" class="icono"> -->
                    <img src="../../assets/reto-blanco.svg" class="icono">
                    <span class="txt">Retos</span><span class="circulo"></span>
               </a>


               <a class="menu-item" [routerLink]="['/dashboard']" routerLinkActive="active" *ngIf="usuario_sesion.rol<=2">
                    <img src="../../assets/airplay-blanco.svg" class="icono">
                    <span class="txt">Dashboard</span><span class="circulo"></span>
               </a>
               <a class="menu-item" [routerLink]="['/perfil']" routerLinkActive="active">
                    <img src="../../assets/Icon feather-user.svg" class="icono">
                    <span class="txt">Mi perfil</span><span class="circulo"></span>
               </a>
               
               <a class="menu-item" [routerLink]="['/soluciones']" routerLinkActive="active" *ngIf="usuario_sesion.rol==4">
                    <img src="../../assets/star-blanco.svg" class="icono">
                    <span class="txt">Mis soluciones</span><span class="circulo"></span>
               </a>
               <!-- 
               <a class="menu-item" [routerLink]="['/usuarios']" routerLinkActive="active" (click)="cambiarSidebar()" *ngIf="usuario_sesion.rol>2">
                    <img src="../../assets/users-blanco.svg" class="icono">
                    <span class="txt">Mi red</span><span class="circulo"></span>
               </a>
               -->
               <a *ngIf="usuario_sesion.rol!=1" class="menu-item" [routerLink]="['/conversaciones']" routerLinkActive="active">
                    <img src="../../assets/message-blanco.svg" class="icono">
                    <span class="txt">Chat</span><span class="circulo" *ngIf="notificaciones_chat==0"></span>
                    <span class="circulo-notificacion" *ngIf="notificaciones_chat > 0">{{notificaciones_chat}}</span>
               </a> 
               <a class="menu-item" [routerLink]="['/soporte']" routerLinkActive="active" *ngIf="usuario_sesion.rol>2">
                    <img src="../../assets/settings-blanco.svg" class="icono">
                    <span class="txt">Soporte</span><span class="circulo"></span>
               </a>
               <a class="menu-item" [routerLink]="['/log']" routerLinkActive="active" *ngIf="usuario_sesion.rol==1">
                    <img src="../../assets/tool-blanco.svg" class="icono">
                    <span class="txt">Log</span>
               </a>
               <a class="menu-item" href="{{url_documentacion}}" target="_blank" *ngIf="usuario_sesion.rol==1">
               <img src="../../assets/book-blanco.svg" class="icono">
               <span class="txt">Documentación</span><span class="circulo"></span>
          </a>
          <a class="menu-item clicable"   (click)="logout()">
                    <img src="../../assets/Icon feather-log-out.svg" class="icono">
                    <span class="txt">Cerrar sesión</span>
               </a>
          </div>
          <div class="usuario">
              
               <img src={{url_imagenes}}{{usuario_sesion.imagen}}>
               <div class="texto">
                   
                    <p class="nombre">{{usuario_sesion.nombre}}</p>
                    <p class="rol"> 
                         <span *ngIf="usuario_sesion.rol == 1">SuperAdministrador</span>
                         <span *ngIf="usuario_sesion.rol == 2">Administrador</span>
                         <span *ngIf="usuario_sesion.rol == 3">Empresa</span>
                         <span *ngIf="usuario_sesion.rol == 4">Investigador/a</span>
                    </p>
               </div>
               <div class="version" *ngIf="usuario_sesion.rol<3">
                    <p>F: {{front_version}}</p>
                    <p>B: {{api_version}}</p>
               </div>
          </div>
     </div>
</div>
