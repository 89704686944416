import { Component, OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConversacionesService } from './conversaciones.services';
import { GlobalService } from '../services/global.services';
import { UsuariosService } from '../usuarios/usuarios.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { NotificacionesService } from '../notificaciones.service';
import { Usuarios } from '../models/usuarios';


@Component({
	selector: 'app-conversaciones',
	templateUrl: './conversaciones.component.html',
	styleUrls: ['./conversaciones.component.css']
})
export class ConversacionesComponent implements OnInit {
	public informacion:string = "";
	public listaConversaciones:any;
	public url_imagenes:string = environment.url_imagenes;
	public usuario_sesion:any;
	public nConvesacion:number = 0
	public loading = false;

	@ViewChild('modal_informacion') modal_informacion:any;
	@ViewChild('modal_aviso') modal_aviso:any;

	public aviso_error:boolean=false;
	public mensaje_error:string=""; 

	public pipe = new DatePipe('en-US');

	private userBot:Usuarios;

	constructor(
		private router:Router,
		private modalService: NgbModal,
		private _conversacionesService: ConversacionesService,
		private _globalService: GlobalService,
		public _usuariosService: UsuariosService,
		private _notificationService: NotificacionesService,
	) { 
		this.usuario_sesion = this._globalService.DatosSesion();
		this.descargarConversaciones();
		this.userBot = new Usuarios('',0,"ChatBot","", "Nexofy", "chatbot@nexofy.com", "", "", "chatbot.png", "Bot", "","","",new Date(), 0,"","","","","","","","","","","",0,1,0,"","","","",0,new Date(), new Date(),0)
	}

	ngOnInit(): void {
		this._notificationService.socket.on('conversaciones_'+this.usuario_sesion._id, (data:any)=>{
			if(data){
				this.descargarConversaciones();
			}
			
		})
	}

	ver_conversacion(id:string){
		this.router.navigate(['/conversaciones/'+id])
	}

	//Abrimos el modal donde aparece información de la pagina.
	abrirInfo(){
		this.informacion = "En la ventana chat, podemos conversar son el soporte técnico y tramitar ver las conversaciones con el Bot, ademas de acceder a las conversaciones activas."
		this.modalService.open(this.modal_informacion,{centered:true});
	}

	descargarConversaciones(){
		
		this._conversacionesService.listConversaciones(this._globalService.getToken()).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				if(res.error_code==1){
					this.listaConversaciones=res.response.listado;
					// Filtramos si es administrador para quitar las convesaciones que no son del usuario.
					if(this.usuario_sesion.rol < 3){
						this.listaConversaciones=this.listaConversaciones.filter((conversacion:any)=>conversacion.usuarios[0]==this.usuario_sesion._id || conversacion.usuarios[1]==this.usuario_sesion._id)
					}
					this.nConvesacion = this.listaConversaciones.length;
					for(let c=0;c<this.listaConversaciones.length;c++){
						let usuarios = this.listaConversaciones[c].usuarios;
						usuarios = usuarios.filter((usuario:any)=>usuario!=this.usuario_sesion._id)
						for(let u=0;u<usuarios.length;u++){
							if(usuarios[u]=="bot"){
								usuarios[u]=this.userBot
							}else{
								this._usuariosService.readUsuarios(this._globalService.getToken(), usuarios[u]).subscribe({
									next: (resp)=>{
										let r:any = resp;
										let user = r.response.listado;
										usuarios[u]=user
									}
								})
							}
							
						}
						this.listaConversaciones[c].usuarios=usuarios;
						
						this.socketConversacion(this.listaConversaciones[c]._id)
					}

					//Ordenamos las conversaciones por el último mensaje
					this.listaConversaciones.sort((a:any,b:any)=>{
						let fecha_ultimo_mensaje_a = new Date();
						let fecha_ultimo_mensaje_b = new Date();
						if(a.mensajes.length>0){
							fecha_ultimo_mensaje_a=a.mensajes[0].cdate;
						}
						if(b.mensajes.length>0){
							fecha_ultimo_mensaje_b=b.mensajes[0].cdate;
						}
						if(fecha_ultimo_mensaje_a>fecha_ultimo_mensaje_b){
							return -1;
						}else{
							return 0;
						}
					})

				}else if(res.error_code==10){
					this.nConvesacion = 0;
					this.loading = true;
				}else{
					this.loading = true;
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error=true;
					this.mensaje_error=res.message;
					this.modalService.open(this.modal_aviso,{centered:true});	
				}
			}
		})
	}

	//Función para suscribirse a las actualizaciones de la conversacion por socket
	socketConversacion(id:string){
		//nos suscribimos al socket de la conversación
		console.log("Conectando a socket: conversacion_nuevo_"+id)
		this._notificationService.socket.on('conversacion_nuevo_'+id, (data:any)=>{
			if(data.id_conversacion){
				
				//Buscamos la conversación para sustituir el útlimo mensaje
				for(let c=0;c<this.listaConversaciones.length;c++){
					if(this.listaConversaciones[c]._id==data.id_conversacion){
						this.listaConversaciones[c].mensajes = [data];
						this.listaConversaciones[c].pendientes = this.listaConversaciones[c].pendientes+1
						break;
					}
				}
			}
			
		})
	}

}
