export class Conversacion{
    constructor(
        public _id: string,
        public id_solucion:string,
        public usuarios: Array<any>,
        public mensajes: Array<any>,
        public cdate: Date,
        public mdate: Date,
        public borrado: string,
        public estado: number
    ){}
}