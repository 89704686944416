import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConversacionesService } from '../conversaciones.services';
import { GlobalService } from 'src/app/services/global.services';
import { ActivatedRoute } from '@angular/router';
import { Conversacion } from 'src/app/models/conversaciones';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { NotificacionesService } from 'src/app/notificaciones.service';
import { Usuarios } from 'src/app/models/usuarios';
import { environment } from 'src/environments/environment';
import { Seguidores } from 'src/app/models/seguidores';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

@Component({
	selector: 'app-ver-conversacion',
	templateUrl: './ver-conversacion.component.html',
	styleUrls: ['./ver-conversacion.component.css']
})
export class VerConversacionComponent implements OnInit {
	public informacion:string = "";
	public ConversacionModelo:Conversacion = new Conversacion('','', [], [], new Date(), new Date(), '0',0);
	public usuario_sesion:any;

	public mensaje:string="";

	public SeguidorModelo:any=[];

	@ViewChild('modal_informacion') modal_informacion:any;

	public pipe = new DatePipe('en-US');

	private userBot:Usuarios
	public url_imagenes:string = environment.url_imagenes


	public chatbot:boolean=false;

	private id_conversacion:string="";

	public aviso_error:boolean=false;
	public mensaje_error:string="";
	@ViewChild('modal_aviso') modal_aviso:any;

	public files: NgxFileDropEntry[] = [];

	/* @ViewChild('modal_bloquear') modal_bloquear:any; */
	constructor(
		private modalService: NgbModal,
		public location:Location,
		public _conversacionesService: ConversacionesService,
		public _globalService: GlobalService,
		private _router:ActivatedRoute,
		private _usuariosService:UsuariosService,
		private _notificationService:NotificacionesService
	) 
	{
		this.usuario_sesion=this._globalService.DatosSesion();
		this.userBot = new Usuarios('',0,"ChatBot","", "Nexofy", "chatbot@nexofy.com", "", "", "chatbot.png", "Bot", "","","",new Date(), 0,"","","","","","","","","","","",0,1,0,"","","","",0,new Date(), new Date(),0 )
		this.SeguidorModelo['estado']=0;
	}

	ngOnInit(): void {
		this._router.params.subscribe( paramMap => {
			
			this.id_conversacion=paramMap['id'];
			this.descargarConversacion();

			//nos suscribimos al socket de la conversación
			this._notificationService.socket.on('conversacion_'+this.id_conversacion, (data:any)=>{
				console.log("Recibido conversacion_"+this.id_conversacion, data)
				if(data._id){
					if(data.mensaje=="##solicitud_conexion##"){
						this.descargarConversacion();
					}else{
						let encontrado=false;

						for(let m=0;m<this.ConversacionModelo.mensajes.length;m++){
							//Si ya tenemos el mensaje lo actualizamos
							if(this.ConversacionModelo.mensajes[m]._id==data._id){
								encontrado=true;
								this.ConversacionModelo.mensajes[m]=data;
								break;
							}


							if(!encontrado && this.ConversacionModelo.mensajes[m].mensaje==data.mensaje){
								encontrado=true;
								this.ConversacionModelo.mensajes[m]=data;
								break;
							}
						}
	
						//si no tenemos el mensaje, lo añadimos
						if(!encontrado){
							this.ConversacionModelo.mensajes.push(data);
							this.scrollBottomMensajes();
							this._conversacionesService.leerMensaje(this._globalService.getToken(), data._id).subscribe({
								next: (resp)=>{
									this._notificationService.actualizarNotificaciones();
								}
							})
						}
					}
					
					
					
				}
				
			})
		})

		
	}

	ngAfterViewInit(){
		this.scrollBottomMensajes();
	}

	descargarConversacion(){
		this._conversacionesService.readConversaciones(this._globalService.getToken(), this.id_conversacion).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				if(res.error_code==1){
					this.ConversacionModelo = res.response.listado;
					let usuarios = this.ConversacionModelo.usuarios;
					//de los usuarios nos eliminamos nosotros
					usuarios = usuarios.filter((usuario:any)=>usuario!=this.usuario_sesion._id)

					if(this.usuario_sesion.rol < 3){
						this.chatbot = true;
					}
					//si el usuario es admin y está en la conversación es porque la conversación es de soporte
					if(this.ConversacionModelo.id_solucion == "SOPORTE" && this.usuario_sesion.rol == 2){
						this.ConversacionModelo.estado=1;
						this.chatbot = false;
					}
					if(this.ConversacionModelo.id_solucion == "BOT"){
						this.chatbot = true;
					}

					//descargamos la info de cada participante de la conversacion
					for(let u=0;u<usuarios.length;u++){
						if(usuarios[u]=='bot'){
							usuarios[u]=this.userBot
							this.chatbot=true;
						}else{
							
							this._usuariosService.readUsuarios(this._globalService.getToken(), usuarios[u]).subscribe({
								next: (resp)=>{
									let r:any = resp;
									
									let user = r.response.listado;
									usuarios[u]=user
								}
							})
						}							
					}
					this.ConversacionModelo.usuarios=usuarios;
					
					// Sacamos aviso de mensaje bloqueado del chat
					if(this.ConversacionModelo.estado != 1 && this.ConversacionModelo.id_solucion != "BOT" && this.usuario_sesion.rol > 2){
						this.abrirInfo();
					}
					
					this.scrollBottomMensajes();
					this.leerConversacion();
				}					
			}
		})
	}

	descargarConexion(id_usuario:string){
		console.log("Descargar conexion con "+id_usuario)
		this._usuariosService.readSeguidores(id_usuario, this._globalService.getToken()).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				if(res.error_code==1){
					this.SeguidorModelo = res.response.conexion;
				}else if(res.error_code==10){
					/*
					//si no se encuentra la relación comprobamos si el otro usuario es un admin, y la conversación es de soporte
					this._usuariosService.readUsuarios(this._globalService.getToken(), id_usuario).subscribe({
						next: (res:any)=>{
							if(res.error_code==1){
								if(res.response.listado.rol<3 || this.usuario_sesion.rol<3){
									this.SeguidorModelo.estado=1;
								}
							}
						}
					})
					*/
				}
			}
		});
	}

	//Abrimos el modal donde aparece información de la pagina.
	abrirInfo(){
		/* this.informacion = "<h2>INFORMACIÓN</h2>En la ventana chat se gestionan las conversaciones con el servicio técnico, los mensajes automáticos del Bot y conversaciones relacionadas con las soluciones y los retos. </br>Las restricciones que existen mientras una solución no está aceptada son: </br><ul><li>Las identidades de los usuarios son anónimas.</li> <li>Cualquier tipo de conversaciones será únicamente informativa referente a presentación, rechazo etc.</li></ul>" */
		this.informacion = "<h2>INFORMACIÓN</h2>En la ventana de chat se gestionan las conversaciones con el servicio técnico, los mensajes automáticos del Bot y las conversaciones relacionadas con la empresa, siempre y cuando la solución esté aprobada.</br> Mientras una solución no esté aprobada: </br><ul><li>Las identidades de los usuarios serán anónimas.</li> <li>Solamente aparecerán mensajes automatizados referentes a la presentación de la solución sin permitir una conversación entre las partes implicadas.</li></ul>"
		this.modalService.open(this.modal_informacion,{centered:true});
	}

	//crea un mensaje basado en la entrada del usuario y lo guarda en la base de datos
	enviarMensaje(){
		if(this.mensaje.length>0 && !this.chatbot){
			let creado = this.crearMensaje(this.mensaje)	
			this.ConversacionModelo.mensajes.push(creado)
			this.guardarMensaje(creado.mensaje, creado._id)			
		}		
	}

	//crea un mensaje solo en local
	crearMensaje(mensaje:string){

		let id_ficticio = 'nuevo_mensaje_'+this.ConversacionModelo.mensajes.length;
		let creado = {
			'_id': id_ficticio,
			'id_emisor': this.usuario_sesion._id,
			'mensaje':mensaje,
			'leidos': [],
			'estado': 1,
			'cdate': new Date()
		}
		return creado;	
	}

	//guarda el mensaje en la base de datos
	guardarMensaje(mensaje:string, id_ficticio:string){
		this._conversacionesService.enviarMensaje(this._globalService.getToken(), this.ConversacionModelo._id, mensaje).subscribe({
			next: (respuesta)=>{
				this.mensaje=""
				let res:any;
				res = respuesta;
				if(res.error_code==1){
					for(let m=0;m<this.ConversacionModelo.mensajes.length;m++){
						if(this.ConversacionModelo.mensajes[m]._id==id_ficticio){
							this.ConversacionModelo.mensajes[m].estado=0;
							break;
						}
					}
				}
			}
		})
		this.scrollBottomMensajes();
	}

	scrollBottomMensajes(){
		let div = document.getElementsByClassName("div-mensajes")[0];
		div.scrollTop = div.scrollHeight;
	}

	//Función para marcar como leidos todos los mensajes
	leerConversacion(){
		this._conversacionesService.leerConversacion(this._globalService.getToken(), this.ConversacionModelo._id).subscribe({
			next: (respuesta)=>{
				//Actualizamos las notificaciones 
				this._notificationService.actualizarNotificaciones();
			}
		});
	}

	aceptarSolicitudAmistad(id_emisor:string){
		this.SeguidorModelo.id_emisor = id_emisor;
		this._usuariosService.aceptarSeguidores(this.SeguidorModelo, this._globalService.getToken()).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				if(res.error_code==1){
					this.descargarConversacion();
				}
			}
		})
	}

	rechazarSolicitudAmistad(id_emisor:string){
		this.SeguidorModelo.id_emisor = id_emisor;
		this._usuariosService.rechazarSeguidores(this.SeguidorModelo, this._globalService.getToken()).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				if(res.error_code==1){
					this.descargarConversacion();
				}
			}
		})
	}

	buscarFichero(){
		document.getElementById("inputFichero")?.click();
	}

	subir_fichero(event:any){
		if(event.target.files.length>0){
			this.enviar_fichero(event.target.files[0])
		}
	}

	enviar_fichero(fichero:any){
		//creamos un mensaje solo en local
		let nombre_fichero=fichero.name;
		let mensaje = this.crearMensaje("##fichero_cargando##"+nombre_fichero);
		this.ConversacionModelo.mensajes.push(mensaje);
		this._globalService.subir_documento(fichero).then((res:any)=> {
				//funcion promesa cumplida
				let respuesta;
				if(res.error_code==1){
					//si se ha subido correctamente guardamos el mensaje en la base de datos
					respuesta = res.response.fichero;
					mensaje.mensaje = "##fichero_subido##"+respuesta;
					this.guardarMensaje(mensaje.mensaje, mensaje._id)
				}else{
					for(let m=0;m<this.ConversacionModelo.mensajes.length;m++){
						if(this.ConversacionModelo.mensajes[m]._id==mensaje._id){
							this.ConversacionModelo.mensajes[m].mensaje="##fichero_erroneo##"+res.message;
							break;
						}
					}
				}				
			},
			(e) => {}
		);
	}

	borrar_fichero(fichero:string){
		
	}

	public dropped(files: NgxFileDropEntry[]) {
		this.files = files;
		if(files.length<=10){
			for (const droppedFile of files) {
				// Is it a file?
				if (droppedFile.fileEntry.isFile) {
					const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
					fileEntry.file((file: File) => {
						console.log("Fichero: "+file.name)
						this.enviar_fichero(file);
					});
				} else {
					// It was a directory (empty directories are added, otherwise only files)
					const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
					console.log(droppedFile.relativePath, fileEntry);
				}
			}
		}else{
			this.modalService.dismissAll(); //cerrar todos los popups
			this.aviso_error=false;
			this.mensaje_error="No se pueden subir más de 10 ficheros simultáneos";
			this.modalService.open(this.modal_aviso,{centered:true});
		}
	}


	descargar_documento(url:string){
		this._globalService.descargar_documento(url);
	}

	//Funcion para bloquear o desbloquear conversacion por parte del administrador:
	desbloqueoBloqueo(tipo:number){
		let conversacion:any = this.ConversacionModelo;
		conversacion.estado = tipo;
		conversacion._id = this.id_conversacion
		this._conversacionesService.desbloqueoBloqueo(conversacion, this._globalService.getToken()).subscribe({
			next: (respuesta)=>{
				let res: any = respuesta;
				if(res.error_code != 1){
					if(tipo == 0){
						conversacion.estado = 1
					}else{
						conversacion.estado = 0
					}
				}
			}
		})
	}

	/* confirmarBloqueo(){
		this.modalService.dismissAll(); //cerrar todos los popups
		this.modalService.open(this.modal_bloquear,{centered:true});
	}

	bloquear(){
		this._usuariosService.deleteSeguidores(this.SeguidorModelo, this._globalService.getToken()).subscribe({
			next:(res:any) =>{
				if(res.error_code==1){
					this.SeguidorModelo.estado=2;
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error=true;
					this.mensaje_error="Este usuario ha sido bloqueado";
					this.modalService.open(this.modal_aviso,{centered:true});
				}else{
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error=false;
					this.mensaje_error=res.message;
					this.modalService.open(this.modal_aviso,{centered:true});
				}
			},
		})
	} */

}
