<div class="politicasBody">
    <header class=" row | mb-xl-5 | position-relative">
            <article class="row | ms-xl-2 mt-xl-5 mb-xl-5 mt-sm-3 mb-sm-3 justify-content-xl-start justify-content-center">
                <img class=" logo main-icon" src="../../assets/logo.png">
            </article>
    </header>

    <div class="tituloPrincipal">
        <!-- <img class="atras me-3 img-fluid | clicable"  src="../../assets/Icon feather-arrow-left-circle.svg" (click)="cerrar()"> --> POLÍTICA DE PRIVACIDAD, TRATAMIENTO DE DATOS Y COOKIES.
    </div>
    
    <article class="seccion">
        <div class="tituloSecundario">
            1.	Política de Privacidad.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                El Sitio Web a través de esta Política de Privacidad sobre el tratamiento y protección de los datos de carácter personal de los usuarios que puedan ser recabados mediante su navegación o contratación de servicios que realicen en este portal. El uso de esta web por parte del usuario conlleva la aceptación por su parte de esta Política de Privacidad. Todas aquellas obligaciones del gestor de la plataforma que no estén expresamente incluidas y mencionadas en este documento, quedarán expresamente excluidas, así como cualquier obligación o garantía relacionada con las mismas.
            </div>
            <div class="parrafo">
                FGCSIC adopta las medidas necesarias para garantizar la seguridad, integridad y confidencialidad de los datos conforme a lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos, y en aquello no previsto por éste por la Ley Orgánica 3/2018 de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica de Protección de Datos, y la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico.
            </div>
            <div class="parrafo">
                Mediante los formularios de registro del presente sitio web o el envío de correo electrónico se recogen y tratan datos personales de cada usuario que son necesarios para la gestión y mantenimiento de algunos de los servicios prestados y cuyo tratamiento se rige por la presente Política de Privacidad.
            </div>
            <div class="parrafo">
                <u>Responsable del tratamiento de los datos personales.</u>
            </div>
            <div class="parrafo">
                El responsable del tratamiento de los datos personales que el usuario facilite al acceder y utilizar el sitio web, es, en calidad de propietario, __FUNDACIÓN GENERAL CSIC__ , con NIF/CIF G-85562106, según escritura nº 649 del protocolo de la notaría de Dña. Isabel Griffo Navarro, de fecha 2 de abril de 2009 e inscrito en el Protectorado de Fundaciones del Ministerio de Educación con fecha 23 de enero de 2009, con el número de registro 1104, con domicilio social en la Calle Joaquín Costa, 12, 28002 Madrid, teléfono _917 815 999___ y correo electrónico __info@fgcsic.es_ .
            </div>
            <div class="parrafo">
                FGCSIC se compromete a aplicar las medidas de seguridad informática previstas en la normativa citada previamente, con el objetivo de impedir el acceso o uso indebido de los datos, su manipulación, deterioro o pérdida.
            </div>
            <div class="parrafo">
                <u>DURACIÓN DEL ALMACENAMIENTO DE LOS DATOS</u>
            </div>
            <div class="parrafo">
                FGCSIC, como responsable del tratamiento, se compromete a almacenar y gestionar los datos personales e información recogida mediante esta página web con la debida confidencialidad mientras se mantenga la relación profesional surgida y la persona interesada no solicite su supresión, y en su caso, por un plazo no superior a 5 años, a contar desde la finalización de la relación comercial establecida entre el usuario y FGCSIC. No obstante, el responsable del tratamiento podrá conservar, debidamente bloqueados, los datos para atender posibles responsabilidades administrativas o jurisdiccionales.
            </div>
            <div class="parrafo">
            <u>Finalidades del tratamiento de datos.</u>
            </div>
            <div class="parrafo">
                Las operaciones, gestiones y procedimientos técnicos, ya sean realizados de forma automatizada como no automatizada, que posibiliten la recogida, almacenamiento, modificación, transferencia y otras acciones sobre datos de carácter personal, tienen la consideración de tratamiento de datos personales.
            </div>
            <div class="parrafo">
                En FGCSIC tratamos los datos personales que el usuario nos facilita a través del Sitio Web con el fin de incluirlos en la agenda de contactos, gestionar la relación comercial solicitada y utilizar así esta información como medio de contacto de dicha empresa. Además, el tratamiento de estos datos recabados también implica gestionar el envío de la información que nos sea solicitada y poder responder a las consultas o cuestiones que el usuario remita a través del sitio web, facilitando a su vez a los interesados las ofertas de servicios que sean de su interés.
            </div>
            <div class="parrafo">
                Los campos de los registros se deben cumplimentar obligatoriamente para que FGCSIC pueda cumplir con las finalidades mencionadas con anterioridad. En otro caso, no se aceptará responsabilidad alguna relacionadas con malfuncionamientos del sistema.
            </div>
            <div class="parrafo">
                El usuario puede oponerse al envío de comunicaciones comerciales en cualquier momento mediante el envío de un mensaje de correo electrónico a la dirección indicada supra líneas.
            </div>
            <div class="parrafo">
                <u>Legitimación para el tratamiento de los datos.</u>
            </div>
            <div class="parrafo">
                El tratamiento de los datos personales que proporciona el usuario se realiza en base a las siguientes bases jurídicas que legitiman el mismo:
            </div>
            <div class="parrafo">
                La contratación de servicios del Sitio Web cuyos términos y condiciones se pondrán a disposición del usuario de forma previa a una eventual contratación. Para poder llevar a cabo esta relación profesional solicitada, el interesado está obligado a facilitar sus datos.
            </div>
            <div class="parrafo">
                El consentimiento libre, específico, informado e inequívoco, ya que tras la lectura de la presente Política de Privacidad el usuario queda informado y, en caso de estar conforme, puede aceptarla mediante una declaración o una clara acción afirmativa, como el marcado de una casilla que se dispone al efecto.
            </div>
            <div class="parrafo">
                En el caso de que el interesado no facilite los citados datos o éstos sean erróneos o inexactos, no podremos atender su solicitud, resultando imposible proporcionarle la información solicitada o llevar a cabo la contratación de los servicios. Por tanto, el responsable del tratamiento quedará exonerado de toda responsabilidad que pudiera derivarse de la no ejecución del encargo profesional o de las consecuencias que se deriven de estas informaciones inexactas o erróneas.
            </div>
            <div class="parrafo">
                <u>Comunicación de datos.</u>
            </div>
            <div class="parrafo">
                Con carácter general FGCSIC no comunicará estos datos personales a terceros, con la salvedad de que la prestación de un servicio implique la necesidad de una relación contractual con un encargado de tratamiento y ello sea estrictamente necesario para gestionar y mantener la relación entre el usuario y la mencionada empresa, previa autorización expresa por parte del usuario. Esto se hará sólo durante el tiempo imprescindible para posibilitar la ejecución del contrato de encargo, y en las mismas condiciones y con las mismas responsabilidades que se le exigen al responsable. Una vez finalizado el encargo, el encargado del tratamiento devolverá al Responsable los datos personales y suprimirá cualquier copia de la que disponga.
            </div>
            <div class="parrafo">
                Por otra parte, sólo tendrán derecho a acceder a estos datos personales los terceros con los que FGCSIC tenga una obligación legal o contractual de facilitarlos, entre los que se incluyen, por ejemplo, el Defensor del Pueblo y Jueces y Tribunales interesados en los procedimientos relacionados con las reclamaciones presentadas.
            </div>
            <div class="parrafo">
                <u>Derechos de los interesados.</u>
            </div>
            <div class="parrafo">
                El usuario podrá ejercitar en todo momento, en los términos establecidos en la legislación vigente, los derechos de acceso, rectificación o supresión de datos, solicitar que se limite el tratamiento, oponerse al mismo, solicitar la portabilidad de sus datos, así como revocar el consentimiento prestado, derechos reconocidos en el citado Reglamento (UE). El ejercicio de estos derechos puede realizarlo el propio usuario dirigiéndose a la FGCSIC en la dirección de su sede social indicada anteriormente; a través del envío de un correo electrónico a la siguiente dirección info@nexofy.es o a la dirección postal calle Joaquín Costa, 12 – 28002 Madrid.
            </div>
            <div class="parrafo">
                Para el efectivo ejercicio de estos derechos, el usuario deberá acreditar su identidad facilitando su nombre y apellidos, fotocopia del DNI o documento identificativo equivalente que acredite su identidad, petición en la que se concrete la solicitud, dirección a efectos de notificaciones, y fecha y firma del solicitante.
            </div>
            <div class="parrafo">
                Asimismo, el usuario podrá ponerse en contacto con el Delegado de protección de datos (DPO o DPD) a través de la dirección ‘hsr2058@colegiados.icagijon.es’ y/o reclamar ante la Agencia Española de Protección de Datos (Autoridad de Control competente en esta materia), especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, mediante escrito dirigido a misma, C/Jorge Juan, nº 6, 28001 - Madrid, o a través de la web: https://www.agpd.es
            </div>
        </div>
    </article>

    <article class="seccion">
        <div class="tituloSecundario">
            2.	Tratamiento de los datos.
        </div>
        <div class="parrafoPrincipal">
            El Considerando (82) del RGPD se refiere a este registro señalando que "para demostrar la conformidad con el presente Reglamento, el responsable o el encargado del tratamiento debe mantener registros de las actividades de tratamiento bajo su responsabilidad. Todos los responsables y encargados están obligados a cooperar con la autoridad de control y a poner a su disposición, previa solicitud, dichos registros, de modo que puedan servir para supervisar las operaciones de tratamiento".

            El RGPD, que se refiere a esta medida expresamente en su artículo 30, señala que los responsables y los encargados están obligados (en determinados supuestos), a mantener un registro de las actividades de tratamiento que realicen.

            Es por ello, que la FGCSIC ha elaborado el siguiente Registro de actividades de tratamiento  :

            <ul>
                <li>A Base jurídica.
                  <ul>
                    <li>Art. 6.1 a del Reglamento 679/2016. El interesado ha dado su consentimiento para el tratamiento de sus datos personales para uno o varios fines específicos.</li>
                    <li>Art. 6.1 b del Reglamento 679/2016. El tratamiento es necesario para la ejecución de un contrato en el que el interesado es parte o para la aplicación a petición de este de medidas precontractuales;</li>
                    <li>Art. 6.1 c del Reglamento 679/2016. El tratamiento es necesario para el cumplimiento de una obligación legal aplicable al responsable del tratamiento.</li>
                    <li>Art. 6.1 f del Reglamento 679/2016. El tratamiento es necesario para la satisfacción de intereses legítimos perseguidos por el responsable del tratamiento o por un tercero.</li>
                  </ul>
                </li>
                <li>B Fines del tratamiento
                  <ul> Gestión de los datos de los usuarios accesible a través del nombre de dominio https://www.nexofy.es y sus subdominios (en adelante Sitio Web).</ul>
                </li>
                <li>C Colectivo.
                  <ul>Personas físicas y jurídicas.</ul>
                </li>
                <li>D Categorías de Datos.
                    <ul>Datos identificativos: nombre, apellidos, teléfono, cuenta bancaria, correo electrónico, formación y profesión.</ul>
                </li>
                <li>E Categoría destinatarios.
                    <ul>
                        Podrán acceder a tus datos personales, en calidad de encargados del tratamiento de la FGCSIC, sus proveedores de servicios de apoyo técnico y de producto.
                    </ul>
                </li>

                <li>F Transferencia Internacional.
                    <ul>
                        Se deja constancia de que no se prevé la realización alguna de transferencias internacionales de datos personales.
                    </ul>
                </li>
                <li>G Plazo supresión.
                    <ul>
                        Se conservarán durante el tiempo necesario para cumplir con la finalidad para la que se recabaron y en su caso, por un plazo no superior a 5 años, y para determinar las posibles responsabilidades que se pudieran derivar de dicha finalidad y del tratamiento de los datos. Será de aplicación lo dispuesto en la normativa de archivos y documentación.
                    </ul>
                </li>
                <li>H Medidas de seguridad.
                    <ul>
                        Las previstas en el RGPD y puestas en marcha por FGCSIC.
                    </ul>
                </li>
                <li>I Entidad responsable.
                    <ul>
                        FGCSIC – Calle Joaquín Costa, 12. Madrid. 28022.
                    </ul>
                </li>
              </ul>

           

            <u>Esquema del Registro de Actividades de Tratamiento :</u>
            <div class="table-responsive mt-2">
                <table class="table align-middle table align-middle table-fixed table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Tratamiento</th>
                            <th scope="col">Finalidad/es del tratamiento</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Uso plataforma Nexofy</td>
                            <td>Tratamiento de datos personales de los/las Usuarios de la plataforma Nexofy*.</td>
                        </tr>
                        <tr>
                            <th scope="row" colspan="2">* <u>Tratamiento Uso plataforma Nexofy </u></th>
                        </tr>
                        <tr>
                            <td>Entidad responsable:</td>
                            <td>FGCSIC</td>
                        </tr>
                        <tr>
                            <td>Delegado de protección de datos (DPO):</td>
                            <td>hsr2058@colegiados.icagijon.es</td>
                        </tr>
                        <tr>
                            <td>Finalidad del tratamiento:</td>
                            <td class="align-middle">Tratamiento de datos personales de los/las Usuarios de la plataforma Nexofy y uso de la citada <br>
                                plataforma con la finalidad de gestionar la inscripción y la participación, así como remitir información relacionada con la misma.</td>
                        </tr>
                        <tr>
                            <td>Base jurídica legitimadora:</td>
                            <td>Artículo Art. 6.1 letras a, b, c y f del Reglamento 679/2016.</td>
                        </tr>
                        <tr>
                            <td> Descripción de las categorías:</td>
                            <td>Nombre y apellidos, DNI/NIF, dirección, firma, teléfono, correo electrónico, formación y profesión,
                                cuenta bancaria.</td>
                        </tr>
                        <tr>
                            <td>Destinatarios: </td>
                            <td>FGCSIC, sus proveedores de servicios de apoyo técnico y de producto.</td>
                        </tr>
                        <tr>
                            <td>Transferencias internaciones de datos:</td>
                            <td>No están previstas.</td>
                        </tr>
                        <tr>
                            <td>Plazo de supresión: </td>
                            <td>Los datos proporcionados se conservarán durante el tiempo necesario para cumplir con la finalidad <br>
                                para la que se obtuvieron. Los datos serán conservados en tanto no se solicite su supresión y en su caso, por un plazo no superior a 5 años.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </article>

    <article class="parrafo">
        <div class="tituloSecundario">
            3. Cookies.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                Nuestro sitio web https://www.nexofy.es utiliza una tecnología denominada “cookies” con la finalidad de poder recabar información acerca del uso que realiza el usuario de la página web con el objetivo de analizar sus hábitos de navegación para así facilitarle la misma y poder distinguirlo de otros usuarios.
            </div>
            <div class="parrafo">
                En cumplimiento con lo previsto en el artículo 22.2 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, la presente Política de Cookies tiene como finalidad informar al usuario sobre la política de recogida y tratamiento de cookies.
            </div>
            <div class="parrafo">
                ¿Qué son las cookies?
            </div>
            <div class="parrafo">
                Las cookies son pequeños archivos que contienen una serie de caracteres (texto) que se envían a su explorador desde el servidor de un sitio web. La cookie puede tener un identificador único pero no contiene información de identificación personal, como su nombre o dirección de correo electrónico. FGCSIC puede usar cookies cuando usted visita su sitio web o visita otros sitios web donde FGCSIC publicita. El explorador de Internet del usuario de esta página web almacena la cookie en el disco duro de su equipo informático y el sitio web puede acceder a ella durante su próxima visita. Otros sitios web también pueden enviar cookies a su explorador pero éste no permitirá que esos sitios web vean la información de la cookie del Sitio Web.
            </div>
            <div class="parrafo">
                Cookies utilizadas en este sitio web:
            </div>
            <div class="parrafo">
                Por una parte, este sitio web utiliza cookies propias técnicas para las cuales no se precisa la obtención del consentimiento del usuario, ya que están excluidas del ámbito de aplicación del art. 22.2 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico.
            </div>
            <div class="parrafo">
                Sin embargo, este sitio web también tiene instaladas cookies que requieren el consentimiento de los usuarios. A continuación, siguiendo las directrices de la Agencia Española de Protección de Datos, procedemos a detallar el uso de cookies mediante su identificación y explicando su tipología y función, con el fin de informarle con la máxima exactitud posible.
            </div>
            <div class="parrafo">
                <ul>
                    - Este sitio web utiliza las siguientes cookies propias : localStorage
                </ul>
                <ul>
                    - Este sitio web utiliza las siguientes cookies de terceros: Google Analytics
                </ul>
            </div>
            <div class="parrafo">
                -LocalStorage: 
                <ul>LocalStorage es una API de almacenamiento de datos en el navegador web que permite almacenar y recuperar datos localmente en el dispositivo del usuario. localStorage y las cookies comparten ciertas similitudes en términos de almacenamiento de datos en el navegador, tienen diferencias clave en cuanto a su alcance, uso y propósito. Mientras que las cookies se utilizan principalmente para rastrear la actividad del usuario en línea y personalizar la experiencia del usuario en el sitio web, localStorage se utiliza para almacenar datos específicos del sitio web y mejorar el rendimiento de la aplicación web. Actualmente nexofy utiliza LocalStorage para almacenar información de sesión del usuario y aceptación de términos.</ul>
                -Google Analytics utiliza una variedad de cookies para recopilar información sobre cómo los usuarios interactúan con un sitio web. Las cookies utilizadas por Google Analytics incluyen:
                <ul>
                    _ga: Esta cookie se utiliza para distinguir a los usuarios únicos al asignar un número generado aleatoriamente como identificador de cliente. Se utiliza para calcular los datos de análisis, como el número de visitantes únicos a un sitio con una duración de 2 años.
                </ul>
                <ul>
                    _gid: Esta cookie se utiliza para distinguir a los usuarios únicos al asignar un número generado aleatoriamente como identificador de sesión. Se utiliza para calcular los datos de análisis, como el número de visitas a una página y tiene una duración de 24 horas.
                </ul>
                <ul>
                    _gat: Esta cookie se utiliza para limitar la velocidad de las solicitudes enviadas al servidor de Google Analytics, lo que ayuda a reducir el tráfico en los sitios web de alto tráfico y tiene una duración de 1 minuto.
                </ul>
                <ul>
                    gac: Esta cookie se utiliza para almacenar información de campañas en Google Analytics. Cuando los visitantes llegan a un sitio web a través de una campaña de marketing, la información de la campaña se almacena en esta cookie y tiene una duración de 90 días.
                </ul>
            </div>
            <div class="parrafo">
                Desactivación o eliminación de cookies
            </div>
            <div class="parrafo">
                No recomendados que restrinja o bloquee cookies, ya que esto puede afectar al funcionamiento y calidad de nuestros servicios y sitios web y, por tanto, su experiencia puede resultar menos satisfactoria. Sin embargo, en cualquier momento, el usuario podrá ejercer su derecho de desactivación o eliminación de cookies de este sitio web, bloqueando o deshabilitando las mismas al modificar la configuración de las opciones del navegador instalado en su ordenador, el cual le permitirá rechazar la instalación de todas o algunas de las cookies.
            </div>
            <div class="parrafo">
                Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar es donde el usuario debe ejercitar su derecho a eliminar o desactivar las mismas. Por tanto, el Sitio Web no puede garantizar la correcta manipulación de las cookies por parte de los navegadores a los que hemos hecho mención.
            </div>
            <div class="parrafo">
                A continuación, le proporcionamos información sobre cómo gestionar el uso de cookies dependiendo del navegador que utilice, ya que estas acciones se realizan de forma distinta en función del navegador que el usuario esté usando.
            </div>
            <div class="parrafo">
                <h4>
                    Listado de Navegadores y Ayuda para Cookies
                </h4>
                <ul>
                    <li>
                        <h5>Google Chrome</h5>
                        <a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer">Ayuda sobre cookies en Google chrome</a>
                    </li>
                    <li>
                        <h5>Microsoft Edge</h5>
                        <a href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-y-administrar-cookies-en-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4" target="_blank" rel="noopener noreferrer">Ayuda sobre cookies en Edge</a>
                    </li>
                    <li>
                        <h5>Mozilla Firefox</h5>
                        <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank" rel="noopener noreferrer"> Ayuda sobre cookies en Mozilla</a>
                    </li>
                    <li>
                        <h5>Safari (Apple)</h5>
                        <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Ayuda sobre cookies en Safari</a>
                    </li>
                </ul>
            </div>
            <div class="parrafo">
                Cambios en la política de cookies
            </div>
            <div class="parrafo">
                Es posible que la presente Política de Cookies sufra modificaciones en un futuro, por ello es aconsejable que cada vez que el usuario acceda a esta página web, consulte nuevamente esta política con el objetivo de estar informado adecuadamente sobre las actualizaciones que se han producido.
            </div>
            <div class="parrafo">
                Contacto
            </div>
            <div class="parrafo">
                Si tiene cualquier duda, comentario o sugerencia sobre nuestra Política de Cookies, no dude en ponerse en contacto con nosotros a través de la siguiente dirección de correo electrónico info@nexofy.es
            </div>
        </div>
    </article>

    <article class="seccion">
        <div class="tituloSecundario">
            4.	Solución de conflictos.
        </div>
        <div class="parrafoPrincipal">
            Si no está usted de acuerdo con estas la presente Política de Privacidad y cookies, por favor, absténgase de visitar o utilizar el presente Sitio Web y las Aplicaciones. Cualquier aspecto relacionado con la presente Política de Privacidad y cookies, 
            y en caso de integración de documentos o cláusulas o en defecto de previsión por parte de estos términos, aplicarán de forma preferente las condiciones generales extensas, por encima de cualquier otro contrato o norma dispositiva del ordenamiento, 
            ante cualquier foro competente y ley aplicable, tal como están recogidas en nuestra página web y accesibles para los usuarios. En su defecto, aplicará la ley española y las partes se someten a la jurisdicción civil ordinaria ante los tribunales de Madrid, España,
             que serán los únicos competentes, renunciando las partes a cualesquiera otros foros, jurisdicciones o leyes que pudieran resultar aplicables; sin perjuicio de que en aquellos supuestos en que el usuario ostente la consideración de consumidor, 
             la FGCSIC se someterá a la jurisdicción de los tribunales que resulten competentes según la legislación vigente en cada momento.
        </div>
    </article>
    <footer>
        <img class="atras me-3 img-fluid | clicable"  src="../../assets/Icon feather-arrow-left-circle.svg" (click)="cerrar()">
    </footer>
</div>

