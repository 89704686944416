import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from './services/global.services';
import io from 'socket.io-client'

@Injectable({
	providedIn: 'root'
})
export class NotificacionesService {
    public socket:any;
    private usuario_sesion: any;

	public nuevas_actualizaciones:boolean=false;

	constructor(
		private _globalService:GlobalService,
	) {
        const token = this._globalService.getToken();
		this.usuario_sesion = this._globalService.DatosSesion();
		console.log("Conectando socket a "+environment.url_socket);
		this.socket = io(environment.url_socket,{
			extraHeaders: {
				'Authorization': token
			  }
		});
        this.socket.on('notification', (data:any)=>{
			console.log("Hay nuevas actualizaciones(servicio): ",data)
			if(data.id_usuario==this.usuario_sesion?._id || data.rol==this.usuario_sesion.rol){
				this.nuevas_actualizaciones=true;
				this.actualizarNotificaciones();
			}
		})
        this.actualizarNotificaciones()
		

	}

    actualizarNotificaciones(){
		console.log("Actualizando notificaciones");
		this.socket.emit('actualizar_notificacion');
	}
}