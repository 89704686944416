
export class Usuarios {
    constructor(
        public _id: string,
        public rol: number,
        public nombre: string,
        public nif: string,
        public apellidos: string,
        public email:string,
        public password: string,
        public biografia: string,
        public imagen: string,
        public cargo: string,
        public url_imagen: string,
        public repetir_password: string,
        public password_hash: string,
        public fecha_nacimiento: Date,
        public sexo: number,
        public pais: string,
        public provincia: string,
        public poblacion: string,
        public direccion: string,
        public cp: string,
        public telefono: string,
        public web: string,
        public facebook: string,
        public linkedin: string,
        public twitter: string,
        public id_empresa: Object,
        public baneado: number,
        public validado: number,
        public miembro_csic:number,
        public id_csic:string,
        public centro_investigacion:string,
        public orcid:string,
        public area:string,
        public personalidad:number,
        public cdate: Date,
        public mdate: Date,
        public borrado: number        
    ){}
}