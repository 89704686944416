<div *ngIf="identificado; then aplicativo else login"></div>

<ng-template #login>
	<div class="cuerpo-login" *ngIf="!ver_landing && !ver_politicas && !ver_condiciones">
		<div class="columna-color">
			<img src="../assets/Mobile testing-pana.svg">
		</div>
		<div class="columna-form">
			<img (click)="abrirLanding()" src="../assets/logo.png" class="clicable logo" title="Inicio NEXOFY">
			<div class="formulario">
				<ul class="nav nav-tabs">
					<li>
						<button [ngClass]="{'active':ver_login}" data-bs-toggle="tab" data-bs-target="#login"
							type="button" (click)="ver_login=true">Iniciar sesión</button>
					</li>
					<li>
						<button [ngClass]="{'active':!ver_login}" data-bs-toggle="tab" data-bs-target="#registro"
							type="button" (click)="empezar_registro()">Registrarse</button>
					</li>
				</ul>
				<div class="tab-content">
					<div class="tab-pane fade" [ngClass]="{'show active':ver_login}" id="login">
						<div class="formulario_login" *ngIf="!recuperar_clave && !resetear_clave">
							<form #formLogin="ngForm" (ngSubmit)="onSubmitLogin()">
								<div class="form-group">
									<label>Email</label>
									<input type="email" class="form-control" placeholder="Email" name="email"
										#email="ngModel" [(ngModel)]="usuarioModelo.email" required>
								</div>
								<div class="form-group">
									<label>Contraseña</label>
									<input type="password" class="form-control" placeholder="Contraseña"
										name="contraseña" #contraseña="ngModel" [(ngModel)]="usuarioModelo.password"
										required>
								</div>
								<button class="boton-envio" type="submit" [disabled]="!formLogin.form.valid">Iniciar
									sesión</button>
							</form>
							<div class="alerta_formulario" *ngIf="errorMessage.length>0">{{errorMessage}}</div>
							<div class="aviso_formulario" *ngIf="avisoMessage.length>0">{{avisoMessage}}</div>
							<a class="enlace_recuperar" (click)="recuperar_clave=true">¿Has olvidado la contraseña?</a>
							<br>
							<a class="enlace_recuperar" (click)="abrirCorreo()" alt="info@nexofy.es">¿Tienes
								problemas para
								registrarte?</a>
						</div>

						<div class="formulario_recuperacion" *ngIf="recuperar_clave">
							<div class="form-group">
								<label>Email</label>
								<input type="email" class="form-control" placeholder="Email" name="email"
									#email="ngModel" [(ngModel)]="usuarioModelo.email" required>
							</div>
							<button class="boton-envio" type="submit" (click)="recuperarClave()"
								*ngIf="!enviando_email">Recuperar contraseña</button>
							<div *ngIf="enviando_email">
								<p>Enviando email...</p>
							</div>

							<a class="enlace_recuperar" (click)="recuperar_clave=false">Atrás</a>
							<div class="alerta_formulario" *ngIf="errorMessage.length>0">{{errorMessage}}</div>
							<div class="aviso_formulario" *ngIf="avisoMessage.length>0">{{avisoMessage}}</div>
						</div>

						<div class="formulario_recuperacion" *ngIf="resetear_clave">
							<div class="form-group">
								<label>Nueva contraseña</label>
								<input type="password" class="form-control" placeholder="Tu nueva contraseña"
									name="nueva_password" [(ngModel)]="nueva_password">
							</div>

							<div class="form-group">
								<label>Repite tu nueva contraseña</label>
								<input type="password" class="form-control" placeholder="Tu nueva contraseña"
									name="nueva_password_verificacion" [(ngModel)]="nueva_password_verificacion">
							</div>
							<button class="boton-envio" type="submit" (click)="restablecerPassword()">Guardar
								contraseña</button>
							<a class="enlace_recuperar" (click)="resetear_clave=false">Atrás</a>
							<div class="alerta_formulario" *ngIf="errorMessage.length>0">{{errorMessage}}</div>
							<div class="aviso_formulario" *ngIf="avisoMessage.length>0">{{avisoMessage}}</div>
						</div>
					</div>

					<div class="tab-pane fade" [ngClass]="{'show active':!ver_login}" id="registro">
						<div class="seccion-registro" [ngClass]="{'active': tipo_registro == ''}">
							<h2>¿Cómo quieres registrarte?</h2>
							<button class="boton" (click)="tipo_registro='investigador'">Personal investigador</button>
							<button class="boton" (click)="tipo_registro='empresa'">Empresa</button>
						</div>

						<div class="seccion-registro" [ngClass]="{'active': tipo_registro !== ''}">

							<div class="datos-personales seccion-formulario"
								[ngClass]="{'active': contador_formulario==1}">
								<h2>Tus datos</h2>
								<div *ngIf="tipo_registro=='investigador'">
									<label>Tipo persona*</label>
									<select class="form-select" placeholder="Personalidad"
										[(ngModel)]="usuarioModelo.personalidad">
										<option value="0">Fisica</option>
										<option value="1">Juridica</option>
									</select>
								</div>
								<label>Nombre*</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="Nombre"
										[(ngModel)]="usuarioModelo.nombre">
									<p *ngIf="camposObligatorios.nombre" class="alerta_formulario">El nombre es
										obligatorio</p>
									<p *ngIf="erroresFormulario.nombreCorto" class="alerta_formulario">El nombre debe
										ser de más de 3 caracteres</p>
								</div>

								<label>Apellidos*</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="Apellidos"
										[(ngModel)]="usuarioModelo.apellidos">
									<p *ngIf="camposObligatorios.apellidos" class="alerta_formulario">El apellido es
										obligatorio</p>
									<p *ngIf="erroresFormulario.apellidosCorto" class="alerta_formulario">El apellido
										debe ser de más de 3 caracteres</p>
								</div>

								<label>NIF/NIE*</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="NIF/NIE"
										[(ngModel)]="usuarioModelo.nif" maxlength="11">
									<p *ngIf="camposObligatorios.nif" class="alerta_formulario">El NIF/NIE es
										obligatorio</p>
									<p *ngIf="erroresFormulario.nifCorto" class="alerta_formulario">Formato de NIF/NIE
										Incorrecto</p>
								</div>

								<label>Email*</label>
								<div class="input-group">
									<input (keyup)="verificarEmail()" class="form-control" type="email"
										placeholder="Email" [(ngModel)]="usuarioModelo.email">
									<p *ngIf="erroresFormulario.emailIncorrecto" class="alerta_formulario">
										{{alertaEmail}} </p>
								</div>

								<label>Contraseña*</label>
								<div class="input-group">
									<input class="form-control" type="password" placeholder="Contraseña de acceso"
										[(ngModel)]="usuarioModelo.password">
									<p *ngIf="camposObligatorios.password" class="alerta_formulario">La contraseña es
										obligatoria</p>
								</div>

								<label>Repite la contraseña*</label>
								<div class="input-group">
									<input class="form-control" type="password" placeholder="Contraseña de acceso"
										[(ngModel)]="usuarioModelo.repetir_password">
									<p *ngIf="erroresFormulario.passwordConfirmacion" class="alerta_formulario">La
										contraseña no coincide</p>
								</div>

								<label>Fecha de nacimiento*</label>
								<div class="input-group">
									<input class="form-control" type="date" placeholder="Fecha de nacimiento"
										[(ngModel)]="usuarioModelo.fecha_nacimiento">
									<p *ngIf="camposObligatorios.fecha_nacimiento" class="alerta_formulario">La fecha de
										nacimiento es obligatoria</p>
									<p *ngIf="erroresFormulario.edadMinima" class="alerta_formulario">Obligatorio tener
										cumplida la mayoría de edad.</p>
								</div>

								<label>Sexo*</label>
								<div class="input-group">
									<select class="form-select" placeholder="Sexo" [(ngModel)]="usuarioModelo.sexo">
										<option value="0">Prefiero no decirlo</option>
										<option value="1">Hombre</option>
										<option value="2">Mujer</option>
									</select>
									<p *ngIf="camposObligatorios.sexo" class="alerta_formulario">Este campo es
										obligatorio</p>
								</div>

								<div *ngIf="tipo_registro=='investigador'">
									<label>Centro de investigación</label>
									<div class="input-group">
										<input class="form-control" type="text" placeholder="Tu centro de investigación"
											[(ngModel)]="usuarioModelo.centro_investigacion">
									</div>

									<label>Área científico-técnica</label>
									<div class="input-group">
										<select class="form-select" placeholder="Área" [(ngModel)]="usuarioModelo.area">
											<option>Matemáticas, Físicas y Tecnologías</option>
											<option>Químicas y Tecnologías</option>
											<option>Ciencia y Tecnología de Materiales</option>
											<option>Ciencia y Tecnología de Alimentos</option>
											<option>Ciencias Agrarias</option>
											<option>Biología Celular y Molecular</option>
											<option>Biomedicina</option>
											<option>Ciencias de la Naturaleza y Recursos Naturales</option>
											<option>Humanidades y Ciencias Sociales</option>
										</select>
									</div>
								</div>


								<label>Cargo*</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="Cargo"
										[(ngModel)]="usuarioModelo.cargo">
									<p *ngIf="camposObligatorios.cargo" class="alerta_formulario">Este campo es
										obligatorio</p>
								</div>
								<div class="form-check">
									<input class="form-check-input check-formulario" type="checkbox"
										[(ngModel)]="checkAvisoFormulario">
									<p class="form-check-label" for="flexCheckDefault">
										<!-- Acepto los <span [routerLink]="['/condiciones']" routerLinkActive="active" (click)="abrirCondiciones()" class="terminos">términos y condiciones de uso</span> -->
										Acepto los <span (click)="abrirCondiciones()" class="terminos">términos y
											condiciones de uso</span>
									</p>
									<p *ngIf="camposObligatorios.terminos" class="alerta_formulario">Debes aceptar los
										términos y condiciones</p>
								</div>
							</div>

							<div class="datos-contacto seccion-formulario"
								[ngClass]="{'active':contador_formulario==2}">
								<h2>Datos de contacto</h2>
								<label>Teléfono móvil*</label>
								<div class="input-group">
									<span class="icono">+34</span><input class="form-control" type="number"
										placeholder="Teléfono" [(ngModel)]="usuarioModelo.telefono"
										(keydown)="verificarCaracteres($event)">
									<p *ngIf="camposObligatorios.telefono" class="alerta_formulario">El teléfono es
										obligatorio</p>
									<p *ngIf="erroresFormulario.telefonoIncorrecto" class="alerta_formulario">El formato
										de teléfono movil es incorrecto</p>
								</div>

								<label>País*</label>
								<div class="input-group">
									<select class="form-select" placeholder="País" (change)="actualizar_estados()"
										[(ngModel)]="usuarioModelo.pais">
										<option *ngFor="let pais of lista_paises">{{pais.name}}</option>
									</select>
									<p *ngIf="camposObligatorios.pais" class="alerta_formulario">El pais es obligatorio
									</p>
								</div>

								<label>Provincia</label>
								<div class="input-group">
									<select class="form-select" placeholder="Provincia"
										[(ngModel)]="usuarioModelo.provincia">
										<option *ngFor="let estado of lista_estados">{{estado.name}}</option>
									</select>
									<p *ngIf="camposObligatorios.provincia" class="alerta_formulario">La provincia es
										obligatoria</p>
								</div>

								<label>Población*</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="Población"
										[(ngModel)]="usuarioModelo.poblacion">
									<p *ngIf="camposObligatorios.poblacion" class="alerta_formulario">La población es
										obligatorio</p>
								</div>

								<label>Dirección</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="Dirección"
										[(ngModel)]="usuarioModelo.direccion">
								</div>

								<label>CP</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="CP"
										[(ngModel)]="usuarioModelo.cp" maxlength="10">
								</div>
							</div>

							<div class="datos-redes seccion-formulario" [ngClass]="{'active':contador_formulario==3}">
								<h2>Tus redes sociales</h2>
								<div *ngIf="tipo_registro=='investigador'">
									<label>ORCID</label>
									<div class="input-group">
										<input class="form-control" type="text" placeholder="ORCID"
											[(ngModel)]="usuarioModelo.orcid">
									</div>
								</div>
								<label>Linkedin</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="LinkedIn"
										[(ngModel)]="usuarioModelo.linkedin">
								</div>

								<label>Facebook</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="Facebook"
										[(ngModel)]="usuarioModelo.facebook">
								</div>

								<label>Twitter</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="Twitter"
										[(ngModel)]="usuarioModelo.twitter">
								</div>
							</div>

							<div class="datos-empresa seccion-formulario"
								[ngClass]="{'active': contador_formulario==4}">
								<h2>Datos básicos de tu empresa</h2>
								<label>Nombre*</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="Nombre"
										[(ngModel)]="empresaModelo.nombre">
									<p *ngIf="camposObligatorios.nombre_empresa" class="alerta_formulario">El nombre es
										obligatorio</p>
									<p *ngIf="erroresFormulario.nombreEmpresaCorto" class="alerta_formulario">El nombre
										debe ser de más de 3 caracteres</p>
								</div>
								<label>CIF*</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="CIF"
										[(ngModel)]="empresaModelo.cif">
									<p *ngIf="camposObligatorios.cif_empresa" class="alerta_formulario">{{ alertaCIF }}
									</p>
								</div>
								<label>Sector*</label>
								<div class="input-group">
									<select class="form-select" placeholder="Sector" [(ngModel)]="empresaModelo.sector">
										<option>IT y/o medios de comunicación</option>
										<option>Sanidad</option>
										<option>Agricultura</option>
										<option>Alimentación y bebidas</option>
										<option>Transporte y logística</option>
										<option>Industria y automoción</option>
										<option>Construcción</option>
										<option>Energía</option>
										<option>Servicios y Banca</option>
										<option>Otros</option>
									</select>
									<p *ngIf="camposObligatorios.sector_empresa" class="alerta_formulario">El sector es
										obligatorio</p>
								</div>
								<label>Email</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="email"
										[(ngModel)]="empresaModelo.email">
									<p *ngIf="erroresFormulario.emailEmpresaIncorrecto" class="alerta_formulario">El
										formato de email es incorrecto</p>
								</div>
								<label>Web</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="web"
										[(ngModel)]="empresaModelo.web">
								</div>
								<label>Número de empleados/as</label>
								<div class="input-group">
									<input class="form-control" type="text"
										oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
										[(ngModel)]="empresaModelo.numero_empleados">
								</div>

								<label>Facturación anual</label>
								<div class="input-group">
									<input class="form-control" type="text"
										oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
										[(ngModel)]="empresaModelo.facturacion_anual">
									<span class="input-group-texto">€</span>
								</div>
							</div>

							<div class="datos-contacto seccion-formulario"
								[ngClass]="{'active':contador_formulario==5}">
								<h2>Datos de contacto de tu empresa</h2>
								<label>País*</label>
								<div class="input-group">
									<select class="form-select" placeholder="País"
										(change)="actualizar_estados_empresa()" [(ngModel)]="empresaModelo.pais">
										<option *ngFor="let pais of lista_paises; let indice = index">{{pais.name}}
										</option>
									</select>
									<p *ngIf="camposObligatorios.pais_empresa" class="alerta_formulario">El pais es
										obligatorio</p>
								</div>
								<label>Provincia</label>
								<div class="input-group">
									<select class="form-select" placeholder="Provincia"
										[(ngModel)]="empresaModelo.provincia">
										<option *ngFor="let estado of lista_estados_empresa; let indice = index">
											{{estado.name}}</option>
									</select>
								</div>
								<label>Población</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="Población"
										[(ngModel)]="empresaModelo.poblacion">
								</div>
								<label>Dirección</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="Dirección"
										[(ngModel)]="empresaModelo.direccion">
								</div>
								<label>CP</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="CP"
										[(ngModel)]="empresaModelo.cp" maxlength="10">
								</div>
							</div>

							<div class="datos-redes seccion-formulario" [ngClass]="{'active':contador_formulario==6}">
								<h2>Redes sociales de tu empresa</h2>
								<label>Facebook</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="Facebook"
										[(ngModel)]="empresaModelo.facebook">
								</div>
								<label>LinkedIn</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="LinkedIn"
										[(ngModel)]="empresaModelo.linkedin">
								</div>
								<label>Twitter</label>
								<div class="input-group">
									<input class="form-control" type="text" placeholder="Twitter"
										[(ngModel)]="empresaModelo.twitter">
								</div>
							</div>

							<button class="boton" (click)="siguiente()">{{boton_siguiente}}</button>
							<button class="boton" (click)="atras_formulario()">Atrás</button>
							<a class="enlace_recuperar" href="#" (click)="abrirCorreo()" alt="info@nexofy.es">¿Tienes
								problemas para
								registrarte?</a>

						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
	<!--Ver landing. -->
	<div *ngIf="ver_landing && !ver_politicas && !ver_condiciones">
		<app-landing></app-landing>
	</div>
	<!--Ver politicas de terminos y cookies.-->
	<div *ngIf="ver_politicas">
		<app-politicas></app-politicas>
	</div>
	<!--Ver condiciones de uso.-->
	<div *ngIf="ver_condiciones">
		<app-condiciones></app-condiciones>
	</div>
	<div class="pieBajo">
		<p class="piePoliticas"><u class="clicable" (click)="irAnuevaWeb(url+'politicas')"> Política de privacidad,
				tratamiento de datos y cookies</u> - <u class="clicable"
				(click)="irAnuevaWeb(url+'condiciones')">Términos y condiciones de uso</u> - <u
				class="clicable">información: info&#64;nexofy.es</u></p>
	</div>

	<!-- <footer *ngIf="!cookieAceptadas && testWeb" class="privacidad">
    <div class="parrafoPoliticas">
        <p>NEXOFY {{year}}&copy; utiliza cookies propias y de terceros para obtener datos estadísticos de la navegación que realizan los usuarios y así poder recopilar información que permita optimizar su visita a las páginas web. Las cookies no serán utilizadas para recabar datos de carácter personal. Si acepta o continúa navegando, se entiende que acepta su uso. Puede optar por rechazar las cookies cuando lo desee, ya sea cambiando la configuración del explorador (si su explorador lo permite), dejando de usar este sitio web o usando las funciones de cancelación correspondientes. Para obtener más información, puede acceder a <u (click)="abrirPoliticas()"><span class="clicable">politicas de privacidad y cookies</span></u></p>
    </div>
    <div class="btnPoliticas">
        <button class="boton secondary medium" (click)="aceptarcookies(1)"> Aceptar </button>
		<button class="btn btn-outline-danger medium btnRechazar" (click)="aceptarcookies(2)"> Rechazar </button>
    </div>
</footer> -->
	<footer *ngIf="!cookieAceptadas" class="privacidad">
		<div class="tituloPoliticas">
			<h4>Consentimiento de cookies</h4>
		</div>
		<div class="cuerpoPoliticas">
			<div class="parrafoPoliticas">
				<p>Para ofrecer una mejor experiencia, utilizamos tecnologías como las cookies para almacenar y/o
					acceder a la información del dispositivo. El consentimiento de estas tecnologías nos permitirá
					procesar datos como el comportamiento de navegación o las identificaciones únicas en este sitio. No
					consentir o retirar el consentimiento, puede afectar negativamente a ciertas características y
					funciones.</p>
			</div>
			<div class="btnPoliticas">
				<button class="boton secondary medium" (click)="aceptarcookies(1)"> Aceptar </button>
				<button class="btn btn-outline-danger medium btnRechazar" (click)="aceptarcookies(2)"> No permitir
				</button>
				<button class="btn btn-outline-info medium btnPreferencias" (click)="aceptarcookies(3)"> Preferencias
				</button>
			</div>
		</div>
		<!-- TODO: El comentado abre la politica en la misma ventana
	<p class="piePoliticas"><u class="clicable" (click)="abrirPoliticas()">Política de privacidad, tratamiento de datos y cookies</u> - <u class="clicable" (click)="abrirCondicionesWeb()">Términos y condiciones de uso</u></p> -->
		<p class="piePoliticas"><u class="clicable" (click)="irAnuevaWeb(url+'politicas')">Política de privacidad,
				tratamiento de datos y cookies</u> - <u class="clicable"
				(click)="irAnuevaWeb(url+'condiciones')">Términos y condiciones de uso</u></p>
	</footer>

</ng-template>

<ng-template #aplicativo>
	<app-sidebar (cerrar_sesion)="logout()"></app-sidebar>
	<div class="contenido-app">
		<router-outlet></router-outlet>
	</div>
</ng-template>

<ng-template #modal_terminos let-modal>
	<div class="modal-content">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">TÉRMINOS Y CONDICIONES DE USO </h5>
			</div>
			<div class="modal-body">
				<app-condiciones></app-condiciones>
				<button class="boton medium" (click)="aceptar_contrato(true)">Aceptar</button>
				<button class="btn btn-outline-danger medium rechazaContrato" (click)="aceptar_contrato(false)">
					Rechazar </button>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #modal_cookies let-modal>
	<div class="modal-content">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title text-center">PREFERENCIAS DE CONSENTIMIENTO DE COOKIES</h5><img
					src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
			</div>
			<div class="opCookies">
				<div class="opcion">
					<div class="opcionIzq">
						<u>Funcionales</u><i *ngIf="!info[0]" (click)="info[0]=true"
							class="clicable fa fa-arrow-circle-down"></i><i *ngIf="info[0]" (click)="info[0]=false"
							class="clicable fa fa-arrow-circle-up"></i>
					</div>
					<div class="opcionDer form-check form-switch">
						<input class="form-check-input" type="checkbox" role="switch"
							id="flexSwitchCheckCheckedDisabled" checked disabled>
						<label class="form-check-label" for="flexSwitchCheckCheckedDisabled"
							title="Siempre activ">Siempre activa</label>
					</div>
				</div>
				<div *ngIf="info[0]" class="tagInfo">
					<span>El almacenamiento o acceso técnico será estrictamente necesario con la finalidad legítima de
						permitir la utilización de un determinado servicio solicitado explícitamente por el abonado o
						usuario, o con la única finalidad de efectuar la transmisión de una comunicación a través de una
						red de comunicaciones electrónicas.</span>
				</div>
				<div class="opcion">
					<div class="opcionIzq">
						<u>Estadísticas</u> <i *ngIf="!info[1]" (click)="info[1]=true"
							class="clicable fa fa-arrow-circle-down"></i><i *ngIf="info[1]" (click)="info[1]=false"
							class="clicable fa fa-arrow-circle-up"></i>
					</div>
					<div class="opcionDer form-check form-switch">
						<input [(ngModel)]="cookiesTmp.estadisticasPoliticas" class="clicable form-check-input"
							type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
						<label *ngIf="cookiesTmp.estadisticasPoliticas" class="form-check-label"
							for="flexSwitchCheckCheckedDisabled" title="Siempre activ">Permitir</label>
						<label *ngIf="!cookiesTmp.estadisticasPoliticas" class="form-check-label"
							for="flexSwitchCheckCheckedDisabled" title="Siempre activ">Denegar</label>
					</div>
				</div>
				<div *ngIf="info[1]" class="tagInfo">
					<span>Almacenamiento o acceso técnico que se utilice exclusivamente con fines estadísticos.</span>
				</div>
				<div class="opcion">
					<div class="opcionIzq">
						<u>Marketing</u> <i *ngIf="!info[2]" (click)="info[2]=true"
							class="clicable fa fa-arrow-circle-down"></i><i *ngIf="info[2]" (click)="info[2]=false"
							class="clicable fa fa-arrow-circle-up"></i>
					</div>
					<div class="opcionDer form-check form-switch">
						<input [(ngModel)]="cookiesTmp.marketingPoliticas" class="clicable form-check-input"
							type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
						<label *ngIf="cookiesTmp.marketingPoliticas" class="form-check-label"
							for="flexSwitchCheckCheckedDisabled" title="Siempre activ">Permitir</label>
						<label *ngIf="!cookiesTmp.marketingPoliticas" class="form-check-label"
							for="flexSwitchCheckCheckedDisabled" title="Siempre activ">Denegar</label>
					</div>
				</div>
				<div *ngIf="info[2]" class="tagInfo">
					<span>El almacenamiento o el acceso técnico son necesarios para crear perfiles de usuario para
						ofrecer publicidad o para rastrear al usuario en uno o varios sitios web con fines de marketing
						similares.</span>
				</div>
			</div>
			<div class="btnCookiesPersonalizadas">
				<button class="boton medium" (click)="guardarCookies()">Guardar preferencias</button>
				<button class="btn btn-outline-danger medium rechazaContrato" (click)="aceptarcookies(2);"> Rechazar
				</button>
			</div>
		</div>
	</div>
</ng-template>