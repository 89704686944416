import { DatePipe } from '@angular/common';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.services';
import { UsuariosService } from '../usuarios/usuarios.service';
import { EmpresasService } from '../empresas/empresas.service';
import { RetosService } from '../retos/retos.service';
import { NotificacionesService } from '../notificaciones.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConversacionesService } from '../conversaciones/conversaciones.services';
import { environment } from 'src/environments/environment';
import { Usuarios } from '../models/usuarios';

import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
	selector: 'app-administracion',
	templateUrl: './administracion.component.html',
	styleUrls: ['./administracion.component.css']
})
export class AdministracionComponent implements OnInit, AfterViewInit {

	public nUsuarios: number = 0;
	public nEmpresas: number = 0;
	public nRetos: number = 0;
	public nConversacion: number = 0;


	public usuario_sesion: any;
	public url_imagenes: string = environment.url_imagenes;

	public lista_usuarios: any;
	public lista_Allusuarios: any;
	public lista_usuarios_filtrados: any;
	public filtro_usuarios: string = "";
	public pagina_usuarios: number = 1;
	public paginas_usuarios: number = 1;

	public lista_empresas: any;
	public lista_empresas_filtrados: any;
	public filtro_empresas: string = "";
	public empresasUsuarios: any;
	public pagina_empresas: number = 1;
	public paginas_empresas: number = 1;

	public lista_retos: any;
	public lista_retos_filtrados: any;
	public filtro_retos: string = "";
	public pagina_retos: number = 1;
	public paginas_retos: number = 1;

	public lista_conversaciones: any;
	public lista_conversaciones_filtrados: any;
	public filtro_conversaciones: string = "";
	public pagina_conversaciones: number = 1;
	public paginas_conversaciones: number = 1;

	public pipe = new DatePipe('en-US');

	public usuarios_pendientes: number = 0;
	public retos_pendientes: number = 0;
	public informacion: string = "";

	public ver_tab_usuarios: boolean = true;
	public ver_tab_empresas: boolean = false;
	public ver_tab_retos: boolean = false;
	public ver_tab_conversaciones: boolean = false;

	public userBot: Usuarios;

	public checkMiembroCsic: boolean = false;
	public usuarioModelo: any;

	public paginaPRUEBA: number = 1
	public paginasPRUEBA: number = 1

	// fichero CSV
	private csvCollection: any[] = []


	@ViewChild('modal_informacion') modal_informacion: any;
	@ViewChild('modal_usuario') modal_usuario: any;
	@ViewChild('modal_aviso') modal_aviso: any;

	public aviso_error: boolean = false;
	public mensaje_error: string = "";

	constructor(
		private router: Router,
		private _globalService: GlobalService,
		private _usuariosService: UsuariosService,
		private _empresasService: EmpresasService,
		private _retosService: RetosService,
		private _notificacionesService: NotificacionesService,
		private _conversacionesService: ConversacionesService,
		private modalService: NgbModal,
	) {
		this.usuario_sesion = this._globalService.DatosSesion();
		this.userBot = new Usuarios('', 0, "ChatBot", "", "Nexofy", "chatbot@nexofy.com", "", "", "chatbot.png", "Bot", "", "", "", new Date(), 0, "", "", "", "", "", "", "", "", "", "", "", 0, 1, 0, "", "", "", "", 0, new Date(), new Date(), 0)
		this.usuarioModelo = new Usuarios('', 0, '', '', '', '', '', '', '', '', '', '', '', new Date, 0, '', '', '', '', '', '', '', '', '', '', '', 0, 0, 0, "", "", "", "", 0, new Date, new Date, 0);
	}



	ngOnInit(): void {

		this._notificacionesService.actualizarNotificaciones();
		this._notificacionesService.socket.on('nuevas_notificaciones', (data: any) => {
			this.usuarios_pendientes = 0;
			this.retos_pendientes = 0;
			if (data.usuarios) {
				this.usuarios_pendientes = data.usuarios;
				if (this.usuarios_pendientes > 0) {
					this.descargarUsuarios();
				}
			}

			if (data.retos) {
				this.retos_pendientes = data.retos;
				if (this.retos_pendientes > 0) {
					this.descargarRetos();
				}
			}
		})

	}

	ngAfterViewInit(): void {
		// Descargamos los datos una vez cargadas las vistas.
		this.descargarUsuarios();
		this.descargarAllUsuarios();
		//this.descargarEmpresas();
		this.descargarConversaciones();
		this.descargarRetos();
	}



	//=================USUARIOS=============================
	ver_usuario(id: string) {
		this.router.navigate(['/usuarios/' + id]);
	}

	descargarUsuarios() {
		this._usuariosService.listUsuarios(this._globalService.getToken(), this.pagina_usuarios, this.filtro_usuarios).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 10) {
					this.nUsuarios = 0
				} else {
					this.lista_usuarios = this.lista_usuarios_filtrados = res.response.listado;

					// this.lista_usuarios_filtrados = this.lista_usuarios.filter((usuario: { borrado: number; }) => usuario.borrado !== 1);
					// console.log(this.lista_usuarios_filtrados);
					this.pagina_usuarios = res.response.pagina
					this.paginas_usuarios = res.response.paginas
					this.nUsuarios = this.lista_usuarios.length
				}
			}
		})
	}

	descargarAllUsuarios() {
		this._usuariosService.listAllUsuarios(this._globalService.getToken(),).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.lista_Allusuarios = res.response.listado;
					this.descargarEmpresas()
				}
			}
		})
	}

	siguientePaginaUsuarios() {
		if (this.pagina_usuarios < this.paginas_usuarios) {
			this.pagina_usuarios++;
			this.descargarUsuarios();
		}
	}

	anteriorPaginaUsuarios() {
		if (this.pagina_usuarios > 1) {
			this.pagina_usuarios--;
			this.descargarUsuarios();
		}
	}

	buscarUsuario() {
		if (this.filtro_usuarios.length > 2) {
			this.filtro_usuarios = this.filtro_usuarios.toLowerCase();
			this.lista_usuarios_filtrados = this.lista_usuarios.filter((user: any) => user.nombre.toLowerCase().includes(this.filtro_usuarios) || user.apellidos.toLowerCase().includes(this.filtro_usuarios) || user.email.toLowerCase().includes(this.filtro_usuarios))
		} else {
			this.lista_usuarios_filtrados = this.lista_usuarios;
		}
		this.nUsuarios = this.lista_usuarios_filtrados.length
	}

	crearUsuario() {
		this.modalService.dismissAll();
		this.modalService.open(this.modal_usuario, { centered: true });
	}

	guardarUsuario() {
		this.usuarioModelo.rol = 2;
		this.usuarioModelo.validado = 1;
		this._usuariosService.createAdmin(this.usuarioModelo, this._globalService.getToken()).subscribe({
			next: (res: any) => {
				if (res.error_code == 1) {
					this.descargarUsuarios();
					this.modalService.dismissAll();
					this.aviso_error = true;
					this.mensaje_error = "Administrador creado correctamente";
					this.modalService.open(this.modal_aviso, { centered: true });
				} else {
					this.modalService.dismissAll();
					this.aviso_error = false;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			}
		})
	}

	//=================EMPRESAS=============================
	descargarEmpresas() {
		this._empresasService.listEmpresas(this._globalService.getToken(), this.pagina_empresas, this.filtro_empresas, 50).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.lista_empresas = res.response.listado;
					this.nEmpresas = res.response.listado.length;
					this.pagina_empresas = res.response.pagina;
					this.paginas_empresas = res.response.paginas;

					// Filtramos las empresas según las condiciones especificadas
					this.lista_empresas_filtrados = this.lista_empresas.filter((empresa: any) => {
						let user = this.lista_Allusuarios.find((usuario: any) => usuario.id_empresa && usuario.id_empresa._id == empresa._id);

						if (user) {
							empresa["usuario_baneado"] = user.baneado;
							empresa.usuario_borrado = user.borrado;
							empresa.usuario_validado = user.validado;
						}

						// Condiciones para filtrar las empresas
						const isUsuarioBorradoValid = empresa.usuario_borrado === 0;
						const isUsuarioBaneadoValid = empresa.usuario_baneado === 0;

						// Solo incluir la empresa si ambas condiciones se cumplen
						return isUsuarioBorradoValid && isUsuarioBaneadoValid;
					});
					console.log(this.lista_empresas_filtrados);

				} else {
					this.lista_empresas = this.lista_empresas_filtrados = [];
					this.nEmpresas = 0;
				}

			}


		});
	}


	siguientePaginaEmpresas() {
		if (this.pagina_empresas < this.paginas_empresas) {
			this.pagina_empresas++;
			this.descargarEmpresas();
		}
	}

	anteriorPaginaEmpresas() {
		if (this.pagina_empresas > 1) {
			this.pagina_empresas--;
			this.descargarEmpresas();
		}
	}

	ver_empresa(id: string) {
		console.log(id);
		console.log(`list of all users: `, this.lista_Allusuarios);
		//buscamos el usuario propietario
		let user = this.lista_Allusuarios.filter((user: any) => user?.id_empresa == id);
		if (user.length > 0) {
			this.ver_usuario(user[0]._id);
		} else {
			console.log("Esta empresa no tiene usuarios");
		}
	}

	//=================RETOS=============================
	descargarRetos() {
		this._retosService.listRetos(this._globalService.getToken(), this.pagina_retos, this.filtro_retos).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.lista_retos = this.lista_retos_filtrados = res.response.listado;
					this.pagina_retos = res.response.pagina
					this.paginas_retos = res.response.paginas
					this.nRetos = res.response.listado.length;
				} else {
					this.lista_retos = this.lista_retos_filtrados = res.response.listado;
					this.nRetos = 0;
				}
			}
		})
	}

	siguientePaginaRetos() {
		if (this.pagina_retos < this.paginas_retos) {
			this.pagina_retos++;
			this.descargarRetos();
		}
	}

	anteriorPaginaRetos() {
		if (this.pagina_retos > 1) {
			this.pagina_retos--;
			this.descargarRetos();
		}
	}

	ver_reto(id: string) {
		this.router.navigate(['/retos/' + id]);
	}

	//=================CONVERSACIONES=============================
	descargarConversaciones() {


		this._conversacionesService.listConversaciones(this._globalService.getToken(), this.pagina_conversaciones, this.filtro_conversaciones).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.nConversacion = res.response.listado.length;
					this.pagina_conversaciones = res.pagina
					this.paginas_conversaciones = res.paginas
					this.lista_conversaciones = this.lista_conversaciones_filtrados = res.response.listado;

					for (let i = 0; i < this.lista_conversaciones.length; i++) {
						for (let j = 0; j < this.lista_conversaciones[i].usuarios.length; j++) {
							for (let z = 0; z < this.lista_usuarios.length; z++) {
								if (this.lista_conversaciones[i].usuarios[j] == this.lista_usuarios[z]._id) {
									this.lista_conversaciones[i].usuarios.nombre_empresa = this.lista_usuarios[z].nombre_empresa;
								}
							}
						}
					}


					//completamos la info de cada usuario
					let lista_usuarios: any = [];
					for (let c = 0; c < this.lista_conversaciones.length; c++) {
						for (let u = 0; u < this.lista_conversaciones[c].usuarios.length; u++) {

							//Si el usuario es bot, lo rellenamos manualmente
							if (this.lista_conversaciones[c].usuarios[u] == 'bot') {
								this.lista_conversaciones[c].usuarios[u] = this.userBot;
							} else {
								//primero lo buscamos en el array de usuarios temporales para no duplicar peticiones
								let usuario = lista_usuarios.filter((usuario: any) => usuario._id == this.lista_conversaciones[c].usuarios[u]._id)
								if (usuario.length > 0) {
									this.lista_conversaciones[c].usuarios[u] = usuario[0];
								} else {
									this._usuariosService.readUsuarios(this._globalService.getToken(), this.lista_conversaciones[c].usuarios[u]).subscribe({
										next: (respuesta) => {
											let res: any = respuesta;
											this.lista_conversaciones[c].usuarios[u] = res.response.listado;
											lista_usuarios.push(res.response.listado)
										},
									})
								}
							}
						}
					}
				} else {
					this.nConversacion = 0;
				}
			}
		})
	}

	siguientePaginaConversacion() {
		if (this.pagina_conversaciones < this.paginas_conversaciones) {
			this.pagina_conversaciones++;
			this.descargarConversaciones();
		}
	}

	anteriorPaginaConversacion() {
		if (this.pagina_conversaciones > 1) {
			this.pagina_conversaciones--;
			this.descargarConversaciones();
		}
	}

	ver_conversacion(id: string) {
		this.router.navigate(['/conversaciones/' + id]);
	}

	//Modificar vista de numero de soluciones
	verNumSoluciones(reto: any, op: number) {
		reto.verNumero = op;
		this._retosService.verNumSoluciones(this._globalService.getToken(), reto).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
			}
		})
	}

	//Abrimos el modal donde aparece información de la pagina.
	abrirInfo() {
		this.informacion = "En la ventana administración, el usuario administrador puede: listar: usuarios, empresas, retos y ver conversaciones.  Dentro de estas opciones podemos permitir o denegar las diferentes solicitudes."
		this.modalService.open(this.modal_informacion, { centered: true });
	}

	cambiar_tab(tab: any) {
		if (typeof (tab) == 'object') {
			tab = tab.value;
		}
		this.ver_tab_usuarios = false;
		this.ver_tab_empresas = false;
		this.ver_tab_retos = false;
		this.ver_tab_conversaciones = false;

		if (tab == 'usuarios') { this.ver_tab_usuarios = true }
		if (tab == 'empresas') { this.ver_tab_empresas = true }
		if (tab == 'retos') { this.ver_tab_retos = true }
		if (tab == 'conversaciones') { this.ver_tab_conversaciones = true }
	}

	exportCollection() {
		this._usuariosService.exportCollection().subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code != 1) {
					console.log(res.error_code);
				} else {
					// console.log(JSON.stringify(res.usuarios, null, 2));

					let usuariosFiltrados = res.usuarios.filter((usuario: { borrado: number; rol: number; }) => usuario.borrado !== 1 && usuario.rol !== 1);
					console.log(usuariosFiltrados);

					this.csvCollection = usuariosFiltrados;
					console.log(this.csvCollection);
					this.fileDownload(this.csvCollection)


				}
			}
		})
	}

	fileDownload(data: any) {
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: true,
			title: 'nexofy-usuarios_Collection',
			useBom: true,
			headers: ["ID", "Rol", "Email", "Contraseña", "Cargo", "Biografia", "Contraseña_hash", "Nombre", "Apellidos", "Fecha_nacimiento", "Sexo", "Pais", "Provincia", "Poblacion", "Direccion", "CP", "Telefono", "Web", "Facebook", "Linkedin", "Twitter", "id_empresa", "Baneado", "Validado", "Miembro_CSIC", "id_CSIC", "Centro_Investigacion", "Orcid", "Area", "Creation Date", "mdate", "Borrado", "V", "Imagen", "Personalidad", "NIF"]
		};
		new ngxCsv(data, "nexofy_usuarios", options);
	}
}