
<div class="cabecera-componente">
    <div class="boton-atras clicable" (click)="this.location.back()">
         <img src="../../assets/Icon feather-arrow-left-circle.svg">
    </div>
    <h1 class="titulo-componente"> Contratos<img class="btn_informacion" src="../../assets/info.svg"(click)="abrirInfo()"></h1>
</div>
<div class="my-4">
    <h3>{{solucionModelo.resumen}}</h3>
</div>
<div class="div-solucion">
    <div  *ngIf="nVentana > 1">
        <h5 class="tituloDechera">{{titulo}}</h5>
    </div>
    
    <div class="formulario px-5 py-3" *ngIf="usuario_sesion.rol < 4  && numeroContratos == 0">
        <!-- Ventana 0: Seleccion de contrato personalizado o automatico -->
        <h4 *ngIf="nVentana < 2" class="tituloFormulario">{{titulo}}<img *ngIf="nVentana == 0" class="btn_informacion" src="../../assets/info.svg"(click)="abrirInfoTipo()"></h4>
        <h4 class="tituloFormulario">{{subtitulo}}</h4>
        <div class="seccion-tipo" *ngIf=" nVentana == 0">
            <button class="boton medium botonTipo" type="button" (click)="ventana(nVentana,1,1)">Intermediación por la FGCSIC</button>
            <button class="boton medium botonTipo" type="button" (click)="ventana(nVentana,0,1)">Contrato personalizado</button>
        </div>
        <!-- Ventana 1: Contrato a subir manualmente --> 
        <div *ngIf="automaticoContrato == 0  && nVentana == 1 && !cargando && usuario_sesion.rol < 4" class="contratoPersonalizado">
            <h3>Adjuntar contrato</h3>
              <div class="div-adjuntos">
                   <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                             <div class="div-arrastrar">
                                  <span>Arrastre aquí o</span>
                                  <button class="boton medium" (click)="buscarFichero()">Seleccione un archivo pdf</button>
                             </div>
                        </ng-template>
                   </ngx-file-drop>
                   <input type="file" style="display: none;" id="inputFichero" accept="application/pdf" (change)="subir_fichero($event)">
              </div>
        </div>

        <!-- Ventana 2: contrato automatico con OTROS INTERMEDIARIAOS DATOS EMPRESA -->
        <div class="seccion-tipo" *ngIf="automaticoContrato == 1 && nVentana == 1">
            <div class="row">
                <div class="div-adjuntos col">
                    <h5>Datos de la empresa:*</h5>
                    <div class="firmante row">
                        <div class="col-xl-5 col-lg-4 col-sm-6">
                            <label for="nomEmpresa">Empresa:*</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="nomEmpresa" [ngStyle]="{'background-color': (firmante1.empresa == '') ? '#F0A199' : ''}" [(ngModel)]="firmante1.empresa" name="nomEmpresa" autocomplete="off" title="Nombre de la empresa">
                        </div>
                        <div class="col-xl-5 col-lg-5 col-sm-6">
                          <label for="direccionEmpresa">Dirección:*</label>
                          <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="direccionEmpresa" [ngStyle]="{'background-color': (contrato.empresaDireccion == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.empresaDireccion" name="direccionEmpresa" autocomplete="off" title="Dirección de la empresa">
                      </div>
                        <div class="col-xl-2 col-lg-3 col-sm-6">
                            <label for="cifEmpresa">CIF:*</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="cifEmpresa" [ngStyle]="{'background-color': (!testventana1[0]) ? '#F0A199' : ''}" [(ngModel)]="firmante1.cif" name="cifEmpresa" autocomplete="off" title="CIF de la empresa" maxlength="15">
                        </div>
                        <div class="col-xl-6 col-lg-6 col-sm-6">
                            <label for="sectorEmpresa">Sector:*</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="sectorEmpresa" [ngStyle]="{'background-color': (contrato.empresaSector == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.empresaSector" name="sectorEmpresa" autocomplete="off" title="Sector principal de la empresa">
                        </div>
                        <div class="col-xl-6 col-lg-6 col-sm-6">
                            <label for="emailEmpresa">Email:*</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="emailEmpresa" [ngStyle]="{'background-color': (!testventana1[1]) ? '#F0A199' : ''}" [(ngModel)]="contrato.empresaEmail" name="rgpdEmpresa" autocomplete="off" title="e-mail de la empresa">
                        </div>

                        <h5>Representante de la empresa:*</h5>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <label for="nombreUsrEmpresa">Nombre:*</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="nombreUsrEmpresa" [ngStyle]="{'background-color': (firmante1.nombre == '') ? '#F0A199' : ''}" [(ngModel)]="firmante1.nombre" name="nombreUsrEmpresa" autocomplete="off" title="Nombre representante de la empresa">
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <label for="apellidosEmpresa">Apellidos:*</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="apellidosEmpresa" [ngStyle]="{'background-color': (firmante1.apellidos == '') ? '#F0A199' : ''}" [(ngModel)]="firmante1.apellidos" name="apellidosEmpresa" autocomplete="off" title="Apellidos representante de la empresa">
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="nifEmpresa">NIF:*</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="nifEmpresa" [ngStyle]="{'background-color': (!testventana1[2]) ? '#F0A199' : ''}" [(ngModel)]="firmante1.nif" name="nifEmpresa"  autocomplete="off" title="NIF/DNI/NIE representante de la empresa" maxlength="12">
                        </div>
                        <div class="col-xl-4 col-lg-4 col-sm-6">
                            <label for="cargoEmpresa">Cargo:*</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="cargoEmpresa" [ngStyle]="{'background-color': (firmante1.cargo == '') ? '#F0A199' : ''}" [(ngModel)]="firmante1.cargo" name="cargoEmpresa"  autocomplete="off" title="Cargo representante de la empresa">
                        </div>
                        <div class="col-xl-3 col-lg-3 col-sm-6">
                          <label for="tlfEmpresa">Telefono móvil:*</label>
                          <input (input)="validarExpresiones()" class="form-control" type="text" id="tlfEmpresa" [ngStyle]="{'background-color': (!testventana1[4]) ? '#F0A199' : ''}" [(ngModel)]="firmante1.telefono" name="tlfEmpresa" (keydown)="verificarCaracteres($event)" autocomplete="off"  title="Telefono móvil representante de la empresa" maxlength="9">
                        </div>
                        <div class="col-xl-9 col-lg-5 col-sm-6">
                            <label for="emailEmpresa">Email:*</label>
                            <input (input)="validarExpresiones()" class="form-control" type="email" id="emailEmpresa" [ngStyle]="{'background-color': (!testventana1[3]) ? '#F0A199' : ''}" [(ngModel)]="firmante1.email" name="emailEmpresa" autocomplete="off"  title="E-mail representante de la empresa">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Ventana 3: contrato automatico con OTOS INTERMEDIARIOS DATOS INVESTIGADOR -->
        <div class="seccion-tipo" *ngIf="automaticoContrato == 1 && nVentana == 2">
            <div class="row">
                <div class="div-adjuntos col">
                    <h5>Datos de la persona investigadora:*</h5>
                    <div class="firmante row">
                        <div class="col-xl-4 col-lg-4 col-sm-6">
                            <label for="nomInvestiga">Nombre:*</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="nomInvestiga" [ngStyle]="{'background-color': (firmante2.nombre == '') ? '#F0A199' : ''}" [(ngModel)]="firmante2.nombre" name="nomInvestiga" autocomplete="off" title="Nombre de la persona inverstigadora">
                        </div>
                        <div class="col-xl-4 col-lg-4 col-sm-6">
                            <label for="apellidosInvestiga">Apellidos:*</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="apellidosInvestiga" [ngStyle]="{'background-color': (firmante2.apellidos == '') ? '#F0A199' : ''}" [(ngModel)]="firmante2.apellidos" name="apellidosInvestiga" autocomplete="off" title="Apellidos de la persona inverstigadora">
                        </div>
                        <div class="col-xl-4 col-lg-4 col-sm-6">
                            <label for="nifInvestiga">NIF:*</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="nifInvestiga" [ngStyle]="{'background-color': (!testventana2[0]) ? '#F0A199' : ''}" [(ngModel)]="firmante2.nif" name="nifInvestiga"  autocomplete="off" title="NIF/DNI/NIE de la persona inverstigadora">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6">
                            <label for="cargoInvestiga">Cargo:*</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="cargoInvestiga" [ngStyle]="{'background-color': (firmante2.cargo == '') ? '#F0A199' : ''}" [(ngModel)]="firmante2.cargo" name="cargoInvestiga"  autocomplete="off" title="Cargo de la persona inverstigadora">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6">
                            <label for="direccionInvestiga">Dirección:*</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="direccionInvestiga" [ngStyle]="{'background-color': (contrato.investigaDireccion == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.investigaDireccion" name="direccionInvestiga"  autocomplete="off" title="Direccion de la persona inverstigadora">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6">
                            <label for="emailInvestiga">Email:*</label>
                            <input (input)="validarExpresiones()" class="form-control" type="email" id="emailInvestiga" [ngStyle]="{'background-color': (!testventana2[1]) ? '#F0A199' : ''}" [(ngModel)]="firmante2.email" name="emailInvestiga" autocomplete="off" title="Email de la persona inverstigadora">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6">
                            <label for="tlfInvestiga">Telefono móvil:*</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="tlfInvestiga" [ngStyle]="{'background-color': (!testventana2[9]) ? '#F0A199' : ''}" [(ngModel)]="firmante2.telefono" name="tlfInvestiga" (keydown)="verificarnumero($event)" autocomplete="off" title="Telefono móvil de la persona inverstigadora" maxlength="9">
                        </div>
                        <div class="col-xl-12 col-lg-12 col-sm-12">
                            <label for="centroInvestiga">Nombre del centro de investigación:*</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="centroInvestiga" [ngStyle]="{'background-color': (contrato.investigaCentro  == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.investigaCentro" name="centroInvestiga" autocomplete="off" title="Nombre del centro de investigación">
                        </div>

                        <h5>Representante del instituto/centro de investigación:</h5>
                        <div class="col-xl-3 col-lg-6 col-sm-6">
                            <label for="tipoDirectorInvestiga"> Puesto:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="tipoDirectorInvestiga" [ngStyle]="{'background-color': (firmante3.cargo == '' && !firmante3.cargo && firmante3.nombre != '') ? '#F0A199' : ''}" [(ngModel)]="firmante3.cargo" name="tipoDirectorInvestiga"  autocomplete="off" title="Puesto de la persona representante del centro.">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6">
                            <label for="nombreDirectorInvestiga">Nombre {{firmante3.cargo | lowercase }}:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="nombreDirectorInvestiga" [ngStyle]="{'background-color': (firmante3.nombre == ''  && firmante3.nif != '') ? '#F0A199' : ''}" [(ngModel)]="firmante3.nombre" name="nombreDirectorInvestiga"  autocomplete="off" title="Nombre de la persona representante del centro.">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6">
                            <label for="apellidosDirectorInvestiga">Apellidos {{firmante3.cargo | lowercase }}:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="email" id="apellidosDirectorInvestiga" [ngStyle]="{'background-color': (firmante3.apellidos == '' && firmante3.nombre != '') ? '#F0A199' : ''}" [(ngModel)]="firmante3.apellidos" name="apellidosDirectorInvestiga" autocomplete="off" title="Apellidos de la persona representante del centro.">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6">
                            <label for="nifDirectorInvestiga">NIF {{firmante3.cargo | lowercase }}:</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="nifDirectorInvestiga" [ngStyle]="{'background-color': (!testventana2[2] || (firmante3.nif == '' && firmante3.nombre != '')) ? '#F0A199' : ''}" [(ngModel)]="firmante3.nif" name="nifDirectorInvestiga" autocomplete="off"  title="NIF/DNI/NIE de la persona representante del centro.">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label for="direccionCentroInvestiga">Dirección postal:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="direccionCentroInvestiga" [ngStyle]="{'background-color': (contrato.investigaDireccionCentro == '' && firmante3.nombre != '') ? '#F0A199' : ''}" [(ngModel)]="contrato.investigaDireccionCentro" name="direccionCentroInvestiga" autocomplete="off" title="Dirección del centro de investigación">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label for="direccionCentroInvestiga">Email:</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="direccionCentroInvestiga" [ngStyle]="{'background-color': (!testventana2[3] || (firmante3.email == '' && firmante3.nombre != '')) ? '#F0A199' : ''}" [(ngModel)]="firmante3.email" name="direccionCentroInvestiga" autocomplete="off" title="email del centro de investigación">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label for="tlfCentroInves">Telefono móvil:</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="tlfCentroInves" [ngStyle]="{'background-color': (!testventana2[10] ||(firmante3.telefono == '' && firmante3.nombre != '')) ? '#F0A199' : ''}" [(ngModel)]="firmante3.telefono" name="tlfCentroInves" autocomplete="off" (keydown)="verificarnumero($event)" title="Telefono móvil del centro de investigación" maxlength="9">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label for="faxCentroInves">Fax:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="faxCentroInves" [(ngModel)]="contrato.investigaFaxCentro" name="faxCentroInves" autocomplete="off" (keydown)="verificarnumero($event)" title="Fax del centro de investigación">
                        </div>
                        
                        <h5>Otra persona representante con derecho a firma:</h5>
                        <div class="col-xl-3 col-lg-6 col-sm-6">
                            <label for="tipoOtroResponsable"> Puesto:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="tipoOtroResponsable" [ngStyle]="{'background-color': (firmante4.cargo == '' && !firmante4.cargo && firmante4.nombre != '') ? '#F0A199' : ''}" [(ngModel)]="firmante4.cargo" name="tipoOtroResponsable"  autocomplete="off" title="Puesto de la otra persona responsable.">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6">
                            <label for="nombreOtroResponsable">Nombre {{firmante4.cargo | lowercase }}:</label>
                            <input (inpute)="guardarContratoTemporal()" class="form-control" type="text" id="nombreOtroResponsable" [ngStyle]="{'background-color': (firmante4.nombre == '' && firmante4.nif != '') ? '#F0A199' : ''}" [(ngModel)]="firmante4.nombre" name="nombreOtroResponsable"  autocomplete="off" title="Nombre de la otra persona responsable.">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6">
                            <label for="apellidosOtroResponsable">Apellidos {{firmante4.cargo | lowercase }}:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="email" id="apellidosOtroResponsable" [ngStyle]="{'background-color': (firmante4.apellidos == '' && firmante4.nombre != '') ? '#F0A199' : ''}" [(ngModel)]="firmante4.apellidos" name="apellidosOtroResponsable" autocomplete="off" title="Apellidos de la otra persona responsable.">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6">
                            <label for="nifOtroResponsable">NIF {{firmante4.cargo | lowercase }}:</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="nifOtroResponsable" [ngStyle]="{'background-color': (!testventana2[4] || (firmante4.nif == '' && firmante4.nombre != '')) ? '#F0A199' : ''}" [(ngModel)]="firmante4.nif" name="nifOtroResponsable" autocomplete="off"  title="NIF/DNI/NIE de la otra persona responsable.">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label for="direccionOtroResponsable">Dirección postal:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="direccionOtroResponsable" [ngStyle]="{'background-color': (contrato.direccionOtroResponsable == '' && firmante4.nombre != '') ? '#F0A199' : ''}" [(ngModel)]="contrato.direccionOtroResponsable" name="direccionOtroResponsable" autocomplete="off" title="Dirección de la otra persona responsable">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label for="direccionOtroResponsable">Email:</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="direccionOtroResponsable" [ngStyle]="{'background-color': (!testventana2[5] || (firmante4.email == '' && firmante4.nombre != '')) ? '#F0A199' : ''}" [(ngModel)]="firmante4.email" name="direccionOtroResponsable" autocomplete="off" title="email de la otra persona responsable">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label for="tlfOtroResponsable">Telefono móvil:</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="tlfOtroResponsable" [ngStyle]="{'background-color': (!testventana2[11] ||(firmante4.telefono == '' && firmante4.nombre != '')) ? '#F0A199' : ''}" [(ngModel)]="firmante4.telefono" name="tlfOtroResponsable" autocomplete="off" (keydown)="verificarnumero($event)" title="Telefono de la otra persona responsable" maxlength="9">
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label for="faxOtroResponsable">Fax:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="faxOtroResponsable" [(ngModel)]="contrato.faxOtroResponsable" name="faxOtroResponsable" autocomplete="off" (keydown)="verificarnumero($event)" title="Fax de la otra persona responsable">
                        </div>
                        
                        
                        <h5>Participantes adicionales:</h5>
                        <h6>1º Participante adicional:</h6>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <label for="adicional1Nombre">Nombre y apellidos:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="adicional1Nombre" [ngStyle]="{'background-color': (contrato.adicionalPer1dni != '' && contrato.adicionalPer1nombre == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.adicionalPer1nombre" name="adicional1Nombre" autocomplete="off" title="Nombre y apellidos participante adicional">
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="adicional1dni">DNI:</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="adicional1dni" [ngStyle]="{'background-color': (!testventana2[6]) ? '#F0A199' : ''}" [(ngModel)]="contrato.adicionalPer1dni" name="adicional1dni" autocomplete="off" title="NIF/DNI/NIE participante adicional" maxlength="12">
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <label for="adicional1catProfe">Categoría profesional:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="adicional1catProfe" [ngStyle]="{'background-color': (contrato.adicionalPer1dni != '' && contrato.adicionalPer1catProfe == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.adicionalPer1catProfe" name="adicional1catProfe" autocomplete="off" title="Categoría profesional participante adicional">
                        </div>
                        <div class="col-xl-4 col-lg-4 col-sm-6">
                            <label for="adicional1institucion">Institución:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="adicional1institucion" [ngStyle]="{'background-color': (contrato.adicionalPer1dni != '' && contrato.adicionalPer1institucion == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.adicionalPer1institucion" name="adicional1institucion" autocomplete="off" title="Institución participante adicional">
                        </div>

                        <h6>2º Participante adicional:</h6>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <label for="adicional2Nombre">Nombre y apellidos:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="adicional2Nombre" [ngStyle]="{'background-color': (contrato.adicionalPer2dni != '' && contrato.adicionalPer2nombre == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.adicionalPer2nombre" name="adicional2Nombre" autocomplete="off" title="Nombre y apellidos participante adicional">
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="adicional2dni">DNI:</label>
                            <input  (input)="validarExpresiones()" class="form-control" type="text" id="adicional2dni" [ngStyle]="{'background-color': (!testventana2[7]) ? '#F0A199' : ''}" [(ngModel)]="contrato.adicionalPer2dni" name="adicional2dni" autocomplete="off" title="NIF/DNI/NIE participante adicional" maxlength="12">
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <label for="adicional2catProfe">Categoría profesional:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="adicional2catProfe" [ngStyle]="{'background-color': (contrato.adicionalPer2dni != '' && contrato.adicionalPer2catProfe == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.adicionalPer2catProfe" name="adicional2catProfe" autocomplete="off" title="Categoría profesional participante adicional">
                        </div>
                        <div class="col-xl-4 col-lg-4 col-sm-6">
                            <label for="adicional2institucion">Institución:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="adicional2institucion" [ngStyle]="{'background-color': (contrato.adicionalPer2dni != '' && contrato.adicionalPer2institucion == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.adicionalPer2institucion" name="adicional2institucion" autocomplete="off" title="Institución participante adicional">
                        </div>
                        <h6>3º Participante adicional:</h6>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <label for="adicional3Nombre">Nombre y apellidos:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="adicional3Nombre" [ngStyle]="{'background-color': (contrato.adicionalPer3dni != '' && contrato.adicionalPer3nombre == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.adicionalPer3nombre" name="adicional2Nombre" autocomplete="off" title="Nombre y apellidos participante adicional">
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="adicional2dni">DNI:</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="adicional3dni" [ngStyle]="{'background-color': (!testventana2[8]) ? '#F0A199' : ''}" [(ngModel)]="contrato.adicionalPer3dni" name="adicional3dni" autocomplete="off" title="NIF/DNI/NIE participante adicional" maxlength="12">
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <label for="adicional2catProfe">Categoría profesional:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="adicional2catProfe" [ngStyle]="{'background-color': (contrato.adicionalPer3dni != '' && contrato.adicionalPer3catProfe == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.adicionalPer3catProfe" name="adicional2catProfe" autocomplete="off" title="Categoría profesional participante adicional">
                        </div>
                        <div class="col-xl-4 col-lg-4 col-sm-6">
                            <label for="adicional2institucion">Institución:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="adicional2institucion" [ngStyle]="{'background-color': (contrato.adicionalPer3dni != '' && contrato.adicionalPer3institucion == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.adicionalPer3institucion" name="adicional2institucion" autocomplete="off" title="Institución participante adicional">
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- Ventana 4: contrato automatico con CSIC  Otros datos -->
        <div class="seccion-tipo" *ngIf="automaticoContrato == 1 && nVentana == 3">
            <div class="row">
                <div class="div-adjuntos col">
                    <div class="firmante row">
                        <h5>FUNDACIÓN GENERAL CSIC:*</h5>
                        <div class="col-xl-5 col-lg-4 col-sm-6">
                            <label for="nombreApellidosDirectorCSIC">Nombre y apellidos Director/a FGCSIC:*</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="nombreApellidosDirectorCSIC" [ngStyle]="{'background-color': (contrato.directorCSICnombreApellidos == '') ? '#F0A199' : ''}" [(ngModel)]="contrato.directorCSICnombreApellidos" name="nombreApellidosDirectorCSIC" autocomplete="off" title="Nombre y apellidos Director/a FGCSIC:*">
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="dniDirectorCSIC">DNI Director/a FGCSIC:*</label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="dniDirectorCSIC" [ngStyle]="{'background-color': (!testventana3[0]) ? '#F0A199' : ''}" [(ngModel)]="contrato.directorCSICdni" name="dniDirectorCSIC" autocomplete="off" title="DNI/NIE/NIF Director/a FGCSIC">
                        </div>
                        <div class="col-xl-5   col-lg-4 col-sm-6">
                            <label class="clicable" (click)="abrirInfoIBAN()" for="numCuenta">Número IBAN cuenta bancaria instituto de investigación:* <span class="informa">i</span></label>
                            <input (input)="validarExpresiones()" class="form-control" type="text" id="numCuenta" [ngStyle]="{'background-color': (!testventana3[1]) ? '#F0A199' : ''}" [(ngModel)]="contrato.numCuenta" name="numCuenta" autocomplete="off" title="Número IBAN cuenta bancaria del FGCSIC (Formato ESXXXXXXXXXXXXXXXXXXXXXX)" placeholder="ESXXXXXXXXXXXXXXXXXXXXXX" maxlength="24">
                        </div>

                        <h5>Datos del contrato:*</h5>
                        <div class="col-xl-6 col-lg-4 col-sm-6">
                            <label for="nomInvestiga">Lugar del contrato:</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="text" id="lugarContrato" [ngStyle]="{'background-color': (contrato.lugar === '' || contrato.lugar == null || contrato.lugar == undefined) ? '#F0A199' : ''}" [(ngModel)]="contrato.lugar" name="lugarContrato" autocomplete="off" title="Lugar de creacción del contrato" placeholder="Madrid">
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <label for="numDuraccion">Duracción desarrollo del proyecto:*</label>
                            <input (input)="guardarContratoTemporal()" class="form-control" type="number" id="numDuraccion" [ngStyle]="{'background-color': (duraccionNum == '' || duraccionNum == '0'|| duraccionNum == undefined) ? '#F0A199' : ''}" [(ngModel)]="duraccionNum" name="numDuraccion" (keydown)="verificarnumero($event)" autocomplete="off" title="Duracción del proyecto">
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <label for="tipoDuraccion"> Meses/Años:*</label>
                            <select (change)="guardarContratoTemporal()" class="form-control" [ngStyle]="{'background-color': (duraccionTipo == '') ? '#F0A199' : ''}" [(ngModel)]="duraccionTipo" name="tipoDuraccion" autocomplete="off" title="Tipo de duracción puede ser meses o años">
                                <option value="Meses">Meses</option>
                                <option value="Años">Años</option>
                            </select>
                        </div>
                        <h5>Importe y condiciones de pago:*</h5>
                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label for="numYtextoPresupuesto">Presupuesto/€:* <span class="alerta" *ngIf="!testventana3[6]">El gasto supera el presupuesto</span></label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="numYtextoPresupuesto" [ngStyle]="{'background-color': (contrato.presupuesto === '' || contrato.presupuesto == null || contrato.presupuesto == 0 || !testventana3[6]) ? '#F0A199' : ''}" [(ngModel)]="contrato.presupuesto" name="numYtextoPresupuesto" (keydown)="verificarnumero($event)" autocomplete="off" title="Presupuesto." (ngModelChange)="validarGastoPresupuesto()">
                                <span class="input-group-texto">€</span>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label for="porcentajeNumyTextoFirma">Porcentual(%) A la firma del contrato:*</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="porcentajeNumyTextoFirma" [ngStyle]="{'background-color': (!testventana3[2]) ? '#F0A199' : ''}" [(ngModel)]="contrato.porcentajeInicial" name="porcentajeNumyTextoFirma" (keydown)="verificarnumero($event)" (ngModelChange)="validarPorcentaje($event, 2)" autocomplete="off" maxlength="3" title="Porcentaje pago de premio a la firma del contrato.">
                                <span class="input-group-texto">%</span>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label for="porcentajeNumyTextoIntermedio">Porcentual(%) Según entrega de trabajo:*</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="porcentajeNumyTextoIntermedio" [ngStyle]="{'background-color': (!testventana3[3]) ? '#F0A199' : ''}" [(ngModel)]="contrato.porcentajeIntermedio" name="porcentajeNumyTextoIntermedio" (keydown)="verificarnumero($event)" (ngModelChange)="validarPorcentaje($event, 3)" autocomplete="off" maxlength="3" title="Porcentaje pago de premio según entrega de trabajo.">
                                <span class="input-group-texto">%</span>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label for="porcentajeNumyTextoFinal">Porcentual(%) A la finalización:*</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="porcentajeNumyTextoFinal" [ngStyle]="{'background-color': (!testventana3[4]) ? '#F0A199' : ''}" [(ngModel)]="contrato.porcentajeFinal" name="porcentajeNumyTextoFinal" (keydown)="verificarnumero($event)" (ngModelChange)="validarPorcentaje($event, 4)" autocomplete="off"  maxlength="3" title="Porcentaje pago de premio a la finalización.">
                                <span class="input-group-texto">%</span>
                            </div>
                        </div>

                        <h5>Gastos:</h5>
                        <div class="col-xl-4 col-lg-4 col-sm-6">
                            <label for="numYtextoGastosGenerales">Gastos generales/€:*</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="numYtextoGastosGenerales" [ngStyle]="{'background-color': (contrato.gastosGenerales === '' || contrato.gastosGenerales === null) ? '#F0A199' : ''}" [(ngModel)]="contrato.gastosGenerales" name="numYtextoGastosGenerales" (keydown)="verificarnumero($event)" autocomplete="off" title="Gastos generales."  (ngModelChange)="validarGastoPresupuesto()">
                                <span class="input-group-texto">€</span>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="gastosPersonal">Costes directos de personal/€:</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="gastosPersonal" [(ngModel)]="contrato.otrosGastospersonal" name="gastosPersonal" (keydown)="verificarnumero($event)" autocomplete="off" title="Costes directos de personal."  (ngModelChange)="validarGastoPresupuesto()">
                                <span class="input-group-texto">€</span>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="gastosPlantillas">Personal de Plantilla/€:</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="gastosPlantillas" [(ngModel)]="contrato.otrosGastosplantilla" name="gastosPlantillas" (keydown)="verificarnumero($event)" autocomplete="off" title="Costes personal de Plantilla."  (ngModelChange)="validarGastoPresupuesto()">
                                <span class="input-group-texto">€</span>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="gastosLaborales">Contratos laborales/€:</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="gastosLaborales" [(ngModel)]="contrato.otrosGastoslaborales" name="gastosLaborales" (keydown)="verificarnumero($event)" autocomplete="off" title="Gastos en contratos laborales."  (ngModelChange)="validarGastoPresupuesto()">
                                <span class="input-group-texto">€</span>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="gastosBecas">Becas/€:</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="gastosBecas" [(ngModel)]="contrato.otrosGastosbecas" name="gastosBecas" (keydown)="verificarnumero($event)" autocomplete="off" title="Gastos en becas"  (ngModelChange)="validarGastoPresupuesto()">
                                <span class="input-group-texto">€</span>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="gastosInventariable">Inventariable/€:</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="gastosInventariable" [(ngModel)]="contrato.otrosGastosinventariable" name="gastosInventariable" (keydown)="verificarnumero($event)" autocomplete="off" title="Gastos inventariable"  (ngModelChange)="validarGastoPresupuesto()">
                                <span class="input-group-texto">€</span>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="gastosFungibles">Fungibles/€:</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="gastosFungibles" [(ngModel)]="contrato.otrosGastosfungibles" name="gastosFungibles" (keydown)="verificarnumero($event)" autocomplete="off" title="Gastos fungibles"  (ngModelChange)="validarGastoPresupuesto()">
                                <span class="input-group-texto">€</span>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="gastosviajesYdietas">Viajes y dietas/€:</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="gastosviajesYdietas" [(ngModel)]="contrato.otrosGastosviajesYdietas" name="gastosviajesYdietas" (keydown)="verificarnumero($event)" autocomplete="off" title="Gastos dietas y viajes"  (ngModelChange)="validarGastoPresupuesto()">
                                <span class="input-group-texto">€</span>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="gastosColaboradores">Colaboradores externos/€:</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="gastosColaboradores" [(ngModel)]="contrato.otrosGastoscolaboradores" name="gastosColaboradores" (keydown)="verificarnumero($event)" autocomplete="off" title="Gastos colaboradores externos"  (ngModelChange)="validarGastoPresupuesto()">
                                <span class="input-group-texto">€</span>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="gastosServExternos">Servicios externos/€:</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="gastosServExternos" [(ngModel)]="contrato.otrosGastosserviciosExternos" name="gastosServExternos" (keydown)="verificarnumero($event)" autocomplete="off" title="Gastos servicios externos"  (ngModelChange)="validarGastoPresupuesto()">
                                <span class="input-group-texto">€</span>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-sm-6">
                            <label for="gastosOtros">Otros gastos/€:</label>
                            <div class="input-group">
                                <input (input)="guardarContratoTemporal()" class="form-control reducido" type="number" id="gastosOtros" [(ngModel)]="contrato.otrosGastosotros" name="gastosOtros" (keydown)="verificarnumero($event)" autocomplete="off" title="Otros gastos"  (ngModelChange)="validarGastoPresupuesto()">
                                <span class="input-group-texto">€</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="botonesPie">
            <div class="btnAtras">
                <button *ngIf="nVentana == 1 && automaticoContrato == 0" class="boton medium " (click)="ventana(nVentana,0,0)">Atras</button>
                <button *ngIf="nVentana > 0 && automaticoContrato == 1" class="boton medium " (click)="ventana(nVentana,1,0)">Atras</button>
                
            </div>
            <div class="btnSiguiente">
                <button *ngIf="nVentana > 0 && automaticoContrato == 1"  [disabled]="cargando" class="boton medium" (click)="ventana(nVentana,1,1)">{{nombreBtnSiguiente}}</button>
            </div>
        </div>

    </div>

    <div *ngIf="cargando" class="loadig">
        <div class="sk-chase">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
          </div>
    </div>
    <div class="noResultados" *ngIf="numeroContratos == 0 && usuario_sesion.rol == 4">
        <h4>SIN CONTRATO ACTUAMENTE</h4>
   </div>

    <div *ngFor="let contrTMP of contratosActivos; let i = index" class="tablas">
        <h5>CONTRATO: <img (click)="descargar(i)" class="icono clicable" src="../../../../assets/pdfAzul.svg" alt="contrato original" title="contrato original"> <span class="clicable" (click)="descargar(i)"> {{contrTMP.nombreFichero}} </span> <a *ngIf="contrTMP.estado && contrTMP.tipo==0" [href]="contrTMP.urlFirmado"> <img class="icono clicable" src="../../../../assets/firmadoCompleto.svg" alt="Contrato Firmado" title="Contrato Firmado"></a></h5>
        <table *ngIf="contrTMP.tipo==0">
        <thead>
            <tr>
                <th>Nombre:</th>
                <th>NIF:</th>
                <th>E-mail:</th>
                <th>Telefono:</th>
                <th>Empresa:</th>
                <th>CIF:</th>
                <th>Estado:</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let firmante of contrTMP.firmantes; let i = index">
                <td>{{firmante.nombre}} {{firmante.apellidos}} </td>
                <td>{{firmante.nif}}</td>
                <td>{{firmante.email}}</td>
                <td>{{firmante.telefono}}</td>
                <td>{{firmante.empresa}}</td>
                <td>{{firmante.cif}}</td>
                <td *ngIf="firmante.estado == 0 "><img class="icono" src="../../../../assets/loading2.svg" title="Pendiente de firma, firma a realizar mediante email del interesado."></td>
                <td *ngIf="firmante.estado != 0 "><img class="icono" src="../../../../assets/Grupo 77.svg" title="Firmado"></td>
            </tr>
        </tbody>
        </table>
    </div>
</div>

<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal >
    <div class="modal-content">
         <div class="modal-content">
           <div class="modal-header">
               <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
           </div>
              <div class="modal-body">
                <p [innerHTML]="informacion"></p>
              </div>
              <div class="modal-footer">
                   <button class="boton medium"(click)="informacionLeida()">Aceptar</button>
              </div>
         </div>
    </div>
</ng-template>
<!--Modal avisos-->
<ng-template #modal_aviso let-modal>
    <div class="modal-content">
         <div class="modal-content">
              <div class="modal-header">
                   <img src="../../../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
              </div>

           <div class="modal-body aviso">
               <div class="icono-aviso">
                   <img src="../../../../assets/x (1).svg" *ngIf="!aviso_error" width="100%">
                   <img src="../../../../assets/correcto.svg" *ngIf="aviso_error" width="100%">
               </div>
               <div class="titulo">
                   <h2>{{this.mensaje_error}}</h2>
               </div>
           </div>
         </div>
    </div>
</ng-template>
<!--Modal vista previa contrato-->
<ng-template #modal_pdf let-modal >
    <div class="modal-content">
         <div class="modal-content">
           <div class="modal-header">
               <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
           </div>
              <div class="modal-body">
                <iframe [src]="pdfSrc" width="100%" height="600px"></iframe>
              </div>
              <div class="modal-footer">
                   <button class="boton medium"(click)="modalService.dismissAll()">Cancelar</button>
                   <button class="boton medium"(click)="enviarAFirmas()">Enviar a firmar</button>
              </div>
         </div>
    </div>
</ng-template>

