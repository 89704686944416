import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../services/global.services';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {
	public token: any;
	public url: any;


	constructor(
		public _http: HttpClient,
        private _globalService:GlobalService
	) {
		this.url = environment.url_api;
        this.token = this._globalService.getToken();
	}


	listUsuarios(desde:Date, hasta:Date) {
		let headers = new HttpHeaders()
        .set('Authorization', this.token)
		.set('Content-Type', 'application/json',);
		return this._http.get(this.url + 'graficoUsuarios?fi='+desde+'&ff='+hasta, { headers: headers });
	}

	listRetos(desde:Date, hasta:Date) {
		let headers = new HttpHeaders()
        .set('Authorization', this.token)
		.set('Content-Type', 'application/json',);
		return this._http.get(this.url + 'graficoRetos?fi='+desde+'&ff='+hasta, { headers: headers });
	}

	listSoluciones(desde:Date, hasta:Date) {
		let headers = new HttpHeaders()
        .set('Authorization', this.token)
		.set('Content-Type', 'application/json',);
		return this._http.get(this.url + 'graficoSoluciones?fi='+desde+'&ff='+hasta, { headers: headers });
	}

	listQuerys(desde:Date, hasta:Date) {
		let headers = new HttpHeaders()
        .set('Authorization', this.token)
		.set('Content-Type', 'application/json',);
		return this._http.get(this.url + 'graficoQuerys?fi='+desde+'&ff='+hasta, { headers: headers });
	}
}