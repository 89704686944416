<h1 class="titulo-componente">Investigadores<img class="btn_informacion" src="../../assets/info.svg"(click)="abrirInfo()"></h1>
<button class="boton secondary medium" (click)="mostrar_filtro()">Filtros</button>
     <div #filtro  class="row | bg-white rounded border p-2 mb-3 |  animate__animated ">
          <div class="fila-inputs">
               <div class="columna-inputs">
                    <label>Nombre</label>
                    <div class="input-group">
                         <input (keyup.enter)="descargar_usuarios()" type="text" placeholder="Nombre del investigador..." class="form-control" [(ngModel)]="filtroNombre">
                    </div>
               </div>
               
               <div class="columna-inputs">
                    <label>Pais</label>
                    <div class="input-group">
                         <select (change)="descargar_usuarios()" class="form-control clicable" #pais="ngModel" [(ngModel)]="filtroPais">
                              <option *ngFor="let pais of lista_paises" >{{pais.name}}</option>
                         </select>
                    </div>
               </div>

               <div class="columna-inputs">
                    <label>Cargo</label>
                    <div class="input-group">
                         <input (keyup.enter)="descargar_usuarios()" type="text" placeholder="Nombre del cargo..." class="form-control" #cargo="ngModel" [(ngModel)]="filtroCargo">
                    </div>
               </div>
          </div>
          <div class="fila-botones">
               <button class="boton secondary" (click)="descargar_usuarios()">Aplicar filtros</button>
          </div>
     </div>
<div class="noResultados" *ngIf="lista_usuarios.length == 0">
     <h4>SIN RESULTADOS</h4>
</div>

<div class="div-usuarios" >
     <div class="div-tarjeta" *ngFor="let usuario of lista_usuarios">
          <div class="tarjeta clicable" (click)="leer_usuario(usuario._id)">
               <div class="imagen">
                    <img src={{url_imagenes}}{{usuario.imagen}}>
               </div>
               <div class="titulo">
                    {{usuario.nombre}}
               </div> 
               <div class="labels">
                    <span class="label_tabla">{{usuario.cargo}}</span>
               </div>
          </div>
     </div>
</div>

<div *ngIf="lista_usuarios.length > 0" class="div-paginacion">
     <div class="atras">
          <button *ngIf="paginaActual>1"  (click)="paginaPrevia()" class="boton secondary"><img src="../../assets/arrow-left.svg">Anterior</button>
     </div>
     <div class="paginas">{{paginaActual}}/{{paginastotal}}</div>
     <div class="siguiente">
          <button class="boton secondary"*ngIf="paginaActual<paginastotal" (click)="paginaSiguiente()"><img src="../../assets/arrow-right.svg">Siguiente</button>
     </div>
</div>
<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal >
     <div class="modal-content">
          <div class="modal-content">
			<div class="modal-header">
				<img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
			</div>
               <div class="modal-body">
				<p>
                        {{informacion}}
                    </p>				
               </div> 
               <div class="modal-footer">
                    <button class="boton medium"(click)="modal.dismiss()">Aceptar</button>
               </div>            
          </div>
     </div>
</ng-template>