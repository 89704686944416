<div>
     <h1 class="titulo-componente">Mi perfil<img class="btn_informacion" src="../../assets/info.svg"
               (click)="abrirInfo(1)"></h1>
     <div class="cuerpo-componente">
          <div class="input-group" class="select_tabs">
               <select class="form-select" (change)="cambiar_tab($event.target)">
                    <option value="datos_personales">Datos personales</option>
                    <option value="contacto">Datos de contacto</option>
                    <option value="redes">Redes sociales</option>
                    <option value="psw">Contraseña</option>
                    <option value="datos_empresa" *ngIf="usuarioModelo.id_empresa">Datos de la empresa</option>
                    <option value="contacto_empresa" *ngIf="usuarioModelo.id_empresa">Contacto de la empresa</option>
                    <option value="redes_empresa" *ngIf="usuarioModelo.id_empresa">Redes de la empresa</option>
               </select>
          </div>
          <div class="div-perfil">
               <div class="columna-menu nav nav-tabs">
                    <div (click)="mostrarGuardar(1)" class="item active clicable" data-bs-toggle="tab"
                         data-bs-target="#personal">
                         Datos personales
                    </div>
                    <div (click)="mostrarGuardar(1)" class="item clicable" data-bs-toggle="tab"
                         data-bs-target="#contacto">
                         Datos de contacto
                    </div>

                    <div (click)="mostrarGuardar(1)" class="item clicable" data-bs-toggle="tab" data-bs-target="#redes">
                         Redes sociales
                    </div>
                    <div (click)="mostrarGuardar(2)" class="item clicable" data-bs-toggle="tab" data-bs-target="#psw">
                         Contraseña
                    </div>
                    <div (click)="mostrarGuardar(1)" *ngIf="usuarioModelo.id_empresa" class="item clicable"
                         data-bs-toggle="tab" data-bs-target="#empresa">
                         Datos de la empresa
                    </div>
                    <div (click)="mostrarGuardar(1)" *ngIf="usuarioModelo.id_empresa" class="item clicable"
                         data-bs-toggle="tab" data-bs-target="#contacto_empresa">
                         Contacto de la empresa
                    </div>
                    <div (click)="mostrarGuardar(1)" *ngIf="usuarioModelo.id_empresa" class="item clicable"
                         data-bs-toggle="tab" data-bs-target="#redes_empresa">
                         Redes de la empresa
                    </div>
               </div>

               <div class="columna-contenido">
                    <div class="boton-guardar">
                         <button *ngIf="botonGuardar" class="boton medium" (click)="actualizar()">Guardar</button>
                    </div>

                    <div class="formulario-personal tab-pane fade" [ngClass]="{'show active':ver_tab_datos_personales}"
                         id="personal">
                         <div class="input-group">
                              <input type="hidden" [(ngModel)]="usuarioModelo.imagen">
                              <input type="file" class="form-control" id="inputImagenUsuario"
                                   (change)="subir_imagen_usuario($event)" style="display: none;">
                         </div>

                         <div class="fila-encabezado">
                              <div class="fila-titulo">
                                   <h2>Tus datos<img class="btn_informacion_min" src="../../assets/info.svg"
                                             (click)="abrirInfo(2)"></h2>
                                   <div *ngIf="usuario_sesion.rol<=2">
                                        <div class="form-check">
                                             <input class="form-check-input check-formulario" type="checkbox"
                                                  [(ngModel)]="checkMiembroCsic"
                                                  (change)="usuarioModelo.miembro_csic=checkMiembroCsic">
                                             <p class="form-check-label" for="flexCheckDefault">
                                                  Miembro del CSIC
                                             </p>
                                        </div>
                                        <div *ngIf="usuarioModelo.miembro_csic==1">
                                             <label>ID de CSIC</label>
                                             <div class="input-group">
                                                  <input type="text" class="form-control" placeholder="ID"
                                                       [(ngModel)]="usuarioModelo.id_csic">
                                             </div>
                                        </div>

                                        <label>Rol</label>
                                        <div class="input-group">
                                             <select class="form-control clicable" placeholder="Rol"
                                                  [(ngModel)]="usuarioModelo.rol" value="{{usuarioModelo.rol}}">
                                                  <option value="1">Superadministrador</option>
                                                  <option value="2">Administrador</option>
                                                  <option value="3">Empresa</option>
                                                  <option value="4">Personal investigador</option>
                                             </select>
                                        </div>
                                   </div>
                              </div>

                              <div class="fila-foto">
                                   <img src={{url_imagenes}}{{usuarioModelo.imagen}} alt="logo usuario"
                                        (click)="buscarImagenUsuario()" *ngIf="!subiendo_imagen">
                                   <img src="../../assets/loading.svg" alt="logo usuario" *ngIf="subiendo_imagen">

                                   <div class="div-icono danger">
                                        <svg class="icono-svg danger clicable" (click)="eliminarFotoUsuario()">
                                             <path id="Trazado_788" data-name="Trazado 788" d="M3,6h9.9"
                                                  transform="translate(0 -1.8)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                             <path id="Trazado_789" data-name="Trazado 789"
                                                  d="M12.7,4.2v7.7A1.1,1.1,0,0,1,11.6,13H6.1A1.1,1.1,0,0,1,5,11.9V4.2m1.65,0V3.1A1.1,1.1,0,0,1,7.75,2h2.2a1.1,1.1,0,0,1,1.1,1.1V4.2"
                                                  transform="translate(-0.9)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                        </svg>
                                   </div>
                              </div>
                         </div>

                         <div class="fila-campos">
                              <label>Nombre</label>
                              <div class="input-group">
                                   <input type="email" class="form-control" placeholder="Email" name="nombre"
                                        #nombre="ngModel" [(ngModel)]="usuarioModelo.nombre"
                                        value="{{usuarioModelo.nombre}}">
                              </div>
                              <label>Apellidos</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="Apellidos..." name="apellidos"
                                        #apellidos="ngModel" [(ngModel)]="usuarioModelo.apellidos"
                                        value="{{usuarioModelo.apellidos}}">
                              </div>
                              <label>NIF/NIE</label>
                              <div class="input-group">
                                   <input [disabled]="usuario_sesion.rol >2" class="form-control" type="text"
                                        placeholder="NIF/NIE..." name="nif" #nif="ngModel"
                                        [(ngModel)]="usuarioModelo.nif" value="{{usuarioModelo.nif}}">
                              </div>
                              <label>Email</label>
                              <div class="input-group">
                                   <input [disabled]="usuario_sesion.rol >2" class="form-control" type="email"
                                        placeholder="Email..." name="mail" #mail="ngModel"
                                        [(ngModel)]="usuarioModelo.email" value="{{usuarioModelo.email}}">
                              </div>
                              <label>Fecha de nacimiento</label>
                              <div class="input-group">
                                   <input class="form-control clicable" type="date" placeholder="Fecha de nacimiento..."
                                        name="fecha_nacimiento" #fecha_nacimiento="ngModel"
                                        [(ngModel)]="usuarioModelo.fecha_nacimiento"
                                        value="{{usuarioModelo.fecha_nacimiento | date: 'yyyy-MM-dd' }}">
                              </div>
                              <label>Sexo</label>
                              <div class="input-group">
                                   <select class="form-control clicable" placeholder="Sexo" name="sexo" #sexo="ngModel"
                                        [(ngModel)]="usuarioModelo.sexo" value="{{usuarioModelo.sexo}}">
                                        <option value="0">Prefiero no decirlo</option>
                                        <option value="1">Hombre</option>
                                        <option value="2">Mujer</option>
                                   </select>
                              </div>

                              <div *ngIf="usuarioModelo.rol==4">
                                   <label>Centro de investigación</label>
                                   <div class="input-group">
                                        <input class="form-control" type="text" placeholder="Tu centro de investigación"
                                             [(ngModel)]="usuarioModelo.centro_investigacion">
                                   </div>

                                   <label>Área científico-técnica</label>
                                   <div class="input-group">
                                        <select class="form-select" placeholder="Área" [(ngModel)]="usuarioModelo.area">
                                             <option>Matemáticas, Físicas y Tecnologías</option>
                                             <option>Químicas y Tecnologías</option>
                                             <option>Ciencia y Tecnología de Materiales</option>
                                             <option>Ciencia y Tecnología de Alimentos</option>
                                             <option>Ciencias Agrarias</option>
                                             <option>Biología Celular y Molecular</option>
                                             <option>Biomedicina</option>
                                             <option>Ciencias de la Naturaleza y Recursos Naturales</option>
                                             <option>Humanidades y Ciencias Sociales</option>
                                        </select>
                                   </div>
                              </div>



                         </div>

                         <label>Cargo</label>
                         <div class="input-group">
                              <input class="form-control" type="text" placeholder="Tu cargo..." name="cargo"
                                   #cargo="ngModel" [(ngModel)]="usuarioModelo.cargo" value="{{usuarioModelo.cargo}}">
                         </div>

                         <label>Biografía</label>
                         <editor name="biografia" #biografia="ngModel" [(ngModel)]="usuarioModelo.biografia"
                              apiKey="l16bkj4mgm1xi9iaeel16pfrvl5hd0234u05d9aim7jrd36w" [init]="{
    branding: false,
    base_url: '/tinymce',
    suffix: '.min',
    height: 500,
    menubar: false,
    resize: false,
    plugins: [],
    force_p_newlines: false,
    force_br_newlines: true,
    entity_encoding: 'raw',
    formats: {
      removeformat: [
      { selector: 'b,strong,em,i,u,strike', remove: 'all', split: false, expand: false, block_expand: true, deep: true }
      ]
    },
    toolbar: 'undo redo'
  }"></editor>
                    </div>

                    <div class="formulario-personal tab-pane fade" [ngClass]="{'show active':ver_tab_contacto}"
                         id="contacto">
                         <div class="fila-campos">
                              <h2>Datos de contacto<img class="btn_informacion_min" src="../../assets/info.svg"
                                        (click)="abrirInfo(3)"></h2>
                              <label>Teléfono*</label>
                              <div class="input-group">
                                   <input class="form-control" type="number" placeholder="Teléfono" #telefono="ngModel"
                                        [(ngModel)]="usuarioModelo.telefono" value="{{usuarioModelo.telefono}}"
                                        (keydown)="verificarCaracteres($event)">
                              </div>

                              <label>País*</label>
                              <div class="input-group">
                                   <select class="form-control clicable" placeholder="País"
                                        (change)="actualizar_estados_usuario()" #pais="ngModel"
                                        [(ngModel)]="usuarioModelo.pais">
                                        <option *ngFor="let pais of lista_paises; let indice = index"
                                             [selected]="pais.name == paisUsuario">{{pais.name}}</option>
                                   </select>
                              </div>

                              <label>Provincia</label>
                              <div class="input-group">
                                   <select class="form-control clicable" placeholder="Provincia" #provincia="ngModel"
                                        [(ngModel)]="usuarioModelo.provincia" value="{{usuarioModelo.provincia}}">
                                        <option *ngFor="let estado of lista_estados; let indice = index"
                                             [selected]="estado.name == estadoUsuario">{{estado.name}}</option>
                                   </select>

                              </div>

                              <label>Población</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="Población" #poblacion="ngModel"
                                        [(ngModel)]="usuarioModelo.poblacion" value="{{usuarioModelo.poblacion}}">

                              </div>

                              <label>Dirección</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="Dirección" #direccion="ngModel"
                                        [(ngModel)]="usuarioModelo.direccion" value="{{usuarioModelo.direccion}}">
                              </div>

                              <label>CP</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="CP" #cp="ngModel"
                                        [(ngModel)]="usuarioModelo.cp" value="{{usuarioModelo.cp}}">
                              </div>

                         </div>
                    </div>

                    <div class="formulario-personal tab-pane fade" [ngClass]="{'show active':ver_tab_redes}" id="redes">
                         <div class="fila-campos">
                              <h2>Tus redes sociales</h2>
                              <label>Facebook</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="Facebook" #facebook="ngModel"
                                        [(ngModel)]="usuarioModelo.facebook" value="{{usuarioModelo.facebook}}">
                              </div>

                              <label>Linkedin</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="LinkedIn" #linkedIn="ngModel"
                                        [(ngModel)]="usuarioModelo.linkedin" value="{{usuarioModelo.linkedin}}">
                              </div>
                              <label>Twitter</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="Twitter" #twitter="ngModel"
                                        [(ngModel)]="usuarioModelo.twitter" value="{{usuarioModelo.twitter}}">
                              </div>
                         </div>
                    </div>
                    <!-- FORMULARIO CONTRASEÑA -->
                    <div class="formulario-personal tab-pane fade" [ngClass]="{'show active':ver_tab_psw}" id="psw">
                         <div class="fila-campos">
                              <h2>Cambiar contraseña<img class="btn_informacion_min" src="../../assets/info.svg"
                                        (click)="abrirInfo(8)"></h2>
                              <label>Contraseña:</label>
                              <div class="input-group">
                                   <input class="form-control" type="password" placeholder="Contraseña"
                                        [(ngModel)]="pswTxt">
                                   <span class="pieAlerta"
                                        *ngIf="(pswTxt == '' && pswTxtRepetida !='') || (pswTxt != '' && pswTxt.length < 6)">*Contraseña
                                        no valida, minimo 6 caracteres.</span>
                              </div>

                              <label>Repetir contraseña:</label>
                              <div class="input-group">
                                   <input class="form-control repetirPsw" type="password"
                                        placeholder="Repetir Contraseña" [(ngModel)]="pswTxtRepetida">
                                   <span class="pieAlerta" *ngIf="pswTxt != pswTxtRepetida">*Las contraseñas no
                                        coinciden</span>
                              </div>
                              <div>
                                   <button *ngIf="!botonGuardar"
                                        [ngStyle]="(pswTxt != pswTxtRepetida || pswTxt.length < 6) ? {'cursor': 'not-allowed'} : {'cursor': 'pointer'}"
                                        (click)="cambiarPassword()"
                                        [ngClass]="(pswTxt != pswTxtRepetida || pswTxt.length < 6) ? 'boton disabled medium mt-4' : 'boton medium mt-4'">Cambiar
                                        contraseña</button>
                              </div>
                         </div>
                    </div>

                    <div class="formulario-empresa tab-pane fade" [ngClass]="{'show active':ver_tab_datos_empresa}"
                         id="empresa">
                         <div class="input-group">
                              <input type="hidden" [(ngModel)]="empresaModelo.imagen">
                              <input type="file" class="form-control" id="inputImagenEmpresa"
                                   (change)="subir_imagen_empresa($event)" style="display: none;">
                         </div>

                         <div class="fila-encabezado">
                              <div class="fila-titulo">
                                   <h2>Datos de tu empresa<img class="btn_informacion_min" src="../../assets/info.svg"
                                             (click)="abrirInfo(5)"></h2>
                                   <label>Nombre*</label>
                                   <div class="input-group">
                                        <input class="form-control" type="text" placeholder="Nombre" #nombre="ngModel"
                                             [(ngModel)]="empresaModelo.nombre" value="{{empresaModelo.nombre}}">
                                   </div>
                              </div>

                              <div class="fila-foto">
                                   <img src={{url_imagenes}}{{empresaModelo.imagen}} alt="logo empresa"
                                        (click)="buscarImagenEmpresa()" *ngIf="!subiendo_imagen">
                                   <img src="../../assets/loading.svg" alt="logo usuario" *ngIf="subiendo_imagen">
                                   <div class="div-icono danger">
                                        <svg class="icono-svg danger clicable" (click)="eliminarFotoEmpresa()">
                                             <path id="Trazado_788" data-name="Trazado 788" d="M3,6h9.9"
                                                  transform="translate(0 -1.8)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                             <path id="Trazado_789" data-name="Trazado 789"
                                                  d="M12.7,4.2v7.7A1.1,1.1,0,0,1,11.6,13H6.1A1.1,1.1,0,0,1,5,11.9V4.2m1.65,0V3.1A1.1,1.1,0,0,1,7.75,2h2.2a1.1,1.1,0,0,1,1.1,1.1V4.2"
                                                  transform="translate(-0.9)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                        </svg>
                                   </div>
                              </div>
                         </div>

                         <div class="fila-campos">
                              <label>CIF*</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="CIF" #cif="ngModel"
                                        [(ngModel)]="empresaModelo.cif" value="{{empresaModelo.cif}}">
                              </div>
                              <label>Sector*</label>
                              <div class="input-group">
                                   <select class="form-control clicable" placeholder="Sector" #sector="ngModel"
                                        [(ngModel)]="empresaModelo.sector" value="{{empresaModelo.sector}}">
                                        <option>IT y/o medios de comunicación</option>
                                        <option>Sanidad</option>
                                        <option>Agricultura</option>
                                        <option>Alimentación y bebidas</option>
                                        <option>Transporte y logística</option>
                                        <option>Industria y automoción</option>
                                        <option>Construcción</option>
                                        <option>Energía</option>
                                        <option>Servicios y Banca</option>
                                        <option>Otros</option>
                                   </select>
                              </div>
                              <label>Email</label>
                              <div class="input-group">
                                   <input class="form-control" type="email" placeholder="Email..."
                                        [(ngModel)]="empresaModelo.email">
                              </div>
                              <label>Web</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="web" #web="ngModel"
                                        [(ngModel)]="empresaModelo.web" value="{{empresaModelo.web}}">
                              </div>
                              <label>Número de empleados</label>
                              <div class="input-group">
                                   <input class="form-control" type="number"
                                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                        #numero_empleados="ngModel" [(ngModel)]="empresaModelo.numero_empleados"
                                        value="{{empresaModelo.numero_empleados}}">
                              </div>
                              <label>Facturación anual</label>
                              <div class="input-group">
                                   <input class="form-control" type="number"
                                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                        #facturacion_anual="ngModel" [(ngModel)]="empresaModelo.facturacion_anual"
                                        value="{{empresaModelo.facturacion_anual}}">
                              </div>
                         </div>

                    </div>

                    <div class="formulario-empresa tab-pane fade" [ngClass]="{'show active':ver_tab_contacto_empresa}"
                         id="contacto_empresa">
                         <div class="fila-campos">
                              <h2>Datos de contacto de tu empresa<img class="btn_informacion_min"
                                        src="../../assets/info.svg" (click)="abrirInfo(6)"></h2>
                              <label>País*</label>
                              <div class="input-group">
                                   <select class="form-control" placeholder="País"
                                        (change)="actualizar_estados_empresa()" #pais="ngModel"
                                        [(ngModel)]="empresaModelo.pais" value="{{empresaModelo.pais}}">
                                        <option *ngFor="let pais of lista_paises_empresa; let indice = index"
                                             [selected]="pais.name == paisEmpresa">{{pais.name}}</option>
                                   </select>
                              </div>
                              <label>Provincia</label>
                              <div class="input-group">
                                   <select class="form-control clicable" placeholder="Provincia" #provincia="ngModel"
                                        [(ngModel)]="empresaModelo.provincia" value="{{empresaModelo.provincia}}">
                                        <option *ngFor="let estado of lista_estados_empresa; let indice = index"
                                             [selected]="estado.name == estadoEmpresa">{{estado.name}}</option>
                                   </select>
                              </div>
                              <label>Población</label>
                              <div class="input-group">
                                   <input class="form-control clicable" type="text" placeholder="Población"
                                        #poblacion="ngModel" [(ngModel)]="empresaModelo.poblacion"
                                        value="{{empresaModelo.poblacion}}">
                              </div>
                              <label>Dirección</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="Dirección" #direccion="ngModel"
                                        [(ngModel)]="empresaModelo.direccion" value="{{empresaModelo.direccion}}">
                              </div>
                              <label>CP</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="CP" #cp="ngModel"
                                        [(ngModel)]="empresaModelo.cp" value="{{empresaModelo.cp}}">
                              </div>

                         </div>
                    </div>

                    <div class="formulario-empresa tab-pane fade" [ngClass]="{'show active':ver_tab_redes_empresa}"
                         id="redes_empresa">
                         <div class="fila-campos">
                              <h2>Redes sociales de tu empresa<img class="btn_informacion_min"
                                        src="../../assets/info.svg" (click)="abrirInfo(7)"></h2>
                              <label>Facebook</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="Facebook" #facebook="ngModel"
                                        [(ngModel)]="empresaModelo.facebook" value="{{empresaModelo.facebook}}">
                              </div>
                              <label>LinkedIn</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="LinkedIn" #linkedin="ngModel"
                                        [(ngModel)]="empresaModelo.linkedin" value="{{empresaModelo.linkedin}}">
                              </div>
                              <label>Twitter</label>
                              <div class="input-group">
                                   <input class="form-control" type="text" placeholder="Twitter" #twitter="ngModel"
                                        [(ngModel)]="empresaModelo.twitter" value="{{empresaModelo.twitter}}">
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<!--Modal para confirmar si se guardaron los cambios correctamente-->
<ng-template #modal_aviso let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>

               <div class="modal-body aviso">
                    <div class="icono-aviso">
                         <img src="../../../assets/x (1).svg" *ngIf="!aviso_error" width="100%">
                         <img src="../../../assets/correcto.svg" *ngIf="aviso_error" width="100%">
                    </div>
                    <div class="titulo">
                         <h2>{{this.mensaje_error}}</h2>
                    </div>
               </div>
          </div>
     </div>
</ng-template>

<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
               <div class="modal-body">
                    <p [innerHTML]="informacion"></p>
               </div>
               <div class="modal-footer">
                    <button class="boton medium" (click)="modal.dismiss()">Aceptar</button>
               </div>
          </div>
     </div>
</ng-template>