import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ContratosService {
	public token:any;
	public url:any;

	constructor(
		public _http:HttpClient,
	) {
		this.url = environment.url_api;
	}
    obtenerToken(token:any){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*");
		return this._http.get(this.url+'createToken', {headers: headers});
	}
	obtenerUsosDisponibles(token:any,firmasModelo:any){
			let params = JSON.stringify(firmasModelo);  
			let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token)
			return this._http.post(this.url+'obtenerUsosDisponibles', params,{headers: headers});
	}
	
	envioFirmas(token:any,firmasModelo:any){
		let params = JSON.stringify(firmasModelo);  
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		return this._http.post(this.url+'envioFirmas', params,{headers: headers});
	}

	listContratosFirma(token:any,id:any){ 
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		return this._http.get(this.url+'listContratosFirma/'+id,{headers: headers});
	}
	
	
	activarWebhook(token:any,modeloWebhook:any){
		let params = JSON.stringify(modeloWebhook);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*");
		return this._http.post(this.url+'activarWebhook', params,{headers: headers});
	}

	//Subir contrato propio para el cliente
	subirContrato(token:any,firmasModelo:any){
		let params = JSON.stringify(firmasModelo);  
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url+'subirContrato', params,{headers: headers});
	}

	crearPDF(token:any, param:any){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url+'crearPDF',param,{headers: headers});
	}


	leerContratoTemporal(token:any,id_solucion:string){  
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.get(this.url+'leerContratoTemporal/'+id_solucion,{headers: headers});
	}


	saveContratoTemporal(token:any,contratoTmp:any){
		let params = JSON.stringify(contratoTmp);  
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url+'saveContratoTemporal', params,{headers: headers});
	}

	eliminarContratoTemporal(token:any,contratoTmp:any){
		let params = JSON.stringify(contratoTmp);  
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url+'eliminarContratoTemporal', params,{headers: headers});
	}
}