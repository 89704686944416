import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, Renderer2, ViewChild, } from '@angular/core';
import { GlobalService } from 'src/app/services/global.services';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { SolucionesService } from '../../soluciones.service';
import { LogService } from '../../../log/log.service';
import { Firmantes } from 'src/app/models/firmante';
import { EmpresasService } from '../../../empresas/empresas.service';
import { UsuariosService } from '../../../usuarios/usuarios.service';
import { ContratosService } from './contratos.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConnectableObservable } from 'rxjs';


@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.css']
})
export class ContratosComponent implements OnInit {
public idEmpresa: any;
public id_solucion: any;
public informacion:string = "";
public usuario_sesion:any;
public mensaje_error = "";
public aviso_error=false;
public solucionModelo:any = [];
public files: NgxFileDropEntry[] = [];
public titulo:string = "TIPO DE CONTRATO:";
public subtitulo:string = "";
public testventana1 = [true,true,true,true,true,true,true];
public testventana2 = [true,true,true,true,true,true,true,true,true,true,true,true];
public testventana3 = [true,false,false,false,false,true,true];

public contrato:any = {};

public firmante1: any;
public firmante2: any;
public firmante3: any;
public firmante4: any;
public contratosActivos:any;
public verCSIC:boolean = false
public cargando: boolean = false;
public numeroContratos: number = 0;
public automaticoContrato: number = 0;
public base64PDF:string = "";

public guardaActualizaciones: boolean = false;


//Expresiones regulares
public nifsExp = new RegExp('^[XYZ]?[0-9]{7,8}[A-Z]$');
public cifExp = new RegExp('^[ABCDEFGHJNPQRSUVW][0-9]{7}[0-9A-J]$');
public emailExp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
public numCuentaExp = new RegExp('^ES\\d{22}$');
public movilExp = new RegExp('^[6|7]{1}[0-9]{8}$');

public nVentana = 0;
public nombreBtnSiguiente:string = "Siguiente";

public duraccionNum:string = '';
public duraccionTipo:string = '';
public pdfSrc: SafeResourceUrl  | undefined;

@ViewChild('modal_aviso') modal_aviso:any;
@ViewChild('modal_informacion') modal_informacion:any;
@ViewChild('modal_pdf') modal_pdf:any;

  constructor(
    public modalService: NgbModal,
    public location:Location,
    private _globalService: GlobalService,
	private _router:ActivatedRoute,
    private _solucionesService: SolucionesService,
	private _contratosService: ContratosService,
	private _usuariosService:UsuariosService,
	private _empresasService:EmpresasService,
	private _logService :LogService,
	private sanitizer: DomSanitizer,
	
    
  ) {
    this.usuario_sesion = this._globalService.DatosSesion();
	this.firmante1 = new Firmantes('','','', '', '', '', '', '', '',environment.tipoNotificacionFirma);
	this.firmante2 = new Firmantes('','','', '', '', '', '', '', '',environment.tipoNotificacionFirma);
	this.firmante3 = new Firmantes('','','', '', '', '', '', '', '',environment.tipoNotificacionFirma);
	this.firmante4 = new Firmantes('','','', '', '', '', '', '', '',environment.tipoNotificacionFirma);
   }

	ngOnInit(): void {
		this._router.params.subscribe( paramMap => {
			this.id_solucion = paramMap['id_solucion'];
			this.leer_solucion();
			this.buscarPresidente();
			this.listarContratosFirma();
		});	
	}
	ngAfterViewInit(): void {
		this.infoInicial();
	}
	// Numero de ventana del formulario en el que estamos. // Siguiente 0 Es hacia atras, 1 es hacia adelante y 2 no pasa por el incremento o decremento
	ventana(num:number, tipo:number,siguiente:number){
		let continua = true;
		if(siguiente){
			continua = this.validar(num); //Si es para avanzar validamos primero que el formulario este disponible para continuar.
		}
		if (continua){
			// Si viene del boton siguiente sumamos pagina, sino quitamos pagina.
			if(siguiente == 1){
				num++;
			}else if (siguiente == 0){
				num--;
			}
			switch (num) {
				case 0:
					if(tipo == 0){
						this.automaticoContrato = tipo;
					}else{
						this.automaticoContrato = 1;
					}
					this.nombreBtnSiguiente = "Siguiente";
					this.nVentana = num;
					this.titulo = "TIPO DE CONTRATO:";
					this.subtitulo = "";
				  break;
				case 1:
					if(tipo == 0){
						this.automaticoContrato = tipo;
						this.subtitulo = "";
						this.titulo = "CONTRATO PERSONALIZADO"
					}else{
						this.automaticoContrato = 1;
						this.modalService.dismissAll();
						this.informacion = "<h2>IMPORTANTE</h2>La empresa, debe <strong>recopilar</strong> los datos necesarios para poder completar el proceso de envió de firma, dichos datos son:<ul><li>Datos de la empresa.</li><li>Representante de la empresa.</li><li>Datos de la persona investigadora.</li><li>Datos del instituto/centro de investigación</li><li>Otro representante del instituto/centro de investigación </li><li>Participantes adicionales.</li><li><b>IBAN del instituto de investigación.</b></li><li>Datos del contrato (ubicación y plazo del proyecto).</li><li>Presupuesto y condiciones de pago.</li><li>Gastos.</li></ul>";
						this.modalService.open(this.modal_informacion,{size: 'lg', backdrop: 'static', scrollable:true });
						this.subtitulo = "EMPRESA";
						this.titulo = "INTERMEDIACIÓN POR LA FGCSIC"
					}
					this.nombreBtnSiguiente = "Siguiente";
					this.nVentana = num;

				  break;
				case 2:
					if(tipo == 0){
						this.automaticoContrato = tipo;
					}else{
						this.automaticoContrato = 1;
					}
					this.nombreBtnSiguiente = "Siguiente";
					this.nVentana = num;
					this.subtitulo = "INVESTIGADOR";
					this.titulo = "INTERMEDIACIÓN POR LA FGCSIC"

					break;
				case 3:
					if(tipo == 0){
						this.automaticoContrato = tipo;
					}else{
						this.automaticoContrato = 1;
					}
					this.nombreBtnSiguiente = "Generar contrato";
					this.nVentana = num;
					this.subtitulo = "DATOS CONTRATO";
					this.titulo = "INTERMEDIACIÓN POR LA FGCSIC"

					break;
				case 4:
					this.crearContrato();
					break;
				default:
					this.nVentana = 0;
				  break;
			}
		}
	}
	// Validamos formulario en funcion de la ventana del contrato y del tipo de Primero los campos que tienen Expresiones regulares y luego los campos que no pueden ser vacios.
	validar(ventana:any){
		//Ventana es la ventana guiada en la que estamos.
		let devuelve = true
		if (ventana == 1){	
			// Primero comprobamos las expresiones regular que no este vacias ni sean incorrectas
			this.testventana1[0] = this.validarForm(this.cifExp,this.firmante1.cif);
			this.testventana1[1] = this.validarForm(this.emailExp,this.contrato.empresaEmail);
			this.testventana1[2] = this.validarForm(this.nifsExp,this.firmante1.nif);
			this.testventana1[3] = this.validarForm(this.emailExp,this.firmante1.email);
			this.testventana1[4] = this.validarForm(this.movilExp,this.firmante1.telefono);
			
			if(!this.testventana1[0] || !this.testventana1[1] || !this.testventana1[2]  || !this.testventana1[3] || !this.testventana1[4] ) devuelve = false;

			//Comprobamos los datos de la empresa para que no existan campos vacios ni nulos:
			if(this.firmante1.empresa == '' || this.contrato.empresaDireccion == '' || this.contrato.empresaSector == '' || this.firmante1.nombre == '' || this.firmante1.apellidos == '' || this.firmante1.cargo == '' || this.firmante1.telefono  == '' || this.firmante1.empresa == null || this.contrato.empresaDireccion == null || this.contrato.empresaSector == null || this.firmante1.nombre == null || this.firmante1.apellidos == null || this.firmante1.cargo == null || this.firmante1.telefono  == null) devuelve = false;
		}else if(ventana == 2){
			// Primero comprobamos las expresiones regular que no este vacias ni sean incorrectas+
			// Verificamos los campos obligarorios con expresiones regulares de: Firmante2 - Investigador
			this.testventana2[0] = this.validarForm(this.nifsExp,this.firmante2.nif);
			this.testventana2[1] = this.validarForm(this.emailExp,this.firmante2.email);
			this.testventana2[9] = this.validarForm(this.movilExp,this.firmante2.telefono);

			// Verificamos los campos opcionales con extresiones regulares de: Centro de investigacion.
			if(this.firmante3.nif != null && this.firmante3.nif != "" && this.firmante3.nif != undefined){
				this.testventana2[2] = this.validarForm(this.nifsExp,this.firmante3.nif);		
			}else{
				this.testventana2[2] =  true;	
			}
			if(this.firmante3.email != null && this.firmante3.email != "" && this.firmante3.email != undefined){
				this.testventana2[3] = this.validarForm(this.emailExp,this.firmante3.email);
				this.testventana2[10] = this.validarForm(this.movilExp,this.firmante3.telefono);
			}else{
				this.testventana2[3] =  true;
				this.testventana2[10] = true;
			}

			// Verificamos los campos opcionales con extresiones regulares de: Otro responsable.
			if(this.firmante4.nif != null && this.firmante4.nif != "" && this.firmante4.nif != undefined){
				this.testventana2[4] = this.validarForm(this.nifsExp,this.firmante4.nif);
			}else{
				this.testventana2[4] =  true;
			}
			if(this.firmante4.email != null && this.firmante4.email != "" && this.firmante4.email != undefined){
				this.testventana2[5] = this.validarForm(this.emailExp,this.firmante4.email);
				this.testventana2[11] = this.validarForm(this.movilExp,this.firmante4.telefono);
			}else{
				this.testventana2[5] =  true;
				this.testventana2[11] = true;
			}

			// Verificamos las expresiones regulares de los campos opcionales de los participantes adicionales.
			if(this.contrato.adicionalPer1dni != null && this.contrato.adicionalPer1dni != "" && this.contrato.adicionalPer1dni != undefined){
				this.testventana2[6] = this.validarForm(this.nifsExp,this.contrato.adicionalPer1dni);
			}else{
				this.testventana2[6] =  true;
			}
			if(this.contrato.adicionalPer2dni != null && this.contrato.adicionalPer2dni != "" && this.contrato.adicionalPer2dni != undefined){
				this.testventana2[7] = this.validarForm(this.nifsExp,this.contrato.adicionalPer2dni);
			}else{
				this.testventana2[7] = true;
			}
			if(this.contrato.adicionalPer3dni != null && this.contrato.adicionalPer3dni != "" && this.contrato.adicionalPer3dni != undefined){
				this.testventana2[8] = this.validarForm(this.nifsExp,this.contrato.adicionalPer3dni);
			}else{
				this.testventana2[8] = true;
			}

			if(!this.testventana2[0] || !this.testventana2[1] || !this.testventana2[2]  || !this.testventana2[3] || !this.testventana2[4] || !this.testventana2[5] || !this.testventana2[6] || !this.testventana2[7] || !this.testventana2[8] || !this.testventana2[9]) devuelve = false;
			//Comprobamos los datos del investigador para que no existan campos vacios ni nulos:
			if(this.firmante2.nombre == '' || this.firmante2.apellidos == '' || this.firmante2.cargo == '' || this.firmante2.telefono == '' || this.firmante2.nombre == null || this.firmante2.apellidos == null || this.firmante2.cargo == null || this.firmante2.telefono == null ) devuelve = false;
			// Comprobamos los datos del centro de investigacion
			if(this.contrato.investigaCentro == '' || this.contrato.investigaCentro == null) devuelve = false;

			if((this.firmante3.nombre != '' && this.firmante3.nombre != null) || (this.firmante3.nif != null && this.firmante3.nif != '')){
				if(this.firmante3.nombre == '' || this.firmante3.cargo == '' || this.firmante3.nif == '' || this.firmante3.apellidos == '' || this.contrato.investigaDireccionCentro == '' || this.firmante3.email == '' || this.firmante3.telefono == '' || this.firmante3.nombre == null || this.firmante3.cargo == null || this.firmante3.nif == null || this.firmante3.apellidos == null || this.contrato.investigaDireccionCentro == null || this.firmante3.email == null || this.firmante3.telefono == null) devuelve = false;
			}

			// Comprobamos los datos del responsable de comunicaciones.
			if((this.firmante4.nombre != '' && this.firmante4.nombre != null) || (this.firmante4.nif != null && this.firmante4.nif != '')){
				if(this.firmante4.nombre == '' || this.firmante4.cargo == '' || this.firmante4.nif == '' || this.firmante4.apellidos == '' || this.contrato.direccionOtroResponsable == '' || this.firmante4.email == '' || this.firmante4.telefono == '' || this.firmante4.nombre == null || this.firmante4.cargo == null || this.firmante4.nif == null || this.firmante4.apellidos == null || this.contrato.direccionOtroResponsable == null || this.firmante4.email == null || this.firmante4.telefono == null) devuelve = false;
			}
			//Verificamos participantes addicionales, que si no existen pase a la siguiente pagina si existen comprueba que no vayan datos vacios.
			if(this.testventana2[6] && this.contrato.adicionalPer1dni != null && this.contrato.adicionalPer1dni != "" && this.contrato.adicionalPer1dni != undefined && (this.contrato.adicionalPer1nombre == '' || this.contrato.adicionalPer1dni == '' || this.contrato.adicionalPer1catProfe == '' || this.contrato.adicionalPer1institucion == '' || this.contrato.adicionalPer1nombre == null || this.contrato.adicionalPer1dni == null || this.contrato.adicionalPer1catProfe == null || this.contrato.adicionalPer1institucion == null)) devuelve = false;

			if(this.testventana2[7] && this.contrato.adicionalPer2dni != null && this.contrato.adicionalPer2dni != "" && this.contrato.adicionalPer2dni != undefined && (this.contrato.adicionalPer2nombre == '' || this.contrato.adicionalPer2dni == '' || this.contrato.adicionalPer2catProfe == '' || this.contrato.adicionalPer2institucion == '' || this.contrato.adicionalPer2nombre == null || this.contrato.adicionalPer2dni == null || this.contrato.adicionalPer2catProfe == null || this.contrato.adicionalPer2institucion == null)) devuelve = false;

			if(this.testventana2[8] && this.contrato.adicionalPer3dni != null && this.contrato.adicionalPer3dni != "" && this.contrato.adicionalPer3dni != undefined && (this.contrato.adicionalPer3nombre == '' || this.contrato.adicionalPer3dni == '' || this.contrato.adicionalPer3catProfe == '' || this.contrato.adicionalPer3institucion == '' || this.contrato.adicionalPer3nombre == null || this.contrato.adicionalPer3dni == null || this.contrato.adicionalPer3catProfe == null || this.contrato.adicionalPer3institucion == null)) devuelve = false;
		}else if(ventana == 3){
				// Primero comprobamos las expresiones regular que no este vacias ni sean incorrectas
				this.testventana3[0] = this.validarForm(this.nifsExp,this.contrato.directorCSICdni);
				this.testventana3[1] = this.validarForm(this.numCuentaExp,this.contrato.numCuenta);
				this.validarGastoPresupuesto()
				if(!this.testventana3[0] || !this.testventana3[1] || !this.testventana3[2]  || !this.testventana3[3] || !this.testventana3[4] || !this.testventana3[6]) devuelve = false;
				//Comprobamos los datos de la empresa para que no existan campos vacios ni nulos:
				if(this.contrato.directorCSICnombreApellidos == '' || this.contrato.lugar == '' || this.duraccionNum == '' || this.duraccionTipo == '' || this.contrato.presupuesto == '' || this.contrato.gastosGenerales  === '' || this.contrato.directorCSICnombreApellidos == null || this.contrato.lugar == null || this.duraccionNum == null || this.duraccionTipo == null || this.contrato.presupuesto == null || this.contrato.gastosGenerales  === null || this.duraccionNum == '0') devuelve = false;
		}
		return devuelve;
	}

	validarExpresiones(){
		//Ponemos en MAYUSCULA los campos que tienen que validarse en MAYUSCULA
		this.contrato.numCuenta = this.contrato.numCuenta.toUpperCase();
		this.firmante1.cif = this.firmante1.cif.toUpperCase();
		this.firmante1.nif = this.firmante1.nif.toUpperCase();
		this.firmante2.nif = this.firmante2.nif.toUpperCase();
		this.firmante3.nif = this.firmante3.nif.toUpperCase();
		this.firmante4.nif = this.firmante4.nif.toUpperCase();
		this.contrato.adicionalPer1dni = this.contrato.adicionalPer1dni.toUpperCase();
		this.contrato.adicionalPer2dni = this.contrato.adicionalPer2dni.toUpperCase();
		this.contrato.adicionalPer3dni = this.contrato.adicionalPer3dni.toUpperCase();
		this.contrato.directorCSICdni = this.contrato.directorCSICdni.toUpperCase();


		//Ponemos en minuscula los campos que tienen que validarse en minuscula
		this.contrato.empresaEmail = this.contrato.empresaEmail.toLowerCase();
		this.firmante1.email = this.firmante1.email.toLowerCase();
		this.firmante2.email = this.firmante2.email.toLowerCase();
		this.firmante3.email = this.firmante3.email.toLowerCase();
		this.firmante4.email = this.firmante4.email.toLowerCase();

		this.testventana1[0] = this.validarForm(this.cifExp,this.firmante1.cif);
		this.testventana1[1] = this.validarForm(this.emailExp,this.contrato.empresaEmail);
		this.testventana1[2] = this.validarForm(this.nifsExp,this.firmante1.nif);
		this.testventana1[3] = this.validarForm(this.emailExp,this.firmante1.email);
		this.testventana1[4] = this.validarForm(this.movilExp,this.firmante1.telefono);

		this.testventana2[0] = this.validarForm(this.nifsExp,this.firmante2.nif);
		this.testventana2[1] = this.validarForm(this.emailExp,this.firmante2.email);
		this.testventana2[9] = this.validarForm(this.movilExp,this.firmante2.telefono);
		// Verificamos los campos opcionales con extresiones regulares de: Centro de investigacion.
		if(this.firmante3.nif != null && this.firmante3.nif != "" && this.firmante3.nif != undefined){
			this.testventana2[2] = this.validarForm(this.nifsExp,this.firmante3.nif);
		}else{
			this.testventana2[2] =  true;
		}

		if(this.firmante3.email != null && this.firmante3.email != "" && this.firmante3.email != undefined){
			this.testventana2[3] = this.validarForm(this.emailExp,this.firmante3.email);
		}else{
			this.testventana2[3] =  true;
		}
		if(this.firmante3.telefono != null && this.firmante3.telefono != "" && this.firmante3.telefono != undefined){
			this.testventana2[10] = this.validarForm(this.movilExp,this.firmante3.telefono);
		}else{
			this.testventana2[10] = true;
		}
		// Verificamos los campos opcionales con extresiones regulares de: Otro responsable.
		if(this.firmante4.nif != null && this.firmante4.nif != "" && this.firmante4.nif != undefined){
			this.testventana2[4] = this.validarForm(this.nifsExp,this.firmante4.nif);
		}else{
			this.testventana2[4] =  true;
		}
		if(this.firmante4.email != null && this.firmante4.email != "" && this.firmante4.email != undefined){
			this.testventana2[5] = this.validarForm(this.emailExp,this.firmante4.email);
		}else{
			this.testventana2[5] =  true;
		}
		if(this.firmante4.telefono != null && this.firmante4.telefono != "" && this.firmante4.telefono != undefined){
			this.testventana2[11] = this.validarForm(this.movilExp,this.firmante4.telefono);
		}else{
			this.testventana2[11] = true;
		}

		if(this.contrato.adicionalPer1dni != null && this.contrato.adicionalPer1dni != "" && this.contrato.adicionalPer1dni != undefined){
			this.testventana2[6] = this.validarForm(this.nifsExp,this.contrato.adicionalPer1dni);
		}else{
			this.testventana2[6] = true;
		}

		if(this.contrato.adicionalPer2dni != null && this.contrato.adicionalPer2dni != "" && this.contrato.adicionalPer2dni != undefined){
			this.testventana2[7] = this.validarForm(this.nifsExp,this.contrato.adicionalPer2dni);
		}else{
			this.testventana2[7] = true;
		}

		if(this.contrato.adicionalPer3dni != null && this.contrato.adicionalPer3dni != "" && this.contrato.adicionalPer3dni != undefined){
			this.testventana2[8] = this.validarForm(this.nifsExp,this.contrato.adicionalPer3dni);
		}else{
			this.testventana2[8] = true;
		}


		this.testventana3[0] = this.validarForm(this.nifsExp,this.contrato.directorCSICdni);
		this.testventana3[1] = this.validarForm(this.numCuentaExp,this.contrato.numCuenta);
		this.guardarContratoTemporal();
	}
	//Descargamos la solución mediante la id y obtenemos los datos de investigador e id empresa.
	leer_solucion(){
		this._solucionesService.readSoluciones(this._globalService.getToken(), this.id_solucion).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				//Asignamos el id de la solución, el nombre del reto, la cifra del premio
				this.contrato.nombreReto = res.response.listado.id_reto.nombre
				this.solucionModelo =  res.response.listado;
				this.idEmpresa = this.solucionModelo.id_reto.id_empresa
				this.datosInicialesSolucion(this.solucionModelo)
				
			}
		})
	}
	//Buscamos Administradores de nexofy para la generacion de contratos por firmafy
	buscarPresidente(){
		this._logService.leerConfigDinamicas().subscribe({
			next: (res:any)=>{
				if(res.error_code == 1){
					this.contrato.directorCSICnombreApellidos = res.response.nombrePresidenteFGCSIC;
					this.contrato.directorCSICdni = res.response.dniPresidenteFGCSIC;
				}else{
					this.contrato.directorCSICnombreApellidos = "";
					this.contrato.directorCSICdni = "";
				}
			}
		})
	}
	
	// Datos por defecto del investigador y del contrato.
	datosInicialesSolucion(modelo:any){
		if (modelo != "" && modelo != null){
			this.firmante2._id = modelo.id_usuario._id;
			this.firmante2.nombre = modelo.id_usuario.nombre;
			this.firmante2.apellidos = modelo.id_usuario.apellidos;
			this.firmante2.nif = modelo.id_usuario.nif;
			this.firmante2.cargo =  modelo.id_usuario.cargo;
			this.firmante2.email = modelo.id_usuario.email;
			this.firmante2.telefono = modelo.id_usuario.telefono;
			this.contrato.investigaCentro = modelo.id_usuario.centro_investigacion;
			this.contrato.investigaDireccion = modelo.id_usuario.direccion+ " " + modelo.id_usuario.poblacion+" ("+modelo.id_usuario.provincia+") CP:"+modelo.id_usuario.cp;
			this.contrato.presupuesto = modelo.id_reto.presupuesto;
			this.contrato.investigaDireccionCentro = "";
			this.contrato.investigaFaxCentro = "";
			this.contrato.numCuenta = "";
			this.contrato.porcentajeInicial = 30;
			this.contrato.porcentajeIntermedio = 40;
			this.contrato.porcentajeFinal = 30;
			this.contrato.gastosGenerales = 0;
			this.contrato.adicionalPer1nombre = "";
			this.contrato.adicionalPer1dni = "";
			this.contrato.adicionalPer1catProfe = "";
			this.contrato.adicionalPer1institucion = "";
			this.contrato.adicionalPer2nombre = "";
			this.contrato.adicionalPer2dni = "";
			this.contrato.adicionalPer2catProfe = "";
			this.contrato.adicionalPer2institucion = "";
			this.contrato.adicionalPer3nombre = "";
			this.contrato.adicionalPer3dni = "";
			this.contrato.adicionalPer3catProfe = "";
			this.contrato.adicionalPer3institucion = "";
			this.contrato.direccionOtroResponsable = "";
			this.contrato.faxOtroResponsable = "";
		}
	}
	// Datos de la empresa
	datosInicialesEmpresa(id:string){
		if(id != "" && id != null){
			this.leer_empresa(id)
			this.leer_usuario_empresa(id)
			setTimeout(() => {
				this.guardaActualizaciones = true;
			  }, 2000);
		}
	}
	// Leer empresa
	leer_empresa(id:any){
		this._empresasService.readEmpresas(this._globalService.getToken(), id).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				this.firmante1.cif = res.response.listado.cif
				this.firmante1.empresa = res.response.listado.nombre
				this.contrato.empresaDireccion = res.response.listado.direccion
				this.contrato.empresaSector = res.response.listado.sector
				this.contrato.empresaEmail = res.response.listado.email
			}
		})
	}
	//Leer el usuario de la empresa
	leer_usuario_empresa(id:any){
		this._usuariosService.readUsuariosEmpresa(this._globalService.getToken(), id).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				this.firmante1._id = res.response.listado._id
				this.firmante1.nombre = res.response.listado.nombre 
				this.firmante1.apellidos = res.response.listado.apellidos;
				this.firmante1.nif = res.response.listado.nif;
				this.firmante1.cargo = res.response.listado.cargo
				this.firmante1.email = res.response.listado.email
				this.firmante1.telefono = res.response.listado.telefono
			}
		})
	}

	// Obtenemos los datos guardados anteriormente para continuar con el contrato.
	encontrarDatosGuardados(){
		this._contratosService.leerContratoTemporal(this._globalService.getToken(),this.id_solucion).subscribe({
			next: (respuesta)=>{ 
				let res:any = respuesta;
				if(res.error_code == 1){
					res.response.listado.contrato = JSON.parse(res.response.listado.contrato);
					res = res.response.listado
					this.contrato = res.contrato;
					this.firmante1 = res.firmantes[0];
					this.firmante2 = res.firmantes[1];
					this.firmante3 = res.firmantes[2];
					this.firmante4 = res.firmantes[3];
					this.duraccionNum = res.contrato.duraccionNum;
					this.duraccionTipo = res.contrato.duraccionTipo;
					if(this.validarForm(this.numCuentaExp,this.contrato.numCuenta)){
						this.testventana3[1] = true;
					}
					if(res.ventana > 1){
						this.automaticoContrato = 1;
						this.ventana(res.ventana,res.tipo,2);
					}
					this.guardaActualizaciones = true;
				}else{
					this.datosInicialesEmpresa(this.idEmpresa)
				}
				
			}
		})
	}

	//Buscar contratos
	listarContratosFirma(){
		if(this.id_solucion != "" && this.id_solucion != null){
			this._contratosService.listContratosFirma(this._globalService.getToken(),this.id_solucion).subscribe({
				next: (respuesta)=>{    
					let res:any = respuesta;   
					if (res.error_code==1){
						this.contratosActivos = res.response.listado
						this.numeroContratos  = this.contratosActivos.length
					}else{
						this.numeroContratos = 0;
						this.encontrarDatosGuardados(); //Recuperamos por donde iba el formulario.
					  	this.testventana3[2] = true;
						this.testventana3[3] = true;
						this.testventana3[4] = true; 

					}
				}
			})
		}
	}
	//Si no existe contratos y no somos investigadores explicamos la ventana contratos:
	infoInicial(){
		if (localStorage.getItem('informacion_contratos') == null && this.usuario_sesion.rol < 4) { 
			this.abrirInfo();
		}
	}
	// Tras leer la informacion guardamos que se a leido ya el tutorial.
	informacionLeida(){
		this.modalService.dismissAll();
		localStorage.setItem('informacion_contratos', 'true')
	}
	// Descarga el documento original sin firmar
	descargar(i:number){
		if (i < this.contratosActivos.length){
			const downloadLink = document.createElement("a");
			downloadLink.href = 'data:application;base64,' + this.contratosActivos[i].base64 +'\n';
			downloadLink.download = this.contratosActivos[i].nombreFichero;
			downloadLink.click(); 
		}
	}
	//Enviamos a firmar los contratos
	enviarAFirmas(){
		this.modalService.dismissAll(); //cerrar todos los popups
			this._contratosService.obtenerToken(this._globalService.getToken()).subscribe({
				next: (respuesta)=>{    
					let res:any = respuesta;   
					if (!res.response.token.error && res.error_code==1){
						this.generarFirmas(res.response.token.data)
					}else{
						this.cargando = false;
						this.modalService.dismissAll(); //cerrar todos los popups
						this.aviso_error = false;
						this.mensaje_error = "Error al acceder a la aplicación de firmas, póngase en contacto con el administrador."
						this.modalService.open(this.modal_aviso,{centered:true});
					}
				}
			}) 
	}
  	//Abrimos el modal donde aparece información de la pagina.
	abrirInfo(){
		this.informacion = "<h2>INFORMACIÓN</h2> En la ventana contratos, podemos descargar y revisar el contrato de la solución presentada. </br> Tanto si está firmado como si esta generado pero pendiente de firma"
		if(this.usuario_sesion.rol < 4){
			this.informacion = "<h2>INFORMACIÓN</h2> En la ventana contratos podemos crear, subir y descargar el contrato entre empresa e investigador/a para las soluciones aprobadas. La creación y subida del contrato se realiza mediante el selector: \n" +
			"<em> Intermediación por la FGCSIC - Contrato personalizado</em> de la siguiente manera:</br><ul><li>\n" +
			"<strong> Intermediación por la FGCSIC: </strong> Crea contratos fácilmente mediante un modelo predefinido el cual creará un formulario utilizando información previa disponible en NEXOFY. \n" +
			"Todos los campos del formulario son modificables y al no estar todos los datos previamente en la plataforma NEXOFY estos serán requeridos, una vez completados y revisados todos los datos podrás avanzar haciendo clic en <em><u>Siguiente</u></em>.</br></br>\n" +
			"Una vez que el formulario esté completo, llegarás a una ventana con el botón <em><u>Generar contrato</u></em>. Al hacer clic en él, se generará un archivo PDF con el contrato, que podrás visualizar en una vista previa y descargar si lo deseas. Si estás satisfecho con el contrato, \n" +
			"puedes presionar el botón <em><u>Enviar a firmar</u></em>. Esto enviará un correo electrónico a los firmantes para que realicen su firma digital. </br></br>En la página aparecerá una tabla que muestra los firmantes y su estado: quienes ya han firmado y quienes están pendientes de firma.\n" + 
			"Una vez que todos los usuarios hayan firmado, podrás descargar el contrato firmado. Si deseas realizar modificaciones antes de enviarlo a firmar, puedes hacerlo haciendo clic en <em><u>Cancelar</u></em> y volver al formulario inicial. </br></br>\n" +
			"Con nuestro componente de generación de contratos automatizados, simplificamos y agilizamos el proceso de creación y firma de contratos, brindándote mayor comodidad y eficiencia. </br></br></li><li>\n" +
			"<strong>Contrato personalizado: </strong> En el caso de subir un contrato ya firmado, tras marcar el selector la aplicación nos dará paso a subir un fichero PDF, el cual podrán descargar posteriormente las personas implicadas que tengan acceso a la solución.";
			this.modalService.open(this.modal_informacion,{size: 'lg', backdrop: 'static', scrollable:true });
		}else{
			this.modalService.open(this.modal_informacion,{centered:true});
		}
		
	}
	abrirInfoTipo(){
		this.modalService.dismissAll();
		this.informacion = "<h2>INFORMACIÓN</h2> <p> <u>Contrato automatizado intermediado por la FGCSIC:</u> Se generará un contrato a dos partes (Empresa y organismo de investigación) con la posibilidad de incorporar una tercera parte firmante como pueden ser las Oficinas de transferencia, Vicerectorados de investigación, etc, y en el que la FGCSIC <b>actuará de intermediaria</b> para la supervisión y la gestión de los pagos, facturando a la empresa y realizando los pagos al organismo investigador. </p><p> <u>Contrato personalizado: </u> Tanto la empresa como el investigador adjuntarán un documento del contrato elaborados y firmados por las dos partes de forma externa <b>sin que haya una intermediación</b> de la FGCSIC tanto para la supervisión como para la gestión de los pagos. </p>";
		this.modalService.open(this.modal_informacion,{size: 'lg', backdrop: 'static', scrollable:true });
	}

	abrirInfoIBAN(){
		this.modalService.dismissAll();
		this.informacion = "<h2>INFORMACIÓN</h2> El <b>IBAN  del Instituto de investigación </b> servirá para que la FGCSIC como intermediaria, realice los  pagos al Instituto de investigación según los acuerdos de pagos del contrato firmados."
		this.modalService.open(this.modal_informacion,{centered:true});
	}

	//Validacion de campos
	validarForm(expresion:any,campo:any){
		if(!expresion.test(campo)){
			return false;
		}else{
			return true;
		}
	}

	// nombre, nif, cargo, email, telefono empresa, cif 
	crearContrato(){
		if(this.usuario_sesion.rol < 4 && this.numeroContratos == 0){
 			this.cargando = true;  
			this.contrato.duraccion = this.duraccionNum + " " +this.duraccionTipo 
			let parametros = {"contrato":this.contrato, "firmante1":this.firmante1, "firmante2":this.firmante2, "firmante3":this.firmante3, "firmante4":this.firmante4, "id_solucion":this.solucionModelo._id,"tipo":2}
			this._contratosService.crearPDF(this._globalService.getToken(),parametros).subscribe({
				next: (respuesta)=>{   
					let res:any = respuesta
					if(res.error_code == "1"){
						const downloadLink = document.createElement("a");
						downloadLink.href = 'data:application/pdf;base64,' + res.response.pdfBase64;
						this.base64PDF = downloadLink.href;
						this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64PDF);
						this.modalService.dismissAll();
						this.modalService.open(this.modal_pdf,{size: 'xl', backdrop: 'static', scrollable:true });
						this.cargando = false;
					}else{
						this.cargando = false;
					}
				}
			})
		}
	}

	//Verificar que todos los elementos de un array son true
	verfArray(elementos: boolean[]): Boolean{
		var verificador = false
		for(let i = 0; i < elementos.length; i++ ){
			if (elementos[i]){
				verificador = true
			}
			else{
				verificador = false
				break
			}
		}

		return verificador
	}

	verfObjeto(objeto: Firmantes):boolean{
		var verificador = false
		for(let verf in objeto){
			if(verf == ""){
				verificador = true
			}else{
				verificador = false
				break
			}
		}

		return verificador
	}


	//Generamos firma y PDF
	generarFirmas(tokenAPI:string){
		if (tokenAPI != null && tokenAPI != ""){
			 this.cargando = true;  
			let firmasModelo:any
			firmasModelo = {
				base64:this.base64PDF,
				solucion:this.solucionModelo,
				token:tokenAPI,
				firmante1:this.firmante1,
				firmante2:this.firmante2,
				firmante3:this.firmante3,
				firmante4:this.firmante4,
			}
			this._contratosService.envioFirmas(this._globalService.getToken(),firmasModelo).subscribe({
				next: (respuesta)=>{    
					let res:any = respuesta
					if(res.error_code == "1"){
 						const downloadLink = document.createElement("a");
						downloadLink.href = 'data:application;base64,' + res.response.pdfBase64 +'\n';
						downloadLink.download = res.response.nombre;
						downloadLink.click(); 
						this.listarContratosFirma();
						this.cargando = false;
						this.modalService.dismissAll();
						this.informacion = "<h2>INFORMACIÓN</h2> Es importante recordar que, una vez generado el contrato, los usuarios implicados en la solución deben <strong>firmarlo</strong> a través del correo electrónico enviado. Una vez que todos los usuarios lo hayan firmado, será posible descargar el contrato con todas las firmas. Hasta ese momento, solo estará disponible la descarga del contrato sin firmas."
						this.modalService.open(this.modal_informacion,{centered:true});

					}else{
						this.cargando = false;
						this.modalService.dismissAll(); //cerrar todos los popups
						this.aviso_error = false;
						this.mensaje_error = "Error crear el contrato, póngase en contacto con el administrador."
						this.modalService.open(this.modal_aviso,{centered:true});
					}
				}
			})
		}
	}

	
	//Verificamos que el telefono no tenga mas de 14 caracteres y solo admina +prefijo internacional y numeros.
	verificarCaracteres(event:any){
		if(event.keyCode != "8" && event.keyCode != "46"){
			let contador = event.target.value.length
			if (contador > 14){
				event.preventDefault();
			}
			if (event.keyCode == "69" || event.keyCode == "109"){
				event.preventDefault();
			}
		}
	}
		//Verificamos que el telefono no tenga mas de 14 caracteres y solo admina +prefijo internacional y numeros.
	verificarnumero(event:any){
		const keyCode = event.keyCode || event.which; // Obtener el código de la tecla
		// Verificar si la tecla presionada es un número (0-9) o las teclas de borrar o suprimir
		if (
		  (keyCode >= 48 && keyCode <= 57) || // Números del teclado numérico
		  (keyCode >= 96 && keyCode <= 105) || // Números de la fila superior del teclado
		  keyCode === 8 || // Tecla de retroceso (borrar)
		  keyCode === 46 || // Tecla de suprimir
		  keyCode === 188 || // Tecla para decimales
		  keyCode === 9 //Tecla TAB
		) {
		  // La tecla es válida, no se hace nada
		} else {
		  // La tecla no es un número ni una tecla de borrar/suprimir, se previene el evento
		  event.preventDefault();
		}
	}
	//Verificamos los porcentajes:
	validarPorcentaje(valor: string, indice: number) {
		const porcentaje = parseInt(valor, 10);

		// Validar que el valor sea un número y esté dentro del rango válido
		if (isNaN(porcentaje) || porcentaje < 0 || porcentaje > 100) {
		  this.testventana3[indice] = false;
		  return;
		}
		// Actualizar la propiedad correspondiente según el índice
		switch (indice) {
		  case 2:
			this.contrato.porcentajeInicial = porcentaje;
			break;
		  case 3:
			this.contrato.porcentajeIntermedio = porcentaje;
			break;
		  case 4:
			this.contrato.porcentajeFinal = porcentaje;
			break;
		}
	  
		// Calcular la suma de los porcentajes
		const sumaPorcentajes = this.contrato.porcentajeInicial + this.contrato.porcentajeIntermedio + this.contrato.porcentajeFinal;

		// Verificar si la suma supera el 100
		if (sumaPorcentajes > 100 || sumaPorcentajes < 100) {
		  this.testventana3[2] = false;
		  this.testventana3[3] = false;
		  this.testventana3[4] = false;
		} else {
		  // Restablecer la validez de los campos
		  this.testventana3[2] = true;
		  this.testventana3[3] = true;
		  this.testventana3[4] = true;
		}
	  }
	// Validamos suma
	validarSumaPorcentajes() {
		let campos = [this.testventana3[2],this.testventana3[3], this.testventana3[4]]
		//let campo = [this.contrato.porcentajeInicial, this.contrato.porcentajeIntermedio, this.contrato.porcentajeFinal] 
		const suma = campos.reduce((total, campo) => total + (campo ? 0 : 1), 0);
		if (suma === 100) {
		  // La suma de los porcentajes es igual a 100, todos los campos son válidos
		  this.testventana3[2] = true;
		  this.testventana3[3] = true;
		  this.testventana3[4] = true;
		} else {
		  // La suma de los porcentajes no es igual a 100, al menos un campo es inválido
		  this.testventana3[2] = false;
		  this.testventana3[3] = false;
		  this.testventana3[4] = false;
		}
	}
	// Validamos Gastos / Presupuesto
	validarGastoPresupuesto() {
		let presupuesto = 0.0;
		let sumatorio = 0.0;
		presupuesto = parseFloat(this.contrato.presupuesto);

		//Damos formato a las variables y controlamos que no sean gastos nulos o vacios.

		if(this.contrato.gastosGenerales != "" && this.contrato.gastosGenerales != null) sumatorio += parseFloat(this.contrato.gastosGenerales);
			
		if(this.contrato.otrosGastospersonal != "" && this.contrato.otrosGastospersonal != null) sumatorio += parseFloat(this.contrato.otrosGastospersonal);

		if(this.contrato.otrosGastosplantilla != "" && this.contrato.otrosGastosplantilla != null) sumatorio += parseFloat(this.contrato.otrosGastosplantilla);

		if(this.contrato.otrosGastoslaborales != "" && this.contrato.otrosGastoslaborales != null) sumatorio += parseFloat(this.contrato.otrosGastoslaborales);

		if(this.contrato.otrosGastosbecas != "" && this.contrato.otrosGastosbecas != null) sumatorio += parseFloat(this.contrato.otrosGastosbecas);

		if(this.contrato.otrosGastosinventariable != "" && this.contrato.otrosGastosinventariable != null) sumatorio += parseFloat(this.contrato.otrosGastosinventariable);

		if(this.contrato.otrosGastosfungibles != "" && this.contrato.otrosGastosfungibles != null) sumatorio += parseFloat(this.contrato.otrosGastosfungibles);

		if(this.contrato.otrosGastosviajesYdietas != "" && this.contrato.otrosGastosviajesYdietas != null) sumatorio += parseFloat(this.contrato.otrosGastosviajesYdietas);

		if(this.contrato.otrosGastoscolaboradores != "" && this.contrato.otrosGastoscolaboradores != null) sumatorio += parseFloat(this.contrato.otrosGastoscolaboradores);

		if(this.contrato.otrosGastosserviciosExternos != "" && this.contrato.otrosGastosserviciosExternos != null) sumatorio += parseFloat(this.contrato.otrosGastosserviciosExternos);

		if(this.contrato.otrosGastosotros != "" && this.contrato.otrosGastosotros != null) sumatorio += parseFloat(this.contrato.otrosGastosotros);

		//Sumamos 1 al presupuesto para tener margen en los decimales.
		if(presupuesto+1>=sumatorio){
			this.testventana3[6] = true;
		}else{
			this.testventana3[6] = false;
		}
	}

	// Cargar localizador de fichero
	buscarFichero(){
		document.getElementById("inputFichero")?.click();
	}
	// Subir fichero
	subir_fichero(event:any){
		this.cargando = true;
		if(event.target.files.length == 1){
			const file = event.target.files[0]
			if (file) {
			  const reader = new FileReader();
			  reader.readAsDataURL(file);
			  reader.onloadend = () => {
				const base64 = (reader.result as string).split(',')[1]; // Obtener solo la parte Base64 del resultado
				this.subirPDF(base64);
			  };
			}
		}
	}
	// Subir contratos propios
	subirPDF(base64:string){
		let contratoPDF:any;
		contratoPDF={
			id_solucion:this.solucionModelo._id,
			nombreFichero: this.solucionModelo._id+".pdf",
			base64:base64,
			urlFirmado:"",
			firmantes:[],
			cdate:Date.now(),
			mdate:Date.now(),
			borrado:0,
			estado:1,
			tipo:1,
		}
		this._contratosService.subirContrato(this._globalService.getToken(),contratoPDF).subscribe({
			next: (respuesta)=>{    
				let res:any = respuesta
				if(res.error_code == "1"){
					this.contratosActivos = [];
					this.contratosActivos.push(contratoPDF);
					this.numeroContratos = this.contratosActivos.length;
					this.cargando = false;
				}else{
					this.cargando = false;
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.mensaje_error = "Error crear el contrato, póngase en contacto con el administrador."
					this.modalService.open(this.modal_aviso,{centered:true});
				}
			}
		})
	}

	//Funcion para capturar base64 contratos propios
	public dropped(files: NgxFileDropEntry[]) {
		this.cargando = true;
		if(files.length==1){
			for (const droppedFile of files) {
				// Is it a file?
				if (droppedFile.fileEntry.isFile) {
					const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

					fileEntry.file((file: File) => {
						const reader = new FileReader();
			  			reader.readAsDataURL(file);
						reader.onloadend = () => {
							if (file.type === 'application/pdf') {
								const base64 = (reader.result as string).split(',')[1];
								this.subirPDF(base64);
							}else{
								this.alertaSubirFichero();
							}
						};
					})
				}
			}
		}else{
			this.alertaSubirFichero();
		}
	}

	alertaSubirFichero(){
		this.cargando = false;
		this.modalService.dismissAll(); //cerrar todos los popups
		this.aviso_error = false;
		this.mensaje_error = "Solo puede subir 1 contrato en formato 'PDF'."
		this.modalService.open(this.modal_aviso,{centered:true});
	}

	// Leemos el contrato tempotal
	leerContratoTemporal(){
		this._contratosService.leerContratoTemporal(this._globalService.getToken(),this.id_solucion).subscribe({
			next: (respuesta)=>{    
				let res:any = respuesta
				if(res.error_code == "1"){
					console.log(res);
				}else{
					console.log(res);
				}
			}
		})
	}

	//Actualizamos campos del formulario, segun se van escribiendo los cambios.
	guardarContratoTemporal(){
		if(this.guardaActualizaciones){
			this.contrato.duraccionNum = this.duraccionNum;
			this.contrato.duraccionTipo = this.duraccionTipo;
			let firmantes = [this.firmante1,this.firmante2,this.firmante3,this.firmante4]
			let param = {contrato:this.contrato, firmantes:firmantes, id_solucion:this.solucionModelo._id,tipo:1,ventana:this.nVentana}
			this._contratosService.saveContratoTemporal(this._globalService.getToken(),param).subscribe({
				next: (respuesta)=>{    
					let res:any = respuesta
				}
			})
		}
	}
}
