<div class="cabecera-componente">
     <div class="boton-atras clicable" (click)="this.location.back()">
          <img src="../../assets/Icon feather-arrow-left-circle.svg">
     </div>
     <h1 class="titulo-componente">{{titulo}}<img class="btn_informacion" src="../../assets/info.svg"
               (click)="abrirInfo(1)"></h1>
</div>
<div class="div-crear">
     <div class="cabecera" *ngIf="retoModelo?.id_empresa">
          <div class="columna-empresa">
               <div class="foto">
                    <img src={{url_imagenes}}{{retoModelo.id_empresa.imagen}}>

               </div>
               <div class="nombre">
                    {{retoModelo.id_empresa.nombre}}
               </div>
          </div>
          <div class="etiqueta">{{retoModelo.id_empresa.sector}}</div>
     </div>

     <h3>Imagen principal</h3>
     <span>Pulse sobre la imagen para modificarla</span>
     <div class="fila-imagen">
          <!-- <input type="file" accept="image/*" class="form-control" id="inputImagen" [(ngModel)]="retoModelo.imagen_destacada" style="display: none;" (change)="subir_imagen($event)"> -->
          <input type="file" accept="image/*" class="form-control" id="inputImagen" style="display: none;"
               (change)="fileChangeEvent($event)">
          <img src="{{imagenReto}}" (click)="buscar_imagen()" *ngIf="!subiendo_imagen"
               alt="Pulse sobre la imagen para modificarla" title="Pulse sobre la imagen para modificarla">
          <img src="../../assets/loading.svg" alt="logo usuario" *ngIf="subiendo_imagen"
               alt="Pulse sobre la imagen para modificarla" title="Pulse sobre la imagen para modificarla">
     </div>


     <div class="div-formulario">
          <div class="inline-form">
               <div class="form-line">
                    <label>Título*</label>
                    <input type="text" class="form-control full-width" placeholder="Título" name="nombre"
                         #nombre="ngModel" [(ngModel)]="retoModelo.nombre" value="{{retoModelo.nombre}}"
                         [ngClass]="{'error': camposObligatorios.nombre}">
               </div>
          </div>
          <div class="inline-form">
               <div class="form-group">
                    <label>Sector*</label>
                    <select class="form-select clicable" placeholder="Sector del reto" name="sector" #sector="ngModel"
                         [(ngModel)]="retoModelo.sector" value="{{retoModelo.sector}}"
                         [ngClass]="{'error': camposObligatorios.sector}">
                         <option *ngFor="let op of opciones.sector">{{op}}</option>
                    </select>
               </div>
               <div class="form-group">
                    <label>Área*</label>
                    <select class="form-select clicable" placeholder="Sector del reto" [(ngModel)]="retoModelo.area"
                         [ngClass]="{'error': camposObligatorios.area}">
                         <option *ngFor="let op of opciones.area">{{op}}</option>
                    </select>
               </div>
               <div class="col-md-2 div-anonimo">
                    <label class="clicable" (click)="abrirInfo(3)"> Anonimizar empresa <span
                              class="informa">i</span></label>
                    <button *ngIf="retoModelo.mostrarAnonimo == 0" (click)="cambiarAnonimo()" [class]="'btn-anonimOK'"
                         class="form-control btn-anonimo">ANÓNIMA</button>
                    <button *ngIf="retoModelo.mostrarAnonimo == 1" (click)="cambiarAnonimo()" [class]="'btn-anonimo'"
                         class="form-control btn-anonimo">VISIBLE</button>
               </div>
               <!--                <div class="form-group">
                    <label>Tipología</label>
                    <select class="form-select clicable" placeholder="Tipología del reto" [(ngModel)]="retoModelo.tipologia" [ngClass]="{'error': camposObligatorios.tipologia}">
                         <option>Desafíos-Retos de innovación empresarial</option>
                         <option>Open Lab</option>
                         <option>Inversión</option>
                         <option>Compra pública-innovadora</option>
                         <option>Ideas</option>
                         <option>Prueba de concepto</option>
                         <option>Retos RSC</option>
                         <option>Consorcios</option>
                    </select>
               </div> -->
               <!--TODO: implementar tags-->

          </div>

          <div class="inline-form">
               <div class="form-group-cuatro">
                    <label>Nº maximos de premios</label>
                    <select class="form-select clicable" [(ngModel)]="retoModelo.numPremios">
                         <option value="1">1</option>
                         <option value="2">2</option>
                         <option value="3">3</option>
                         <option value="4">4</option>
                         <option value="5">5</option>
                         <option value="6">9</option>
                         <option value="7">7</option>
                         <option value="8">8</option>
                         <option value="9">9</option>
                         <option value="10">10</option>
                    </select>
               </div>

               <div class="form-group-cuatro">
                    <label>Importe por premio</label>
                    <div class="input-group">
                         <input type="number" class="form-control reducido" placeholder="Cantidad del premio"
                              [(ngModel)]="retoModelo.premio">
                         <span class="input-group-texto">€</span>
                    </div>
               </div>
               <div class="form-group-cuatro">
                    <label class="clicable" (click)="abrirInfo(2)"> Presupuesto e Información <span
                              class="informa">i</span></label>
                    <div class="input-group">
                         <input type="number" class="form-control reducido" placeholder="Presupuesto para invertir"
                              [(ngModel)]="retoModelo.presupuesto">
                         <span class="input-group-texto">€</span>
                         <button (click)="muestraInfoPresupuesto()"
                              class="input-group-boton">{{simboloPresupuesto}}</button>
                    </div>
               </div>

               <div class="form-group-cuatro">
                    <label>Fecha de finalización*</label>
                    <input type="date" class="form-control" placeholder="Fecha de finalización..."
                         min="{{fechaActual | date:'yyyy-MM-dd'}}" name="fecha_fin" #fecha_fin="ngModel"
                         [(ngModel)]="retoModelo.fecha_fin" value="{{retoModelo.fecha_fin}}"
                         [ngClass]="{'error': camposObligatorios.fecha_fin}">
               </div>
          </div>

          <div class="form-group" *ngIf="simboloPresupuesto == '-'">
               <h3>Detalles del presupuesto </h3>
               <p [ngClass]="{'max-longitud':caracteres_criterios_presupuesto>max_caracteres_criterios_presupuesto}">
                    {{caracteres_criterios_presupuesto}}/{{max_caracteres_criterios_presupuesto}}
               </p>
               <p *ngIf="camposObligatorios.criterios" class="alerta_formulario">Este campo es obligatorio</p>
               <p *ngIf="caracteres_criterios_presupuesto>max_caracteres_criterios_presupuesto"
                    class="alerta_formulario">El texto es demasiado largo</p>
               <editor name="detalles" #detalles="ngModel" [(ngModel)]="retoModelo.criterios_presupuesto"
                    (onKeyUp)="actualizarCaracteres()" apiKey="l16bkj4mgm1xi9iaeel16pfrvl5hd0234u05d9aim7jrd36w" [init]="{
                         branding: false,
                         base_url: '/tinymce',
                         suffix: '.min',
                         height: 500,
                         menubar: false,
                         plugins: [
                         'advlist autolink lists link image charmap print preview anchor',
                         'searchreplace visualblocks image code fullscreen',
                         'insertdatetime media table paste code help wordcount emoticons textcolor'
                         ],
                         toolbar:
                         'undo redo | formatselect forecolor backcolor| bold italic backcolor | \
                         alignleft aligncenter alignright alignjustify | \
                         bullist numlist outdent indent | removeformat | fontfamily fontsize blocks | image code emoticons | help'
                    }"></editor>
          </div>

          <h3>Breve descripción*</h3>
          <p [ngClass]="{'max-longitud':caracteres_descripcion>max_caracteres_descripcion}">
               {{caracteres_descripcion}}/{{max_caracteres_descripcion}}
          </p>
          <p *ngIf="caracteres_descripcion>max_caracteres_descripcion" class="alerta_formulario">El texto es demasiado
               largo</p>
          <p *ngIf="camposObligatorios.descripcion" class="alerta_formulario">Este campo es obligatorio</p>
          <textarea class="form-control" name="descripcion" #descripcion="ngModel" [(ngModel)]="retoModelo.descripcion"
               (keyup)="actualizarCaracteres()"></textarea>
          <p></p>

          <h3>Detalles</h3>
          <p [ngClass]="{'max-longitud':caracteres_detalles>max_caracteres_detalles}">
               {{caracteres_detalles}}/{{max_caracteres_detalles}}
          </p>
          <p *ngIf="caracteres_detalles>max_caracteres_detalles" class="alerta_formulario">El texto es demasiado largo
          </p>
          <editor name="detalles" #detalles="ngModel" [(ngModel)]="retoModelo.detalles"
               (onKeyUp)="actualizarCaracteres()" apiKey="l16bkj4mgm1xi9iaeel16pfrvl5hd0234u05d9aim7jrd36w" [init]="{
                    branding: false,
                    base_url: '/tinymce',
                    suffix: '.min',
                    height: 500,
                    menubar: false,
                    plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks image code fullscreen',
                    'insertdatetime media table paste code help wordcount emoticons textcolor'
                    ],
                    toolbar:
                    'undo redo | formatselect forecolor backcolor| bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | fontfamily fontsize blocks | image code emoticons | help'
               }"></editor>



          <h3>Archivos adjuntos</h3>
          <div class="div-adjuntos">
               <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                         <div class="div-arrastrar">
                              <span>Arrastre aquí un archivo o</span>
                              <button class="boton medium" (click)="buscarFichero()">Seleccione un archivo</button>
                         </div>
                    </ng-template>
               </ngx-file-drop>

               <input type="file" style="display: none;" id="inputFichero" (change)="subir_fichero($event)">

               <table class="lista-ficheros">
                    <tr *ngFor="let documento of retoModelo.documentos">
                         <td>
                              <img class="icono" src="../../../assets/pdf.svg"
                                   *ngIf="documento.fichero.includes('.pdf')">
                              <img class="icono" src="../../../assets/txt.svg"
                                   *ngIf="documento.fichero.includes('.txt')">
                              <img class="icono" src="../../../assets/doc.svg"
                                   *ngIf="documento.fichero.includes('.doc')">
                              <img class="icono" src="../../../assets/svg.svg"
                                   *ngIf="documento.fichero.includes('.svg')">
                              <img class="icono" src="../../../assets/jpg.svg"
                                   *ngIf="documento.fichero.includes('.jpg')">
                              <img class="icono" src="../../../assets/png.svg"
                                   *ngIf="documento.fichero.includes('.png')">
                              <img class="icono" src="../../../assets/rar.svg"
                                   *ngIf="documento.fichero.includes('.rar') || documento.fichero.includes('.zip')">
                              <img class="icono" src="../../../assets/vicdeo-azul.svg"
                                   *ngIf="documento.fichero.includes('.mp4') || documento.fichero.includes('.mpeg') || documento.fichero.includes('.mpg') || documento.fichero.includes('.mpg')">
                              <img class="icono" src="../../../assets/x (1).svg"
                                   *ngIf="documento.fichero.includes('error.error')">
                              <img class="icono" src="../../../assets/loading.svg" *ngIf="documento.fichero==''">
                         </td>
                         <td>{{documento.nombre}}</td>
                         <td><button class="boton icono secondary danger"
                                   (click)="borrar_fichero(documento.fichero)"><img
                                        src="../../../assets/trash-2-rojo.svg"></button></td>
                    </tr>
               </table>
          </div>

          <button class="boton large" (click)="prepararImagenParaSubir()">
               <!--Cambiar para hacer primero imagen prepararImagenParaSubir()-->
               <span *ngIf="retoModelo._id==''">Guardar</span>
               <span *ngIf="retoModelo._id!=''">Actualizar</span>
          </button>

          <!-- </form> -->
     </div>

</div>


<!--Modal para aceptar o rechazar contratos-->
<ng-template #modal_aviso let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>

               <div class="modal-body aviso">
                    <div class="icono-aviso">
                         <img src="../../../assets/x (1).svg" *ngIf="!aviso_error" width="100%">
                         <img src="../../../assets/correcto.svg" *ngIf="aviso_error" width="100%">
                    </div>
                    <div class="titulo">
                         <h2>{{this.mensaje_error}}</h2>
                    </div>
               </div>
          </div>
     </div>
</ng-template>


<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
               <div class="modal-body">
                    <p [innerHTML]="informacion"></p>
               </div>
               <div class="modal-footer">
                    <button class="boton medium" (click)="modal.dismiss()">Aceptar</button>
               </div>
          </div>
     </div>
</ng-template>

<!--Modal para mostrar informacion-->
<ng-template #modal_imagen let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="cancelarImagen()">
               </div>
               <div class="modal-body">
                    <image-cropper [imageURL]="imageChangedEvent" [maintainAspectRatio]="false" [resizeToWidth]="415"
                         [resizeToHeight]="234" [canvasRotation]="0" [cropperMinWidth]="415" [cropperMinHeight]="234"
                         [cropperMaxWidth]="415" [cropperMaxHeight]="234" format="png"
                         (imageCropped)="imageCropped($event)" #cropper>
                    </image-cropper>
                    <span>Tamaño de imagen 400 x 260</span>
               </div>
               <div class="modal-footer">
                    <button class="boton medium" (click)="cargarImagen()">Aceptar</button>
               </div>
          </div>
     </div>
</ng-template>