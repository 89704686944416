import { DatePipe, getLocaleEraNames } from '@angular/common';
import { outputAst } from '@angular/compiler';
import { Component, OnInit, Output,ViewChild, ElementRef} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventEmitter } from 'stream';
import { EmpresasService } from '../empresas/empresas.service';
import { Empresas } from '../models/empresas';
import { Retos } from '../models/retos';
import { GlobalService } from '../services/global.services';
import { SolucionesService } from '../soluciones/soluciones.service';
import { RetosService } from './retos.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { LogService } from '../log/log.service';


@Component({
	selector: 'app-retos',
	templateUrl: './retos.component.html',
	styleUrls: ['./retos.component.css'],
})


export class RetosComponent implements OnInit {

	public crear_reto:boolean=false;
	public lista_retos:any;
	public lista_soluciones:any;
	public lista_soluciones_agrupadas:any;
	public lista_retos_activados:any;
	public retoModelo:any;
	public empresaModelo:any;
	public formulario_reto:UntypedFormGroup;
	public usuario_sesion:any;
	public validar_formulario:boolean;
	public arrayRetoModelo = new Array;
	public cantidad:any;
	public variable_vacia = "";
	public ver_boton_crear:any;
	//@Output()  reto_seleccionado = new EventEmitter<any>();
	public pipe = new DatePipe('en-US');
	public paginaActual:any =1;
	public paginastotal:any = 1;
	public informacion:string = "";
	public nRetos:number = 0;
	public tieneContrato:number = 0;
	public fechaActualFormateada:any;
	public dniPresidenteFGCSIC:string = "";
	public nombrePresidenteFGCSIC: string = "";
	public checkaceptado: boolean = false;
	
	
	@ViewChild('modal_informacion') modal_informacion:any;
	@ViewChild('modal_contrato') modal_contrato:any;
	@ViewChild('checkbox') checkbox:any;

	constructor(
		private router:Router,
		private _retosService:RetosService,
		private _empresasService:EmpresasService,
		private _solucionesService:SolucionesService,
		private _globalService:GlobalService,
		private formBuilder: UntypedFormBuilder,
		private modalService: NgbModal,
		private _logService:LogService,

	) { 
		this.usuario_sesion = this._globalService.DatosSesion();
		this.retoModelo = new Retos('', '', '','', '', 0, '', '', new Date, 0, '', '', new Date, new Date, '', 0,1,1,'','',0, 0,'','',[],'');
		this.empresaModelo =  new Empresas('', '', '', '', '', '', '', '', '', '', '', '', '', '', 0, 0, '', new Date, new Date, '','',0, new Date);
		this.descargar_retos();	
		this.formulario_reto = this.montar_formulario_retos();
		this.leer_empresa(this.usuario_sesion.id_empresa);
		this.validar_formulario = true;	
		this.leerConfigDinamicas();
	}


	ngOnInit(): void {
		
	}
	ngAfterViewInit(): void {
		const fechaActual = new Date();
		const formatoFecha = "d 'de' MMMM 'de' yyyy";
		this.fechaActualFormateada = format(fechaActual, formatoFecha, { locale: es });
		
	}

	// Leemos las variables dinamicas de la API Para obtener el nombre y NIF del presidente de la asociación.:
	leerConfigDinamicas(){
		this._logService.leerConfigDinamicas().subscribe({
			next: (res:any)=>{
				if(res.error_code==1){
					this.dniPresidenteFGCSIC = res.response.dniPresidenteFGCSIC;
					this.nombrePresidenteFGCSIC = res.response.nombrePresidenteFGCSIC;
				}
			}
		})
	}


	descargar_retos(){
		this._retosService.listRetos(this._globalService.getToken(),this.paginaActual).subscribe({
			next: (respuesta)=>{ 
				let res:any = respuesta;     
				if(res.error_code == 1){
					this.lista_retos =  res.response.listado; 	
					this.paginastotal = res.response.paginas;
					this.paginaActual = res.response.pagina;
					if (res.response.listado.length > 0){
						this.nRetos = res.response.listado.length;
					}else{
						this.nRetos = 0;
					}
				}else{
					this.nRetos = 0;
				}

			}
		})
	}

	
	descargar_soluciones(){
		this._solucionesService.listSoluciones(this._globalService.getToken()).subscribe({
			next: (respuesta)=>{    
				let res:any = respuesta;     
				this.lista_soluciones  = res.response.listado;  
				var arrayListaSolucionesTotales = new Array; 
				arrayListaSolucionesTotales= this.lista_soluciones;
				for(let j=0; j < arrayListaSolucionesTotales.length; j++){
					this.lista_soluciones_agrupadas = this.arrayRetoModelo.filter((item)=> item._id == this.lista_soluciones[j].id_reto._id);
				}
				this.cantidad = this.lista_soluciones_agrupadas.length
			}
		})
	}


	leer_empresa(id:any){
		this._empresasService.readEmpresas(this._globalService.getToken(), id._id).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				this.empresaModelo =  res.response.listado;
				this.tieneContrato =this.empresaModelo.contrato
			}
		})
	}


/* 	leer_reto(id:any){
		this._retosService.readRetos(this._globalService.getToken(), id).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				//this.reto_seleccionado.emit(res.response.listado);
			}
		})
	} */


	ver_reto(id:any){
		this.router.navigate(['/retos/' + id])
	}


	editar_reto(id:any){
		if(id == ""){
			if (this.tieneContrato == 1){
				id = "create"
				this.router.navigate(['/retos-editar/' + id]);
			}else{
				this.modalService.open(this.modal_contrato, { size: 'xl', backdrop: 'static', scrollable: true })
			}
		}else{
			this.router.navigate(['/retos-editar/' + id]);
		}	
	}
	aceptar_contrato(){
		console.log("EMPRESA MODELO: ",this.empresaModelo)
		this._empresasService.aceptarContrato(this.empresaModelo,this._globalService.getToken()).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				if(res.error_code == 1){
					this.modalService.dismissAll();
					let id = "create"
					this.router.navigate(['/retos-editar/' + id])
				}else{
					this.modalService.dismissAll();
					this.informacion = "Fallo al aceptar el contrato"
					this.modalService.open(this.modal_informacion,{centered:true});
				}
			}
		})
	}

	rechazar_contrato(){
		this.modalService.dismissAll();
	}

	montar_formulario_retos(){
		return this.formBuilder.group({	
			nombre:[this.retoModelo.nombre, [Validators.required]],
			descripcion:[this.retoModelo.descripcion, [Validators.required]],
			detalles:[this.retoModelo.detalles, [Validators.required]],
			sector:[this.retoModelo.sector, [Validators.required]],
			fecha_fin:[this.retoModelo.fecha_fin, [Validators.required]],
		})
	}


	mostrar_boton_activo(){
		var fecha = new Date();
		var array = new Array;
		array = this.lista_retos_activados;

		for( var i = 0; i < array.length ; i++ ) {
			if(array[i].fecha_fin.getTime() > fecha){
				array[i].estado = 0;
			}else{
				array[i].estado = 1;
			}
		}	
	}
	paginaPrevia(){
		if(this.paginaActual > 1){
			this.paginaActual= this.paginaActual - 1;
			this.descargar_retos()
		}
	}
	
	paginaSiguiente(){
		if(this.paginaActual < this.paginastotal){
			this.paginaActual = this.paginaActual +1;
			this.descargar_retos()
		}
	}
	abrirInfo(){
		this.informacion = "En esta ventana podremos crear y ver los retos que como empresa tenemos creados, pinchando encima de uno de ellos nos llevara visualizarlo."
		this.modalService.open(this.modal_informacion,{centered:true});
	}
}
