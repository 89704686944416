<div *ngIf="usuario_sesion.rol==3">
     <h1 class="titulo-componente">Mis retos<img class="btn_informacion" src="../../assets/info.svg"(click)="abrirInfo()"></h1>
     <button *ngIf="usuario_sesion.rol != 4" class="boton secondary medium" (click)="editar_reto(variable_vacia) ">Crear reto</button>
     <div class="div-retos">
          <table class="table">
               <thead>
                    <th>Nombre</th>
                    <th>Estado</th>
                    <th>Fecha publicación</th>
                    <th>Fecha fin</th>
                    <th>Soluciones</th>
               </thead>
               <tbody >
                    <tr class="clicable"  *ngFor="let reto of lista_retos"  (click)="ver_reto(reto._id)" [ngClass]="{'baneado':reto.baneado}">
                         <td>{{reto.nombre}}</td>
                         <td>
                              <span class="label_tabla px-4 amarillo" *ngIf="reto.estado == 0">Borrador</span>
                              <span class="label_tabla px-4 amarillo" *ngIf="reto.estado == 1">En revisión</span>
                              <span class="label_tabla px-4 verde" *ngIf="reto.estado == 2">Validado</span>
                              <span class="label_tabla px-4 verde" *ngIf="reto.estado == 3">Activo</span>
                              <span class="label_tabla px-4" *ngIf="reto.estado == 4">Finalizado</span>
                              
                         </td>
                         <td>{{pipe.transform(reto.cdate, 'dd-MM-yyyy')}}</td>
                         <td>{{pipe.transform(reto.fecha_fin, 'dd-MM-yyyy')}}</td>
                         <td>{{reto.numero_soluciones}}</td> 
                    </tr>
               </tbody>
          </table>
     </div>
</div>
<div *ngIf="usuario_sesion.rol==3 && nRetos > 0" class="div-paginacion">
     <div class="atras">
          <button *ngIf="paginaActual>1"  (click)="paginaPrevia()" class="boton secondary"><img src="../../assets/arrow-left.svg">Anterior</button>
     </div>
     <div class="paginas">{{paginaActual}}/{{paginastotal}}</div>
     <div class="siguiente">
          <button class="boton secondary"*ngIf="paginaActual<paginastotal" (click)="paginaSiguiente()"><img src="../../assets/arrow-right.svg">Siguiente</button>
     </div>
</div>

<!--Modal para mostrar informacion-->
 <ng-template #modal_informacion let-modal >
     <div class="modal-content">
          <div class="modal-content">
			<div class="modal-header">
				<img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
			</div>
               <div class="modal-body">
				<p>
                        {{informacion}}
                    </p>				
               </div> 
               <div class="modal-footer">
                    <button class="boton medium"(click)="modal.dismiss()">Aceptar</button>
               </div>            
          </div>
     </div>
</ng-template>

<ng-template #modal_contrato let-modal >
     <div class="modal-content">
           <div class="modal-content">
                <div class="modal-header">
                     <h5 class="modal-title">CONTRATO DE CONFIDENCIALIDAD</h5>
                </div>
                <div class="modal-body">
                    <div class="parrafo">
                         <input type="text" style="display: none;">
                   </div>
                    <div class="parrafo fecha"> En {{empresaModelo.poblacion}}, a {{fechaActualFormateada}}</div>
 
                    <span class="titulo">COMPARECE. </span> 
                    <div class="parrafo">{{empresaModelo.nombre}} con C.I.F {{empresaModelo.cif}} y sede en {{empresaModelo.direccion}} {{empresaModelo.poblacion}} ({{empresaModelo.provincia}}) CP:{{empresaModelo.cp}}, y en representación D./Dña. {{usuario_sesion.nombre}} {{usuario_sesion.apellidos}} (en adelante el “usuario persona proponente”) actuando en calidad de su cargo como {{usuario_sesion.cargo}}, con D.N.I. {{usuario_sesion.nif}}</div>
                    <div class="parrafo">Que esta parte podrá ser parte emisora y/o parte receptora según el objeto de este acuerdo y en adelante denominada como “LA PARTE” o en su conjunto como “LAS PARTES”, y en adelante en este documento, podrán ser denominada como USUARIA.
                   </div>
                   <div class="parrafo">
                       En presencia de la <b>FUNDACIÓN GENERAL CSIC </b>con domicilio social en la calle Serrano nº 117; 28006 Madrid y con N.I.F.: G-85562106, representada por <b>D.{{nombrePresidenteFGCSIC}}</b>, mayor de edad, con DNI {{dniPresidenteFGCSIC}}, en nombre y representación de la en su calidad de director general, en base a las facultades que dicho cargo tiene conferidas en escrituras públicas de fecha 2 de abril de 2009, otorgada ante la notaria del Ilustre Colegio de Madrid, Doña Isabel Griffo Navarro, bajo el número 649 de su protocolo y de fecha 12 de mayo de 2021, otorgada ante el notario del Ilustre Colegio de Madrid, Don José Periel Martín, bajo el número 2.919 de su protocolo, de ampliación de facultades; cargo que ostenta desde su nombramiento el 1 de enero de 2021. (En adelante en este documento, <b>FGCSIC)</b>
                   </div>
                   <div class="parrafo">
                       Las partes han acordado celebrar el presente <b>ACUERDO DE CONFIDENCIALIDAD </b>que se regirá por las siguientes cláusulas, previas las siguientes
                   </div>

                   <span class="titulo"> CONSIDERACIONES </span>
                   <div class="parrafo">
                       <span class="tituloSecundario">Primera.- </span>Que FGCSIC es una fundación privada perteneciente al CSIC que se dedica, entre otras actividades, a la innovación aplicada a través de diferentes prácticas como son: la creación de modelos de negocio -&nbsp;detección de nuevas oportunidades de mercado, conceptualización y desarrollo de productos compatibilizando soluciones tecnológicas específicas, incubación y aceleración corporativa – creación de spin in/off, aceleración de startups y transferencia tecnológica con su financiación correspondiente a través del fondo de inversión FGCSIC SCIENCE TECH en constitución, así como a la gestión de la plataforma NEXOFY.
                   </div>
                   <div class="parrafo">
                       <span class="tituloSecundario">Segunda.-</span> Que LA PARTE es una persona que actúa en su propio nombre y representación o en nombre y por cuenta de la entidad con los datos suministrados más arriba.
                   </div>
                   <div class="parrafo">
                       <span class="titulo">Tercera.- </span>Que LA PARTE pretende iniciar una serie de contactos a través de la plataforma NEXOFY en las que se aportará información y documentación para poder estudiar el futuro desarrollo de un Proyecto de colaboración entre las partes que incluya la potencial viabilidad del proyecto, entre otros, que podrá incluir cualesquiera otros no expresamente excluidos que impliquen la puesta en marcha del proyecto, sin que exista limitación al estudio y sin que suponga ningún compromiso para ninguna de las partes, más allá del estudio del potencial de colaboración..
                   </div>
                   <div class="parrafo">
                       <span class="titulo">Cuarto.- </span> Que LA PARTE, por tanto, tendrá acceso a información calificada como Confidencial, con el objeto de llevar a cabo el proyecto descrito, y tienen la voluntad de intercambiar información técnica y/o comercial de naturaleza confidencial y/o información sujeta a derechos de propiedad intelectual, e industrial actualmente en su poder, además de tener la intención de asegurarse de que continúe siendo confidencial.
                   </div>
                   <span class="titulo"> CLÁUSULAS </span>
                   <div class="parrafo">
                       <span class="tituloSecundario">1.- DEFINICIONES. </span> Los conceptos siguientes tendrán los significados expresados en el mismo:
                       <p><span>1.1. “INFORMACIÓN CONFIDENCIAL”: aquella información técnica y/o comercial revelada por cualquiera de las partes (“PARTE EMISORA”) a otra parte (“PARTE RECEPTORA”), en el marco del proyecto arriba mencionado, ya sea por escrito, oralmente, en forma de documentos, dibujos, diseños, muestras, modelos, programas informáticos o cualquier otra forma, incluyendo sin limitación alguna, la información objeto de derecho de autor, patentes, técnicas, modelos, invenciones, know-how, procesos, algoritmos, programas, ejecutables, investigaciones, detalles de diseño, prototipos información financiera, lista de USUARIO/S, inversionistas, empleados, relaciones de negocios y contractuales, pronósticos de negocios, precios, contactos de fabricación, planes de mercado y negocio, cuentas, información financiera y cualquier información revelada o intercambiada entre LAS PARTES, y que en el momento de su divulgación o distribución sea comunicada como confidencial.</span></p>
                       <p><span>1.2. “LAS PARTES”: la “persona investigadora” y el “usuario persona proponente” (“LA PARTE” en el presente acuerdo) que deben ejecutar este acuerdo pudiendo ser ambas receptoras y emisoras </span></p>
                       <p><span>1.3. “PARTE EMISORA”: aquella parte del presente acuerdo que revele Información Confidencial a la otra Parte.</span></p>
                       <p><span>1.4. “PARTE RECEPTORA”: aquella parte del presente acuerdo que reciba, utilice, distribuya, estudie, y que, en cualquier manera, tenga acceso a la Información Confidencial expuesta o entregada por la PARTE EMISORA. </span></p>
                   </div>
                   <div class="parrafo">
                       <span class="tituloSecundario">2.-OBJETO </span> El objeto del presente acuerdo es fijar los términos y condiciones bajo los cuales LA PARTE mantendrá la confidencialidad de los datos e INFORMACIÓN CONFIDENCIAL intercambiados entre ellas a la que tengan acceso por causa o con ocasión del proyecto anteriormente señalado.
                   </div>
                   <div class="parrafo">
                       <span class="tituloSecundario">3.- OBLIGACIONES. </span> Las partes tendrán las obligaciones siguientes:
                       <p><span>3.1. LA PARTE acuerda que toda y cada una de la INFORMACIÓN CONFIDENCIAL intercambiada, facilitada o creada entre LAS PARTES en el transcurso del proyecto mencionado, será tratada como tal y mantenida en estricta confidencialidad, y se comprometen a utilizarla exclusivamente con fines de desarrollar el proyecto conforme establece el contrato suscrito entre las partes.</span></p>
                       <p><span>3.2. La PARTE EMISORA, deberá confirmar por escrito toda información oral o intercambiada por un medio no escrito, en cualquier otro soporte, que sea clasificada como confidencial. Dicho escrito indicará específicamente la fecha de divulgación y calificará la información como confidencial. Este Acuerdo no debe aplicarse a Información no Confidencial revelada oralmente y no ratificada por escrito ni señalada del mismo modo dentro de un plazo de diez (10) días a partir de la revelación oral inicial. </span></p>
                       <p><span>3.3. LA PARTE se compromete a:</span></p>
                       <ul>
                           <li>Mantener en secreto y no revelar a terceros (por tercero se entenderá incluido cualquier persona física o jurídica que controle, sea controlada por o esté bajo el control de la PARTE RECEPTORA) cualquier INFORMACIÓN CONFIDENCIAL suministrada por la PARTE EMISORA; sin ponerla a disposición del público, ni hacerla pública o accesible de cualquier forma, salvo con el consentimiento previo, expreso y por escrito de la parte que la ha suministrado (PARTE EMISORA).</li>
                           <li>No utilizar la INFORMACIÓN CONFIDENCIAL en provecho propio o de terceros y para otro propósito que no sea él interés del proyecto acordado tal y como se describe en el acuerdo suscrito entre las partes.</li>
                           <li>Permitir el acceso a la INFORMACIÓN CONFIDENCIAL únicamente a aquellas personas físicas o jurídicas que, prestando, en ambos casos, sus servicios para el desarrollo del proyecto, necesiten la información para el desarrollo de tareas para las que el uso de esta información sea estrictamente necesario, conforme a los términos indicados en la cláusula quinta del presente acuerdo.</li>
                           <li>Proteger los intereses comerciales de la PARTE EMISORA que se deriven o puedan derivarse en el futuro de la información suministrada, incluidos los derechos de propiedad industrial/intelectual presentes o posibles, así como a no impedir de ninguna manera el desarrollo de dichos intereses comerciales o el ejercicio de cualquier derecho que pudiera asistir a la PARTE EMISORA.</li>
                           <li>No copiar muestras, modelos, dibujos u otros documentos proporcionados por la PARTE EMISORA en virtud de este Acuerdo, a menos que esté expresamente permitido por la PARTE EMISORA en cada caso o cuando sea necesario para el proyecto acordado. </li>
                           <li>Adoptar y mantener mecanismos internos de seguridad adecuados para proteger la confidencialidad de toda la INFORMACIÓN CONFIDENCIAL que conozcan o llegaren a conocer en desarrollo del proyecto, observado el mismo cuidado en la salvaguarda de tal INFORMACIÓN CONFIDENCIAL que el que observan para su propia información de igual carácter e importancia.</li>
                           <li>Comunicar a la otra parte toda filtración de información de la que tengan o lleguen a tener conocimiento, producida por la vulneración del presente Acuerdo de Confidencialidad o infidelidad de las personas que hayan accedido a la INFORMACIÓN CONFIDENCIAL.</li>
                       </ul>
                   </div>
                   <div class="parrafo">
                       <span class="tituloSecundario">4.-RESPONSABILIDADES. </span> LA PARTE tendrá las responsabilidades siguientes:
                       <p><span>4.1. La PARTE RECEPTORA será responsable de la revelación no autorizada de Información Confidencial, excepto cuando la PARTE RECEPTORA haya observado el mismo cuidado en la salvaguarda de tal INFORMACIÓN CONFIDENCIAL que el que observa para su propia información de igual carácter e importancia y después del descubrimiento de dicha revelación, notifique la misma a la PARTE EMISORA y tome medidas razonables para prevenir cualquier ulterior revelación.</span></p>
                       <p><span>4.2 La PARTE RECEPTORA y sus trabajadores reconocen que cualquier revelación o uso no autorizado por una parte de Información Confidencial de la otra parte podría causar daños irreparables y graves perjuicios, cuyas proporciones resultan difíciles de calcular. Por consiguiente, se conviene acuerda que la parte infractora estará obligada a abonar a la otra parte penalizaciones por cualquier incumplimiento de este Acuerdo según decisión de un tribunal. Todo ello, sin perjuicio de cualquier otra reclamación de daños y perjuicios que sea exigible en derecho en función del incumplimiento y sin limitación de tipo alguno. En tal caso, la parte cumplidora podrá exigir a la parte no cumplidora todos los daños y perjuicios que pueda demostrar con independencia de la penalización acordada. </span></p>
                   </div>
                   <div class="parrafo">
                       <span class="tituloSecundario">5-. EXCEPCIONES. </span> Las obligaciones especificadas en la cláusula tercera del presente acuerdo no se aplicarán en relación con aquella INFORMACIÓN CONFIDENCIAL que:
                       <ul>
                           <li>Fuese de dominio público en el momento de divulgarla o que a partir de ese momento haya pasado a formar parte del mismo, sin incumplimiento de los términos del presente contrato en lo que respecta a la PARTE RECEPTORA; o bien que</li>
                           <li>La PARTE RECEPTORA pueda probar fehacientemente que ya la conocía mediante la divulgación por fuentes distintas a la PARTE EMISORA y que posee el derecho de divulgar dicha información; o que</li>
                           <li>Sea requerida a la PARTE RECEPTORA por las Autoridades administrativas o judiciales que puedan obligar a ello. En este caso deberá notificarse inmediatamente esta circunstancia a la PARTE EMISORA. Asimismo, la PARTE RECEPTORA sólo revelará aquella parte de la INFORMACIÓN CONFIDENCIAL que sea requerida legalmente y aportará a la PARTE EMISORA garantías de que a dicha Información se le dará un tratamiento confidencial.</li>
                       </ul>
                   </div>
                   <div class="parrafo">
                       <span class="tituloSecundario">6-.TRATAMIENTO. </span>
                       <p><span>6.1 Salvo que resulte necesario para los fines establecidos en el presente acuerdo y siempre que toda INFORMACIÓN CONFIDENCIAL divulgada o copia de la misma se haga accesible únicamente a los empleados que tengan necesidad de conocerla, la PARTE RECEPTORA no copiará ni reproducirá, sin consentimiento previo de la PARTE EMISORA, ningún elemento o documento que le haya sido entregado (que consista o contenga, en parte o en su totalidad, INFORMACIÓN CONFIDENCIAL).</span></p>
                       <p><span>6.2 La PARTE RECEPTORA devolverá dichos documentos o elementos, así como las copias de los mismos en el plazo de menos de veinticuatro (24) horas, a petición de la parte que los emitió y procederá a destruir cualquier archivo existente en sus sistemas informáticos que se hayan creado en relación con la información revelada. </span></p>
                   </div>
                   <div class="parrafo">
                       <span class="tituloSecundario">7-. PROPIEDAD. </span>
                       <p><span>7.1. Toda la INFORMACIÓN CONFIDENCIAL, así como todos los derechos de propiedad intelectual e industrial sobre la información y sobre los soportes que las contengan, permanecerá y seguirá siendo propiedad exclusiva de la parte que la haya generado y, posteriormente, revelado, sin que la comunicación de tal información suponga variación alguna respecto a la titularidad de la misma. El presente acuerdo no implica, ni concede cesión de derechos, licencias o cualquier otro tipo de transferencia sobre derechos a la PARTE RECEPTORA y tampoco crea obligación comercial alguna para LAS PARTES, salvo que estas acuerden lo contrario.</span></p>
                       <p><span>7.2. El presente acuerdo concede un derecho no exclusivo y no transferible de uso de la Información comunicada que cada parte entrega a la otra en las condiciones que se recogen en el presente acuerdo y para el exclusivo uso durante el proyecto y la vida del acuerdo a los fines pretendidos en este acuerdo. Caso de producirse resultados conjuntos susceptibles de protección y registro, las partes procederán de mutuo acuerdo. </span></p>
                       <p><span>7.3. LA PARTE conviene que toda la INFORMACIÓN CONFIDENCIAL será accesible “tal como es” y que no se concede garantía alguna, ni tácita ni expresa, en cuanto a la calidad de dicha INFORMACIÓN CONFIDENCIAL incluyendo, aunque sin por ello limitar, ni garantizar su adaptabilidad a cualquier uso, la no infracción de derechos de terceros, su exactitud, su integridad o precisión.</span></p>
                   </div>
                   <div class="parrafo">
                       <span class="tituloSecundario">8-. DURACIÓN </span>
                       <p><span>8.1. El presente acuerdo entrará en vigor a partir de la fecha de la firma y será válido durante el periodo en que se desarrolle el proyecto tal y como se especifica en el respectivo contrato y como máximo por un período de cinco años, salvo que la ley prevea un término mayor.</span></p>
                       <p><span>8.2. No obstante lo establecido en el punto anterior, las obligaciones aquí contenidas referentes a la confidencialidad y uso de la información mantendrán su vigencia a la terminación de este Acuerdo por el período máximo que permita la ley y, caso de no prever nada, por un período mínimo de 5 años contados desde la finalización del acuerdo, del proyecto convenido o la comunicación de la información, contando desde el último hecho producido. </span></p>
                       <p><span>8.3. Durante este tiempo LA PARTE se compromete a abstenerse de usar, en todo o en parte, directa o indirectamente, en igual forma o modificada la INFORMACIÓN CONFIDENCIAL a la que tengan acceso por causa o con ocasión del proyecto anteriormente señalado, y se abstendrán de utilizarla para cualquier tipo de negociación y/o prestación de servicios propia o a terceros.</span></p>
                   </div>
                   <div class="parrafo">
                       <span class="tituloSecundario">9-. MODIFICACIÓN. </span> 
                       <p><span>9.1. El presente Acuerdo de Confidencialidad o cualquier cláusula del mismo podrán ser modificadas o corregidas por acuerdo mutuo entre las partes. Las nuevas modificaciones y/o correcciones deberán para su validez presentarse por escrito y estar firmadas por todas las Partes, fechadas junto a la modificación o añadido realizados.</span></p>
                       <p><span>9.2. El presente Acuerdo solo podrá darse por terminado con el consentimiento expreso y por escrito de todas LAS PARTES. </span></p>
                   </div>
                   <div class="parrafo">
                       <span class="tituloSecundario">10-. JURISDICCIÓN. </span> 
                       <p><span>10.1. El presente acuerdo se rige e interpretará de conformidad con la legislación española y en caso de discrepancias relativas a la interpretación o ejecución del mismo, LA PARTE acuerda resolver de manera amistosa cualquier disputa o desacuerdo que pueda surgir durante a aplicación del presente Acuerdo</span></p>
                       <p><span>10.2 Caso de no conseguirlo en un plazo de sesenta días, con renuncia de cualquier otro fuero se someten a los juzgados y tribunales de Madrid, España. </span></p>
                       <p><span>10.3 En caso de cualquier conflicto o discrepancia que pueda surgir en relación con la interpretación y/o cumplimiento del presente Acuerdo, se aplicarán las presentes cláusulas, y, en su defecto, por las condiciones de condiciones generales extensas de la plataforma, por encima de cualquier otro contrato o norma dispositiva del ordenamiento, ante cualquier foro competente y ley aplicable, tal como están recogidas en nuestra página web y accesibles para los usuarios. En su defecto, aplicará el Código Civil y la ley española</span></p>
                   </div>
                   <div class="parrafo">
                       <span class="tituloSecundario">11-. VALIDEZ. </span> 
                       <p><span>11.1 El presente Acuerdo requiere para su validez y perfeccionamiento la <b>Aceptación digital</b> de estos términos. Cualquier añadido posterior a la Aceptación de este acuerdo de confidencialidad, se añadirán en forma de anexos firmados con posterioridad por ambas partes, enmienda, tachadura, modificación o alteración del tipo que sea deberá, para su validez, ir salvado mediante escrito con la firma de ambas partes junto al elemento retocado, con la fecha de la enmienda.</span></p>
                       <p><span>11.2 El presente Acuerdo sustituye a cualquier otro anterior y a cualesquiera comunicaciones o forma de operar entre las partes; formando un todo integral siendo obligatorios para las partes en todos sus extremos. </span></p>
                       <p><span>11.3 La nulidad de una cualquiera de sus cláusulas no producirá la nulidad del resto del contrato, que conservará su validez. Las partes integrarán tal laguna cumpliendo el contrato del tenor del espíritu del mismo.</span></p>
                       <p><span>11.4 LA PARTE necesitarán obtener el previo consentimiento escrito de la otra parte para hacer cualquier anuncio público o cualquier referencia, en cualquier medio, incluso, sin limitación, en listas de USUARIO/S, presentaciones, etc. acerca de la existencia del presente Contrato, de su contenido o cualquier tipo de comentario cuando se mencione o pudiera sobrentenderse de manera razonable, la identidad de la otra parte o de sus USUARIO/S</span></p>
                   </div>
                   <div class="parrafo">
                       Para constancia, y en señal de aceptación, se firma el presente acuerdo en un ejemplar, con el mismo efecto en el lugar y fecha que figura en el encabezado.
                   </div>
                    <div class="form-check">
                         <input class="clicable form-check-input check-formulario" type="checkbox" name="flexCheckDefault" id="flexCheckDefault" [(ngModel)]="checkaceptado" >
                         <p class="form-check-label"><b>Acepto los términos y condiciones de confidencialidad</b></p>
                   </div>
                </div> 
                <div class="modal-footer">
                     <button class="boton medium" [disabled]="!checkaceptado" (click)="aceptar_contrato()">Aceptar</button>
                     <button class="boton medium secondary danger" (click)="rechazar_contrato()">Rechazar</button>
                </div>            
           </div>
      </div>
 </ng-template>