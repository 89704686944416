<!--<div class="no-disponible"> <p>Esta sección no está disponible por el momento </p></div>-->

<div class="cabecera-componente">
     <div class="boton-atras clicable" (click)="this.location.back()">
          <img src="../../assets/Icon feather-arrow-left-circle.svg">
     </div>
     <h1 class="titulo-componente">Chat<img class="btn_informacion" src="../../assets/info.svg"(click)="abrirInfo()"></h1>
</div>
<div class="div-conversacion">
     <div class="cabecera">
          <div class="columna-usuario">
               <div class="foto">
                    <img *ngIf="ConversacionModelo.estado > 0 || usuario_sesion.rol < 3" src="{{url_imagenes}}{{ConversacionModelo.usuarios[0].imagen}}">
                    <img *ngIf="ConversacionModelo.estado == 0 && usuario_sesion.rol > 2" src="../../assets/anonimo.png" alt="Anónimo" title="Anónimo">
               </div>
               <div *ngIf="ConversacionModelo.estado > 0 || usuario_sesion.rol < 3" class="nombre">
                    {{ConversacionModelo.usuarios[0].nombre}} {{ConversacionModelo.usuarios[0].apellidos}}
                    <span *ngIf="ConversacionModelo.usuarios[0].rol == 3">{{ConversacionModelo.usuarios[0].id_empresa.nombre}} </span>
               </div>
               <div *ngIf="ConversacionModelo.estado == 0 && usuario_sesion.rol == 3" class="nombre">
                    Investigador/ra Anónimo/a
               </div>
               <div *ngIf="ConversacionModelo.estado == 0 && usuario_sesion.rol == 4" class="nombre">
                    Empresa Anónima
               </div>
          </div>
          <div class="perfil">
               <div class="label_tabla px-4" *ngIf="usuario_sesion.rol < 3 || ConversacionModelo.estado > 0 ">{{ConversacionModelo.usuarios[0].cargo}}</div>
               <div class="label_tabla px-4" *ngIf="ConversacionModelo.estado == 0 && usuario_sesion.rol > 2"> Anónimo/a </div>
          </div>
          <div class="bloqueo" *ngIf="usuario_sesion.rol<3 && ConversacionModelo.estado == 1">
               <button class="boton secondary danger" (click)="desbloqueoBloqueo(0)">Bloquear</button>
          </div>
          <div class="bloqueo" *ngIf="usuario_sesion.rol<3 && ConversacionModelo.estado == 0 ">
               <button class="boton secondary" (click)="desbloqueoBloqueo(1)">Desbloquear</button>
          </div>
          
     </div>

     <div class="div-mensajes">
          <div class="fila" *ngFor="let mensaje of ConversacionModelo.mensajes">
               <div class="fila-mensaje" [ngClass]="{'mio': mensaje.id_emisor==usuario_sesion._id || (usuario_sesion.rol <3 && mensaje.id_emisor != ConversacionModelo.usuarios[0]._id)}" *ngIf="mensaje.mensaje!=null">
                    <div class="mensaje" >
                         <img src="../../../assets/clock.svg" *ngIf="mensaje.estado==1 && mensaje.leidos.length<1 && mensaje.id_emisor==usuario_sesion._id">
                         <img src="../../../assets/check.svg" *ngIf="mensaje.estado==0 && mensaje.leidos.length<1 && mensaje.id_emisor==usuario_sesion._id">
                         <img src="../../../assets/double-check.svg" *ngIf="mensaje.leidos.length>=1 && mensaje.id_emisor==usuario_sesion._id">
                         
                         
                         <span *ngIf="!mensaje.mensaje.includes('##solicitud_conexion##') && !mensaje.mensaje.includes('##fichero')">
                              {{mensaje.mensaje}}                   
                         </span>

                         <!--SOLICITUD DE CONEXION-->
                         <span *ngIf="mensaje.mensaje.includes('##solicitud_conexion##') && usuario_sesion.rol>2">
                              <span *ngIf="SeguidorModelo.id_receptor._id==usuario_sesion._id">
                                   {{ConversacionModelo.usuarios[0].nombre}} quiere conectar contigo
                                   <span *ngIf="SeguidorModelo.estado==0">
                                        <br>
                                        <button class="boton secondary" (click)="aceptarSolicitudAmistad(ConversacionModelo.usuarios[0]._id)">Aceptar</button>
                                        <button class="boton secondary danger" (click)="rechazarSolicitudAmistad(ConversacionModelo.usuarios[0]._id)">Rechazar</button> 
                                   </span>
                              </span>

                              <span *ngIf="SeguidorModelo.id_receptor._id!=usuario_sesion._id">
                                   Solicitud de conexión enviada
                              </span>
                         </span>
                         <span *ngIf="mensaje.mensaje.includes('##solicitud_conexion##') && usuario_sesion.rol<3">
                              Solicitud de conexión
                         </span>
                         <!--SOLICITUD DE CONEXION-->
                         
                         <!--SUBIDA DE FICHEROS-->
                         <div class="fichero">

                              <div class="icono-fichero">
                                   <span *ngIf="mensaje.mensaje.includes('##fichero_cargando##')">
                                        <img src="../../../assets/loading.svg">
                                   </span>
                                   <span *ngIf="mensaje.mensaje.includes('##fichero_subido##')">
                                        <img src="../../../assets/pdf.svg" *ngIf="mensaje.mensaje.includes('.pdf')">
                                        <img src="../../../assets/txt.svg" *ngIf="mensaje.mensaje.includes('.txt')">
                                        <img src="../../../assets/doc.svg" *ngIf="mensaje.mensaje.includes('.doc')">
                                        <img src="../../../assets/svg.svg" *ngIf="mensaje.mensaje.includes('.svg')">
                                        <img src="../../../assets/jpg.svg" *ngIf="mensaje.mensaje.includes('.jpg')">
                                        <img src="../../../assets/png.svg" *ngIf="mensaje.mensaje.includes('.png')">
                                        <img src="../../../assets/rar.svg" *ngIf="mensaje.mensaje.includes('.rar') || mensaje.mensaje.includes('.zip')">
                                        <img src="../../../assets/vicdeo-azul.svg" *ngIf="mensaje.mensaje.includes('.mp4') || mensaje.mensaje.includes('.mpeg') || mensaje.mensaje.includes('.mpg') || mensaje.mensaje.includes('.avi')">
                                   </span>
                                   <span *ngIf="mensaje.mensaje.includes('##fichero_erroneo##')">
                                        <img src="../../../assets/x (1).svg">
                                   </span>
                              </div>

                              <div class="texto-fichero" (click)="descargar_documento(mensaje.mensaje.split('##')[2])">
                                   <span *ngIf="mensaje.mensaje.includes('##fichero_cargando##')">
                                        Subiendo fichero...
                                   </span>
                                   <span *ngIf="mensaje.mensaje.includes('##fichero_subido##') || mensaje.mensaje.includes('##fichero_erroneo##')">
                                        <div class="texto-fichero">{{mensaje.mensaje.split('##')[2]}}</div>
                                   </span>
                              </div>

                              
                         </div>
                         
                         <!--SUBIDA DE FICHEROS-->
                         
                         <div class="hora">{{pipe.transform(mensaje.cdate, 'dd/MM/yyyy HH:mm')}}</div>
                    </div>
               </div>
          </div>
     </div>

     <div class="controles" *ngIf="!chatbot && ConversacionModelo.estado>0 && ConversacionModelo.borrado == '0'">
          <div class="textarea">
               <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" dropZoneClassName="textarea">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                         
                         <textarea class="form-control text-area-pc" [(ngModel)]="mensaje" (keyup.enter)="enviarMensaje()"></textarea>
                         
                         <form class="text-area-movil">
                              <textarea class="form-control" [(ngModel)]="mensaje" (keyup.enter)="enviarMensaje()"></textarea>
                         </form>
                    </ng-template>
               </ngx-file-drop>
          </div>
          
          
          <div class="div-botones">
               <input type="file" style="display: none;" id="inputFichero" (change)="subir_fichero($event)">


               <img class="boton-adjuntar" src="../../assets/clip-azul.svg" (click)="buscarFichero()">
               <button class="boton boton-enviar" style="display:none"(click)="enviarMensaje()"><img src="../../assets/send-blanco.svg"></button>
          </div>
     </div>
</div>

<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal >
     <div class="modal-content">
          <div class="modal-content">
			<div class="modal-header">
				<img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
			</div>
               <div class="modal-body">
				<p [innerHTML]="informacion">
                    </p>				
               </div> 
               <div class="modal-footer">
                    <button class="boton medium"(click)="modal.dismiss()">Aceptar</button>
               </div>            
          </div>
     </div>
</ng-template>

<!--Modal para confirmar cambios guardados-->
<ng-template #modal_aviso let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
 
            <div class="modal-body aviso">
                <div class="icono-aviso">
                    <img src="../../../assets/x (1).svg" *ngIf="!aviso_error" width="100%">
                    <img src="../../../assets/correcto.svg" *ngIf="aviso_error" width="100%">
                </div>
                <div class="titulo">
                    <h2>{{this.mensaje_error}}</h2>
                </div>
            </div>
          </div>
     </div>
</ng-template>


<!--Modal confirmar el bloqueo del usuario-->
<!-- <ng-template #modal_bloquear let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
 
               <div class="modal-body aviso">
                    <div class="titulo">
                         <h2>¿Seguro que quieres bloquear a este usuario?</h2>
                    </div>
                    <button class="boton medium" (click)="bloquear()">Sí, bloquear</button>
                    <button class="boton medium secondary danger" (click)="modal.dismiss()">Cancelar</button>
               </div>
          </div>
     </div>
</ng-template> -->