import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Retos } from 'src/app/models/retos';
import { Soluciones } from 'src/app/models/soluciones';
import { RetosService } from 'src/app/retos/retos.service';
import { GlobalService } from 'src/app/services/global.services';
import { SolucionesService } from '../soluciones.service';
import { environment } from 'src/environments/environment';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { htmlToText } from 'html-to-text';


@Component({
	selector: 'app-presentar-solucion',
	templateUrl: './presentar-solucion.component.html',
	styleUrls: ['./presentar-solucion.component.css']
})
export class PresentarSolucionComponent implements OnInit {
	public solucionesModelo:any;
	public validar_formulario_solucion:any;
	public id_reto:string='';
	public id_solucion:string='';
	public retoModelo:any;
	public usuario_sesion:any;
	public mensaje_error = "";
	public aviso_error=false;
	public url_imagenes:string = environment.url_imagenes
	public cargando:boolean = false;
	public informacion:string = "";

  	@ViewChild('modal_aviso') modal_aviso:any;
	@ViewChild('modal_informacion') modal_informacion:any;
	

	public files: NgxFileDropEntry[] = [];

	public caracteres_resumen:number=0;
	public max_caracteres_resumen:number=300;

	public caracteres_descripcion:number=0;
	public max_caracteres_descripcion:number=2000;

	public caracteres_conclusion:number=0;
	public max_caracteres_conclusion:number=300;

	public camposObligatorios={
		'resumen':false,
		'descripcion':false,
		'fichero':false,
		'conclusion':false,
	};

	constructor(
		private modalService: NgbModal,
		public location:Location,
		private router:Router,
		private _solucionesService: SolucionesService,
		private _globalService: GlobalService,
		private _retosService:RetosService,
		private _router:ActivatedRoute,
	) { 
		this.usuario_sesion = this._globalService.DatosSesion();
		this.solucionesModelo = new Soluciones('', 0, 0, 0, '', '', '', '', 0, new Date, new Date, '0',"",[], new Date);
		this.retoModelo = new Retos('', '', '','', '', 0, '', '', new Date, 0, '', '', new Date, new Date, '', 0,1,1,'','',0,0,'','',[],'');
	}

	ngOnInit(): void {
		this._router.params.subscribe( paramMap => {
			if(paramMap['id_reto']){
				this.id_reto = paramMap['id_reto']
				this.leer_reto();
			}else if(paramMap['id_solucion']){
				this.id_solucion = paramMap['id_solucion']
				this.leer_solucion();
			}
			
		});	
	}

	leer_reto(){
		this._retosService.readRetos(this._globalService.getToken(), this.id_reto).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				this.retoModelo = res.response.listado;
			}
		})
	} 

	leer_solucion(){
		this._solucionesService.readSoluciones(this._globalService.getToken(), this.id_solucion).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				this.solucionesModelo = res.response.listado;
				this.retoModelo = this.solucionesModelo.id_reto;
				this.id_reto = this.solucionesModelo.id_reto._id;
				this.actualizarCaracteres();
			}
		})
	} 

	guardar(){
		this.solucionesModelo.id_usuario = this.usuario_sesion._id;
		this.solucionesModelo.id_reto = this.id_reto;
		let solucion_valida = true;
		/*
		if(this.solucionesModelo.url_archivo==""){
			this.camposObligatorios.fichero=true;
			solucion_valida=false;
		}else{
			this.camposObligatorios.fichero=false;
		}
		*/

		if(this.solucionesModelo.resumen==""){
			this.camposObligatorios.resumen=true;
			solucion_valida=false;
		}else{
			this.camposObligatorios.resumen=false;
		}
		
		if(this.solucionesModelo.descripcion==""){
			this.camposObligatorios.descripcion=true;
			solucion_valida=false;
		}else{
			this.camposObligatorios.descripcion=false;
		}

		if(this.solucionesModelo.conclusion==""){
			this.camposObligatorios.conclusion=true;
			solucion_valida=false;
		}else{
			this.camposObligatorios.conclusion=false;
		}
		console.log(solucion_valida);
		console.log(this.id_solucion);

		if(solucion_valida){
			if(this.id_solucion!=""){
				//si estamos editando la solución
				this._solucionesService.updateSoluciones(this.solucionesModelo, this._globalService.getToken()).subscribe({ 
					next: (respuesta) => {
						let res:any = respuesta;
						if(res.error_code==1){
							// Si se guarda correctamente: Almacenamos en localStorage la variable codigo_error para mostral el modal en soluciones.
							localStorage.removeItem('codigo_error');
							localStorage.setItem('codigo_error', '1');
							this.router.navigate(['/soluciones/']);
						}else{  
							this.modalService.dismissAll(); //cerrar todos los popups
							this.aviso_error=false;
							this.mensaje_error=res.message;
							this.modalService.open(this.modal_aviso,{centered:true});
						}  
					},
					error: (e) => {
					}
				}) 
			}else{
				//si estamos creando una solución nueva
				this._solucionesService.createSoluciones(this.solucionesModelo, this._globalService.getToken()).subscribe({ 
					next: (respuesta) => {
						let res:any = respuesta;
						if(res.error_code==1){
							// Si se guarda correctamente: Almacenamos en localStorage la variable codigo_error para mostral el modal en soluciones.
							localStorage.removeItem('codigo_error');
							localStorage.setItem('codigo_error', '1');
							this.router.navigate(['/soluciones/']);
						}else{  
							this.modalService.dismissAll(); //cerrar todos los popups
							this.aviso_error=false;
							this.mensaje_error=res.message;
							this.modalService.open(this.modal_aviso,{centered:true});
						}  
					},
					error: (e) => {
					}
				}) 
			}
			
		}
	} 

	//Abrimos el modal donde aparece información de la pagina.
	abrirInfo(){
		this.informacion = "En la ventana de presentar solución, como investigador agregaremos soluciones para el reto seleccionado, en este formulario, obligatoriamente tenemos que adjuntar un fichero aportando material a la solución presentada, una descripción y una conclusión, de manera opcional se puede adjuntar una URL que vaya a un video explicativo."
		this.modalService.open(this.modal_informacion,{centered:true});
	}

	buscarFichero(){
		document.getElementById("inputFichero")?.click();
	}

	subir_fichero(event:any){
		this.cargando = true;
		if(event.target.files.length>0){
			let documentos:Array<any> = this.solucionesModelo.documentos; 
			documentos.push({'nombre':event.target.files[0].name, fichero:''});
			this.solucionesModelo.documentos = documentos;

			this._globalService.subir_documento(event.target.files[0]).then((res:any)=> {
					//funcion promesa cumplida
					let respuesta;
					if(res.error_code==1){
						respuesta = res.response.fichero;
						this.cargando = false;
					}else{
						this.cargando = false;
						respuesta = 'error.error'; //añadimos este nombre de fichero para cambiar el icono que aparece
						this.modalService.dismissAll(); //cerrar todos los popups
						this.aviso_error=false;
						this.mensaje_error=res.message;
						this.modalService.open(this.modal_aviso,{centered:true});
					}
					for(let d=0;d<this.solucionesModelo.documentos.length;d++){
						if(this.solucionesModelo.documentos[d].nombre==event.target.files[0].name && this.solucionesModelo.documentos[d].fichero==""){
							this.solucionesModelo.documentos[d].fichero = respuesta;
							break;
						}
					}					
				},
				(e) => {}
			);
		}
	}

	borrar_fichero(fichero:string){
		this.solucionesModelo.documentos = this.solucionesModelo.documentos.filter((documento:any)=>documento.fichero!=fichero);
	}

	public dropped(files: NgxFileDropEntry[]) {
		this.cargando = true;
		this.files = files;
		if(files.length<=10){
			for (const droppedFile of files) {
				// Is it a file?
				if (droppedFile.fileEntry.isFile) {
					const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
					fileEntry.file((file: File) => {

						// Here you can access the real file
						let documentos:Array<any> = this.solucionesModelo.documentos; 
						documentos.push({'nombre':file.name, fichero:''});
						this.solucionesModelo.documentos = documentos;

						this._globalService.subir_documento(file).then((res:any)=> {
							//funcion promesa cumplida
							let respuesta;
							if(res.error_code==1){
								respuesta = res.response.fichero;
								this.cargando = false;
							}else{
								respuesta = 'error.error'; //añadimos este nombre de fichero para cambiar el icono que aparece
								this.modalService.dismissAll(); //cerrar todos los popups
								this.aviso_error=false;
								this.mensaje_error=res.message;
								this.modalService.open(this.modal_aviso,{centered:true});
								this.cargando = false;
							}
							for(let d=0;d<this.solucionesModelo.documentos.length;d++){
								if(this.solucionesModelo.documentos[d].nombre==file.name && this.solucionesModelo.documentos[d].fichero==""){
									this.solucionesModelo.documentos[d].fichero = respuesta;
									break;
								}
							}						
						},
						(e) => {}
					);


					});
				} else {
					// It was a directory (empty directories are added, otherwise only files)
					const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
					console.log(droppedFile.relativePath, fileEntry);
				}
			}
		}else{
			this.cargando = false;
			this.modalService.dismissAll(); //cerrar todos los popups
			this.aviso_error=false;
			this.mensaje_error="No se pueden subir más de 10 ficheros simultáneos";
			this.modalService.open(this.modal_aviso,{centered:true});
		}
	}

	actualizarCaracteres(){
		let resumen = htmlToText(this.solucionesModelo.resumen, {
			singleNewLineParagraphs: true,
			ignoreImage: true,
		});
		this.caracteres_resumen = resumen.length;

		let descripcion = htmlToText(this.solucionesModelo.descripcion, {
			singleNewLineParagraphs: true,
			ignoreImage: true,
		});
		this.caracteres_descripcion = descripcion.length;

		let conclusion = htmlToText(this.solucionesModelo.conclusion, {
			singleNewLineParagraphs: true,
			ignoreImage: true,
		});
		this.caracteres_conclusion = conclusion.length;
	}

	
}
