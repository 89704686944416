// 12/12/2022 Mario Lopez: Solucionar fallos en select de pais y estado, y modal al guardar los cambios.

import { ActivatedRoute, Route, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmpresasService } from '../empresas/empresas.service';
import { Empresas } from '../models/empresas';
import { Usuarios } from '../models/usuarios';
import { GlobalService } from '../services/global.services';
import { UsuariosService } from '../usuarios/usuarios.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Component({
	selector: 'app-perfil',
	templateUrl: './perfil.component.html',
	styleUrls: ['./perfil.component.css'],

})

export class PerfilComponent implements OnInit {

	public html: any;
	public usuarioModelo: any;
	public empresaModelo: any;
	public usuario_sesion: any;
	public formulario_actualizar_empresa: any;
	public imagen_subida: any;
	public lista_paises: any;
	public lista_paises_empresa: any;
	public lista_estados_empresa: any;
	public errorCargaPaises: any;
	public lista_estados: any;
	public paisUsuario: any;
	public paisEmpresa: any;
	public estadoUsuario: any;
	public estadoEmpresa: any
	public mensaje_error = "";
	public cod_Guardar = 0;
	public aviso_error = false;
	public actualizacionCorrecta = true;
	public imgUsuarioDefault = "anonymous.png";
	public imgEmpresaDefault = "anonymous.png";
	public informacion: string = "";
	public subiendo_imagen: boolean = false;
	public pswTxt: string = "";
	public pswTxtRepetida: string = "";

	//FotoUsuario:
	public imageChangedEvent: any = '';

	@ViewChild('modal_aviso') modal_aviso: any;
	@ViewChild('modal_informacion') modal_informacion: any;

	public url_imagenes: string = environment.url_imagenes
	public checkMiembroCsic: boolean = false;

	public ver_tab_datos_personales: boolean = true;
	public ver_tab_contacto: boolean = false;
	public ver_tab_redes: boolean = false;
	public ver_tab_datos_empresa: boolean = false;
	public ver_tab_contacto_empresa: boolean = false;
	public ver_tab_redes_empresa: boolean = false;
	public ver_tab_psw: boolean = false;
	public botonGuardar: boolean = true;

	// Variable cifrar y descifrar
	public claveCompartida = 'C1FR@D0SN€EKISOF1'.toString();

	constructor(
		private modalService: NgbModal,
		private _usuariosService: UsuariosService,
		private _empresasService: EmpresasService,
		private _globalService: GlobalService,
		private _router: ActivatedRoute,
	) {

		this.usuario_sesion = this._globalService.DatosSesion();
		this.usuarioModelo = new Usuarios('', 0, '', '', '', '', '', '', '', '', '', '', '', new Date, 0, '', '', '', '', '', '', '', '', '', '', '', 0, 0, 0, "", "", "", "", 0, new Date, new Date, 0);
		this.empresaModelo = new Empresas('', '', '', '', '', '', '', '', '', '', '', '', '', '', 0, 0, '', new Date, new Date, '', '', 0, new Date);
	}

	ngOnInit() {
		this._router.params.subscribe(paramMap => {
			if (Object.keys(paramMap).includes('id')) {
				this.leer_usuario(paramMap['id'])
			} else {
				this.leer_usuario(this.usuario_sesion._id)
			}

		})
	}

	//carga los paises para usuarios:
	cargar_paises_usuario() {
		this._usuariosService.listPaises().subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.lista_paises = res.response.listado;
					this.actualizar_estados_usuario();
				} else {
					this.errorCargaPaises = res.message;
				}
			},
			error: (e) => {
			}
		})
	}

	//carga los paises para usuarios:
	cargar_paises_empresas() {
		this._usuariosService.listPaises().subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.lista_paises_empresa = res.response.listado;
					this.actualizar_estados_empresa();
				} else {
					this.errorCargaPaises = res.message;
				}
			},
			error: (e) => {
			}
		})
	}

	//En funcion del pais carga el SELEC de estados para usuarios:
	actualizar_estados_usuario() {
		this.lista_estados = this.lista_paises.filter((pais: any) => pais.name == this.usuarioModelo.pais)
		if (this.lista_estados.length > 0) {
			this.lista_estados = this.lista_estados[0].states
		}
	}

	//En funcion del pais carga el SELEC de estados para empresas:
	actualizar_estados_empresa() {
		this.lista_estados_empresa = this.lista_paises.filter((pais: any) => pais.name == this.empresaModelo.pais)
		if (this.lista_estados_empresa.length > 0) {
			this.lista_estados_empresa = this.lista_estados_empresa[0].states
		}
	}

	// Elimina la fotos de usuario dejando una por defecto.
	eliminarFotoUsuario() {
		this.usuarioModelo.imagen = this.imgUsuarioDefault;
	}

	// Elimina la fotos de empresa dejando una por defecto.
	eliminarFotoEmpresa() {
		this.empresaModelo.imagen = this.imgEmpresaDefault;
	}

	leer_usuario(id: any) {
		this._usuariosService.readUsuarios(this._globalService.getToken(), id).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				this.usuarioModelo = res.response.listado;
				const fecha = new Date(this.usuarioModelo.fecha_nacimiento);
				this.cargar_paises_usuario();
				if (this.usuarioModelo.id_empresa != null && this.usuarioModelo.id_empresa != "") {
					this.leer_empresa(this.usuarioModelo.id_empresa._id);
				}
				this.paisUsuario = this.usuarioModelo.pais;
				this.estadoUsuario = this.usuarioModelo.provincia;
			}
		})
	}

	leer_empresa(id: any) {
		this._empresasService.readEmpresas(this._globalService.getToken(), id).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				this.empresaModelo = res.response.listado;
				this.paisEmpresa = this.empresaModelo.pais;
				this.estadoEmpresa = this.empresaModelo.provincia;
				this.cargar_paises_empresas()
				this.actualizar_estados_empresa()
			}
		})
	}

	buscarImagenUsuario() {
		document.getElementById("inputImagenUsuario")?.click();
	}


	async resizeImage(file: any, maxWidth: any, maxHeight: any) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (e) => {
				if (e.target && typeof e.target.result === 'string') {
					const img = new Image();

					img.onload = () => {
						const canvas = document.createElement('canvas');
						const MAX_WIDTH = maxWidth;
						const MAX_HEIGHT = maxHeight;
						let width = img.width;
						let height = img.height;

						// Verificar si es necesario redimensionar el ancho o la altura
						if (width > MAX_WIDTH || height > MAX_HEIGHT) {
							const scaleFactor = Math.min(MAX_WIDTH / width, MAX_HEIGHT / height);
							width *= scaleFactor;
							height *= scaleFactor;
						} else {
							const scaleFactor = Math.max(MAX_WIDTH / width, MAX_HEIGHT / height);
							width *= scaleFactor;
							height *= scaleFactor;
						}

						// Crear un nuevo canvas con las dimensiones redimensionadas
						canvas.width = width;
						canvas.height = height;

						const ctx = canvas.getContext('2d');
						ctx!.drawImage(img, 0, 0, width, height);

						// Convertir la imagen del canvas a un objeto Blob
						canvas.toBlob(
							(blob) => {
								resolve(blob);
							},
							file.type,
							1 // Calidad de la imagen, 1 es la máxima
						);
					};

					img.src = e.target.result;
				} else {
					reject(new Error('No se pudo leer la imagen'));
				}
			};

			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	}


	subir_imagen_usuario(event: any) {
		if (event.target.files.length > 0) {
			this.subiendo_imagen = true;
			const file = event.target.files[0];
			this.resizeImage(file, 300, 200).then((resizedFile: any) => {
				this._globalService.subir_imagen(resizedFile).then((res: any) => {
					this.subiendo_imagen = false;
					//funcion promesa cumplida
					if (res.error_code == 1) {
						this.usuarioModelo.imagen = res.response.imagen;
						this.usuarioModelo.url_imagen = res.response.url_imagen;
					} else {
						this.modalService.dismissAll(); //cerrar todos los popups
						this.aviso_error = false;
						this.mensaje_error = res.message;
						this.modalService.open(this.modal_aviso, { centered: true });
					}
				},
					(e) => {
					});
			})
		}
	}

	//FORMATEADO DE LA FOTO:


	buscarImagenEmpresa() {
		document.getElementById("inputImagenEmpresa")?.click();
	}

	subir_imagen_empresa(event: any) {
		if (event.target.files.length > 0) {
			this.subiendo_imagen = true;
			const file = event.target.files[0];
			this.resizeImage(file, 300, 200).then((resizedFile: any) => {
				this._globalService.subir_imagen(resizedFile).then((res: any) => {
					//funcion promesa cumplida
					this.subiendo_imagen = false;
					if (res.error_code == 1) {
						this.empresaModelo.imagen = res.response.imagen;
						this.empresaModelo.url_imagen = res.response.url_imagen;
					} else {
						this.modalService.dismissAll(); //cerrar todos los popups
						this.aviso_error = false;
						this.mensaje_error = res.message;
						this.modalService.open(this.modal_aviso, { centered: true });
					}
				},
					(e) => { }
				);
			})
		}
	}

	actualizar() {
		console.log(this.removeHtmlTags(this.usuarioModelo.biografia))

		this.usuarioModelo.biografia = this.removeHtmlTags(this.usuarioModelo.biografia)

		this._usuariosService.updateUsuarios(this.usuarioModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					//Si estamos editando nuestro propio perfil actualizamos la información en el navegador
					if (this.usuario_sesion._id == res.response.user._id) {
						localStorage.setItem('identity', JSON.stringify(res.response.user));
						localStorage.setItem('token', res.response.token);
					}

					this.mensaje_error = 'Se guardaron los cambios correctamente';
				} else {
					this.mensaje_error = 'Error! no se pudieron guardar los cambios';
					this.actualizacionCorrecta = false;
				}
			},
			error: (e) => {
			}
		})

		if (this.empresaModelo._id != null && this.empresaModelo._id != "") {
			this._empresasService.updateEmpresas(this.empresaModelo, this._globalService.getToken()).subscribe({
				next: (respuesta) => {
					let res: any = respuesta;
					if (res.error_code == 1) {
						this.mensaje_error = 'Se guardaron los cambios correctamente';
					} else {
						this.mensaje_error = 'Error! no se pudieron guardar los cambios';
						this.actualizacionCorrecta = false;
					}
				},
				error: (e) => {
				}
			})
		}
		this.modal_Cambios()
	}

	removeHtmlTags(html: string): string {
		const div = document.createElement('div');
		div.innerHTML = html;
		return div.textContent || div.innerText || '';
	}


	// Modal para informar si se guardo correctamente los cambios.
	modal_Cambios() {
		this.modalService.dismissAll(); //cerrar todos los popups
		if (this.actualizacionCorrecta) {
			this.aviso_error = true;
			this.modalService.open(this.modal_aviso, { centered: true });
		} else {
			this.aviso_error = false;
			this.modalService.open(this.modal_aviso, { centered: true });
		}
	}

	/* Cargamos modal para ver información de la ventana, la variable op aporta desde donde se le llama del formulario:
		OP = 1: Viene de la cabecera H1 (MI PERFIL)
		OP = 2: Viene de la cabecera H2 Datos personales del usuario
		OP = 3: Viene de la cabecera H2 Datos de contacto del usuario
		OP = 4: Viene de la cabecera H2 Redes sociales del usuario
		OP = 5: Viene de la cabecera H2 Datos de la empresa
		OP = 6: Viene de la cabecera H2 Datos de contacto de la empresa
		OP = 7: Viene de la cabecera H2 Redes sociales de la empresa
		OP = 8: 
	 */
	abrirInfo(op: number) {
		this.informacion = "";
		if (op == 1) {
			this.informacion = "En la ventana perfil, podemos ver y modificaremos nuestra imagen de avatar, datos personales, datos de contacto y redes sociales. En caso de ser empresa podemos ver o modificar información sobre la compañía."
		} else if (op == 2) {
			this.informacion = "<u>Tus datos:</u> Tenemos la opción de agregar o modificar para nuestro usuario los campos: nombre, apellidos, fecha de nacimiento, sexo, estudios, cargo y biografía. Además, podemos agregar una imagen de avatar o mantener la que viene por defecto.";
		} else if (op == 3) {
			this.informacion = "<u>Datos de contacto:</u> En datos de contacto tenemos la opción de agregar o modificar los campos obligatorios: Teléfono y país, de manera opcional podemos incluir o modificar los campos provincia, población, dirección y código postal.";
		} else if (op == 4) {
			this.informacion = "<u>Tus redes sociales:</u> En tus redes sociales tenemos la opción como usuario de agregar o modificar nuestras redes sociales: Facebook, LinkedIn y Twitter, los campos son opcionales";
		} else if (op == 5) {
			this.informacion = "<u>Datos de tu empresa:</u> En datos básicos de tu empresa, tenemos la opción de agregar o modificar los campos: nombre, CIF/VAT, Sector, web, número de empleados y facturación. Además, podemos agregar una imagen de la empresa o mantener la que viene por defecto.";
		} else if (op == 6) {
			this.informacion = "<u>Datos de contacto de tu empresa:</u> En datos de contacto de tu empresa tenemos la opción de agregar o modificar el campo obligatorio: país y de manera opcional podemos incluir o modificar los campos provincia, población, dirección y código postal.";
		} else if (op == 7) {
			this.informacion = "<u>Redes sociales de tu empresa:</u> En tus redes sociales de tu empresa tenemos la opción como usuario de agregar o modificar nuestras redes sociales: Facebook, LinkedIn y Twitter, los campos son opcionales";
		} else if (op == 8) {
			this.informacion = "<u>Cambiar contraseña:</u> Introduce una nueva contraseña de mínimo 6 caracteres, en el campo repetir contraseña tiene que validarse introduciendo la misma contraseña. <p>Una vez relleno y validado, para cambiar la contraseña pulsar: <b><u>Cambiar contraseña</u></b>.</p>";
		}
		this.modalService.open(this.modal_informacion, { centered: true });
	}

	cambiar_tab(tab: any) {
		if (typeof (tab) == 'object') {
			tab = tab.value;
		}
		this.ver_tab_contacto = false;
		this.ver_tab_datos_personales = false;
		this.ver_tab_redes = false;
		this.ver_tab_datos_empresa = false;
		this.ver_tab_contacto_empresa = false;
		this.ver_tab_redes_empresa = false;
		this.ver_tab_psw = false;
		this.botonGuardar = true;

		if (tab == 'contacto') { this.ver_tab_contacto = true }
		if (tab == 'datos_personales') { this.ver_tab_datos_personales = true }
		if (tab == 'redes') { this.ver_tab_redes = true }
		if (tab == 'datos_empresa') { this.ver_tab_datos_empresa = true }
		if (tab == 'contacto_empresa') { this.ver_tab_contacto_empresa = true }
		if (tab == 'redes_empresa') { this.ver_tab_redes_empresa = true }
		if (tab == 'psw') { this.ver_tab_psw = true; this.botonGuardar = false; }
	}
	//Verificamos que el telefono no tenga mas de 14 caracteres y solo admina +prefijo internacional y numeros.
	verificarCaracteres(event: any) {
		if (event.keyCode != "8" && event.keyCode != "46") {
			let contador = event.target.value.length
			if (contador > 14) {
				event.preventDefault();
			}
			if (event.keyCode == "69" || event.keyCode == "109") {
				event.preventDefault();
			}
		}
	}

	mostrarGuardar(op: number) {
		if (op == 1) {
			this.botonGuardar = true;
		} else {
			this.botonGuardar = false;
		}
	}
	cambiarPassword() {
		if (this.pswTxt == this.pswTxtRepetida && this.pswTxt.length > 5) {

			let newPasswordCifrada: string = CryptoJS.AES.encrypt(this.pswTxt.toString(), this.claveCompartida).toString();
			this._usuariosService.cambiarPassword(this._globalService.getToken(), newPasswordCifrada).subscribe({
				next: (respuesta) => {
					let res: any = respuesta;
					if (res.error_code == 1) {
						this.pswTxt = "";
						this.pswTxtRepetida = "";
						this.mensaje_error = 'Contraseña cambiada correctamente'
						this.actualizacionCorrecta = true;
					} else {
						this.mensaje_error = 'Error! no se pudo guardar la contraseña';
						this.actualizacionCorrecta = false;
					}
					this.modal_Cambios();
				}
			})
		}
	}
}