import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PerfilComponent } from './perfil/perfil.component';
import { RetosComponent } from './retos/retos.component';
import { VerRetoComponent } from './retos/ver-reto/ver-reto.component';
import { EditarRetoComponent } from './retos/editar-reto/editar-reto.component';
import { ConversacionesComponent } from './conversaciones/conversaciones.component';
import { VerConversacionComponent } from './conversaciones/ver-conversacion/ver-conversacion.component';
import { SoporteComponent } from './soporte/soporte.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { HomeComponent } from './home/home.component';
import { AdministracionComponent } from './administracion/administracion.component';
import { SolucionesComponent } from './soluciones/soluciones.component';
import { PresentarSolucionComponent } from './soluciones/presentar-solucion/presentar-solucion.component';
import { ContratosComponent } from './soluciones/ver-solucion/contratos/contratos.component';
import { VerSolucionComponent } from './soluciones/ver-solucion/ver-solucion.component';
import { VerUsuarioComponent } from './usuarios/ver-usuario/ver-usuario.component';
import { LogComponent } from './log/log.component';
import { PoliticasComponent } from './politicas/politicas.component';
import { CondicionesComponent } from './condiciones/condiciones.component';
import { AppComponent } from './app.component';

const routes: Routes = [
  {path: '', component:HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'perfil', component: PerfilComponent},
  {path: 'perfil/:id', component: PerfilComponent},
  {path: 'retos', component: RetosComponent},
  {path: 'retos-editar/:id', component: EditarRetoComponent},
  {path: 'retos/:id', component: VerRetoComponent},
  {path: 'conversaciones', component: ConversacionesComponent}, 
  {path: 'conversaciones/:id', component: VerConversacionComponent},
  {path: 'soporte', component: SoporteComponent},
  {path: 'usuarios/:id', component: VerUsuarioComponent},
  {path: 'administracion', component:AdministracionComponent},
  {path: 'administracion', component:AdministracionComponent},
  {path: 'soluciones', component:SolucionesComponent},
  {path: 'soluciones/:id', component:VerSolucionComponent},
  {path: 'presentar-solucion/:id_reto', component:PresentarSolucionComponent},
  {path: 'editar-solucion/:id_solucion', component:PresentarSolucionComponent},
  {path: 'contrato/:id_solucion', component:ContratosComponent},
  {path: 'log', component:LogComponent},
  {path: 'politicas', component:PoliticasComponent},
  {path: 'condiciones', component:CondicionesComponent},
  { path: '**', component:HomeComponent },
  
  /*   {path: 'usuarios', component: UsuariosComponent}, */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
