import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild , Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../services/global.services';
import { LogService } from './log.service';
import { ContratosService } from '../soluciones/ver-solucion/contratos/contratos.service';


@Component({
	selector: 'app-log',
	templateUrl: './log.component.html',
	styleUrls: ['./log.component.css']
})

export class LogComponent implements OnInit {

	public usuario_sesion:any;
	public lista_logs:any;
	public pagina:number=1;
	public paginas:number=1;
	public url_imagenes:string;
	public filtros:boolean = false;
	public configs:boolean = false;
	public desde:Date = new Date();
	public hasta:Date = new Date();
	public filtro_error:any;
	public filtro_query:string="";
	public filtro_usuario:string="";
	public quitar_ok:boolean=false;
	public ver_log:any;
	public pipe = new DatePipe('en-US');
	public informacion:string = "";
	public tipoModal:number = 0;
	public aviso_error: boolean = false;
	public mensaje_error: string = "";
	public numeroResultado:number = 0;
	public ordenAscendente: boolean = false;
	public variablesDinamicas:any = {urlwebhook:"",nombrePresidenteFGCSIC:"",dniPresidenteFGCSIC:""} //Variables con los datos de configuracion de la API.




	@ViewChild('filtro') filtro_class!: ElementRef ;
	@ViewChild('config') config_class!: ElementRef;
	@ViewChild('modal_log') modal_log:any;
	@ViewChild('modal_confirmacion') modal_confirmacion:any;
	@ViewChild('modal_aviso') modal_aviso:any;
	@ViewChild('modal_informacion') modal_informacion:any;
	@ViewChild('modal_cambioPresidente') modal_cambioPresidente:any;

	

	constructor(
		private _globalService:GlobalService,
		private _logService:LogService,
		private modalService: NgbModal,
		private _contratosService: ContratosService,
		private renderer: Renderer2,
	) { 
		this.usuario_sesion = _globalService.DatosSesion();
		this.url_imagenes = environment.url_imagenes;
		this.desde.setDate(this.desde.getDate() - 7); //configuramos por defecto el filtro de los últimos 7 días
	}

	ngOnInit(): void {
		this.descargarLog();
		this.leerConfigDinamicas();
	}

	ngAfterViewInit(): void{
		const filtroClass = this.filtro_class.nativeElement;
		const configClass = this.config_class.nativeElement;
		this.renderer.addClass(configClass, 'd-none')
		this.renderer.addClass(filtroClass, 'd-none');
	}

	// Vemos modal de confirmacion para activar notificaciones
    confirmarCambios(){
        this.modalService.dismissAll(); //cerrar todos los popups
        this.informacion = "Las notificaciones por defecto están activas, Si desea activarla Introduzca la URL de la API. "
        this.modalService.open(this.modal_confirmacion,{centered:true});
    }

	actualizarPresiFGCSIC(){
        this.modalService.dismissAll(); //cerrar todos los popups
        this.informacion = "En los siguientes campos se puede actualizar y modificar el nombre y DNI del presidente del FGCSIC."
        this.modalService.open(this.modal_cambioPresidente,{centered:true});
    }

	//Activamos webhood para recibir notificaciones de firmafy
    activarNotificaciones(){
		if(this.variablesDinamicas.urlwebhook !="" && this.variablesDinamicas.urlwebhook != ""){
			this.modalService.dismissAll();
			if (this.usuario_sesion.rol == 1){
				this._contratosService.obtenerToken(this._globalService.getToken()).subscribe({
					next: (respuesta)=>{
						let res:any = respuesta
						if (!res.response.token.error && res.error_code==1){
							let modeloAPI ={
								tokenAPI:res.response.token.data,
								urlAPIWEBHOOK:this.variablesDinamicas.urlwebhook,
							}
							this._contratosService.activarWebhook(this._globalService.getToken(),modeloAPI).subscribe({
								next: (respuesta)=>{
									let res2:any = respuesta
									if(res2.error_code == "1"){
										this._logService.actualizarConfiguracionDinamica(this.variablesDinamicas).subscribe({
											next: (resConfig:any)=>{
												if(resConfig.error_code==1){
													this.modalService.dismissAll();
													this.aviso_error = true;
													this.mensaje_error = "Activadas las notificaciones correctamente."
													this.modalService.open(this.modal_aviso,{centered:true});
												}else{
													this.modalService.dismissAll();
													this.aviso_error = false;
													this.mensaje_error = "Error al actualizar los datos."
													this.modalService.open(this.modal_aviso,{centered:true});
												}
											}
										})
									}else{
										this.modalService.dismissAll();
										this.aviso_error = false;
										this.mensaje_error = "Error al activar las notificaciones."
										this.modalService.open(this.modal_aviso,{centered:true});
									}
								}
							})
						}else{
							this.aviso_error = false;
							this.mensaje_error = "Error al acceder a la aplicación de firmas, póngase en contacto con el administrador."
							this.modalService.open(this.modal_aviso,{centered:true});
						}
					}
				})
			}else{
				this.aviso_error = false;
				this.mensaje_error = "No tiene permisos para activar las notificaciones"
				this.modalService.open(this.modal_aviso,{centered:true});
			}
		}else{
			this.aviso_error = false;
			this.mensaje_error = "Necesita especificar la URL"
			this.modalService.open(this.modal_aviso,{centered:true});
		}
    }
	
	mostrar_filtro(el: HTMLElement){

		if(this.filtros){
			this.filtros = !this.filtros;
			this.renderer.removeClass(el, 'animate__flipInX')
			this.renderer.addClass(el,'animate__flipOutX')
			setTimeout(()=>{
				this.renderer.addClass(el, 'd-none')
			}, 500)
		}else{
			this.filtros = !this.filtros;
			this.renderer.removeClass(el,'animate__flipOutX')
			this.renderer.removeClass(el,'d-none')
			this.renderer.addClass(el, 'animate__flipInX')
		}
		
	}

	mostrar_conf(el: HTMLElement){
		
		if(this.configs){
			this.configs = !this.configs;
			this.renderer.removeClass(el,'animate_flipInX');
			this.renderer.addClass(el,'animate__flipOutX')
			setTimeout(()=>{
				this.renderer.addClass(el, 'd-none')
			}, 500)
		}else{
			this.configs = !this.configs;
			this.renderer.removeClass(el,'animate__flipOutX')
			this.renderer.removeClass(el,'d-none')
			this.renderer.addClass(el, 'animate__flipInX')
		}
		el.scrollIntoView();
	}

	aplicarFiltros(){
		this.pagina = 1;
		this.descargarLog()
	}
	// Leemos las variables dinamicas de la API:
	leerConfigDinamicas(){
		this._logService.leerConfigDinamicas().subscribe({
			next: (res:any)=>{
				if(res.error_code==1){
					this.variablesDinamicas.dniPresidenteFGCSIC = res.response.dniPresidenteFGCSIC;
					this.variablesDinamicas.nombrePresidenteFGCSIC = res.response.nombrePresidenteFGCSIC;
					this.variablesDinamicas.urlwebhook = res.response.urlwebhook;
				}
			}
		})
	}
	//Actualizamos los datos de las variables:
	actualizarConfiguracionDinamica(){
		this._logService.actualizarConfiguracionDinamica(this.variablesDinamicas).subscribe({
			next: (res:any)=>{
				if(res.error_code==1){
					this.modalService.dismissAll();
				}else{
					this.modalService.dismissAll();
					this.aviso_error = false;
					this.mensaje_error = "Error al actualizar los datos."
					this.modalService.open(this.modal_aviso,{centered:true});
				}
			}
		})
	}

	descargarLog(){
		this._logService.listLogs(this.pagina, this.desde, this.hasta, this.filtro_error, this.filtro_query, this.filtro_usuario, this.quitar_ok,this.ordenAscendente).subscribe({
			next: (res:any)=>{
				if(res.error_code==1){
					this.lista_logs = res.response.listado.listado;
					this.pagina =  res.response.listado.pagina;
					this.paginas =  res.response.listado.paginas;
					this.numeroResultado = res.response.listado.listado.length
				}else{
					this.numeroResultado = 0;
				}
			}
		})
	}

	siguientePagina(){
		if(this.pagina<this.paginas){
			this.pagina++;
			this.descargarLog();
		}
	}

	anteriorPagina(){
		if(this.pagina>1){
			this.pagina--;
			this.descargarLog();
		}
	}

	verLog(log:any){
		this.ver_log = log;
		this.modalService.open(this.modal_log,{size: 'lg', backdrop: 'static', scrollable:true });
	}
	//Abrimos el modal donde aparece información de la pagina.
	abrirInfo(){
		this.informacion = "En la ventana Log, los usuarios con permisos de superadministrador podrán:</br> <li>Obtener el log de eventos, si no se aplica fechas muestra los últimos 7 días por defecto.</li><li> Además tras pulsar el botón <em>configuración</em> en la parte inferior se podrá aplicar funcionalidades como activar Webhook de firmafy, comprobar el estado de la licencia de firmafy y modificar los datos del presidente de la FGCSIC para generar los contratos.</li>"
		this.modalService.open(this.modal_informacion,{centered:true});
	}
	//obtenerUsosDisponibles
	consultaConsumos(){
		if (this.usuario_sesion.rol == 1){
			this._contratosService.obtenerToken(this._globalService.getToken()).subscribe({
				next: (respuesta)=>{    
					let res:any = respuesta;   
					if (!res.response.token.error && res.error_code==1){
						let res:any = respuesta;  
						let paramentros = {
							tokenAPI:res.response.token.data,
						}
						this._contratosService.obtenerUsosDisponibles(this._globalService.getToken(),paramentros).subscribe({
							next: (respuestaFinal)=>{
								let resFinal:any = respuestaFinal;
								if(!resFinal.response.error){
									this.modalService.dismissAll(); //cerrar todos los popups
									this.aviso_error = true;
									this.mensaje_error = "La licencia de Firmafy expira en "+resFinal.response.days+" días y actualmente tiene disponible: "+resFinal.response.sms+" SMS de texto, "+resFinal.response.credits+" envíos de email y "+resFinal.response.mb.toFixed(2)+" Mb de almacenamiento.";
									this.modalService.open(this.modal_aviso,{centered:true});
								}else{
									this.modalService.dismissAll(); //cerrar todos los popups
									this.aviso_error = false;
									this.mensaje_error = "La licencia de firmafy no dispone envios disponibles."
									this.modalService.open(this.modal_aviso,{centered:true});
								}
							}
						})
					}else{
						this.modalService.dismissAll(); //cerrar todos los popups
						this.aviso_error = false;
						this.mensaje_error = "Error al acceder a la aplicación de firmas, póngase en contacto con el administrador."
						this.modalService.open(this.modal_aviso,{centered:true});
					}
				}
			})
		}else{
			this.modalService.dismissAll(); //cerrar todos los popups
			this.aviso_error = false;
			this.mensaje_error = "El usuario con el que esta intentando acceder no tiene permisos"
			this.modalService.open(this.modal_aviso,{centered:true});
		}
	}
	ordenar(){
		if (this.ordenAscendente){
			this.ordenAscendente = false;
		}else{
			this.ordenAscendente = true;
		}
		this.descargarLog()
	}
}
