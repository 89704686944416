<div id="politicasBody" class="politicasBody">
    <header class=" row | mb-xl-5 | position-relative">
            <article *ngIf="verIconos" class="row | ms-xl-2 mt-xl-5 mb-xl-5 mt-sm-3 mb-sm-3 justify-content-xl-start justify-content-center">
                <img class=" logo main-icon" src="../../assets/logo.png">
            </article>
    </header>

    <div class="tituloPrincipal">
        <!-- <img *ngIf="verIconos" class="atras me-3 img-fluid | clicable"  src="../../assets/Icon feather-arrow-left-circle.svg" (click)="cerrar()"> --> CONDICIONES GENERALES DE CONTRATACIÓN.
    </div>
    
    <article class="seccion">
        <div class="parrafoPrincipal">
            <div class="parrafo">
                Las presentes Condiciones generales rigen el acceso y la utilización del Sitio Web accesible a través del nombre de dominio <a class="clicable" (click)="irAnuevaWeb(url)"><u>{{url}}</u></a> y sus subdominios (en adelante Sitio Web), así como la contratación a través del mismo. De forma subsidiaria y supletoria se aplicarán las Condiciones Generales de la Fundación General CSIC (en adelante, FGCSIC) que se encuentran accesibles a través del siguiente enlace <a class="clicable" (click)="irAnuevaWeb(url+'condiciones')"><u>{{url}}condiciones</u></a>
            </div>
            <div class="parrafo">
                La utilización del Sitio Web atribuye la condición de USUARIO del Sitio Web e implica la aceptación de todos los términos incluidos en estas Condiciones Generales.
            </div>
            <div class="parrafo">
                Cada vez que el USUARIO acceda al Sitio Web debe leer las presentes Condiciones Generales, ya que tanto el Sitio Web como las condiciones pueden sufrir modificaciones. El titular del Sitio Web se reserva la facultad de realizar en cualquier momento y sin necesidad de preaviso cualquier actualización o modificación de sus contenidos y servicios de las presentes Condiciones Generales y, en general, de cuantos elementos integran el diseño y configuración del Sitio Web.
            </div>
            <div class="parrafo">
                Por medio de la aceptación de las presentes Condiciones Generales, el USUARIO manifiesta: (i) que ha leído, entiende y comprende lo aquí expuesto; (ii) que es una persona con capacidad suficiente para contratar en caso de querer hacerlo; y, (iii) que asume todas las obligaciones aquí dispuestas.
            </div>
            <div class="parrafo">
                <ol>

<!--                <li><a (click)="scrollToSection('servicios')">Servicios</a></li>
                    <li><a (click)="scrollToSection('registroycuenta')">Información de registro y cuenta</a></li>-->

                    <li> <a class="clicable" (click)="scrollToSection('introduccion')"><u> Introducción.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('servicios')"><u> Servicios.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('registroycuenta')"> <u>Información de registro y cuenta.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('responsabilidades')"><u> Responsabilidades del Usuario.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('prohibicionDeTerceros')"><u> Agentes de terceros.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('serviciosExternos')"><u> Servicios de terceros.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('contenido')"><u> Contenido.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('licenciaDeContenido')"><u> Licencia de contenido.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('limitaciones')"><u> Limitaciones de responsabilidad.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('derechosDePropiedad')"><u> Derechos de propiedad intelectual.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('renuncia')"><u> Renuncia de garantías.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('indemnizacion')"><u> Indemnización.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('general')"><u> General.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('procedimiento')"><u> Procedimiento de uso y contratación del Sitio Web.</u></a></li>
                    <li> <a class="clicable" (click)="scrollToSection('acuerdoCompleto')"><u> Acuerdo completo y otras cuestiones.</u></a></li>    
                </ol>
            </div>
        </div>
    </article>
    <article id="introduccion" class="seccion">
        <div class="tituloSecundario">
            1. Introducción.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                <div class="parrafo lista">
                    TITULAR: FUNDACIÓN GENERAL CSIC (FGCSIC).
                </div>
                <div class="parrafo lista">
                    DIRECCIÓN: C/ Joaquín Costa Nº12 Madrid (Madrid) CP:28002
                </div>
                <div class="parrafo lista">
                    CONTACTO: info@nexofy.es
                </div>
                <div class="parrafo lista">
                    DATOS REGISTRALES: Inscrito en el Protectorado de Fundaciones del Ministerio de Educación con fecha 23 de enero de 2009, con el número de registro 1104.
                </div>
                <div class="parrafo lista">
                    C.I.F: G-85562106
                </div>
            </div>
            <div class="parrafo">
                Al utilizar el presente Sitio Web, usted indica su aceptación de estar sujeto a estas Condiciones Generales y particulares que se puedan establecer para cada Proyecto. Si no estuviera de acuerdo con alguna o todas de estas Condiciones Generales, no use el Sitio Web.
            </div>
            <div class="parrafo">
                Al acceder a los servicios prestados por el Sitio Web, usted acepta quedar sujeto a estas Condiciones Generales y a cualquier condición adicional que se refiera en las mismas, incluyendo nuestro Aviso de Privacidad y Cookies <a class="clicable" (click)="irAnuevaWeb(url+'politicas')"> <u>{{url}}politicas</u></a> que establecen los términos en los que procesamos cualquier dato personal que se pudiese recopilar de usted o que usted nos proporcione. Si está de acuerdo, entenderemos que tiene las facultades suficientes para vincularse a sí mismo y/o a la empresa que representa y ha proporcionado toda la información necesaria a la FGCSIC para acceder a los servicios que ofrece el Sitio Web. 
            </div>
        </div>
    </article>

    <article id="servicios" class="seccion">
        <div class="tituloSecundario">
            2. Servicios.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                Las presentes Condiciones Generales se aplican a todos los servicios que FGCSIC ofrece en el presente Sitio Web, incluyendo: (a)__nexofy.es, nexofy.org y nexofy.net__; (b)__subdominios__ (c); __aplicaciones__;(d) el Servicio de emparejamiento de FGCSIC ("FGCSIC Match"); y (e) otros sitios, aplicaciones, comunicaciones y otros servicios relacionados con el presente Sitio Web que señalan que se ofrecen bajo este acuerdo. Este acuerdo se aplica a todos los Usuarios registrados de nuestros servicios, incluyendo, pero no limitado, a Usuarios que ofrecen sus servicios ("usuarios investigadores o candidatos") y a “usuarios empresas” o interesados, es decir, a las personas, físicas y jurídicas, interesadas en contratar a los candidatos.
            </div>    
        </div>
    </article>

    <article id="registroycuenta" class="seccion">
        <div class="tituloSecundario">
            3. Información de registro y cuenta.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                Para usar los servicios del Sitio Web, cada Usuario debe registrarse y crear una cuenta. Al registrarse, acepta proporcionar información precisa, actual y completa sobre el Usuario mismo y mantenerlos actualizados. Si FGCSIC tiene motivos para creer que los datos de registro son inexactos, desactualizados o incompletos, FGCSIC puede suspender o cancelar su cuenta. 
            </div>
            <div class="parrafo">
                El Usuario no podrá bajo ningún concepto: (a) crear una cuenta utilizando una identidad falsa o información que no sea suya y veraz; (b) tener más de una cuenta; o (c) crear una cuenta o usar los servicios si anteriormente su cuenta ha sido suspendida y/o cancelada. FGCSIC se reserva el derecho, a su sola discreción, de aceptar o rechazar el registro para usar los servicios del Sitio Web, así como de suspender o cancelar su cuenta por cualquier motivo o sin motivo alguno. El Usuario es responsable de mantener la confidencialidad de su contraseña y cuenta y será totalmente responsable de cualesquiera actividades que se realicen en su cuenta. El Usuario acepta notificar de inmediato cualquier uso no autorizado de su cuenta o cualquier otra violación de la seguridad de su cuenta o cualquier otra irregularidad detectada.
            </div>
        </div>    
    </article>

    <article id="responsabilidades" class="seccion">
        <div class="tituloSecundario">
            4.	Responsabilidades del Usuario.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                El Usuario es responsable del uso de los servicios del presente Sitio Web realizado a través de su cuenta, incluyendo su uso por parte de terceros. Al usar los servicios del presente Sitio Web, no podrá: (a) copiar o distribuir ninguna parte o la totalidad de los servicios, incluido el denominado "scraping" automatizado o no automatizado; (b) usar cualquier sistema automatizado, incluidos "robots" y "spiders"; (c) interferir o comprometer la integridad de los sistemas del Sitio Web o descifrar cualesquiera transmisiones del servidor; (d) imponer una carga excesivamente grande en la infraestructura del Sitio Web; (e) subir virus o gusanos al Sitio Web; (f) recolectar o retener cualquier información personal que contenga el Sitio Web y el servicio más allá del uso permitido en este acuerdo; (g) acceder al Sitio Web y al servicio por cualquier medio que no sea el autorizado en las presentes Condiciones Generales, incluidos las redes privadas virtuales que están expresamente prohibidas; (h) acosar, intimidar o causar daños a otros; (i) personificar a cualquier persona o entidad; o (j) piratear, enviar correo no deseado, engañar o proporcionar contenido fraudulento, injurioso o calumnioso o (i) realizar cualquier otro acto u omisión que pudiera resultar contrario al interés de la plataforma o de su explotador o perjudicar a esta o al resto de usuarios o terceros. Su acceso continuo al Sitio Web y a los servicios está condicionado a su acuerdo de actuar de manera profesional y de buena fe. El Usuario acepta, tiene facultades suficientes y garantiza que cualquier información personal de terceros que proporcione en relación con el uso de los servicios, se proporcionarán con todos los consentimientos y permisos legales necesarios.
            </div>
            <div class="parrafo">
                El uso del Sitio Web se realizará estrictamente en cumplimiento de las leyes aplicables sobre acceso digital o transmisión de datos y ciberseguridad que rigen en la Unión Europea y en las del país del usuario, siendo las primeras prioritarias. El Usuario no podrá utilizarlo con intención de realizar o para la realización de infracciones o delitos financieros, digitales o personales, incluidos: robo de identidad, fraude de identidad, acoso, acoso cibernético y formas similares de delitos, etc. Esto incluye hacer o causar que lo mismo surja circunstancialmente a cualquier otra persona o entidad, daño innecesario a través de tergiversación, amenazas físicas a través de Internet, robo de secretos comerciales o violaciones de derechos de autor como piratería de software o cualesquiera otros actos similares.
            </div>
            <div class="parrafo">
                En ningún caso la FGCSIC, creadora del Sitio Web, asumirá responsabilidad alguna por los contenidos de algún enlace ajeno ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y legalidad de cualquier material o información contenida en ningunos de dichos hipervínculos u otros sitios de internet. Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con dichas entidades conectadas.
            </div>
            <div class="parrafo">
                El usuario guardará secreto y confidencialidad de todas las informaciones que hubiera podido conocer o a las que haya tenido acceso, sean técnicas, comerciales, etc. en el alta de la plataforma de servicios y se obliga a usarlos con la debida diligencia y discreción, siendo responsable de las violaciones relacionadas con su falta de diligencia en el mantenimiento de dicha obligación.
            </div>
            <div class="parrafo">
                El USUARIO se obliga a no revelar a terceros y a comprometer por escrito a su personal para que mantenga estricta confidencialidad respecto de cualquier información que pueda obtener respecto al Sitio Web y como consecuencia de las presentes Condiciones Generales, así como de cualquier información que pueda obtener, directa o indirectamente, relativas al funcionamiento del Sitio Web. Igualmente, se obliga a devolver a la FGCSIC a petición suya, y en cualquier caso a la finalización del uso del Sitio web, todo el material y la información que le haya sido facilitada, sin reservarse copia alguna de los mismos. El USUARIO se compromete expresamente a firmar un Acuerdo de Confidencialidad cuando así se le requiera desde el Sitio Web
            </div>
        </div>    
    </article>

    <article id="prohibicionDeTerceros" class="seccion">
        <div class="tituloSecundario">
            5. Prohibición de terceros.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                Los Usuarios no pueden, en ningún caso, permitir que cualesquiera terceros usen el servicio en su nombre, dado que el mismo tiene carácter personalísimo. En todo caso y si infringieran dicha disposición, serán totalmente responsables de las acciones y omisiones de esos terceros, teniendo en cuenta que cualquier violación de estas Condiciones Generales por parte de los Usuarios y de terceros se considerará una infracción muy grave. El servicio del Sitio Web tiene carácter personal e intransferible y solo pueden acceder los Usuarios registrados ya que el Sitio Web en ningún caso tiene una función comercial.
            </div>
        </div>    
    </article>

    <article id="serviciosExternos" class="seccion">
        <div class="tituloSecundario">
            6. Servicios externos al Sitio Web ofrecidos por FGCSIC.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                La FGCSIC puede proporcionar a los Usuarios ciertos servicios de terceros o proveedores de servicios de terceros relacionados con los servicios ofrecidos por el Sitio Web. Cada Usuario será responsable de evaluar si desea acceder o usar tales servicios de terceros, y, en ciertas circunstancias cuando sea requerido o aplicable, puede darse de baja de tales servicios de terceros disponibles fuera de la red de FGCSIC, o puede elegir no utilizar dichos servicios de terceros en cualquier momento. La FGCSIC se reserva el derecho de suspender los servicios de terceros en cualquier momento. El Usuario puede revisar cualquier término y/o política de privacidad aplicable de un servicio de terceros antes de usarlo o compartir cualquier información con él, ya que puede darle al tercero permiso para usar su información fuera de las presentes Condiciones Generales. La FGCSIC no es responsable ni respalda ninguna función, contenido, publicidad, productos u otros materiales disponibles en o desde tales servicios de terceros.
            </div>
        </div>    
    </article>

    <article id="contenido" class="seccion">
        <div class="tituloSecundario">
            7. Contenido.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                El contenido que los Usuarios publiquen, transmitan o envíen a través del Sitio Web está destinado a compartirse con otros Usuarios. Al enviar cualquier contenido el Usuario garantiza que posee todos los derechos sobre el mismo y que el contenido no infringe los derechos de propiedad intelectual, los derechos de privacidad, los derechos de publicidad u otros derechos legales de cualquier tercero.
            </div>
            <div class="parrafo">
                La FGCSIC no está obligada a revisar cualquier contenido publicado por los Usuarios en nuestros servicios del Sitio Web, aunque se reserva el derecho de hacerlo con o sin aviso, para evitar o corregir cualquier presunta infracción legal en este sentido, pudiendo eliminar parte o todo del contenido en cualquier momento. La FGCSIC no será responsable de la precisión, utilidad, seguridad o derechos de propiedad intelectual relacionados con dicho contenido y que dicho contenido no es responsabilidad de la FGCSIC.
            </div>
        </div>    
    </article>

    <article id="licenciaDeContenido" class="seccion">
        <div class="tituloSecundario">
            8. Licencia de contenido.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                El Usuario se obliga a hacer un uso correcto del Sitio Web de conformidad con la Ley y las presentes Condiciones Generales, así como con las demás condiciones, reglamentos e instrucciones que en su caso pudieran ser de aplicación. El Usuario responderá frente a la FGCSIC o frente a terceros de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.
            </div>
            <div class="parrafo">
                Queda expresamente prohibido el uso del Sitio Web con fines lesivos de bienes o intereses de la FGCSIC o de terceros. Con carácter meramente enunciativo, el Usuario de acuerdo con la legislación vigente deberá abstenerse de:
            </div>
            <div class="parrafo">
                a) Reproducir, copiar, distribuir, poner a disposición, comunicar públicamente, transformar o modificar los Contenidos salvo en los casos autorizados en la ley o expresamente consentidos por la FGCSIC o por quien ostente la titularidad de los derechos de explotación en su caso.
            </div>
            <div class="parrafo">
                b) Reproducir o copiar para uso privado los Contenidos que puedan ser considerados como confidenciales de conformidad con la legislación vigente en materia de propiedad intelectual, así como su comunicación pública o puesta a disposición de terceros cuando estos actos impliquen necesariamente la reproducción por parte del Usuario o de un tercero.
            </div>
            <div class="parrafo">
                c) Extraer y/o reutilizar la totalidad o una parte sustancial de los Contenidos integrantes del Sitio Web.
            </div>
            <div class="parrafo">
                El acceso al Sitio web no implica la obligación por parte de la FGCSIC de comprobar la veracidad, exactitud, adecuación, idoneidad, exhaustividad y actualidad de la información suministrada a través del mismo por el Usuario. Los contenidos de esta página son de carácter general y no constituyen, en modo alguno, la prestación de un servicio de asesoramiento de ningún tipo, por lo que dicha información resulta insuficiente para la toma de decisiones personales o empresariales por parte del Usuario.
            </div>
            <div class="parrafo">
                La FGCSIC no se responsabiliza de las decisiones tomadas a partir de la información suministrada en el Sitio Web ni de los daños y perjuicios producidos en el Usuario o terceros con motivo de actuaciones que tengan como único fundamento la información obtenida en el Sitio Web.
            </div>
            <div class="parrafo">
                Los Usuarios garantizan que su contenido proporcionado en el Sitio Web cumple con las leyes aplicables y no viola ninguna disposición legal y/o no infringe ningún derecho de terceros. Los Usuarios se comprometen a cumplir con las disposiciones legales pertinentes y, en particular, a no infringir los derechos de Propiedad Intelectual e Industrial de terceros u otros derechos e indemnizará y mantendrá indemne a la FGCSIC a este respecto.
            </div>
        </div>    
    </article>

    <article id="limitaciones" class="seccion">
        <div class="tituloSecundario">
            9. Limitaciones de responsabilidad.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                La FGCSIC no será responsable de los efectos relacionados con: (a) cualquier contenido publicado en nuestros servicios; (b) los contratos u otras obligaciones que puedan surgir entre los usuarios; (c) cualquier daño o perjuicio que resulte a través en relación con el uso de nuestros servicios a través del Sitio Web en violación de estas condiciones; (d) cualquier comentario negativo o crítico que puedan publicar otros Usuarios a través de los servicios del Sitio Web o de forma externa al mismo; (e) cualquier servicio de terceros que se le proporcione en virtud de su uso de los servicios; (f) cualquier información de identificación personal de terceros que cargue o proporcione a la FGCSIC en virtud de los servicios; (g) la FGCSIC elude expresamente toda responsabilidad por cualquier pérdida o daño directo, indirecto o consecuente que pueda sufrir cualquier usuario en relación con esta web o en relación con el uso, la incapacidad de utilizar, o los resultados del uso de la información del proyecto, el Portal o cualquier web vinculada al Portal y cualquier material publicado en ella, incluidos, sin limitación, la responsabilidad por la pérdida de ingresos o rentas, las pérdidas comerciales, la pérdida de beneficios o contratos, la pérdida de los ahorros previstos, la pérdida de datos, la pérdida de buena reputación o fondo de comercio y cualquier otra pérdida o daño de cualquier tipo, cualquiera que sea la causa.
            </div>
            <div class="parrafo">
                LA FGCSIC PROPORCIONA EL SERVICIO “TAL CUAL” Y “SEGÚN DISPONIBILIDAD” Y, HASTA DONDE PERMITA LA LEGISLACIÓN APLICABLE, NO OTORGA GARANTÍA DE NINGÚN TIPO, YA SEA EXPRESA, IMPLÍCITA, LEGAL O DE OTRO TIPO, CON RESPECTO AL SERVICIO (INCLUYENDO TODO EL CONTENIDO DEL MISMO), INCLUYENDO CUALQUIER GARANTÍA IMPLÍCITA DE CALIDAD SATISFACTORIA, COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO PARTICULAR O DE NO INFRACCIÓN. LA FGCSIC NO GARANTIZA QUE (A) EL SERVICIO SE PROPORCIONE DE FORMA ININTERRUMPIDA, SEGURA O LIBRE DE ERRORES, (B) QUE SE CORRIJAN TODOS LOS DEFECTOS O ERRORES DEL SERVICIO, NI (C) QUE EL CONTENIDO O LA INFORMACIÓN QUE OBTENGA EN O A TRAVÉS DEL SERVICIO SEA CORRECTA.
            </div>
            <div class="parrafo">
                LA FGCSIC NO SE HACE RESPONSABLE DEL CONTENIDO QUE USTED U OTRO USUARIO O TERCERO PUBLIQUE, ENVÍE O RECIBA A TRAVÉS DEL SERVICIO. EL ACCESO A CUALQUIER MATERIAL DESCARGADO U OBTENIDO DE OTRA FORMA A TRAVÉS DE LA UTILIZACIÓN DEL SERVICIO SE REALIZA BAJO SU CUENTA Y RIESGO.
            </div>
            <div class="parrafo">
                HASTA DONDE PERMITA LA LEGISLACIÓN APLICABLE, NI LA FGCSIC NI SUS AFILIADOS, EMPLEADOS, LICENCIANTES Y PROVEEDORES DE SERVICIOS SERÁN EN NINGÚN CASO RESPONSABLES DE LOS DAÑOS Y PERJUICIOS INDIRECTOS, CONSECUENCIALES, EJEMPLARIZANTES, INCIDENTALES, ESPECIALES, PUNITIVOS O AUMENTADOS, INCLUYENDO LA PÉRDIDA DE BENEFICIOS, YA SEA DIRECTA O INDIRECTA, Y CUALQUIER PÉRDIDA DE DATOS, UTILIZACIÓN, FONDO DE COMERCIO U OTRAS PÉRDIDAS INTANGIBLES, QUE SEAN RESULTADO DE: (I) SU ACCESO A, UTILIZACIÓN DE O IMPOSIBILIDAD DE ACCESO A O UTILIZACIÓN DEL SERVICIO, (II) LA CONDUCTA O CONTENIDO DE OTROS USUARIOS O TERCEROS EN, A TRAVÉS DE O TRAS LA UTILIZACIÓN DEL SERVICIO; O (III) EL ACCESO, USO O ALTERACIÓN NO AUTORIZADOS DE SU CONTENIDO, INCLUSO AUNQUE SE HAYA ADVERTIDO A LA FGCSIC DE LA POSIBILIDAD DE DICHOS DAÑOS. TOMANDO EN CUENTA LO ANTERIOR, EL USUARIO ENTIENDE Y ACEPTA QUE EN NINGÚN CASO LA FGCSIC SERÁ RESPONSABLE POR DAÑOS PERSONALES INDIRECTOS, INCIDENTALES O CONSECUENCIALES QUE SURJAN O ESTÉN RELACIONADOS CON EL SERVICIO DEL SITIO WEB, YA SEA QUE LA FGCSIC HAYA SIDO NOTIFICADO DE LA POSIBILIDAD DE TALES DAÑOS, O DAÑOS POR LESIONES PERSONALES O DAÑOS EMOCIONALES QUE SURJAN O ESTÉN RELACIONADOS CON ESTE ACUERDO O DE CUALQUIER COMUNICACIÓN, INTERACCIÓN O REUNIÓN CON OTROS USUARIOS DEL SERVICIO, SOBRE CUALQUIER TEORÍA DE RESPONSABILIDAD (INCLUIDO CONTRATO, AGRAVIO INCLUYENDO NEGLIGENCIA, O DE CUALQUIER OTRA FORMA) QUE SURJA O ESTÉ RELACIONADA CON: (I) EL USO O LA INCAPACIDAD DE USAR LOS SERVICIOS; (II) DECLARACIONES O CONDUCTA DE CUALQUIER TERCERO EN LOS SERVICIOS; O (III) CUALQUIER OTRO ASUNTO RELACIONADO CON LOS SERVICIOS.
            </div>
            <div class="parrafo">
                LA RESPONSABILIDAD TOTAL DE FGCSIC CON USTED POR TODAS LAS DEMANDAS RELACIONADAS CON EL SERVICIO NO EXCEDERÁ EN NINGÚN CASO LA CANTIDAD PAGADA POR USTED, EN CASO DE QUE HAYA PAGADO ALGUNA CANTIDAD, A LA FGCSIC POR EL SERVICIO MIENTRAS HAYA TENIDO UNA CUENTA.
            </div>
            <div class="parrafo">
                LA RESPONSABILIDAD TOTAL DE FGCSIC CON USTED POR TODAS LAS DEMANDAS RELACIONADAS CON EL SERVICIO NO EXCEDERÁ EN NINGÚN CASO LA CANTIDAD PAGADA POR USTED, EN CASO DE QUE HAYA PAGADO ALGUNA CANTIDAD, A LA FGCSIC POR EL SERVICIO MIENTRAS HAYA TENIDO UNA CUENTA.
            </div>
        </div>    
    </article>
    
    <article id="derechosDePropiedad" class="seccion">
        <div class="tituloSecundario">
            10.	Derechos de propiedad intelectual.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                La FGCSIC, creadora del Sitio Web, es titular de todos los derechos de propiedad intelectual e industrial del Sitio Web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software, textos, marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.) titularidad de la FGCSIC.
            </div>
            <div class="parrafo">
                Todos los derechos reservados. En virtud de lo dispuesto en la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución, la transformación y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de este Sitio Web, con fines comerciales y/o con cualesquiera otros fines, en cualquier soporte o por cualquier medio técnico, sin la autorización previa y expresa de la FGCSIC.
            </div>
            <div class="parrafo">
                El USUARIO se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de FGCSIC. Podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado y relacionado con los fines de los servicios del Sitio Web. El Usuario deberá devolver o destruir sin demora todas las copias (independientemente de la forma en que se hayan reproducido o almacenado), incluyendo todas las notas y productos derivados de la Información Confidencial divulgada en el Sitio Web conforme a estas Condiciones Generales una vez se produzca el primero de los tres eventos siguientes: (i) la realización o terminación de los acuerdos contemplados en estas Condiciones Generales, (ii) o la terminación del uso del Sitio Web, (iii) o en el momento en que FGCSIC así lo solicite.
            </div>
            <div class="parrafo">
                El USUARIO deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en el Sitio Web.
            </div>
            <div class="parrafo">
                El USUARIO afirma disponer de todos los derechos de propiedad intelectual e industrial sobre sus contenidos, se obliga a asumir toda reclamación o responsabilidad, incluyendo indemnizaciones por daños y perjuicios, que cualquier tercero pueda ejercitar contra la FGCSIC por considerar infringidos sus derechos por cualquiera de las acciones derivadas de las obligaciones que el USUARIO contrae directa o indirectamente con estas Condiciones Generales. Además, el USUARIO se obliga a mantener indemne a la FGCSIC ante cualquier perjuicio que él o un tercero puedan sufrir como consecuencia del uso y/o de los contenidos en relación con los servicios ofrecidos por el Sitio Web.
            </div>
            <div class="parrafo">
                El USUARIO garantiza a la FGCSIC que el contenido de aquél está amparado por patentes, marcas, copyright y cualquier tipo de derecho de propiedad industrial y/o intelectual y que están protegidas frente a cualquier reclamación de daños que terceros pudieran realizar. El USUARIO exime a la FGCSIC de toda responsabilidad por las infracciones de la propiedad industrial y/o intelectual en que se pudiera incurrir con ocasión de las presentes Condiciones Generales y el Sitio web y se obliga a realizar cuanto sea necesario para dejar a la FGCSIC al margen y a salvo de las reclamaciones que por dichas infracciones pudiera haber. En caso de infracción, el USUARIO defenderá y, en su caso, indemnizará a la FGCSIC por cualquier demanda o reclamación que pudiera iniciarse contra ella, asumiendo todos los costes, directos e indirectos, las costas y gastos que se pudieran originar, incluidos, sin limitación, la responsabilidad por la pérdida de ingresos o rentas, las pérdidas comerciales, la pérdida de beneficios o contratos, la pérdida de los ahorros previstos, la pérdida de datos, la pérdida de buena reputación o fondo de comercio y cualquier otra pérdida o daño de cualquier tipo, cualquiera que sea la causa.
            </div>
            <div class="parrafo">
                Los usuarios se obligan a poner en conocimiento de la FGCSIC cualquier acto que pueda considerarse como una usurpación, o violación de cualquier derecho de propiedad industrial y/o intelectual de FGCSIC y asumen el compromiso de no impugnar cualquier signo distintivo de propiedad industrial o derecho de propiedad intelectual solicitados o concedidos, bajo ningún concepto o causa, en cualquier territorio. Si se produce alguna infracción del derecho concedido por un tercero, o si existe el peligro de que tal infracción pueda producirse, el usuario cooperará con la FGCSIC para adoptar las medidas legales apropiadas para la protección de los derechos de la FGCSIC. Si la FGCSIC ejercitara cualquier acción en defensa de sus derechos quedará obligado el usuario a colaborar con aquél en toda la extensión que le sea requerida por la FGCSIC.
            </div>
        </div>    
    </article>

    <article id="renuncia" class="seccion">
        <div class="tituloSecundario">
            11.	Renuncia de garantías.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                Los servicios que se ofrecen a través del Sitio Web se proporcionan en la forma establecida por lo que se excluyen expresamente todas las garantías implícitas y representaciones, tales como garantías de comercialización, idoneidad para un propósito específico, exactitud de los datos y no infracción. Todas aquellas obligaciones del gestor de la plataforma que no estén expresamente incluidas y mencionadas en este documento, quedarán expresamente excluidas, así como cualquier obligación o garantía relacionada con las mismas.
            </div>
            <div class="parrafo">
                Asimismo, no se puede garantizar que: (a) los servicios cumplirán sus objetivos; (b) el uso de los servicios será ininterrumpido, seguro o libre de errores; (c) los servicios serán precisos o confiables; o (d) se corregirán cualquier error en los servicios.
            </div>
            <div class="parrafo">
                El Usuario acepta que es únicamente responsable de todas sus comunicaciones e interacciones con otros usuarios de servicios ofrecidos en el Sitio Web. El Usuario entiende y reconoce que cualquier comunicación enviada a través del servicio no es responsabilidad de la FGCSIC ni por el contenido de esos mensajes ni por cualquier adjunto a esos mensajes ni por los destinatarios elegidos por el remitente, ya sea que se envíen a través del servicio o a través del servidor de correo electrónico preferido por el Usuario.
            </div>
        </div>    
    </article>

    <article id="indemnizacion" class="seccion">
        <div class="tituloSecundario">
            12.	Indemnización.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                El Usuario deberá indemnizar a la FGCSIC en relación con: (a) su contenido falso o fraudulento; (b) su uso y acceso a los servicios en infracción de este acuerdo; (c) su infracción de este acuerdo; (d) su infracción de cualquier ley, norma o regulación o los derechos de cualquier tercero, incluyendo su carga o provisión de información personalmente identificable a través del servicio; (e) su uso de cualquier servicio de terceros.
            </div>
        </div>    
    </article>

    <article id="general" class="seccion">
        <div class="tituloSecundario">
            13. General.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                13.1. Cualquier aspecto relacionado con las presentes Condiciones Generales y este Sitio Web, quedará sujeto a la legislación española y a los Juzgados y Tribunales de la ciudad de Madrid (España). Sin perjuicio de que en aquellos supuestos en que el usuario ostente la consideración de consumidor, la FGCSIC se someterá a la jurisdicción de los tribunales que resulten competentes según la legislación vigente en cada momento, siguiendo los procedimientos de la cláusula 15.
            </div>
            <div class="parrafo">
                13.2. Caso Fortuito y fuerza mayor. La FGCSIC no será responsable por cualquier fallo o retraso en el desempeño en la medida en que sea causado por cualquier circunstancia fuera de nuestro control razonable.
            </div>
            <div class="parrafo">
                13.3. La ineficacia de cualquier disposición de estas Condiciones Generales no afectará la eficacia de ninguna otra disposición. Si se considera que alguna disposición de este acuerdo entra en conflicto con otra, la FGCSIC tendrá el derecho exclusivo de elegir cuál disposición permanece en vigor. Los encabezados se proporcionan solo por conveniencia.
            </div>
            <div class="parrafo">
                13.4. La FGCSIC se reserva el ejercicio de todos los derechos que le asistan en defensa de sus intereses bajo las leyes aplicables. La no aplicación de cualquier disposición de estas Condiciones Generales o bajo las leyes aplicables no se interpretará como renuncia a cualquier derecho de aplicación en las mismas o diferentes circunstancias en cualquier momento en el futuro.
            </div>
            <div class="parrafo">
                13.5. Terminación. La FGCSIC podrá suspender o terminar los servicios ofrecidos en el Sitio Web o la cuenta de los Usuarios a discreción de la FGCSIC, siendo suficiente una breve explicación y aviso previo. A cada Usuario, en caso de incumplimiento de estas Condiciones Generales, le notificaremos tal incumplimiento y, en caso de que el incumplimiento pueda ser subsanado, le daremos treinta (30) días hábiles para subsanar dicho incumplimiento. Si dicho incumplimiento permanece, procederemos a suspender y/o cancelar su cuenta según se establece en las presentes Condiciones Generales. FGCSIC no tendrá ninguna responsabilidad alguna hacia el Usuario por cualquier suspensión o cancelación. Todas las disposiciones de estas Condiciones Generales que por su naturaleza deben permanecer en vigor, así lo harán a la terminación, incluyendo la propiedad, descargo de responsabilidad de garantía, indemnización y limitaciones de responsabilidad, entre otras.
            </div>
            <div class="parrafo">
                13.6. Pago. Los servicios ofrecidos por el Sitio Web tendrán la consideración de no gratuitos, si bien, inicialmente no supondrán desembolso el primer año, por un tiempo determinado. La FGCSIC podrá variar los servicios y sus precios en cualquier momento, notificando de ello a todos los Usuarios. El pago a la FGCSIC por el “usuario empresa” o interesado y la retribución, compensación o premio que pueda cobrar el "usuario investigador o candidatos" por la participación en el desempeño de cada Actividad o Desafío, se determinará según la tarifa razonable y de mercado que se establezca por la FGCISC cuando corresponda y vigente en el momento de la inscripción a la Actividad o Desafío y que serán publicadas en las secciones correspondientes del Sitio Web (caso de no establecerse nada será de un mínimo del 2% del importe total ofertado). Los USUARIOS aceptan expresamente el pago y la retribución, compensación o premio con el uso de los servicios del Sitio Web. Los pagos y las retribuciones, compensaciones y premios serán en euros y por transferencia bancaria en la cuenta que indique en cada momento la FGCSIC. Los importes en euros publicados son brutos debiéndose aplicar los impuestos que legalmente sean de aplicación en su caso. Salvo las retribuciones complementarias que puedan indicar los “usuarios empresa” o interesados, los gastos incurridos por el "usuario investigador o candidatos" en relación con el desempeño de las Actividades y Desafíos serán íntegramente a cargo de éste.
            </div>
            <div class="parrafo">
                13.7. El Usuario acepta que la FGCSIC le envíe comunicaciones a la información de contacto que proporcionó en el registro del Sitio Web. El Usuario es responsable de proporcionar su dirección de correo electrónico más reciente y mantenerla actualizada. Si ha proporcionado un correo electrónico no válido o dicha dirección no es capaz de recibir comunicaciones, el envío de dicho correo electrónico por parte de la FGCSIC constituirá, no obstante, una comunicación válida.
            </div>
            <div class="parrafo">
                13.8. Modificaciones. Cuando se realizan cambios, pondremos a disposición de los Usuarios una nueva copia de las Condiciones Generales mediante su publicación en el Sitio Web. También se actualizará la fecha de "Última revisión" en la parte superior de las Condiciones Generales. También se podrá notificar a cada Usuario por correo electrónico. Los cambios entrarán en vigor inmediatamente. Si el Usuario no está de acuerdo con cualquier cambio(s), éste acepta dejar de usar los servicios ofrecidos en el Sitio Web. El uso continuado de los servicios constituye la aceptación de dichos cambios. EL USUARIO ACEPTA REVISAR REGULARMENTE LAS CONDICIONES GENERALES VIGENTES EN CADA MOMENTO.
            </div>
            <div class="parrafo">
                13.9. Uso de fase beta o fase de evaluación en el Sitio Web. Si por parte de la FGCSIC se indica alguna característica de funcionalidad en los servicios y/o en el Sitio Web como beta o evaluación, entonces el Usuario puede usar el servicio beta durante un período de tiempo según lo indicado, teniendo en cuenta que es un servicio en pruebas y por ello la responsabilidad por fallos o daños de cualquier tipo recaerá exclusivamente en el Usuario.
            </div>
        </div>    
    </article>

    <article id="procedimiento" class="seccion">
        <div class="tituloSecundario">
            14.	Procedimiento de uso y contratación del Sitio Web.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo">
                Tanto el “usuario empresa” o interesado como el "usuario investigador o candidato" aceptan y aprueban expresamente tanto las Condiciones Generales anteriormente citadas como el procedimiento de uso del Sitio Web que a continuación se recoge:
            </div>
            <div class="parrafo lista">
                14.1. El Sitio Web es propiedad y está operado por la FGCSIC y proporciona una plataforma para poner en relación a los Usuarios entre sí, "usuario investigador o candidato" por un lado y “usuario empresa” por otro, para conectarse entre sí y que el "usuario investigador o candidato" pueda ofrecer sus servicios y dar soluciones a problemas concretos al “usuario empresa”. La FGCSIC solo actúa como proveedor de la plataforma, y cualquier comunicación, contratación u otra decisión es exclusivamente de parte de los Usuarios, en ningún caso de la FGCSIC.
            </div>
            <div class="parrafo lista">
                14.2. Los Usuarios que se registren en el Sitio Web formarán parte de una comunidad con acceso a servicios y contenidos de innovación abierta, donde podrán, entre otros y bajo las condiciones que en cada caso se establezcan entre los Usuarios, responder a desafíos propuestos a la comunidad que tendrán un precio y un plazo concreto para presentar su solución.
            </div>
            <div class="parrafo lista">
                14.3. Esos desafíos estarán relacionados con problemas comerciales, técnicos, de ingeniería, científicos, logísticos, de fabricación o matemáticos, entre otros, que serán publicados por el “usuario empresa” en el Sitio Web y para que el "usuario investigador o candidato" vea y seleccione dicho desafíos según corresponda.
            </div>
            <div class="parrafo lista">
                14.4.	El procedimiento de uso y contratación del Sitio Web será el siguiente:
                <div class="lista">
                    1. Tanto el “usuario empresa” como el "usuario investigador o candidato" deben acceder al Sitio Web y registrase en el mismo como Usuario y aceptar las Condiciones Generales de Uso que serán tratados de conformidad con la Política de Privacidad que puede consultarse en este link <a class="clicable" (click)="irAnuevaWeb(url+'condiciones')"> <u>{{url}}condiciones</u></a> y el resto de documentos conteniendo condiciones y políticas vinculados a la plataforma. 
                </div>
                <div class="lista">
                    2. Los datos proporcionados por el Usuario deben ser correctos, veraces y completos, asumiendo el usuario toda responsabilidad sobre la falta de veracidad o exactitud de los mismos. El Sitio Web se reserva la facultad, sin perjuicio de otras medidas legales a su disposición, de retirar, denegar o suspender el acceso a los servicios en caso de que los datos proporcionados sean o puedan ser falsos, inexactos o engañosos. El Usuario será responsable de mantener la confidencialidad de su contraseña y tiene la obligación de comunicar al Sitio Web cualquier problema relacionado con la seguridad de su Cuenta. 
                </div>
                <div class="lista">
                    3. Una vez aceptado por la plataforma, el “usuario empresa” podrá publicar un desafío en la plataforma NEXOFY©, para lo cual, antes de su publicación deberá aceptar expresamente el Acuerdo de Confidencialidad y el resto de documentos conteniendo condiciones y políticas vinculados a la plataforma. 
                </div>
                <div class="lista">
                    4. El desafío deberá constar de los siguientes elementos: un resumen, una descripción detallada del desafío, el objeto del mismo, un plazo de solución y ejecución, un premio y un precio. 
                </div>
                <div class="lista">
                    5. La plataforma NEXOFY© será libre de publicar en su Sitio Web el resumen del mencionado reto, cuyo resumen se hará público con la autorización expresa del usuario empresa y con el objeto de su lectura por parte de los posibles candidatos que quieran contestar al mismo.
                </div>
                <div class="lista">
                    6. El "usuario investigador o candidato" podrá realizar su inscripción en la plataforma, para lo que deberá aceptar expresamente el Acuerdo de Confidencialidad y el resto de documentos conteniendo condiciones y políticas vinculados a la plataforma. El "usuario investigador o candidato" tendrá acceso a los resúmenes de los desafíos y podrá aceptar con plena libertad y autonomía el desafío. 
                </div>
                <div class="lista">
                    7. Una vez aceptado el reto y las condiciones e inscrito en la plataforma legalmente podrá acceder a la publicación completa y lanzar el formulario de solución potencial resumida a la plataforma. 
                </div>
                <div class="lista">
                    8. La plataforma enviará las soluciones recibidas de los candidatos al “usuario empresa” en el plazo marcado en el reto. Antes del inicio del desafío deberá esperar un plazo de tres (3) días a la confirmación del “usuario empresa”. En caso de múltiples aceptaciones por parte de varios "usuarios investigadores o candidatos" para un mismo desafío, el “usuario empresa” tendrá el derecho, a su entera discreción, de elegir al "usuario investigador o candidato" que se encargará de la ejecución de las mismas o dejar a todos los "usuarios investigadores o candidatos" que puedan desarrollar el desafío de forma independiente. Para dicha opción el “usuario empresa” deberá confirmar la asignación del premio a uno de los "usuarios investigadores o candidatos" en un plazo de tres (3) días. En caso de no pronunciarse en ese plazo, se entenderá que el “usuario empresa” acepta que todos los "usuarios investigadores o candidatos" inscritos en el desafío puedan llevarlo a cabo, en este caso el precio, premio y compensación se pagará a prorrata de todos ellos.
                </div>
                <div class="lista">
                    9. El “usuario empresa” deberá ingresar por adelantado a la FGCSIC entre un 10-30% del importe total del precio en concepto de premio por hacer el desafío. La FGCSIC podrá retener un porcentaje razonable de mercado en concepto de gastos de gestión (caso de no haberse pactado nada, podrá retener hasta el 2% del importe total de premio y del precio).
                </div>
                <div class="lista">
                    10.	Una vez que el “usuario empresa” haya elegido al "usuario investigador o candidato" para llevar a cabo el desafío publicado a la plataforma y esta lo notificará al candidato seleccionado, entendiendo que ambas partes han aceptado expresamente las condiciones del desafío publicado en el Sitio Web con completa ajenidad a la FGCSIC.
                </div>
                <div class="lista">
                    11. Para completar el desafío, se pedirá siempre al "usuario investigador o candidato" que emita un informe o memoria según el desafío publicado dentro del plazo especificado. Se considerará que el desafío se ha completado una vez transcurrido el plazo de 30 días desde la notificación de la plataforma de la solución aportada al usuario empresa. Una vez recibido el informe o memoria en la plataforma, esta lo hará llegar al “usuario empresa”, para su valoración y estudio en el plazo mencionado.
                </div>
                <div class="lista">
                    12. Transcurrido dicho plazo, el “usuario empresa” estará obligado pagar en un plazo no superior a treinta (30) días desde la recepción del informe a la FGCISC el resto del precio, premio y compensación según lo publicado, descontando la cantidad mencionada en el apartado 9, quién deberá realizar el abono de la parte correspondiente al "usuarios investigadores o candidatos", descontando los honorarios pactados. En este caso, ambos usuarios quedarán libres para formalizar otro tipo de acuerdos, a través de sus respectivas unidades de contratación para más detalle o especialización del reto, etc., ya fuera de la plataforma y conforme a las condiciones que libre y voluntariamente pacten al respecto. 
                </div>
                <div class="lista">
                    13.	En caso de que el “usuario empresa” manifieste causa justificada para no realizar el abono dentro del plazo anterior y lo notifique a la plataforma de la FGCSIC por escrito motivado a través del Sitio Web de acuerdo con las reglas de la buena fe, se entenderá que no acepta el resultado recibido por el "usuario investigador o candidato". En todo caso, el “usuario empresa” que no realice el abono y no acepte el resultado recibido, no podrá usar bajo ningún concepto o circunstancia el resultado recibido y en caso de hacerlo tendrá que abonar como indemnización un mínimo del triple de la cantidad total sumados el precio más el premio por hacer el desafío.
                </div>
                <div class="lista">
                    14. La FGCSIC estudiará las razones aducidas por el usuario empresa para no aceptar la solución propuesta por el candidato y decidir si es o no justificada dicha decisión. Caso de ser justificada procederá a no exigir el importe del precio pendiente de abono, advirtiendo a la empresa la parte retenida inicial no susceptible de devolución y la imposibilidad de uso de la solución enviada en el informe que es de propiedad del candidato y sobre la que no ostenta derecho alguno. Caso de no ser justificada la decisión del “usuario empresa”, la FGCSIC abrirá el procedimiento de mediación previsto en estas condiciones para tomar una decisión técnica al respecto y exigir el resto del pago en su caso o tomar cualquier otro tipo de medida al respecto.
                </div>
                <div class="lista">
                    15.	La FGCSIC se reserva el derecho a dar de baja a aquellos Usuarios que estime que no cumplen con las reglas de la buena fe tanto en los retos planteados, las soluciones aportadas o la aceptación mutua de condiciones o términos.
                </div>
            </div>
            <div class="parrafo lista">
                14.5. Los Usuarios son los únicos responsables de cualquier contrato o acuerdo que puedan formalizar entre ellos. La FGCSIC no controla, administra ni proporciona ninguna orientación con respecto a la determinación del estado de los servicios, servicios contratados, acuerdos entre los Usuarios o servicios y pagos realizados de conformidad con lo acordado por los Usuarios. Los Usuarios acuerdan expresamente que, salvo pacto expreso en contra entre los propios usuarios, no existe ningún acuerdo de empresa conjunta, sociedad, empleo u otros entre ellos y la FGCSIC como resultado de estos servicios ofrecidos a través del Sitio Web y de los acuerdos entre los Usuarios o cualquier uso de los servicios.
            </div>
            <div class="parrafo lista">
                14.6. Los Usuarios asumen toda la responsabilidad por la clasificación adecuada como contratistas o empleados independientes en función de las pautas legales aplicables. Los Usuarios reconocen que la FGCSIC no supervisa, dirige o controla de ninguna manera los medios o las condiciones de cualquier trabajo o servicio realizado. 
            </div>
            <div class="parrafo lista">
                14.7. Los Usuarios serán los únicos responsables de todas las declaraciones de impuestos y pagos que deban presentarse o realizarse ante cualquier autoridad fiscal y/o laboral, con respecto a los ingresos y pagos recibidos y emitidos. Los Usuarios acuerdan indemnizar, eximir de responsabilidad y  defender a la FGCSIC de todos y cada uno de las  reclamaciones, demandas,  pérdidas, daños,  responsabilidades, costes  y gastos que surjan de o estén relacionados con su compromiso y con los servicios ofrecidos en el Sitio Web, así como el incumplimiento de las presentes Condiciones Generales,  acción,  inacción,  omisión o cualquier reclamación bajo cualquier norma, incluida la legislación laboral. La FGCSIC podrá solicitar a los Usuarios - que estarán obligados a facilitarla - cuanta información se requiera sobre las condiciones laborales y cumplimiento normativo que se apliquen efectivamente a los trabajadores y trabajadoras que realicen la actividad objeto de las presentes Condiciones Laborales.
            </div>
            <div class="parrafo lista">
                14.8.	Respecto al procedimiento de uso y contratación, toda controversia, incluida cualquier cuestión relativa a su existencia, validez, terminación, interpretación o cumplimiento, derivada de o que guarde relación con él, se someterá para su resolución, tras haber en su caso intentado la negociación directa entre los Usuarios, a mediación. La FGCSIC elegirá a un mediador que medie entre los Usuarios. Si en un plazo de sesenta (60) días naturales la mediación resultare infructuosa en todo o en parte, y en este último caso respecto de las cuestiones no resueltas, la controversia será́ resuelta definitivamente mediante la jurisdicción civil ordinaria ante los tribunales de Madrid, España que serán los únicos competentes, renunciando las partes a cualesquiera otros foros, jurisdicciones o leyes que pudieran resultar aplicables.
            </div>
        </div>    
    </article>

    <article id="acuerdoCompleto" class="seccion">
        <div class="tituloSecundario">
            15.	Acuerdo completo y otras cuestiones.
        </div>
        <div class="parrafoPrincipal">
            <div class="parrafo lista">
                15.1. Solución de conflictos. Si no está usted de acuerdo con estas Condiciones de Uso, por favor, absténgase de visitar o utilizar el Sitio Web y las Aplicaciones. Cualquier aspecto relacionado con las presentes Condiciones Generales y este Sitio Web, y en caso de integración de documentos o cláusulas o en defecto de previsión por parte de estos términos, aplicarán de forma preferente las condiciones generales extensas, por encima de cualquier otro contrato o norma dispositiva del ordenamiento, ante cualquier foro competente y ley aplicable, tal como están recogidas en nuestra página web y accesibles para los usuarios. En su defecto, aplicará la ley española. 
            </div>
            <div class="parrafo lista">
                15.2. Foro competente y mediación. Para cualquier diferencia que pudiera surgir las partes se someten a la mediación exclusiva de la propia FGCSIC o quién en esta delegue. Transcurridos sesenta días sin llegar a un acuerdo, las partes serán libres de acudir a los tribunales, para lo cual se someten a la jurisdicción civil ordinaria ante los tribunales de Madrid, España, que serán los únicos competentes, renunciando las partes a cualesquiera otros foros, jurisdicciones o leyes que pudieran resultar aplicables; sin perjuicio de que en aquellos supuestos en que el usuario ostente la consideración de consumidor, la FGCSIC se someterá a la jurisdicción de los tribunales que resulten competentes según la legislación vigente en cada momento.
            </div>
            <div class="parrafo lista">
                15.3. Documentos y jerarquía aplicables. Este Acuerdo, que incluye la Política de privacidad, la Política de cookies, los Consejos de seguridad, las Reglas de la comunidad y los Procedimientos de arbitraje (si le son aplicables) y todos los términos que le hayan sido comunicados y que usted haya aceptado al comprar alguna de las características, productos o servicios adicionales que ofrecemos en el Servicio, constituye la totalidad del acuerdo entre usted y la FGCSIC con respecto a la utilización del Servicio. 
            </div>
            <div class="parrafo lista">
                15.4. Validez. Si alguna de las disposiciones de este Acuerdo es declarada no válida, el resto del Acuerdo seguirá siendo plenamente vigente. El hecho de que la Empresa no ejerza o no haga valer un derecho o disposición de este Acuerdo no constituirá una renuncia a tal derecho o disposición. Usted acepta que su cuenta de la FGCSIC es intransferible y que todos sus derechos sobre su cuenta y su Contenido terminan en el momento de su fallecimiento. 
            </div>
            <div class="parrafo lista">
                15.5. Agencia y otros. Este Acuerdo no comporta la creación de ninguna agencia, sociedad, empresa en participación, negocio fiduciario ni de ninguna relación especial u ocupación especial, y usted no puede hacer ninguna representación en nombre de la FGCSIC ni comprometer a la FGCSIC de ninguna manera.
            </div>
        </div>    
    </article>
   
    <footer *ngIf="verIconos">
        <img class="atras me-3 img-fluid | clicable"  src="../../assets/Icon feather-arrow-left-circle.svg" (click)="cerrar()">
    </footer>
</div>