import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AppComponent } from '../app.component';


@Component({
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
  styleUrls: ['./politicas.component.css']
})
export class PoliticasComponent implements OnInit {

  constructor(
    public appComponentes: AppComponent,
    public location:Location,
  ) {
    
  }

  ngOnInit(): void {
  }
  cerrar(){
    this.appComponentes.ver_politicas = false;
  }

}
