import { Component, ElementRef, OnInit,ViewChild, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { Seguidores } from '../models/seguidores';
import { Router } from '@angular/router';
import { Usuarios } from '../models/usuarios';
import { GlobalService } from '../services/global.services';
import { UsuariosService } from './usuarios.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-usuarios',
	templateUrl: './usuarios.component.html',
	styleUrls: ['./usuarios.component.css']
})

export class UsuariosComponent implements OnInit {

	public lista_usuarios:any=[];
	public usuario_sesion:any;
	public lista_paises:any;
	public paginaActual:any =1;
	public paginastotal:any = 1;
	public informacion:string = "";
	public url_imagenes:string = environment.url_imagenes
	public filtroNombre:string="";
	public filtroPais:string="";
	public filtroCargo:string="";
	public filtros:boolean = false;


	@ViewChild('modal_informacion') modal_informacion:any;
	@ViewChild('filtro') filtroClass!: ElementRef; 

	constructor(
		private _usuariosService:UsuariosService,
		private _globalService:GlobalService,
		private router:Router,
		private modalService: NgbModal,
		private renderer: Renderer2,
	) { 
		this.usuario_sesion=this._globalService.DatosSesion();
		this.descargar_usuarios();
	}

	ngOnInit(): void {
		this.descargar_paises();
	}
	ngAfterViewInit(): void{
		const filtroClass = this.filtroClass.nativeElement;
		this.renderer.addClass(filtroClass, 'd-none');
	}

	descargar_paises(){
		this._usuariosService.listPaises().subscribe({ 
			next: (respuesta) => {
				let res:any = respuesta;
				if(res.error_code==1){
					this.lista_paises=res.response.listado;
					this.lista_paises.unshift({'name':"TODOS"})
				}else{   
					console.log(res.error_code)
				}  
			},
			error: (e) => {
			}
		})
	}

	descargar_usuarios(){
		let pais = ""
		if (this.filtroPais  == "TODOS"){
			pais = "";
		}else{
			pais = this.filtroPais
		}
		
		this.lista_usuarios = [];
		//Si es usuario empresa, solo le mostramos los seguidores
		if(this.usuario_sesion.rol==3){
			this._usuariosService.listSeguidores(this._globalService.getToken(), this.filtroNombre, pais, this.filtroCargo, this.paginaActual).subscribe({
				next: (respuesta)=>{    
					let res:any = respuesta;     
					let lista = res.response.listado;
					for(let u=0;u<lista.length;u++){
						if(lista[u].id_emisor._id!=this.usuario_sesion._id){this.lista_usuarios.push(lista[u].id_emisor)}
						if(lista[u].id_receptor._id!=this.usuario_sesion._id){this.lista_usuarios.push(lista[u].id_receptor)}					
					}
				}
			})
		}else{
			//Si es un investigador le mostramos todos los usuarios
			this._usuariosService.listUsuarios(this._globalService.getToken(), this.paginaActual, this.filtroNombre, pais, this.filtroCargo ).subscribe({
				next: (respuesta)=>{    
					let res:any = respuesta;     
					this.lista_usuarios = this.lista_usuarios = res.response.listado;
					this.lista_usuarios = this.lista_usuarios.filter((usuario:any)=>usuario._id!=this.usuario_sesion._id);
				}
			})
		}

		
	}

	leer_usuario(id:any){
		this.router.navigate(['usuarios/'+id])
	}
	
	//agregamos botones de adelante y atras para la carga de usuarios.
	paginaPrevia(){
		if(this.paginaActual > 1){
			this.paginaActual= this.paginaActual - 1;
			this.descargar_usuarios()
		}
	}

	paginaSiguiente(){
		if(this.paginaActual < this.paginastotal){
			this.paginaActual = this.paginaActual +1;
			this.descargar_usuarios()
		}
	}
	// Cargamos modal para ver información de la ventana
	abrirInfo(){
		this.informacion = "En la ventana investigadores, podemos conectar con los diferentes investigadores o creadores de retos siempre que estemos asociado a algún reto. Una vez conectados podemos ver enviarle un mensaje. "
		this.modalService.open(this.modal_informacion,{centered:true});
	}
	mostrar_filtro(){
		const filtroClass = this.filtroClass.nativeElement;

		if(this.filtros){
			this.filtros = !this.filtros;
			this.renderer.removeClass(filtroClass, 'animate__flipInX')
			this.renderer.addClass(filtroClass,'animate__flipOutX')
			setTimeout(()=>{
				this.renderer.addClass(filtroClass, 'd-none')
			}, 500)
			
		}else{
			this.filtros = !this.filtros;
			this.renderer.removeClass(filtroClass,'animate__flipOutX')
			this.renderer.removeClass(filtroClass,'d-none')
			this.renderer.addClass(filtroClass, 'animate__flipInX')
			
		}
		
	}
}
