import { Component, OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RetosService } from 'src/app/retos/retos.service';
import { GlobalService } from '../services/global.services';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Opciones } from 'src/app/models/opciones';


@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
	public lista_retos:any;
	public usuario_sesion:any;
	public termino_busqueda:string="";
	public estado_busqueda:number=3;
	public inicio_creado_busqueda:string ="";

	public sector_busqueda:string ="TODOS";
	public area_busqueda:string ="TODAS";

	public fin_creado_busqueda:string ="";
	public mostrar_filtros:boolean=false;
	public url_imagenes:string=environment.url_imagenes
	public paginaActual:any =1;
	public paginastotal:any = 1;
	public informacion:string = "";
	public numeroResultado:number = 0;
	public opciones: any;

	@ViewChild('modal_informacion') modal_informacion:any;

	constructor(
		private router:Router,
		private _retosService:RetosService,
		private _globalService:GlobalService,
		private modalService: NgbModal,
	) { 
		this.usuario_sesion = this._globalService.DatosSesion();
		this.opciones = new Opciones();
	}

	ngOnInit(): void {
		if(this.usuario_sesion.rol==3){
			this.router.navigate(['/retos'])
		}else{
			this.opciones.sector.unshift("TODOS");
			this.opciones.area.unshift("TODAS");
			this.descargar_retos()
		}
		
	}


	ver_reto(id:any){
		this.router.navigate(['/retos/' + id])
	} 

	descargar_retos(){
		this._retosService.listRetos(this._globalService.getToken(), this.paginaActual, this.termino_busqueda,this.estado_busqueda,this.inicio_creado_busqueda,this.fin_creado_busqueda,this.sector_busqueda,this.area_busqueda).subscribe({
			next: (respuesta)=>{    
				let res:any = respuesta;
				if(res.error_code == 1){
					this.lista_retos =  res.response.listado; 	
					this.paginaActual = res.response.pagina
					this.paginastotal = res.response.paginas
					this.numeroResultado = res.response.listado.length
				}else{
					this.numeroResultado = 0;
				}
			}
		})
	}

	//Acciona los botones de la paginacion
	paginaPrevia(){
		if(this.paginaActual > 1){
			this.paginaActual= this.paginaActual - 1;
			this.descargar_retos()
		}
	}
	
	paginaSiguiente(){
		if(this.paginaActual < this.paginastotal){
			this.paginaActual = this.paginaActual +1;
			this.descargar_retos()
		}
	}
	//Abrimos el modal donde aparece información de la pagina.
	abrirInfo(){
		this.informacion = "En la ventana Home, podemos ver los retos existentes por defecto aparecen los que están activos, que actualmente están activos, pudiendo realizar un filtrado por nombre del reto, estado (TODOS, ACTIVO y FINALIZADO) y fecha de creación."
		this.modalService.open(this.modal_informacion,{centered:true});
	  }
}


