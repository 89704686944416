import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RetosService {
	public usuario_sesion:any;
	public token:any;
	public url:any;

	constructor(
		public _http:HttpClient
	) { 
		this.url = environment.url_api
	}

	createRetos(reto:any, token:any){
		let params = JSON.stringify(reto);  
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url+'createRetos', params,{headers: headers});
	}

	readRetos(token:any, id:any){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.get(this.url+'readRetos/'+id, {headers: headers});
	}

	updateRetos(retos: any, token: any) {
		let params = JSON.stringify(retos);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'updateRetos', params, { headers: headers });
	}

	validateRetos(retos: any, token: any) {
		let params = JSON.stringify(retos);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'validateRetos', params, { headers: headers });
	}

	banearRetos(retos: any, token: any) {
		let params = JSON.stringify(retos);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'banearRetos', params, { headers: headers });
	}

	desbanearRetos(retos: any, token: any) {
		let params = JSON.stringify(retos);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'desbanearRetos', params, { headers: headers });
	}

	deleteRetos(retos: any, token: any) {
		let params = JSON.stringify(retos);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'deleteRetos', params, { headers: headers });
	}

	activeRetos(retos: any, token: any) {
		let params = JSON.stringify(retos);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'activeRetos', params, { headers: headers });
	}

	listRetos(token:any, pagina:number = 1, termino_busqueda:string="",estado:number=0,fechaDesde:string="",fechaHasta="", sector:string = "TODOS", area:string = "TODAS"){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*");
		return this._http.get(this.url+'listRetos?p='+pagina+'&query='+termino_busqueda+'&status='+estado+'&fi='+fechaDesde+'&ff='+fechaHasta+'&sector='+sector+'&area='+area, {headers: headers});
	}

	createContrato(contrato:any, token:any){
		let params = JSON.stringify(contrato);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url+'createContratos', params,{headers: headers});
	}

	aceptarContrato(contrato:any, token:any){
		let params = JSON.stringify(contrato);  
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url+'aceptarContratos', params,{headers: headers});
	}

	rechazarContrato(contrato:any, token:any){
		let params = JSON.stringify(contrato);  
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url+'rechazarContratos', params,{headers: headers});
	}

	listContratos(token:any){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*");
		return this._http.get(this.url+'listContratos', {headers: headers});
	}

	readContratos(token:any, id:any){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.get(this.url+'readContratos/'+id, {headers: headers});
	}

	publicarRetos(retos: any, token: any) {
		let params = JSON.stringify(retos);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'publicarRetos', params, { headers: headers });
	}

	retosLanding(){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set("Accept", "*/*");
		return this._http.get(this.url+'retoslanding/', {headers: headers});
	}

	verNumSoluciones(token: any,retos: any) {
		let params = JSON.stringify(retos);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'vernumsoluciones', params, { headers: headers });
	}
}
