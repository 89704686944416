import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConversacionesService } from '../conversaciones/conversaciones.services';
import { GlobalService } from '../services/global.services';

@Component({
	selector: 'app-soporte',
	templateUrl: './soporte.component.html',
	styleUrls: ['./soporte.component.css']
})
export class SoporteComponent implements OnInit {

	@ViewChild('modal_aviso') modal_aviso:any;
	@ViewChild('modal_informacion') modal_informacion:any;

	public mensaje:string="";
	public mensaje_error = "";
	public aviso_error=false;
	public estadoBtn = false;
	public informacion = "";

	constructor(
		private modalService: NgbModal,
		private _conversacionService:ConversacionesService,
		private _globalService:GlobalService,
		private router:Router,
	) {
	}

	ngOnInit(): void {
	}

	enviar(){
		if(this.estadoBtn && this.mensaje.length > 10){
			this._conversacionService.crearTicket(this._globalService.getToken(), this.mensaje).subscribe({
				next: (res:any)=>{
					
					if(res.error_code==1){
						if(res.response.conversacion!=null){
							console.log("Redireccionar a: /conversaciones/"+res.response.conversacion)
							this.router.navigate(['/conversaciones/'+res.response.conversacion]);
						}else{
							console.log("Redireccionar a: conversaciones/")
							this.router.navigate(['/conversaciones/']);
						}
						
					}else{  
						this.modalService.dismissAll(); //cerrar todos los popups
						this.aviso_error=true;
						this.mensaje_error=res.message;
						this.modalService.open(this.modal_aviso,{centered:true});
					} 
				}
			})
		}
	}
	//Verificamos que al menos existan 10 caracteres para abrir el ticket
	verificarCaracteres(event:any){
		let contador = event.target.value.length
		if(contador > 10){
			this.estadoBtn = true;
		}else{
			this.estadoBtn = false;
		}
	}
	//Abrimos modal de informacion.
	abrirInfo(){
		this.informacion = "En esta ventana podremos crear conversaciones con el administrador de la plataforma para revisar posibles incidencias."
		this.modalService.open(this.modal_informacion,{centered:true});
	}

}
