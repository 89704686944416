/* Modificado 5/12/2022 por Mario Se revisan visualizaciones de los retos segun rol, se agrega funcionalidad de agregar soluciones. */
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import { Component, ElementRef, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { Contrato } from 'src/app/models/contratos';
import { Empresas } from 'src/app/models/empresas';
import { Retos } from 'src/app/models/retos';
import { GlobalService } from 'src/app/services/global.services';
import { SolucionesService } from 'src/app/soluciones/soluciones.service';
import { RetosService } from '../retos.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import Chart from 'chart.js/auto';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { LogService } from '../../log/log.service';

/* roles:
1 - SuperAdmin
2 - Administrador (Personal de Nexofy)
3 - Administrador de empresa (Usuario con empresa)
4 - Investigador */

@Component({
	selector: 'app-ver-reto',
	templateUrl: './ver-reto.component.html',
	styleUrls: ['./ver-reto.component.css']
})

export class VerRetoComponent implements OnInit {
	public alto_grafico: number = screen.height * 0.17;
	public lista_soluciones: any;
	public lista_contratos: any;
	public usuario_sesion: any;
	public retoModelo: any;
	public contratoModelo: any;
	public pipe = new DatePipe('en-US');
	public mensaje_error: string = "";
	public aviso_error: boolean = false;
	private id_reto: string = "";
	public informacion: string = "";
	public dias_restantes: number = 0;
	public porcentaje_progreso: number = 0;
	public url_imagenes: string = environment.url_imagenes
	public divulgante: string = "";
	public receptor: string = ""
	public cargaPorciento = false;
	public fechaActualFormateada: any;
	public dniPresidenteFGCSIC: string = "";
	public nombrePresidenteFGCSIC: string = "";
	public checkaceptado: boolean = false;

	@ViewChild('progressBar') progressBar!: ElementRef;
	@ViewChild('modal_contrato') modal_contrato: any;
	@ViewChild('modal_aviso') modal_aviso: any;
	@ViewChild('modal_informacion') modal_informacion: any;
	@ViewChild('modal_publicar') modal_publicar: any;
	@ViewChild('modal_borrar') modal_borrar: any;

	constructor(
		private modalService: NgbModal,
		private router: Router,
		private _retosService: RetosService,
		private _globalService: GlobalService,
		private _solucionesService: SolucionesService,
		private _router: ActivatedRoute,
		private sanitizer: DomSanitizer,
		public location: Location,
		private renderer2: Renderer2,
		private _logService: LogService,
	) {

		this.retoModelo = new Retos('', '', '', '', '', 0, '', '', new Date, 0, '', '', new Date, new Date, '', 0, 1, 1, '', '', 0, 0, '', '', [], '');
		this.contratoModelo = new Contrato('', '', '', 0, new Date, new Date, '');
		this.usuario_sesion = this._globalService.DatosSesion();
		this.lista_soluciones = [];
	}


	graficoSoluciones = {}
	graficoContratos = {}

	ngOnInit(): void {
		this._router.params.subscribe(paramMap => {
			this.id_reto = paramMap['id'];
			this.leer_reto();
		})
	}

	ngAfterViewInit(): void {
		this.receptor = this.usuario_sesion.nombre + " " + this.usuario_sesion.apellidos
		// Obtenemos datos para poder general contrato.
		const fechaActual = new Date();
		const formatoFecha = "d 'de' MMMM 'de' yyyy";
		this.fechaActualFormateada = format(fechaActual, formatoFecha, { locale: es });
		this.leerConfigDinamicas();
	}

	leer_reto() {
		this._retosService.readRetos(this._globalService.getToken(), this.id_reto).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				this.retoModelo = res.response.listado;
				this.divulgante = res.response.listado.id_empresa.nombre;


				if (this.retoModelo?.detalles) {
					this.retoModelo.detalles = this.sanitizer.bypassSecurityTrustHtml(this.retoModelo.detalles)
				}

				if (this.retoModelo?.criterios_premio) {
					this.retoModelo.criterios_premio = this.sanitizer.bypassSecurityTrustHtml(this.retoModelo.criterios_premio)
				}

				if (this.usuario_sesion.rol <= 3) {
					this.crear_grafico_soluciones();
					this.crear_grafico_contratos();
				}
				let hoy = new Date();
				let fecha_fin = new Date(this.retoModelo.fecha_fin);
				if (hoy > fecha_fin) {
					this.retoModelo.estado = 4;
				}
				let dias_restantes = 0;
				if (new Date().getTime() < new Date(this.retoModelo.fecha_fin).getTime()) {
					dias_restantes = Math.abs(new Date().getTime() - new Date(this.retoModelo.fecha_fin).getTime());
					this.dias_restantes = dias_restantes = Math.round(dias_restantes / 1000 / 60 / 60 / 24);
				}
				let dias_duracion = Math.abs(new Date(this.retoModelo.cdate).getTime() - new Date(this.retoModelo.fecha_fin).getTime());
				dias_duracion = Math.round(dias_duracion / 1000 / 60 / 60 / 24);

				this.porcentaje_progreso = Math.round(100 - (dias_restantes * 100 / dias_duracion));
				//Si se acaba de iniciar la barra de progreso le damos color.
				if (this.porcentaje_progreso == 0) {
					this.porcentaje_progreso = 0.1;
				}

				if (!this.cargaPorciento) {
					this.cargaPorciento = true;
					const barraProgreso = this.progressBar.nativeElement;
					const p = this.renderer2.createElement('div');
					const text = this.renderer2.createText(this.porcentaje_progreso + '%');
					this.renderer2.appendChild(p, text);
					this.renderer2.setStyle(barraProgreso, 'width', this.porcentaje_progreso + '%');
					this.renderer2.appendChild(barraProgreso, p);

					if (this.porcentaje_progreso >= 50 && this.porcentaje_progreso < 75) {
						this.renderer2.addClass(barraProgreso, "bg-warning");

					} else if (this.porcentaje_progreso >= 75 && this.porcentaje_progreso < 100) {
						this.renderer2.addClass(barraProgreso, "bg-danger");

					} else if (this.porcentaje_progreso == 100) {
						this.renderer2.addClass(barraProgreso, "bg-success")

					}
				}
			}
		});


	}

	// Leemos las variables dinamicas de la API Para obtener el nombre y NIF del presidente de la asociación.:
	leerConfigDinamicas() {
		this._logService.leerConfigDinamicas().subscribe({
			next: (res: any) => {
				if (res.error_code == 1) {
					this.dniPresidenteFGCSIC = res.response.dniPresidenteFGCSIC;
					this.nombrePresidenteFGCSIC = res.response.nombrePresidenteFGCSIC;
				}
			}
		})
	}

	//Al entrar en el modal te crea el contrato
	crear_contrato() {
		this.contratoModelo.id_usuario = this.usuario_sesion._id;
		this.contratoModelo.id_reto = this.retoModelo._id;
		this._retosService.createContrato(this.contratoModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1 || res.error_code == 7) {
					this.contratoModelo._id = res.response.contrato;
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
			}
		})
	}

	// Al aceptar modal tras ver el contrato te agrega como contrato aceptado
	aceptar_contrato() {
		this._retosService.aceptarContrato(this.contratoModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.contratoModelo.estado = 1;
					this.leer_reto()
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
			}
		})
	}

	// Al rechazar modal tras ver el contrato te agrega como contrato visualizado y rechazado
	rechazar_contrato() {
		this._retosService.rechazarContrato(this.contratoModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.contratoModelo.estado = 2;
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
			}
		})
	}

	crear_grafico_soluciones() {
		//Creamos un objeto cuya key sea la fecha y cuyo valor sea el número de soluciones presentadas
		let objetoFechas: any = {}
		for (let i = 0; i < this.retoModelo.soluciones?.length; i++) {
			//Dejamos unicamente la fecha quitando la hora.
			let fecha = this.retoModelo.soluciones[i].cdate.split('T')[0];
			if (Object.keys(objetoFechas).includes(fecha)) {
				objetoFechas[fecha] = objetoFechas[fecha] + 1;
			} else {
				objetoFechas[fecha] = 1;
			}
		}

		//Convertimos el objeto anterior en un array
		let arraySoluciones = [];
		let labels = [];
		//Creamos el objeto con fecha y cantidad de retos.
		for (let k = 0; k < Object.keys(objetoFechas).length; k++) {
			let fecha = Object.keys(objetoFechas)[k];
			labels.push(fecha);
			arraySoluciones.push(objetoFechas[fecha]);
		}

		this.graficoSoluciones = new Chart("GraficoSoluciones", {
			type: 'line',
			data: {
				labels: labels,
				datasets: [
					{
						label: "Soluciones",
						backgroundColor: "#b1d9ff",
						data: arraySoluciones
					}

				]
			},
			options: {
				scales: {
					x: {
						grid: {
							display: false
						}

					},
					y: {
						grid: {
							display: false
						},
						ticks: {
							display: false
						}
					}
				},
				plugins: {
					legend: {
						display: false
					}
				},

			},

		});
	}

	crear_grafico_contratos() {
		//Creamos un objeto cuya key sea la fecha y cuyo valor sea el número de soluciones presentadas
		let objetoFechas: any = {}
		for (let i = 0; i < this.retoModelo.contratos?.length; i++) {
			//Sacamos los contratos creados por fecha
			let fecha = this.retoModelo.contratos[i].cdate.split('T')[0];
			if (Object.keys(objetoFechas).includes(fecha)) {
				objetoFechas[fecha].creados = objetoFechas[fecha].creados + 1;
			} else {
				objetoFechas[fecha] = { "creados": 1, 0: 0, 1: 0, 2: 0 }
			}

			//Sacamos los contratos aceptados o rechazados por fecha
			let mdate = this.retoModelo.contratos[i].mdate.split('T')[0];
			let estado = this.retoModelo.contratos[i].estado;
			if (Object.keys(objetoFechas).includes(mdate)) {
				objetoFechas[mdate][estado] = objetoFechas[mdate][estado] + 1;
			}
		}

		//Convertimos el objeto anterior en un array
		let arrayCreados = [];
		let arrayAceptados = [];
		let arrayRechazados = [];

		let labels = [];
		//Creamos el objeto con fecha y cantidad de retos.
		for (let k = 0; k < Object.keys(objetoFechas).length; k++) {
			let fecha = Object.keys(objetoFechas)[k];
			labels.push(fecha);
			arrayCreados.push(objetoFechas[fecha].creados);
			arrayAceptados.push(objetoFechas[fecha][1]);
			arrayRechazados.push(objetoFechas[fecha][2]);
		}

		this.graficoContratos = new Chart("GraficoContratos", {
			type: 'line',

			data: {
				labels: labels,
				datasets: [
					{
						label: "Visualizaciones",
						backgroundColor: "#b1d9ff",
						data: arrayCreados
					},
					{
						label: "Aceptados",
						backgroundColor: "#e8bb7d",
						data: arrayAceptados
					},
					{
						label: "Rechazados",
						backgroundColor: "#7f6def",
						data: arrayRechazados
					}

				]
			},
			options: {
				plugins: {
					legend: {
						display: false
					}
				},
				scales: {
					x: {
						grid: {
							display: false
						}

					},
					y: {
						grid: {
							display: false
						},
						ticks: {
							display: false
						}
					}
				}

			}
		});
	}

	convertirNumero(numero: number): string {
		const regex = /\B(?=(\d{3})+(?!\d))/g;
		return numero.toString().replace(regex, ".");
	}

	presentar_solucion() {
		this.router.navigate(['/presentar-solucion/' + this.id_reto]);
	}

	editar(id: any) {
		if (id == "") {
			id = "create"
		}

		this.router.navigate(['/retos-editar/' + id]);
	}

	abrir_contrato() {
		this.modalService.open(this.modal_contrato, { size: 'xl', backdrop: 'static', scrollable: true });
		this.crear_contrato();
	}

	ver_solucion(id: any) {
		this.router.navigate(["/soluciones/" + id]);
	}

	validar_reto() {
		this._retosService.validateRetos(this.retoModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					//this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = "Reto validado";
					this.modalService.open(this.modal_aviso, { centered: true });
					this.retoModelo.estado = 3;
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
			}
		})
	}

	banear_reto() {
		this._retosService.banearRetos(this.retoModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.mensaje_error = "Reto baneado";
					this.modalService.open(this.modal_aviso, { centered: true });
					this.retoModelo.baneado = 1;
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
			}
		})
	}

	desbanear_reto() {
		this._retosService.desbanearRetos(this.retoModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = "Reto desbaneado";
					this.modalService.open(this.modal_aviso, { centered: true });
					this.retoModelo.baneado = 0;
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
			}
		})
	}

	borrar_reto() {
		this.modalService.dismissAll(); //cerrar todos los popups
		this.modalService.open(this.modal_borrar, { centered: true });
	}

	confirmar_borrar() {
		this._retosService.deleteRetos(this.retoModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.modalService.dismissAll(); //cerrar todos los popups;	
					this.aviso_error = true;
					this.mensaje_error = "Reto borrado";
					this.modalService.open(this.modal_aviso, { centered: true });
					this.retoModelo.borrado = 1;
					this.router.navigate(['/home']);

				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
			}
		})
	}

	reactivar_reto() {
		this._retosService.activeRetos(this.retoModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = "Reto reactivado";
					this.modalService.open(this.modal_aviso, { centered: true });
					this.retoModelo.borrado = 0
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
			}
		})
	}

	//Abrimos el modal donde aparece información de la pagina.
	abrirInfo() {
		this.informacion = "En la ventana Ver Reto, podemos ver como investigadores una breve descripción del reto, y una vez aceptados los términos y condiciones podemos ver el detalle del reto y presentar una solución."
		if (this.usuario_sesion.rol == 3) {
			this.informacion = "En la ventana Ver Reto, podemos ver de nuestros retos una gráfica de soluciones presentadas, y visualizaciones, además de ver soluciones presentadas para dicho reto y que investigadores aceptaron y rechazaron ver el detalle de la solución."
		}
		if (this.usuario_sesion.rol < 3) {
			this.informacion = "En la ventana Ver Reto, podemos ver del reto seleccionado una gráfica de soluciones presentadas, y visualizaciones, además de ver resumen de las soluciones a las cuales podemos acceder pinchando encima de ellas, también podemos observar que investigadores aceptaron y rechazaron ver el detalle de la solución además, como administradores podemos: editar, banear y borrar el reto."
		}
		this.modalService.open(this.modal_informacion, { centered: true });
	}

	descargar_documento(url: string) {
		this._globalService.descargar_documento(url);
	}

	confirmarPublicar() {
		this.modalService.dismissAll(); //cerrar todos los popups
		this.modalService.open(this.modal_publicar, { centered: true });
	}

	publicar() {
		this._retosService.publicarRetos(this.retoModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				this.modalService.dismissAll(); //cerrar todos los popups

				if (res.error_code == 1) {
					this.location.back();
				} else {
					this.aviso_error = false;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
			}
		})
	}
}
