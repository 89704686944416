<div class="container">
     <div class="row">
          <div class="row">
               <h1 class="col-xl-8">
                    <img class=" me-3 img-fluid | clicable" width="30"
                         src="../../assets/Icon feather-arrow-left-circle.svg" (click)="this.location.back()">
                    <strong>{{retoModelo.nombre}}</strong>
                    <img class="img-fluid align-top clicable" width="25" src="../../assets/info.svg"
                         (click)="abrirInfo()">
               </h1>
               <div class="col-xl-4">
                    <button class="btn boton"
                         *ngIf="retoModelo.estado==0 && retoModelo.borrado ==  0 && usuario_sesion.rol == 3"
                         (click)="confirmarPublicar()">Publicar</button>
                    <button class="btn btn-outline-danger"
                         *ngIf="retoModelo.estado>2 && usuario_sesion.rol<=2  && !retoModelo.baneado"
                         (click)="banear_reto()">Banear reto</button>
                    <button class="boton secondary medium" *ngIf="usuario_sesion.rol<=2  && retoModelo.baneado"
                         (click)="desbanear_reto()">Desbanear reto</button>
                    <button class="boton secondary medium" *ngIf="retoModelo.estado==1 && usuario_sesion.rol<=2"
                         (click)="validar_reto()">Validar reto</button>
                    <button class="btn btn-outline-primary mx-2"
                         *ngIf="usuario_sesion.rol<=2 || (usuario_sesion.id_empresa?._id==retoModelo.id_empresa._id && retoModelo.estado==0)"
                         (click)="editar(retoModelo._id)">Editar</button>
                    <button class="btn btn-outline-danger"
                         *ngIf=" !retoModelo.borrado && (usuario_sesion.rol<=2  || (usuario_sesion.id_empresa?._id==retoModelo.id_empresa._id && retoModelo.estado==0) )"
                         (click)="borrar_reto()">Borrar</button>
                    <button class="boton secondary medium"
                         *ngIf="retoModelo.estado>2 && usuario_sesion.rol<=2 && retoModelo.borrado"
                         (click)="reactivar_reto()">Reactivar reto</button>

               </div>
          </div>

          <div class="row mt-3">
               <div class="col-xl-6 offset-xl-3 col-lg-9 offset-lg-2 col-12 | text-center">
                    <img class=" max-img" *ngIf="retoModelo.imagen_destacada != ''"
                         src="{{url_imagenes}}{{retoModelo.imagen_destacada}}">
               </div>
          </div>
     </div>


     <div class="row justify-content-evenly" *ngIf="usuario_sesion.rol<=3">
          <div class="col-lg-5 col-12 | border rounded p-3 mb-3 mt-3">
               <div class="h3">Soluciones</div>
               <div *ngIf="!retoModelo.soluciones?.length">Todavía no se han presentado soluciones</div>
               <div class="h1 fw-bolder" *ngIf="retoModelo.soluciones?.length">{{retoModelo.soluciones.length}}</div>
               <div>
                    <canvas id="GraficoSoluciones">{{ graficoSoluciones }}</canvas>
               </div>

          </div>
          <div class="col-lg-5 col-12 | border rounded p-2 mb-3 mt-3 overflow-x-auto d-flex flex-nowrap">
               <table class="bg-white table rounded table-hover fit-h ">
                    <thead>
                         <tr>
                              <th scope="col">Investigador</th>
                              <th scope="col">Fecha</th>
                              <th scope="col">Estado</th>
                         </tr>

                    </thead>
                    <tbody>
                         <tr class="clicable " *ngFor="let solucion of retoModelo.soluciones"
                              (click)="ver_solucion(solucion._id)">
                              <td *ngIf="solucion.estado != 1 && solucion.estado != 3" scope="row">
                                   {{solucion.id_usuario?._id}}</td>
                              <td *ngIf="solucion.estado == 1" scope="row">{{solucion.id_usuario?.nombre}}</td>
                              <td *ngIf="solucion.estado < 3">{{pipe.transform(solucion.cdate, 'dd-MM-yyyy')}}</td>
                              <td *ngIf="solucion.estado < 3">
                                   <span class="label_tabla px-4 amarillo"
                                        *ngIf="solucion.estado == 0">Presentada</span>
                                   <span class="label_tabla px-4 verde" *ngIf="solucion.estado == 1">Aprobada</span>
                                   <span class="label_tabla px-4 rojo" *ngIf="solucion.estado == 2">Rechazada</span>
                              </td>
                         </tr>
                    </tbody>
               </table>
          </div>

          <div class="col-lg-5 col-12 | border rounded columna-grafico p-3 mb-3">
               <div class="h3">Visualizaciones</div>
               <div *ngIf="!retoModelo.contratos?.length">Todavía no existen visualizaciones</div>
               <div class="h1 fw-bolder" *ngIf="retoModelo.contratos?.length">{{retoModelo.contratos.length}}</div>
               <div class="div_grafico">
                    <canvas id="GraficoContratos">{{ graficoContratos }}</canvas>
               </div>

          </div>
          <div class="col-lg-5 col-12 | border rounded p-2 mb-3 overflow-x-auto d-flex flex-nowrap">
               <table class="table table-striped fit-h">
                    <thead>
                         <tr>
                              <th scope="col">Investigador</th>
                              <th scope="col">Fecha</th>
                              <th scope="col">Estado</th>
                         </tr>
                    </thead>
                    <tbody>
                         <tr *ngFor="let visualizacion of retoModelo.contratos">
                              <td scope="row">{{visualizacion.id_usuario._id}}</td>
                              <td>{{pipe.transform(visualizacion.lgate, 'dd-MM-yyyy')}}</td>
                              <td>
                                   <span class="label_tabla px-4 amarillo"
                                        *ngIf="visualizacion.estado == 0">Pendiente</span>
                                   <span class="label_tabla px-4 verde"
                                        *ngIf="visualizacion.estado == 1">Aceptado</span>
                                   <span class="label_tabla px-4 rojo"
                                        *ngIf="visualizacion.estado == 2">Rechazado</span>
                              </td>
                         </tr>
                    </tbody>
               </table>
          </div>
     </div>


     <div class="row | border-start border-5 border-info bg-info-subtle rounded p-2 mt-3">
          <div class="col-12 col-lg-6">
               <h3 class="fw-bold">Fecha de finalización:</h3>
               <h5>{{retoModelo.fecha_fin | date: 'dd/MM/yyyy'}}</h5>
               <p class="subtitulo"> Quedan {{dias_restantes}} días</p>
               <div class="progress" role="progressbar" aria-label="Animated striped example">
                    <div #progressBar class="progress-bar progress-bar-striped progress-bar-animated"></div>
               </div>
          </div>
          <div class="col-12 col-lg-6">
               <div><span><b>Sector</b>: {{retoModelo.sector}}</span></div>
               <div><span><b>Área</b>: {{retoModelo.area}}</span></div>
               <div><span><b>Tipo</b>: {{retoModelo.tipologia}}</span></div>
          </div>
     </div>



     <div class=" row | border-start border-5 border-warning bg-warning-subtle rounded p-2 mt-3">
          <div class="col-12 col-lg-6">
               <h2>Resumen:</h2>
               <p>{{retoModelo.descripcion}}</p>
          </div>
          <div class="col-12 col-lg-6" *ngIf="(!retoModelo.detalles) && (usuario_sesion.rol == 4)">
               Para ver los detalles del reto debes aceptar los términos y condiciones.
               <button class="boton medium" (click)="abrir_contrato()">Ver detalles</button>
          </div>
          <div *ngIf="retoModelo?.detalles" class="col-12 col-lg-6 ">
               <h2>Detalles:</h2>
               <div [innerHTML]="retoModelo.detalles"></div>
          </div>
     </div>



     <div class="row | border-start border-5 border-success bg-success-subtle rounded p-2 mt-3"
          *ngIf="retoModelo?.premio && retoModelo?.presupuesto">
          <div class="col-12 col-lg-6 ">
               <h2 *ngIf="retoModelo.numPremios != 1"> {{retoModelo.numPremios}} Premios de:
                    {{convertirNumero(retoModelo.premio)}}€</h2>
               <h2 *ngIf="retoModelo.numPremios == 1"> {{retoModelo.numPremios}} Premio de:
                    {{convertirNumero(retoModelo.premio)}}€</h2>
          </div>

          <div class="col-12 col-lg-6">
               <h2>Presupuesto disponible: {{convertirNumero(retoModelo.presupuesto)}}€</h2>
               <p><b>Condiciones del presupuesto:</b></p>
               <div [innerHTML]="retoModelo.criterios_presupuesto"></div>
          </div>
     </div>

     <div *ngIf="retoModelo?.documentos"
          class="row | border-start border-5 border-light-subtle bg-light rounded p-2 mt-3 text-center justify-content-center overflow-x-auto d-flex flex-nowrap">
          <div class="col-12 col-lg-6" *ngIf="retoModelo?.documentos">
               <h2>Documentos</h2>
               <table>
                    <tr *ngFor="let documento of retoModelo.documentos">
                         <td>
                              <img class="icono img-fluid w-25" src="../../../assets/pdf.svg"
                                   *ngIf="documento.fichero.includes('.pdf')">
                              <img class="icono img-fluid w-25" src="../../../assets/txt.svg"
                                   *ngIf="documento.fichero.includes('.txt')">
                              <img class="icono img-fluid w-25" src="../../../assets/doc.svg"
                                   *ngIf="documento.fichero.includes('.doc')">
                              <img class="icono img-fluid w-25" src="../../../assets/svg.svg"
                                   *ngIf="documento.fichero.includes('.svg')">
                              <img class="icono img-fluid w-25" src="../../../assets/jpg.svg"
                                   *ngIf="documento.fichero.includes('.jpg')">
                              <img class="icono img-fluid w-25" src="../../../assets/png.svg"
                                   *ngIf="documento.fichero.includes('.png')">
                              <img class="icono img-fluid w-25" src="../../../assets/rar.svg"
                                   *ngIf="documento.fichero.includes('.rar') || documento.fichero.includes('.zip')">
                              <img class="icono img-fluid w-25" src="../../../assets/vicdeo-azul.svg"
                                   *ngIf="documento.fichero.includes('.mp4') || documento.fichero.includes('.mpeg') || documento.fichero.includes('.mpg') || documento.fichero.includes('.avi')">
                         </td>
                         <td>{{documento.nombre}}</td>
                         <td>
                              <button class="boton icono secondary"
                                   (click)="descargar_documento(documento.fichero)"><img
                                        src="../../../assets/descargar.svg"></button>
                         </td>
                    </tr>
               </table>
          </div>
          <div class="col-12 col-lg-6 |  align-self-center"
               *ngIf="usuario_sesion.rol==4 && !retoModelo.baneado && !retoModelo.borrado && retoModelo.detalles">
               <button class="boton medium" (click)="presentar_solucion()">Presentar solución</button>
          </div>
     </div>
</div>


<ng-template #modal_contrato let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <h5 class="modal-title">CONTRATO DE CONFIDENCIALIDAD DEL DESARROLLO DEL DESAFÍO PROPUESTO</h5>
               </div>
               <div class="modal-body">
                    <div class="parrafo">
                         <input type="text" style="display: none;">
                    </div>
                    <div class="parrafo fecha"> En {{usuario_sesion.poblacion}}, a {{fechaActualFormateada}}</div>
                    <span class="titulo">COMPARECE. </span>
                    <div class="parrafo">El investigador {{usuario_sesion.nombre}} {{usuario_sesion.apellidos}} con DNI
                         {{usuario_sesion.nif}}, perteneciente al Instituto/Centro de investigación
                         {{usuario_sesion.centro_investigacion}} con poderes suficientes para la firma de este acuerdo
                         en virtud de la delegación de competencias de la institución a su personal investigador,
                         poderes que no le han sido derogados ni modificados.</div>
                    <div class="parrafo">Que esta parte podrá ser parte emisora y/o parte receptora según el objeto de
                         este acuerdo y en adelante denominada como “LA PARTE” o en su conjunto como “LAS PARTES”, y en
                         adelante en este documento, podrán ser denominada como USUARIA.
                    </div>
                    <div class="parrafo">
                         En presencia de la <b>FUNDACIÓN GENERAL CSIC</b> con domicilio social en la calle Serrano nº
                         117; 28006 Madrid y con N.I.F.: G-85562106, representada por
                         <b>D.{{nombrePresidenteFGCSIC}}</b>, mayor de edad, con DNI {{dniPresidenteFGCSIC}}, en nombre
                         y representación de la en su calidad de director general, en base a las facultades que dicho
                         cargo tiene conferidas en escrituras públicas de fecha 2 de abril de 2009, otorgada ante la
                         notaria del Ilustre Colegio de Madrid, Doña Isabel Griffo Navarro, bajo el número 649 de su
                         protocolo y de fecha 12 de mayo de 2021, otorgada ante el notario del Ilustre Colegio de
                         Madrid, Don José Periel Martín, bajo el número 2.919 de su protocolo, de ampliación de
                         facultades; cargo que ostenta desde su nombramiento el 1 de enero de 2021. (En adelante en este
                         documento, <b>FGCSIC)</b>
                    </div>
                    <div class="parrafo">
                         Las partes han acordado celebrar el presente <b>ACUERDO DE CONFIDENCIALIDAD </b>que se regirá
                         por las siguientes cláusulas, previas las siguientes
                    </div>
                    <span class="titulo"> CONSIDERACIONES </span>
                    <div class="parrafo">
                         <span class="tituloSecundario">Primera.- </span>Que FGCSIC es una fundación privada
                         perteneciente al CSIC que se dedica, entre otras actividades, a la innovación aplicada a través
                         de diferentes prácticas como son: la creación de modelos de negocio - detección de nuevas
                         oportunidades de mercado, conceptualización y desarrollo de productos compatibilizando
                         soluciones tecnológicas específicas, incubación y aceleración corporativa – creación de spin
                         in/off, aceleración de startups y transferencia tecnológica con su financiación correspondiente
                         a través del fondo de inversión FGCSIC SCIENCE TECH en constitución, así como a la gestión de
                         la plataforma NEXOFY.
                    </div>
                    <div class="parrafo">
                         <span class="tituloSecundario">Segunda.-</span> Que LA PARTE es una persona que actúa en su
                         propio nombre y representación o en nombre y por cuenta de la entidad con los datos
                         suministrados más arriba.
                    </div>
                    <div class="parrafo">
                         <span class="titulo">Tercera.- </span>Que LA PARTE pretende iniciar una serie de contactos a
                         través de la plataforma NEXOFY en las que se aportará información y documentación para poder
                         estudiar el futuro desarrollo de un Proyecto de colaboración entre las partes que incluya la
                         potencial viabilidad del proyecto, entre otros, que podrá incluir cualesquiera otros no
                         expresamente excluidos que impliquen la puesta en marcha del proyecto, sin que exista
                         limitación al estudio y sin que suponga ningún compromiso para ninguna de las partes, más allá
                         del estudio del potencial de colaboración.
                    </div>
                    <div class="parrafo">
                         <span class="titulo">Cuarto.- </span> Que LA PARTE, por tanto, tendrá acceso a información
                         calificada como Confidencial, con el objeto de llevar a cabo el proyecto descrito, y tienen la
                         voluntad de intercambiar información técnica y/o comercial de naturaleza confidencial y/o
                         información sujeta a derechos de propiedad intelectual, e industrial actualmente en su poder,
                         además de tener la intención de asegurarse de que continúe siendo confidencial.
                    </div>
                    <span class="titulo"> CLÁUSULAS </span>
                    <div class="parrafo">
                         <span class="tituloSecundario">1.- DEFINICIONES. </span> Los conceptos siguientes tendrán los
                         significados expresados en el mismo:
                         <p><span>1.1. “INFORMACIÓN CONFIDENCIAL”: aquella información técnica y/o comercial revelada
                                   por cualquiera de las partes (“PARTE EMISORA”) a otra parte (“PARTE RECEPTORA”), en
                                   el marco del proyecto arriba mencionado, ya sea por escrito, oralmente, en forma de
                                   documentos, dibujos, diseños, muestras, modelos, programas informáticos o cualquier
                                   otra forma, incluyendo sin limitación alguna, la información objeto de derecho de
                                   autor, patentes, técnicas, modelos, invenciones, know-how, procesos, algoritmos,
                                   programas, ejecutables, investigaciones, detalles de diseño, prototipos información
                                   financiera, lista de USUARIO/S, inversionistas, empleados, relaciones de negocios y
                                   contractuales, pronósticos de negocios, precios, contactos de fabricación, planes de
                                   mercado y negocio, cuentas, información financiera y cualquier información revelada o
                                   intercambiada entre LAS PARTES, y que en el momento de su divulgación o distribución
                                   sea comunicada como confidencial.</span></p>
                         <p><span>1.2. “LAS PARTES”: la “persona investigadora” (“LA PARTE” en el presente acuerdo) y el
                                   “usuario persona proponente” que deben ejecutar este acuerdo pudiendo ser ambas
                                   receptoras y emisoras. </span></p>
                         <p><span>1.3. “PARTE EMISORA”: aquella parte del presente acuerdo que revele Información
                                   Confidencial a la otra Parte.</span></p>
                         <p><span>1.4. “PARTE RECEPTORA”: aquella parte del presente acuerdo que reciba, utilice,
                                   distribuya, estudie, y que, en cualquier manera, tenga acceso a la Información
                                   Confidencial expuesta o entregada por la PARTE EMISORA. </span></p>
                    </div>
                    <div class="parrafo">
                         <span class="tituloSecundario">2.-OBJETO </span> El objeto del presente acuerdo es fijar los
                         términos y condiciones bajo los cuales LA PARTE mantendrá la confidencialidad de los datos e
                         INFORMACIÓN CONFIDENCIAL intercambiados entre ellas a la que tengan acceso por causa o con
                         ocasión del proyecto anteriormente señalado.
                    </div>
                    <div class="parrafo">
                         <span class="tituloSecundario">3.- OBLIGACIONES. </span> Las partes tendrán las obligaciones
                         siguientes:
                         <p><span>3.1. LA PARTE acuerda que toda y cada una de la INFORMACIÓN CONFIDENCIAL
                                   intercambiada, facilitada o creada entre LAS PARTES en el transcurso del proyecto
                                   mencionado, será tratada como tal y mantenida en estricta confidencialidad, y se
                                   comprometen a utilizarla exclusivamente con fines de desarrollar el proyecto conforme
                                   establece el contrato suscrito entre las partes.</span></p>
                         <p><span>3.2. La PARTE EMISORA, deberá confirmar por escrito toda información oral o
                                   intercambiada por un medio no escrito, en cualquier otro soporte, que sea clasificada
                                   como confidencial. Dicho escrito indicará específicamente la fecha de divulgación y
                                   calificará la información como confidencial. Este Acuerdo no debe aplicarse a
                                   Información no Confidencial revelada oralmente y no ratificada por escrito ni
                                   señalada del mismo modo dentro de un plazo de diez (10) días a partir de la
                                   revelación oral inicial. </span></p>
                         <p><span>3.3. LA PARTE se compromete a: </span></p>
                         <ul>
                              <li>Mantener en secreto y no revelar a terceros (por tercero se entenderá incluido
                                   cualquier persona física o jurídica que controle, sea controlada por o esté bajo el
                                   control de la PARTE RECEPTORA) cualquier INFORMACIÓN CONFIDENCIAL suministrada por la
                                   PARTE EMISORA; sin ponerla a disposición del público, ni hacerla pública o accesible
                                   de cualquier forma, salvo con el consentimiento previo, expreso y por escrito de la
                                   parte que la ha suministrado (PARTE EMISORA).</li>
                              <li>No utilizar la INFORMACIÓN CONFIDENCIAL en provecho propio o de terceros y para otro
                                   propósito que no sea él interés del proyecto acordado tal y como se describe en el
                                   acuerdo suscrito entre las partes.</li>
                              <li>Permitir el acceso a la INFORMACIÓN CONFIDENCIAL únicamente a aquellas personas
                                   físicas o jurídicas que, prestando, en ambos casos, sus servicios para el desarrollo
                                   del proyecto, necesiten la información para el desarrollo de tareas para las que el
                                   uso de esta información sea estrictamente necesario, conforme a los términos
                                   indicados en la cláusula quinta del presente acuerdo.</li>
                              <li>Proteger los intereses comerciales de la PARTE EMISORA que se deriven o puedan
                                   derivarse en el futuro de la información suministrada, incluidos los derechos de
                                   propiedad industrial/intelectual presentes o posibles, así como a no impedir de
                                   ninguna manera el desarrollo de dichos intereses comerciales o el ejercicio de
                                   cualquier derecho que pudiera asistir a la PARTE EMISORA.</li>
                              <li>No copiar muestras, modelos, dibujos u otros documentos proporcionados por la PARTE
                                   EMISORA en virtud de este Acuerdo, a menos que esté expresamente permitido por la
                                   PARTE EMISORA en cada caso o cuando sea necesario para el proyecto acordado. </li>
                              <li>Adoptar y mantener mecanismos internos de seguridad adecuados para proteger la
                                   confidencialidad de toda la INFORMACIÓN CONFIDENCIAL que conozcan o llegaren a
                                   conocer en desarrollo del proyecto, observado el mismo cuidado en la salvaguarda de
                                   tal INFORMACIÓN CONFIDENCIAL que el que observan para su propia información de igual
                                   carácter e importancia.</li>
                              <li>Comunicar a la otra parte toda filtración de información de la que tengan o lleguen a
                                   tener conocimiento, producida por la vulneración del presente Acuerdo de
                                   Confidencialidad o infidelidad de las personas que hayan accedido a la INFORMACIÓN
                                   CONFIDENCIAL.</li>
                         </ul>
                    </div>
                    <div class="parrafo">
                         <span class="tituloSecundario">4.-RESPONSABILIDADES. </span> LA PARTE tendrá las
                         responsabilidades siguientes:
                         <p><span>4.1. La PARTE RECEPTORA será responsable de la revelación no autorizada de Información
                                   Confidencial, excepto cuando la PARTE RECEPTORA haya observado el mismo cuidado en la
                                   salvaguarda de tal INFORMACIÓN CONFIDENCIAL que el que observa para su propia
                                   información de igual carácter e importancia y después del descubrimiento de dicha
                                   revelación, notifique la misma a la PARTE EMISORA y tome medidas razonables para
                                   prevenir cualquier ulterior revelación.</span></p>
                         <p><span>4.2 La PARTE RECEPTORA y sus trabajadores reconocen que cualquier revelación o uso no
                                   autorizado por una parte de Información Confidencial de la otra parte podría causar
                                   daños irreparables y graves perjuicios, cuyas proporciones resultan difíciles de
                                   calcular. Por consiguiente, se conviene acuerda que la parte infractora estará
                                   obligada a abonar a la otra parte penalizaciones por cualquier incumplimiento de este
                                   Acuerdo según decisión de un tribunal. Todo ello, sin perjuicio de cualquier otra
                                   reclamación de daños y perjuicios que sea exigible en derecho en función del
                                   incumplimiento y sin limitación de tipo alguno. En tal caso, la parte cumplidora
                                   podrá exigir a la parte no cumplidora todos los daños y perjuicios que pueda
                                   demostrar con independencia de la penalización acordada. </span></p>
                    </div>
                    <div class="parrafo">
                         <span class="tituloSecundario">5-. EXCEPCIONES. </span> Las obligaciones especificadas en la
                         cláusula tercera del presente acuerdo no se aplicarán en relación con aquella INFORMACIÓN
                         CONFIDENCIAL que:
                         <ul>
                              <li>Fuese de dominio público en el momento de divulgarla o que a partir de ese momento
                                   haya pasado a formar parte del mismo, sin incumplimiento de los términos del presente
                                   contrato en lo que respecta a la PARTE RECEPTORA; o bien que</li>
                              <li>La PARTE RECEPTORA pueda probar fehacientemente que ya la conocía mediante la
                                   divulgación por fuentes distintas a la PARTE EMISORA y que posee el derecho de
                                   divulgar dicha información; o que</li>
                              <li>Sea requerida a la PARTE RECEPTORA por las Autoridades administrativas o judiciales
                                   que puedan obligar a ello. En este caso deberá notificarse inmediatamente esta
                                   circunstancia a la PARTE EMISORA. Asimismo, la PARTE RECEPTORA sólo revelará aquella
                                   parte de la INFORMACIÓN CONFIDENCIAL que sea requerida legalmente y aportará a la
                                   PARTE EMISORA garantías de que a dicha Información se le dará un tratamiento
                                   confidencial.</li>
                         </ul>
                    </div>
                    <div class="parrafo">
                         <span class="tituloSecundario">6-.TRATAMIENTO. </span>
                         <p><span>6.1 Salvo que resulte necesario para los fines establecidos en el presente acuerdo y
                                   siempre que toda INFORMACIÓN CONFIDENCIAL divulgada o copia de la misma se haga
                                   accesible únicamente a los empleados que tengan necesidad de conocerla, la PARTE
                                   RECEPTORA no copiará ni reproducirá, sin consentimiento previo de la PARTE EMISORA,
                                   ningún elemento o documento que le haya sido entregado (que consista o contenga, en
                                   parte o en su totalidad, INFORMACIÓN CONFIDENCIAL). </span></p>
                         <p><span>6.2 La PARTE RECEPTORA devolverá dichos documentos o elementos, así como las copias de
                                   los mismos en el plazo de menos de veinticuatro (24) horas, a petición de la parte
                                   que los emitió y procederá a destruir cualquier archivo existente en sus sistemas
                                   informáticos que se hayan creado en relación con la información revelada.</span></p>
                    </div>
                    <div class="parrafo">
                         <span class="tituloSecundario">7-. PROPIEDAD. </span>
                         <p><span>7.1. Toda la INFORMACIÓN CONFIDENCIAL, así como todos los derechos de propiedad
                                   intelectual e industrial sobre la información y sobre los soportes que las contengan,
                                   permanecerá y seguirá siendo propiedad exclusiva de la parte que la haya generado y,
                                   posteriormente, revelado, sin que la comunicación de tal información suponga
                                   variación alguna respecto a la titularidad de la misma. El presente acuerdo no
                                   implica, ni concede cesión de derechos, licencias o cualquier otro tipo de
                                   transferencia sobre derechos a la PARTE RECEPTORA y tampoco crea obligación comercial
                                   alguna para LAS PARTES, salvo que estas acuerden lo contrario.</span></p>
                         <p><span>7.2. El presente acuerdo concede un derecho no exclusivo y no transferible de uso de
                                   la Información comunicada que cada parte entrega a la otra en las condiciones que se
                                   recogen en el presente acuerdo y para el exclusivo uso durante el proyecto y la vida
                                   del acuerdo a los fines pretendidos en este acuerdo. Caso de producirse resultados
                                   conjuntos susceptibles de protección y registro, las partes procederán de mutuo
                                   acuerdo.</span></p>
                         <p><span>7.3. LA PARTE conviene que toda la INFORMACIÓN CONFIDENCIAL será accesible “tal como
                                   es” y que no se concede garantía alguna, ni tácita ni expresa, en cuanto a la calidad
                                   de dicha INFORMACIÓN CONFIDENCIAL incluyendo, aunque sin por ello limitar, ni
                                   garantizar su adaptabilidad a cualquier uso, la no infracción de derechos de
                                   terceros, su exactitud, su integridad o precisión.</span></p>
                    </div>
                    <div class="parrafo">
                         <span class="tituloSecundario">8-. DURACIÓN </span>
                         <p><span>8.1. El presente acuerdo entrará en vigor a partir de la fecha de la firma y será
                                   válido durante el periodo en que se desarrolle el proyecto tal y como se especifica
                                   en el respectivo contrato y como máximo por un período de cinco años, salvo que la
                                   ley prevea un término mayor.</span></p>
                         <p><span>8.2. No obstante lo establecido en el punto anterior, las obligaciones aquí contenidas
                                   referentes a la confidencialidad y uso de la información mantendrán su vigencia a la
                                   terminación de este Acuerdo por el período máximo que permita la ley y, caso de no
                                   prever nada, por un período mínimo de 5 años contados desde la finalización del
                                   acuerdo, del proyecto convenido o la comunicación de la información, contando desde
                                   el último hecho producido. </span></p>
                         <p><span>8.3. Durante este tiempo LA PARTE se compromete a abstenerse de usar, en todo o en
                                   parte, directa o indirectamente, en igual forma o modificada la INFORMACIÓN
                                   CONFIDENCIAL a la que tengan acceso por causa o con ocasión del proyecto
                                   anteriormente señalado, y se abstendrán de utilizarla para cualquier tipo de
                                   negociación y/o prestación de servicios propia o a terceros.</span></p>
                    </div>
                    <div class="parrafo">
                         <span class="tituloSecundario">9-. MODIFICACIÓN. </span>
                         <p><span>9.1. El presente Acuerdo de Confidencialidad o cualquier cláusula del mismo podrán ser
                                   modificadas o corregidas por acuerdo mutuo entre las partes. Las nuevas
                                   modificaciones y/o correcciones deberán para su validez presentarse por escrito y
                                   estar firmadas por todas las Partes, fechadas junto a la modificación o añadido
                                   realizados.</span></p>
                         <p><span>9.2. El presente Acuerdo solo podrá darse por terminado con el consentimiento expreso
                                   y por escrito de todas LAS PARTES. </span></p>
                    </div>
                    <div class="parrafo">
                         <span class="tituloSecundario">10-. JURISDICCIÓN. </span>
                         <p><span>10.1. El presente acuerdo se rige e interpretará de conformidad con la legislación
                                   española y en caso de discrepancias relativas a la interpretación o ejecución del
                                   mismo, LA PARTE acuerda resolver de manera amistosa cualquier disputa o desacuerdo
                                   que pueda surgir durante a aplicación del presente Acuerdo.</span></p>
                         <p><span>10.2 Caso de no conseguirlo en un plazo de sesenta días, con renuncia de cualquier
                                   otro fuero se someten a los juzgados y tribunales de Madrid, España. </span></p>
                         <p><span>10.3 En caso de cualquier conflicto o discrepancia que pueda surgir en relación con la
                                   interpretación y/o cumplimiento del presente Acuerdo, se aplicarán las presentes
                                   cláusulas, y, en su defecto, por las condiciones de condiciones generales extensas de
                                   la plataforma, por encima de cualquier otro contrato o norma dispositiva del
                                   ordenamiento, ante cualquier foro competente y ley aplicable, tal como están
                                   recogidas en nuestra página web y accesibles para los usuarios. En su defecto,
                                   aplicará el Código Civil y la ley española</span></p>
                    </div>
                    <div class="parrafo">
                         <span class="tituloSecundario">11-. VALIDEZ. </span>
                         <p><span>11.1 El presente Acuerdo requiere para su validez y perfeccionamiento la <b>Aceptación
                                        digital</b> de estos términos. Cualquier añadido posterior a la Aceptación de
                                   este acuerdo de confidencialidad, se añadirán en forma de anexos firmados con
                                   posterioridad por ambas partes, enmienda, tachadura, modificación o alteración del
                                   tipo que sea deberá, para su validez, ir salvado mediante escrito con la firma de
                                   ambas partes junto al elemento retocado, con la fecha de la enmienda.</span></p>
                         <p><span>11.2 El presente Acuerdo sustituye a cualquier otro anterior y a cualesquiera
                                   comunicaciones o forma de operar entre las partes; formando un todo integral siendo
                                   obligatorios para las partes en todos sus extremos.</span></p>
                         <p><span>11.3 La nulidad de una cualquiera de sus cláusulas no producirá la nulidad del resto
                                   del contrato, que conservará su validez. Las partes integrarán tal laguna cumpliendo
                                   el contrato del tenor del espíritu del mismo.</span></p>
                         <p><span>11.4 LA PARTE necesitará obtener el previo consentimiento escrito de la otra parte
                                   para hacer cualquier anuncio público o cualquier referencia, en cualquier medio,
                                   incluso, sin limitación, en listas de USUARIO/S, presentaciones, etc. acerca de la
                                   existencia del presente Contrato, de su contenido o cualquier tipo de comentario
                                   cuando se mencione o pudiera sobrentenderse de manera razonable, la identidad de la
                                   otra parte o de sus USUARIO/S</span></p>
                    </div>
                    <div class="parrafo">
                         Para constancia, y en señal de aceptación, se firma el presente acuerdo en un ejemplar, con el
                         mismo efecto en el lugar y fecha que figura en el encabezado.
                    </div>
                    <div class="form-check">
                         <input class="clicable form-check-input check-formulario" type="checkbox"
                              name="flexCheckDefault" id="flexCheckDefault" [(ngModel)]="checkaceptado">
                         <p class="form-check-label"><b>Acepto los términos y condiciones de confidencialidad</b></p>

                    </div>
               </div>

          </div>
          <div class="modal-footer">
               <button class="boton medium" [disabled]="!checkaceptado" (click)="aceptar_contrato()">Aceptar</button>
               <button class="boton medium secondary danger" (click)="rechazar_contrato()">Rechazar</button>
          </div>
     </div>
</ng-template>

<!--Modal para avisos-->
<ng-template #modal_aviso let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>

               <div class="modal-body aviso">
                    <div class="icono-aviso">
                         <img src="../../../assets/x (1).svg" *ngIf="!aviso_error" width="100%">
                         <img src="../../../assets/correcto.svg" *ngIf="aviso_error" width="100%">
                    </div>
                    <div class="titulo">
                         <h2>{{this.mensaje_error}}</h2>
                    </div>
               </div>
          </div>
     </div>
</ng-template>

<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
               <div class="modal-body">
                    <p>
                         {{informacion}}
                    </p>
               </div>
               <div class="modal-footer">
                    <button class="boton medium" (click)="modal.dismiss()">Aceptar</button>
               </div>
          </div>
     </div>
</ng-template>

<!--Modal confirmar la publicación del reto-->
<ng-template #modal_publicar let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>

               <div class="modal-body aviso">
                    <div class="titulo">
                         <h2>¿Seguro que quieres publicar el reto?</h2>

                    </div>
                    <p>Una vez se publique el reto, será revisado y validado por el administrador de la plataforma y
                         activado en el menor tiempo posible. Después de publicarlo, ya no podrás hacer modificaciones
                         sobre el reto.</p>
                    <button class="boton medium" (click)="publicar()">Sí, publicar</button>
                    <button class="boton medium secondary danger" (click)="modal.dismiss()">Cancelar</button>
               </div>
          </div>
     </div>
</ng-template>

<!--Modal confirmar borrado-->
<ng-template #modal_borrar let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>

               <div class="modal-body aviso">
                    <div class="titulo">
                         <h2>¿Seguro que quieres borrar el reto?</h2>
                    </div>
                    <button class="boton medium" (click)="confirmar_borrar()">Sí, borrar</button>
                    <button class="boton medium secondary danger" (click)="modal.dismiss()">Cancelar</button>
               </div>
          </div>
     </div>
</ng-template>