
export class Empresas{
    constructor(
        public _id: string,
        public nombre: string,
        public cif: string,
        public web: string,
        public imagen: string,
        public url_imagen: string,
        public cp: string,
        public facebook: string,
        public twitter: string,
        public linkedin: string,
        public pais: string,
        public provincia: string,
        public poblacion: string,
        public direccion: string,
        public facturacion_anual: number,
        public numero_empleados: number,
        public sector: string,
        public cdata: Date,
        public mdate: Date,
        public borrado: string, 
        public email:string,
        public contrato: number,
        public date_contrato: Date,
    ){}
}