import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {
  public token:any;
  public url:any;


  	constructor(
    		public _http:HttpClient,
  	) {
    		this.url = environment.url_api;
   	}


  	createEmpresas(empresas:any){
		let params = JSON.stringify(empresas);  
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json');
		return this._http.post(this.url+'createEmpresas', params,{headers: headers});
	}

	
	readEmpresas(token:any, id:any){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.get(this.url+'readEmpresas/'+id, {headers: headers});
	}

	listEmpresas(token:any,pagina:number = 1, termino_busqueda:string="", resultados:number=10){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.get(this.url+'listEmpresas/?p='+pagina+'&q='+termino_busqueda+'&r='+resultados, {headers: headers});
	}

	
	updateEmpresas(empresa:any, token:any){
		let params = JSON.stringify(empresa)
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'updateEmpresas', params, {headers: headers});
	}
	aceptarContrato(empresa:any, token:any){
		let params = JSON.stringify(empresa)
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'aceptarContratoEmpresa', params, {headers: headers});
	}



}
