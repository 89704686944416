export class Firmantes{
    constructor(
        public _id: string,
        public nombre: string,
        public apellidos: string,
        public nif: string,
        public cargo: string,
        public email: string,
        public telefono: string,
        public empresa: string,
        public cif: string,
        public type_notifications: string,
    ){}
}