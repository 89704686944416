<div class="container" *ngIf="usuario_sesion.rol<=2">
     <div class="tituloPrincipal">
          <h1 class="titulo-componente">Dashboard<img class="btn_informacion" src="../../assets/info.svg"(click)="abrirInfo()"></h1>
          <button class="boton secondary medium" (click)="mostrar_filtro()">Filtros</button>
     </div>

     <div #filtro class="row | bg-white rounded border p-2 mb-3 |  animate__animated ">
          <div class="col-lg-4 col-12">
               <label>Desde</label>
               <div class="">
                    <input type="date" placeholder="Fecha de publicación..." class="form-control" [(ngModel)]="desde">
               </div>
          </div>

          <div class="col-lg-4 col-12">
               <label>Hasta</label>
               <div class="">
                    <input type="date" placeholder="Fecha del publicación..." class="form-control" [(ngModel)]="hasta">
               </div>
          </div>

          <div class="col-lg-4 col-12 | align-self-center text-center text-lg-start">
               <button class="boton secondary" (click)="buscar()">Aplicar filtros</button>
          </div>
     </div>

     <div class="row ">
          <div class="col-xl-6  col-12  | mb-3 ">
               <div class=" border rounded p-3">
                    <strong class="mb-3">Nuevos usuarios</strong>
                    <div class="mt-3" >
                         <canvas class="h-100 w-100" id="GraficoUsuariosNuevos"></canvas>
                    </div>
               </div>
          </div>

          <div class="col-xl-6 col-12 | mb-3 ">
               <div class="border rounded p-3">
                    <strong class="mb-3">Usuarios y empresas</strong>
                    <div class="mt-3" >
                         <canvas class="h-50 w-50" id="GraficoEmpresasUsuarios"></canvas>
                    </div>
               </div>
          </div>

          <div class="col-xl-6 col-12 | mb-3 ">
               <div class="border rounded p-3">
                    <strong class="mb-3">Retos publicados</strong>
                    <div class="mt-3">
                         <canvas id="GraficoRetosNuevos"></canvas>
                    </div>
               </div>
               
          </div>

          <div class="col-xl-6 col-12 | mb-3 ">
               <div class="border rounded p-3">
                    <strong class="mb-3">Soluciones presentadas</strong>
                    <div class="mt-3" >
                         <canvas id="GraficoSolucionesNuevas"></canvas>
                    </div>
               </div>
               
          </div>
          
          <div class="col-xl-6 col-12 | mb-3 ">
               <div class="border rounded p-3">
                    <strong class="mb-3">Estado de los retos</strong>
                    <div class="mt-3" >
                         <canvas class="h-50 w-50" id="GraficoEstadoRetos"></canvas>
                    </div>
               </div>
          </div>

          <div class="col-xl-6 col-12 | mb-3 ">
               <div class="border rounded p-3 ">
                    <strong class="mb-3">Búsquedas</strong>
                    <div class="mt-3 overflow-x-auto d-flex flex-nowrap">
                         <table>
                              <thead>
                                   <th>Término</th>
                                   <th>Nº de búsquedas</th>
                              </thead>
                              <tbody>
                                   <tr *ngFor="let query of dataQuerys">
                                        <td>{{query.termino}}</td>
                                        <td>{{query.cantidad}}</td>
                                   </tr>
                              </tbody>
                         </table>
                    </div>
               </div>
          </div>
     </div>
</div>


<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal >
     <div class="modal-content">
          <div class="modal-content">
			<div class="modal-header">
				<img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
			</div>
               <div class="modal-body">
				<p [innerHTML]="informacion"></p>				
               </div> 
               <div class="modal-footer">
                    <button class="boton medium"(click)="modal.dismiss()">Aceptar</button>
               </div>            
          </div>
     </div>
</ng-template> 