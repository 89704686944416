import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild,  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../services/global.services';
import { SolucionesService } from './soluciones.service';
import { Token } from '@angular/compiler';



@Component({
	selector: 'app-soluciones',
	templateUrl: './soluciones.component.html',
	styleUrls: ['./soluciones.component.css']
})
export class SolucionesComponent implements OnInit {
	
	public lista_soluciones:any;
	public lista_soluciones_totales:any;
	public solucionModelo:any;
	public usuario_sesion:any;
	public pipe = new DatePipe('en-US');
	public mensaje_error = "";
	public aviso_error=false;
	public codigo_error:any;
	public informacion:string = "";
	public nSoluciones:number = 0;

	@ViewChild('modal_aviso') modal_aviso:any;
	@ViewChild('modal_informacion') modal_informacion:any;

	constructor(
		private modalService: NgbModal,
		private router:Router,
		private _globalService: GlobalService,
		private _solucionesService: SolucionesService,
		private _router:ActivatedRoute,
	) {
		this.usuario_sesion = this._globalService.DatosSesion();
		this.descargar_soluciones();
		
	}


	ngOnInit(): void {
	}

	ngAfterViewInit():void{
		// Si al crear solucion nueva  mostramos el modal informando que se guardo correctamente.
		this.codigo_error = localStorage.getItem('codigo_error')
		if(this.codigo_error){
			if (this.codigo_error == 1){
				this.mensaje_error="<h2>Solución guardada</h2> Recuerda, para que tu reto se publique y esté disponible para su posible aprobación es necesario presentarla para ella dentro de la solución tienes que seleccionar la opción <strong><em>PRESENTAR</em></strong>. Al hacerlo, confirmarás que tu solución está lista para ser aprobada.";
			}else if(this.codigo_error==3){
				this.mensaje_error="<h2>Solución presentada</h2>";
			}
			this.modalService.open(this.modal_aviso,{centered:true});
			localStorage.removeItem('codigo_error');
		}
		
	}
	
	descargar_soluciones(){
		this._solucionesService.listSoluciones(this._globalService.getToken()).subscribe({
			next: (respuesta)=>{    
				let res:any = respuesta;   
				if(res.error_code == 1){
					this.lista_soluciones = res.response.listado; 
					this.lista_soluciones = this.lista_soluciones.filter((solucion:any)=>solucion.id_usuario._id == this.usuario_sesion._id)
					this.nSoluciones = this.lista_soluciones.length;
				}else{
					this.nSoluciones = 0;
				}
				
			}
		})
	}


	ver_solucion(id:any){
		this.router.navigate(["/soluciones/" + id]);
	}
	
	//Abrimos el modal donde aparece información de la pagina.
	abrirInfo(){
		this.informacion = "En la ventana Soluciones, podemos ver como investigadores las soluciones que hemos presentado a los distintos retos."
		this.modalService.open(this.modal_informacion,{centered:true});
	}

}
