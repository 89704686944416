//Servicios globales para toda la aplicación
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';


@Injectable({
     providedIn: 'root'
})
export class GlobalService {
     public usuario_sesion: any;
     public token: any;
     public url: any;
     public debug: boolean;


     constructor(
          public _http: HttpClient,
     ) {
          this.url = environment.url_api;
          this.debug = GLOBAL.debug;
     }


     //recupera los datos de sesion del localstorage
     DatosSesion() {
          //let datos_sesion:any = localStorage.getItem("identity");
          let datos_sesion: any = sessionStorage.getItem("identity");
          if (datos_sesion != 'undefined') {
               this.usuario_sesion = JSON.parse(datos_sesion);
               //console.log(this.usuario_sesion)
          }
          return this.usuario_sesion;
     }

     //recupera el token del localstorage
     getToken() {
          let token = sessionStorage.getItem('token');
          //let token = localStorage.getItem('token');
          if (token != "undefined") {
               this.token = token;
          } else {
               this.token = null;
          }
          return this.token;
     }

     //peticion de login o recuperacion de token de la api
     login(datos_usuario: any, gethash: any = null): Observable<any> {
          if (gethash != null) {
               datos_usuario.gethash = gethash
          }
          let json = JSON.stringify(datos_usuario);
          let headers = new HttpHeaders().set('Content-Type', 'application/json');
          console.log(this.url)
          return this._http.post(this.url + 'loginUsuarios/', json, { headers: headers }).pipe(
               catchError(error => {
                    let respuesta: any = { error_code: 26, message: 'Error de comunicacion con la API' }
                    return of(respuesta);
               })
          );
     }

     //recuperar clave
     recoveryPassword(email: any) {
          let headers = new HttpHeaders()
               .set('Content-Type', 'application/json');

          return this._http.get(this.url + 'recoverypassword/' + email, { headers: headers });
     }

     //guardar la nueva contraseña
     restartPassword(hash: any, password: any) {
          let params = JSON.stringify({ password: password, hash: hash });

          let headers = new HttpHeaders()
               .set('Content-Type', 'application/json')

          return this._http.put(this.url + 'restartpassword', params, { headers: headers });
     }


     //subir imagen temporal
     subir_imagen(file: any) {
          let url_api = this.url + 'uploadimage';
          let token = this.getToken();
          //Hace la llamada AJAX para subir los archivos
          return new Promise(function (resolve, reject) {
               if (file) {
                    var formData: any = new FormData();
                    var xhr = new XMLHttpRequest();
                    formData.append('imagen', file);
                    xhr.onreadystatechange = function () {
                         if (xhr.readyState == 4) {
                              if (xhr.status == 200) {
                                   resolve(JSON.parse(xhr.response));
                              } else {
                                   reject(xhr.response);
                              }
                         }
                    }
                    xhr.open('POST', url_api, true);
                    xhr.setRequestHeader('Authorization', token);
                    xhr.send(formData);
               } else {
                    reject("No hay fichero");
               }
          });
     }

     //Eliminar Imagen
     eliminar_imagen(nombre: string) {
          let token = this.getToken();
          let headers = new HttpHeaders()
               .set('Content-Type', 'application/json')
               .set('Authorization', token)
               .set("Accept", "*/*");
          let params = JSON.stringify({ "nombre": nombre });
          return this._http.post(this.url + 'limpiarFichero/', params, { headers: headers });
     }

     //subir documento
     subir_documento(file: any) {
          let url_api = this.url + 'uploaddocumento';
          let token = this.getToken();
          //Hace la llamada AJAX para subir los archivos
          return new Promise(function (resolve, reject) {
               if (file) {
                    var formData: any = new FormData();
                    var xhr = new XMLHttpRequest();
                    formData.append('fichero', file);
                    xhr.onreadystatechange = function () {
                         if (xhr.readyState == 4) {
                              if (xhr.status == 200) {
                                   resolve(JSON.parse(xhr.response));
                              } else {
                                   reject(xhr.response);
                              }
                         }
                    }
                    xhr.open('POST', url_api, true);
                    xhr.setRequestHeader('Authorization', token);
                    xhr.send(formData);
               } else {
                    reject("No hay fichero");
               }
          });
     }

     //descargar documento
     descargar_documento(fichero: string) {
          let token = this.getToken();
          let headers = new HttpHeaders()
               .set('Content-Type', 'application/json')
               .set('Authorization', token)
               .set("Accept", "*/*");
          this._http.get(this.url + 'downloaddocumento/' + fichero, { headers: headers, responseType: 'blob' }).subscribe(data => {
               const a = document.createElement('a');
               a.href = URL.createObjectURL(data);
               a.download = fichero;
               a.click();
          });
     }

     recuperar_password(email: string) {
          let params = JSON.stringify({ email: email })
          let headers = new HttpHeaders()
               .set('Content-Type', 'application/json')
          return this._http.post(this.url + 'recuperarpassword', params, { headers: headers });
     }

     restablecer_password(password: string, hash: string) {
          let params = JSON.stringify({ password: password, hash: hash })
          let headers = new HttpHeaders()
               .set('Content-Type', 'application/json')
          return this._http.post(this.url + 'restablecerpassword', params, { headers: headers });
     }


}