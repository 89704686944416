<h1 class="titulo-componente">Soporte técnico<img class="btn_informacion" src="../../assets/info.svg"(click)="abrirInfo()"></h1>

<div class="cuerpo-componente">
     <div class="div-soporte">
          <label>Mensaje</label>
          <div class="input-group">
               <textarea (keydown)="verificarCaracteres($event)" rows="8" class="form-control" placeholder="¿En qué podemos ayudarle?" [(ngModel)]="mensaje"></textarea>
          </div>
          <button [disabled]="!estadoBtn" class="boton medium" (click)="enviar()">Enviar</button>
     </div>
</div>

<!--Modal para aceptar o rechazar contratos-->
<ng-template #modal_aviso let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
 
            <div class="modal-body aviso">
                <div class="icono-aviso">
                    <img src="../../../assets/x (1).svg" *ngIf="!aviso_error" width="100%">
                    <img src="../../../assets/correcto.svg" *ngIf="aviso_error" width="100%">
                </div>
                <div class="titulo">
                    <h2>{{this.mensaje_error}}</h2>
                </div>
            </div>
          </div>
     </div>
</ng-template>
<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal >
     <div class="modal-content">
          <div class="modal-content">
			<div class="modal-header">
				<img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
			</div>
               <div class="modal-body">
				<p>
                        {{informacion}}
                    </p>				
               </div> 
               <div class="modal-footer">
                    <button class="boton medium"(click)="modal.dismiss()">Aceptar</button>
               </div>            
          </div>
     </div>
</ng-template>