<!-- La estructura de bootstrap irá de la siguiente manera: containers | offset
| col | estilos bootstrap | estilos personales-->

<div class="overflow-hidden">
  <main>
    <div (click)="inicio()"
      class="col-sm-1 col-md-2 offset-9 offset-sm-11 offset-md-10 | fixed-top text-lg-center z-2 me-3 me-lg-0 d-flex justify-content-end pe-lg-4">
      <div class="clicable d-none d-md-block boton medium  align-self-center animate__animated">
        Iniciar sesión
      </div>
      <!-- En la clase del boton quitamos: rounded-circle-->
      <button class="btn d-md-none boton medium ">
        <span class="material-symbols-outlined"> login </span>
      </button>
    </div>



    <header class="row | mb-xl-5 | position-relative">
      <section class="offset-xl-1 offset-sm-1 | col-xl-5 col-sm-10 col-12 | p-3 p-sm-0 text-xl-start text-center">
        <article class="row | ms-xl-2 mt-xl-5 mb-xl-5 mt-sm-3 mb-sm-3 justify-content-xl-start justify-content-center">
          <img class="img-fluid | main-icon" src="../../assets/logo.png" />
          <div>
            <img class="clicable" (click)="irAnuevaWeb('https://fgcsic.es/')" id="byfgcsic"
              src="../../assets/logos/Logo_Horizontal_Negro_Con_Color_Fondo_Transparente.png" alt="Logo FGCSIC">
          </div>
        </article>
        <article class="row | mt-xl-5">
          <div class="col | ms-xl-4 mt-xl-5 mt-lg-3">
            <h1 class="txt-princ w-xl-75">
              Únete a Nexofy y construye tu innovación sin límites.
            </h1>
            <div class="titulo">
              <img class="flechaPunto" src="../../assets/flecha1.svg" />
              <p class="mt-lg-4 | txt-sec">
                Como empresa ya no hace falta tener grandes recursos para
                acceder a una innovación de excelencia. Nexofy le permite
                conectarse con una amplia red de talento en innovación para
                encontrar soluciones a sus problemas y detectar nuevas
                oportunidades. Reducir los costes de inversión en I+D y obtener
                soluciones innovadoras, ahora es posible.
              </p>
            </div>
            <div class="titulo">
              <img class="flechaPunto" src="../../assets/flecha2.svg" />
              <p class="mt-lg-4 | txt-sec">
                Como personal investigador puede acercar su talento innovador a la
                sociedad y obtener más recursos para sus proyectos.
              </p>
            </div>
            <div class="mt-lg-4 | mb-sm-5 mb-3">
              <button class="boton medium secondary" (click)="registro_investigador()">
                Soy investigador/a
              </button>
              <button class="boton medium" (click)="registro_empresa()">
                Soy empresa
              </button>
            </div>
          </div>
        </article>
      </section>

      <section class="| col-xl-6 | text-end d-none d-xl-inline">
        <img class="img-princ" src="../../assets/foto-cabecera-landing2.png" />
      </section>

      <section class="position-absolute top-left d-none d-sm-inline">
        <img src="../../assets/flechas-landing.svg" />
      </section>
    </header>

    <section class="row | bg-white">
      <article class="col-10 offset-1 | mt-xl-5 mt-3 pt-xl-5 pt-3 text-center">
        <h4>BIENVENIDO</h4>
        <img src="../../assets/flechas-landing.svg" class="img-fluid d-none d-lg-inline | flechas" />
        <div class="tituloPasos clicable">
          <h2 (click)="descargarInfo('../../assets/Guia_basica_v5.pdf')" class="txt-princ">
            Accede a una red de ideas y soluciones
          </h2>
          <h2 (click)="descargarInfo('../../assets/Guia_basica_v5.pdf')" class="txt-princ">
            para tus retos empresariales<img class="btn_informacion" src="../../assets/info.svg"
              (click)="descargarInfo('../../assets/Guia_basica_v5.pdf')" />
          </h2>
        </div>
      </article>
      <article class="col | mt-xl-5 mb-xl-5">
        <div class="row justify-content-center">
          <div class="col-xl-2 col-lg-3 col-sm-5 col-8 | mx-5">
            <div class="card border-0 row pasos clicable" (click)="descargarInfo('../../assets/Guia_basica_v5.pdf')">
              <div class="card-body">
                <img class="img-fluid card-img-top" src="../../assets/People search-cuate.png" />
                <!-- <p class="txt-tarj"><span class="h1">1.</span> Sube tu reto empresarial / social o plantea tu pregunta de innovación</p> -->
                <h2>
                  1. Sube tu reto empresarial / social o plantea tu pregunta de
                  innovación
                </h2>
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-3 col-sm-5 col-8 | mx-5">
            <div class="card border-0 row pasos clicable" (click)="descargarInfo('../../assets/Guia_basica_v5.pdf')">
              <div class="card-body">
                <img class="img-fluid card-img-top" src="../../assets/Online world-cuate.png" />
                <!-- <p class="txt-tarj"><span class="h1">2.</span> Los investigadores esbozan su solución de innovación</p> -->
                <h2>2. El personal investigador propone su solución de innovación</h2>
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-3 col-sm-4 col-8 | mx-5">
            <div class="card border-0 row pasos clicable" (click)="descargarInfo('../../assets/Guia_basica_v5.pdf')">
              <div class="card-body">
                <img class="img-fluid card-img-top" src="../../assets/People search-bro.png" />
                <!-- <p class="txt-tarj"><span class="h1">3.</span> La empresa elige la solución/idea preferida</p> -->
                <h2>3. La empresa elige la solución/idea preferida</h2>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-sm-4 col-8 | mx-5">
            <div class="card border-0 row pasos clicable" (click)="descargarInfo('../../assets/Guia_basica_v5.pdf')">
              <div class="card-body">
                <img class="img-fluid card-img-top" src="../../assets/High five-cuate.png" />
                <!-- <p class="txt-tarj"><span class="h1">4.</span> Contrato de confidencialidad empresa-investigador para desarrollar la solución</p> -->
                <h2>
                  4. Contrato de confidencialidad empresa-personal investigador para
                  desarrollar la solución
                </h2>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section class="row | mt-sm-5 pb-sm-5 mt-3 pb-3 | position-relative">
      <article class="position-absolute text-end d-none d-sm-inline | right-float">
        <img src="../../assets/flechas-landing.svg" />
      </article>

      <article class="offset-lg-1 | col-lg-5 | mt-xl-5 d-none d-lg-block align-self-center">
        <img class="img-fluid" src="../../assets/freelance2.png" />
      </article>

      <article class="offset-1 | col-lg-5 col-10 | mb-xl-5 mb-3 align-self-center text-lg-start text-center">
        <div>
          <span>ÚNETE A NEXOFY</span>
        </div>
        <h2 class="mb-xl-2">
          Una solución que aporta confianza y protege la información de su
          negocio, obteniendo ideas del talento científico reconocido
        </h2>
        <img src="../../assets/flechas-landing.svg" class="w-25 mt-xl-4 mb-xl-2" />
        <p class="mt-xl-4  | txt-sec">
          Reducir los costes de inversión en I+D y obtener soluciones
          innovadoras, ahora es posible.
        </p>
        <p class="mt-xl-4  | txt-sec">
          Está interesado en innovar, pero no tiene tiempo ni recursos para
          crear su propia estructura… Nexofy es su solución.
        </p>
        <button class="boton secondary medium" (click)="registro()">
          Quiero unirme
        </button>
      </article>

      <div class="position-absolute left-float d-none d-sm-inline">
        <img src="../../assets/flechas-landing.svg" />
      </div>
    </section>

    <section class="row | mb-xl-5 pb-xl-5 pb-3 | bg-white">
      <article class="fila-cabecera col-lg-12 | mt-xl-5 pt-xl-5 mb-xl-5 mt-3 pt-3 mb-3 text-center">
        <h4>ATENCIÓN</h4>
        <img src="../../assets/flechas-landing.svg" class="img-fluid d-none d-lg-inline | flechas" />
        <h2 class="txt-princ">Algunos de los</h2>
        <h2 class="txt-princ">desafíos Nexofy activos</h2>
      </article>

      <article class="col-12 | mb-xl-5 mb-sm-3">
        <div class="row | offset-1 justify-content-xl-center justify-content-sm-evenly">
          <div class="offset-sm-0 | border-0 col-xl-3 col-sm-4 col-10 | my-2 mx-1 my-sm-0 me-xl-5 card"
            *ngFor="let reto of lista_retos">
            <img title="Imagen de reto" class="img-fluid" src="{{ url_imagenes }}{{ reto.imagen_destacada }}"
              *ngIf="reto.imagen_destacada" />
            <img title="Imagen de reto" class="img-fluid" src="../../assets/logo.png" *ngIf="!reto.imagen_destacada" />
            <div class="card-body">
              <h4 class="card-title">{{ reto.nombre }}</h4>
              <p class="card-text | txt-sec">{{ reto.descripcion }}</p>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section class="row | mt-xl-5 mt-3">
      <article class="text-center col-12">
        <button class="boton medium" (click)="registro()">Quiero unirme</button>
        <button class="boton medium secondary" (click)="inicio()">
          Iniciar sesión
        </button>
      </article>

      <article class="offset-xl-2 offset-lg-1 | col-xl-8 col-lg-10 | pb-xl-5 mt-xl-5 mt-3">
        <div class="row text-center">
          <div class="col-xl-3 col-sm-6 mb-sm-0 mb-5">
            <div>
              <img src="../../assets/Grupo 649.svg" class="img-fluid" />
            </div>
            <div>
              <img src="../../assets/flechas-landing.svg" class="img-fluid w-25" />
            </div>
            <h2>Personal investigador verificados</h2>
          </div>
          <div class="col-xl-3 col-sm-6 mb-sm-0 mb-5">
            <div>
              <img src="../../assets/Grupo 650.svg" class="img-fluid" />
            </div>
            <div>
              <img src="../../assets/flechas-landing.svg" class="img-fluid w-25" />
            </div>
            <h2>Proyectos asegurados</h2>
          </div>
          <div class="col-xl-3 col-sm-6 mb-sm-0 mb-5">
            <div>
              <img src="../../assets/Grupo 655.svg" class="img-fluid" />
            </div>
            <div>
              <img src="../../assets/flechas-landing.svg" class="img-fluid w-25" />
            </div>
            <h2>Fácil y ágil</h2>
          </div>
          <div class="col-xl-3 col-sm-6 mb-sm-0 mb-5">
            <div>
              <img src="../../assets/Grupo 653.svg" class="img-fluid" />
            </div>
            <div>
              <img src="../../assets/flechas-landing.svg" class="img-fluid w-25" />
            </div>
            <h2>Con tiempos marcados</h2>
          </div>
        </div>
      </article>
    </section>
  </main>
  <div class="pie">
    <div class="patrocinioImg">
      <img src="../../assets/logos/unionEuropea2.png" alt="Union Europea">
      <img src="../../assets/logos/logo fondos eu.svg" alt="Fondos Union Europea">
      <img src="../../assets/logos/CAM_Consejeria_de_Ciencia2.png" alt="CAM consejeria de ciencia">
    </div>
    <div class="patrocinioTexto">
      <p class="patrocinioTitulo">ENTIDADES DE ENLACE DE LA INNOVACIÓN TECNOLÓGICA DE LA COMUNIDAD DE MADRID</p>
      <p> Actividad del Proyecto “<b>Actuaciones de colaboración público-privada</b>" de Ref.: OI2022-FGCSIC, concedido
        en la Convocatoria 2022 de ayudas para potenciar la innovación tecnológica e impulsar la transferencia de
        tecnología al sector productivo comprendido en las prioridades de la Estrategia Regional de Especialización
        Inteligente (S3) de la Comunidad de Madrid a través de entidades de enlace de la innovación tecnológica,
        cofinanciado en un 30% por la Comunidad de Madrid y en otro 20% por el Fondo Europeo de Desarrollo Regional en
        el marco del Programa Operativo FEDER 2021-2027. </p>
    </div>
  </div>
</div>