import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class UsuariosService {
	public usuario_sesion: any;
	public token: any;
	public url: any;


	constructor(
		public _http: HttpClient,
	) {
		this.url = environment.url_api;
	}


	createUsuarios(usuario: any) {
		let params = JSON.stringify(usuario);
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json',);
		return this._http.post(this.url + 'createUsuarios', params, { headers: headers });
	}

	createAdmin(usuario: any, token: any) {
		let params = JSON.stringify(usuario);
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json',)
			.set('Authorization', token);
		return this._http.post(this.url + 'creatAdminUsuarios', params, { headers: headers });
	}

	readUsuarios(token: any, id: any) {
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.get(this.url + 'readUsuarios/' + id, { headers: headers });
	}
	readUsuariosEmpresa(token: any, id: any) {
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.get(this.url + 'readUsuariosEmpresa/' + id, { headers: headers });
	}

	readEmpresas(token: any, id: any) {
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.get(this.url + 'readEmpresas/' + id, { headers: headers });
	}

	updateUsuarios(usuario: any, token: any) {
		let params = JSON.stringify(usuario)
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'updateUsuarios', params, { headers: headers });
	}

	banearUsuarios(usuario: any, token: any) {
		let params = JSON.stringify(usuario)
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'banearUsuarios', params, { headers: headers });
	}

	verificarUsuarios(usuario: any, token: any) {
		let params = JSON.stringify(usuario)
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'validateUsuarios', params, { headers: headers });
	}

	desbanearUsuarios(usuario: any, token: any) {
		let params = JSON.stringify(usuario)
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'desbanearUsuarios', params, { headers: headers });
	}

	borrarUsuarios(usuario: any, token: any) {
		let params = JSON.stringify(usuario)
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'deleteUsuarios', params, { headers: headers });
	}

	reactivarUsuarios(usuario: any, token: any) {
		let params = JSON.stringify(usuario)
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'activeUsuarios', params, { headers: headers });
	}

	listPaises() {
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set("Accept", "*/*");
		return this._http.get(this.url + 'listPaises', { headers: headers });
	}

	listUsuarios(token: any, pagina: number = 1, termino_busqueda: string = "", pais: string = "", cargo: string = "", rol: string = "") {
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token)
			.set("Accept", "*/*");
		return this._http.get(this.url + 'listUsuarios?p=' + pagina + '&q=' + termino_busqueda + '&pais=' + pais + '&cargo=' + cargo + '&rol=' + rol + '&r=10', { headers: headers });
	}
	listAllUsuarios(token: any, pagina: number = 1, termino_busqueda: string = "", pais: string = "", cargo: string = "", rol: string = "") {
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token)
			.set("Accept", "*/*");
		return this._http.get(this.url + 'listUsuarios?p=' + pagina + '&q=' + termino_busqueda + '&pais=' + pais + '&cargo=' + cargo + '&rol=' + rol + '&r=1000', { headers: headers });
	}

	createSeguidores(seguidor: any, token: any) {
		let params = JSON.stringify(seguidor);
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'createSeguidores', params, { headers: headers });
	}

	updateSeguidores(seguidor: any, token: any) {
		let params = JSON.stringify(seguidor);
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'updateSeguidores', params, { headers: headers });
	}

	deleteSeguidores(seguidor: any, token: any) {
		let params = JSON.stringify(seguidor);
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'deleteSeguidores', params, { headers: headers });
	}
	aceptarSeguidores(seguidor: any, token: any) {
		let params = JSON.stringify(seguidor);
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'aceptarSeguidores', params, { headers: headers });
	}
	rechazarSeguidores(seguidor: any, token: any) {
		let params = JSON.stringify(seguidor);
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'rechazarSeguidores', params, { headers: headers });
	}

	listSeguidores(token: any, filtroNombre: string = "", filtroPais: string = "", filtroCargo: string = "", pagina: number = 1) {
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token)
			.set("Accept", "*/*");
		return this._http.get(this.url + 'listSeguidores/?nombre=' + filtroNombre + "&pais=" + filtroPais + "&cargo=" + filtroCargo, { headers: headers });
	}

	createConversacion(id_seguidor: any, token: any) {
		let params = JSON.stringify({ id_usuario: id_seguidor });
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'createConversaciones', params, { headers: headers });
	}

	readSeguidores(id_usuario: any, token: any) {
		let params = JSON.stringify({ 'id_usuario': id_usuario });
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.get(this.url + 'readSeguidores/' + id_usuario, { headers: headers });
	}

	comprobarUsuario(email: string) {
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set("Accept", "*/*");
		return this._http.get(this.url + 'comprobarusuario/?email=' + email, { headers: headers });
	}

	cambiarPassword(token: any, password: string) {
		let params = JSON.stringify({ 'password': password });
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', token);
		return this._http.post(this.url + 'cambiarPassword', params, { headers: headers });
	}

	exportCollection() {
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set("Accept", "*/*");
		return this._http.get(this.url + 'exportar_coleccion_Usuarios', { headers: headers });

	}

}
