
export class Seguidores {
    constructor(
        public _id: string,
        public id_emisor: string,
        public id_receptor: string,
        public estado: number,
        public cdata: Date,
        public mdate: Date,
        public borrado: string     
    ){}
}