/* 9/12/2022 Mario Lopez Actualizamos como muestramos los botones en funcion de los roles de usuario */
import { Location, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.services';
import { SolucionesService } from '../soluciones.service';
import { ConversacionesService } from '../../conversaciones/conversaciones.services';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-ver-solucion',
	templateUrl: './ver-solucion.component.html',
	styleUrls: ['./ver-solucion.component.css']
})
export class VerSolucionComponent implements OnInit {
	public id:any;
	public solucionModelo:any;
	public usuario_sesion:any;
	private id_solucion:string="";
	public mensaje_error:string="";
	public aviso_error:boolean=false;
	public informacion:string = "";
	public id_conversacion:string = "";
	public pipe = new DatePipe('en-US');

	public url_imagenes:string = environment.url_imagenes

	@ViewChild('modal_aviso') modal_aviso:any;
	@ViewChild('modal_informacion') modal_informacion:any;
	@ViewChild('modal_presentar') modal_presentar:any;
	@ViewChild('modal_rechazo_masivo') modal_rechazo_masivo:any;
	@ViewChild('modal_rechazar') modal_rechazar:any;

	constructor(
		private router:Router,
		private modalService: NgbModal,
		private _solucionesService: SolucionesService,
		private _conversacionesService: ConversacionesService,
		private _globalService: GlobalService,
		private _router:ActivatedRoute,
		public location:Location,
		private sanitizer:DomSanitizer,
	) { 
		this.usuario_sesion=this._globalService.DatosSesion();
	}

	ngOnInit(): void {
		this._router.params.subscribe( paramMap => {
			this.id_solucion = paramMap['id']
			this.leer_solucion();
			
		});		
	}

	//Descargamos la solución mediante la id.
	leer_solucion(){
		this._solucionesService.readSoluciones(this._globalService.getToken(), this.id_solucion).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				this.solucionModelo =  res.response.listado;
				this.solucionModelo.descripcion = this.sanitizer.bypassSecurityTrustHtml(this.solucionModelo.descripcion);
				this.leer_conversacion(this.solucionModelo._id);
			}
		})
	}
	leer_conversacion(id_solucion:string){
		this._conversacionesService.buscarConversacionSolucion(this._globalService.getToken(), id_solucion).subscribe({
			next: (respuesta)=>{
				let res:any = respuesta;
				if(res.error_code == 1){
					this.id_conversacion = res.response.id_conversacion;
				}
			}
		})

	}

	aprobar_solucion(){
		this._solucionesService.aprobarSoluciones(this.solucionModelo, this._globalService.getToken()).subscribe({    
			next: (respuesta) => {
				let res:any = respuesta;
				if(res.error_code==1){
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error=false;
					if(res.response.PremiosDisponibles > 0){
						this.mensaje_error="Solución aprobada, quedan "+res.response.PremiosDisponibles+" premios por asignar";
						this.modalService.open(this.modal_aviso,{centered:true});
					}else{
						this.modalService.dismissAll(); //cerrar todos los popups
						this.aviso_error=false;
						this.modalService.open(this.modal_rechazo_masivo,{centered:true});
					}
					this.solucionModelo.estado=1;
				}else if(res.error_code==27){ //Este error sale cuando estan todos los premios asignados
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error=true;
					this.modalService.open(this.modal_rechazo_masivo,{centered:true});
				}else{   
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error=true;
					this.mensaje_error=res.message;
					this.modalService.open(this.modal_aviso,{centered:true});
				}  
			},
			error: (e) => {
			}
		})
	}
	contratos(){
		this.router.navigate(["/contrato/" + this.id_solucion]);
	}
	chat(){
		this.router.navigate(["/conversaciones/" + this.id_conversacion]);
	}
	confirmarRechazo(){
		this.modalService.dismissAll(); //cerrar todos los popups
		this.modalService.open(this.modal_rechazar,{centered:true});
	}

	rechazar_solucion(){
		this._solucionesService.rechazarSoluciones(this.solucionModelo, this._globalService.getToken()).subscribe({    
			next: (respuesta) => {
				let res:any = respuesta;
				if(res.error_code==1){
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error=false;
					this.mensaje_error="Solución rechazada";
					this.modalService.open(this.modal_aviso,{centered:true});
					this.solucionModelo.estado=2;
				}else{   
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error=true;
					this.mensaje_error=res.message;
					this.modalService.open(this.modal_aviso,{centered:true});
				}  
			},
			error: (e) => {
			}
		})
	}

	ver_reto(){
		this.router.navigate(['/retos/'+this.solucionModelo.id_reto._id])
	}
	descargarSolucion(){
		this._solucionesService.descargarSolucion(this._globalService.getToken(),this.solucionModelo.url_archivo);
	}

	//Abrimos el modal donde aparece información de la pagina.
	abrirInfo(){
		this.informacion = "En la ventana de visualizar solución, podemos como investigador ver la descripción y conclusión de nuestra solución además de descargarnos el fichero que subimos y en caso de tener video redirigirnos a la url del video, también podemos mediante el botón Ver reto ir al detalle del reto."
		if(this.usuario_sesion.rol == 3){
			this.informacion = "En la ventana de visualizar solución, podemos como empresa leer la descripción y conclusión de la solución presentada, descargarnos el fichero y en caso de tener video redirigirnos a la url del video. Una vez revisada la solución podemos aceptarla o rechazarla."
		}
		if(this.usuario_sesion.rol < 3){
			this.informacion = "En la ventana de visualizar solución, podemos ver la descripción y conclusión de la solución además de descargarnos el fichero subido y en caso de tener video redirigirnos a la url del video, también podemos mediante el botón Ver reto ir al detalle del reto."
		}
		this.modalService.open(this.modal_informacion,{centered:true});
	}

	editar_solucion(){
		this.router.navigate(['/editar-solucion/'+this.solucionModelo._id])
	}

	descargar_documento(url:string){
		this._globalService.descargar_documento(url);
	}

	confirmarPresentacion(){
		this.modalService.dismissAll(); //cerrar todos los popups
		this.modalService.open(this.modal_presentar,{centered:true});
	}

	presentar(){
		this._solucionesService.presentarSoluciones(this.solucionModelo, this._globalService.getToken()).subscribe({ 
			next: (respuesta) => {
				let res:any = respuesta;
				this.modalService.dismissAll(); //cerrar todos los popups
				if(res.error_code==1){
					this.solucionModelo.estado = 0;
					this.aviso_error=false;
					this.mensaje_error="Tu solución se ha presentado correctamente";
					this.modalService.open(this.modal_aviso,{centered:true});
					this.leer_conversacion(this.solucionModelo._id);
				}else{  
					this.aviso_error=true;
					this.mensaje_error=res.message;
					this.modalService.open(this.modal_aviso,{centered:true});
				}  
			},
			error: (e) => {
			}
		}) 
	}
	rechazarTodas(){
		this.modalService.dismissAll();
		this._solucionesService.rechazarTodasSoluciones(this.solucionModelo.id_reto._id, this._globalService.getToken()).subscribe({    
			next: (respuesta) => {
				let res:any = respuesta;
				if(res.error_code == 1){
					this.solucionModelo.estado = 2;
				}
			}
		})
		

	}
	pagoPremio(){
 		if (this._globalService.usuario_sesion.rol < 3){
			this._solucionesService.registrarPagoRegalo(this.solucionModelo, this._globalService.getToken()).subscribe({    
				next: (respuesta) => {
					let res:any = respuesta;
					if (res.error_code == 1){
						this.modalService.dismissAll(); //cerrar todos los popups
						this.aviso_error=false;
						this.mensaje_error="Se acaba de registrar el pago del premio";
						this.modalService.open(this.modal_aviso,{centered:true});
						this.solucionModelo.fechaPagoPremio = res.response.fechaPagoPremio
					}else{
						this.modalService.dismissAll(); //cerrar todos los popups
						this.aviso_error=true;
						this.mensaje_error="Fallo al registrar el pago del premio";
						this.modalService.open(this.modal_aviso,{centered:true});
					}
				}
			})
		}else{
			this.modalService.dismissAll(); //cerrar todos los popups
			this.aviso_error=true;
			this.mensaje_error="No tiene permisos para registrar el pago del premio";
			this.modalService.open(this.modal_aviso,{centered:true});
		}
	}

}
