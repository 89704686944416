import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})

export class ConversacionesService {
	public usuario_sesion: any;
	public token: any;
	public url: any;


	constructor(
		public _http: HttpClient,
	) {
		this.url = environment.url_api;
	}


	listConversaciones(token:any,pagina:number=0,termino:string=""){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*"); 
	  	return this._http.get(this.url+'listConversaciones?p='+pagina+"&t="+termino, {headers: headers});
	}

	buscarConversacionSolucion(token:any,id_solucion:string){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*"); 
	  	return this._http.get(this.url+'buscarConversacionSolucion?t='+id_solucion, {headers: headers});
	}

	readConversaciones(token:any, id_conversacion:string){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*");
	  	return this._http.get(this.url+'readConversaciones/'+id_conversacion, {headers: headers});
	}

	enviarMensaje(token:any, id_conversacion:string, mensaje:string){
		let params = JSON.stringify({mensaje:mensaje});
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*");
	  	return this._http.post(this.url+'createMensajes/'+id_conversacion, params, {headers: headers});
	}

	leerConversacion(token:any, id_conversacion:string){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*");
	  	return this._http.post(this.url+'leerConversacion/'+id_conversacion,{param:null}, {headers: headers});
	}

	leerMensaje(token:any, id_mensaje:string){
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*");
	  	return this._http.post(this.url+'leerMensajes/'+id_mensaje,{param:null}, {headers: headers});
	}

	crearTicket(token:any, mensaje:string){
		let params = JSON.stringify({mensaje:mensaje});
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token)
		.set("Accept", "*/*");
	  	return this._http.post(this.url+'crearTicket/', params, {headers: headers});
	}

	desbloqueoBloqueo(conversacion: any, token: any) {
		let params = JSON.stringify(conversacion);
		let headers = new HttpHeaders()
		.set('Content-Type', 'application/json')
		.set('Authorization', token);
		return this._http.post(this.url + 'desbloqueoBloqueo', params, { headers: headers }); 
	}
}