export class Retos {
    constructor(
        public _id: string,
        public id_empresa: string,
        public nombre: string,
        public descripcion: string,
        public detalles: string,
        public estado: number,
        public sector: string,
        public tags: string,
        public fecha_fin: Date,
        public numero_soluciones: number,
        public soluciones: string,
        public contratos: string,
        public cdate: Date,
        public mdate: Date,
        public borrado: string,
        public baneado: number,  
        public verNumero:number,
        public mostrarAnonimo:number,
        public area:string,
        public tipologia:string,
        public presupuesto:Number,
        public numPremios:number,
        public premio:string,
        public criterios_presupuesto:string,   
        public documentos:any,
        public imagen_destacada:string
    ){}
}