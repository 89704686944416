import { Component, OnInit, ViewChild } from '@angular/core';
import { Usuarios } from 'src/app/models/usuarios';
import { Empresas } from 'src/app/models/empresas';
import { Seguidores } from 'src/app/models/seguidores'
import { UsuariosService } from '../usuarios.service';
import { GlobalService } from 'src/app/services/global.services';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-ver-usuario',
	templateUrl: './ver-usuario.component.html',
	styleUrls: ['./ver-usuario.component.css']
})
export class VerUsuarioComponent implements OnInit {
	public usuarioModelo: any;
	public empresaModelo: any;
	public biografiaUsuario: any;
	public usuario_sesion: any;
	public peticionAmistadModelo: any; //Para crear seguidor
	public Biografia: any

	public conectar: boolean = true;
	public aviso_error: boolean = false;
	public mensaje_error: string = "";
	public existeConexionUsuario: boolean = false;
	public informacion: string = "";

	public url_imagenes: string = environment.url_imagenes

	@ViewChild('modal_aviso') modal_aviso: any;
	@ViewChild('modal_informacion') modal_informacion: any;

	constructor(
		private sanitizer: DomSanitizer,
		private _usuarioService: UsuariosService,
		private _globalService: GlobalService,
		private _router: ActivatedRoute,
		private modalService: NgbModal,
		public location: Location,
		private router: Router,
	) {
		this.usuario_sesion = _globalService.DatosSesion();
		this.usuarioModelo = new Usuarios('', 0, '', '', '', '', '', '', '', '', '', '', '', new Date, 0, '', '', '', '', '', '', '', '', '', '', '', 0, 0, 0, "", "", "", "", 0, new Date, new Date, 0);
		this.peticionAmistadModelo = new Seguidores('', '', '', 0, new Date, new Date, '0');
	}


	ngOnInit(): void {
		this._router.params.subscribe(paramMap => {
			this._usuarioService.readUsuarios(this._globalService.getToken(), paramMap['id']).subscribe({
				next: (respuesta) => {
					let res: any = respuesta;
					this.usuarioModelo = res.response.listado;
					if (this.usuarioModelo.id_empresa != null) {
						this.empresaModelo = this.usuarioModelo.id_empresa;
					}
					this.usuarioModelo.biografia = this.sanitizer.bypassSecurityTrustHtml(this.usuarioModelo.biografia);
					this.descargarSeguidores();
				}
			})
		})
	}
	ngAfterViewInit(): void {

	}

	aprobar() {
		this._usuarioService.verificarUsuarios(this.usuarioModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.mensaje_error = "Usuario aprobado";
					this.modalService.open(this.modal_aviso, { centered: true });
					this.usuarioModelo.validado = 1;
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			}
		})
	}


	descargarSeguidores() {
		this._usuarioService.listSeguidores(this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					//filtramos la primera petición en la que esté el usuario
					this.peticionAmistadModelo = res.response.listado.filter((modelo: any) => modelo.id_receptor._id == this.usuarioModelo._id || modelo.id_emisor._id == this.usuarioModelo._id)
					if (this.peticionAmistadModelo.length > 0) {
						this.peticionAmistadModelo = this.peticionAmistadModelo[0]
						this.existeConexionUsuario = true;
						this.conectar = false;
						if (this.peticionAmistadModelo.estado == 2) {
							this.conectar = true;
						}
					}
				} else {
					this.existeConexionUsuario = false;
				}
			}
		})
	}


	conectarConUsuario() {
		this.peticionAmistadModelo = new Seguidores('', '', '', 0, new Date, new Date, '0');
		this.peticionAmistadModelo.id_emisor = this.usuario_sesion._id;
		this.peticionAmistadModelo.id_receptor = this.usuarioModelo._id;

		this._usuarioService.createSeguidores(this.peticionAmistadModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.descargarSeguidores()
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
				//console.log(e)
			}
		})
	}

	aceptaPeticion() {
		this._usuarioService.aceptarSeguidores(this.peticionAmistadModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.descargarSeguidores()
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.conectar = false;
					this.peticionAmistadModelo.estado = 1;
					this.mensaje_error = this.usuarioModelo.nombre + " ahora está en tu red";
					this.modalService.open(this.modal_aviso, { centered: true });
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
				//console.log(e)
			}
		})
	}

	rechazaPeticion() {
		this._usuarioService.rechazarSeguidores(this.peticionAmistadModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.descargarSeguidores()
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.conectar = false;
					this.peticionAmistadModelo.estado = 2;
					this.mensaje_error = "Has rechazado la petición de " + this.usuarioModelo.nombre;
					this.modalService.open(this.modal_aviso, { centered: true });
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
				//console.log(e)
			}
		})
	}

	eliminarContacto() {
		this._usuarioService.deleteSeguidores(this.peticionAmistadModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.conectar = true;
					this.peticionAmistadModelo.estado = 2;
					this.mensaje_error = "Eliminado de la red";
					this.modalService.open(this.modal_aviso, { centered: true });
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			},
			error: (e) => {
				//console.log(e)
			}
		})
	}

	enviarMensaje() {
		this._usuarioService.createConversacion(this.usuarioModelo._id, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1 || res.error_code == 10) {
					this.modalService.dismissAll(); //cerrar todos los popups
					//Abrimos la conversacion
					this.router.navigate(['/conversaciones/', res.response.conversacion])
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			}
		})

	}

	editar() {
		this.router.navigate(['/perfil/', this.usuarioModelo._id])
	}

	banear() {
		this._usuarioService.banearUsuarios(this.usuarioModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.mensaje_error = "Usuario baneado";
					this.modalService.open(this.modal_aviso, { centered: true });
					this.usuarioModelo.baneado = 1;
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			}
		})
	}

	desbanear() {
		this._usuarioService.desbanearUsuarios(this.usuarioModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.usuarioModelo.baneado = 0;
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.mensaje_error = "Usuario desbaneado";
					this.modalService.open(this.modal_aviso, { centered: true });
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			}
		})
	}

	borrar() {
		this._usuarioService.borrarUsuarios(this.usuarioModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.mensaje_error = "Usuario borrado";
					this.modalService.open(this.modal_aviso, { centered: true });
					this.usuarioModelo.borrado = 1;
					this.location.back()
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			}
		})
	}

	reactivar() {
		this._usuarioService.reactivarUsuarios(this.usuarioModelo, this._globalService.getToken()).subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = false;
					this.mensaje_error = "Usuario reactivado";
					this.modalService.open(this.modal_aviso, { centered: true });
					this.usuarioModelo.borrado = 0;
				} else {
					this.modalService.dismissAll(); //cerrar todos los popups
					this.aviso_error = true;
					this.mensaje_error = res.message;
					this.modalService.open(this.modal_aviso, { centered: true });
				}
			}
		})
	}
	//Abrimos el modal donde aparece información de la pagina.
	abrirInfo() {
		this.informacion = "Ver Perfil, en esta ventana podemos ver información del investigador, además de conectar a nuestra red, eliminar de nuestra red, chatear y acceder a sus redes sociales."
		if (this.usuario_sesion.rol < 3) {
			this.informacion = "Ver Perfil, en esta ventana podemos ver información del investigador, además de eliminarlo y bloquearlo."
		}
		this.modalService.open(this.modal_informacion, { centered: true });
	}
}
