import { Component, ElementRef, EventEmitter, Injectable, OnChanges, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { GlobalService } from '../services/global.services';
import { NotificacionesService } from '../notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
	@ViewChild('bodyBar') sideBar!: ElementRef; //Cojemos el alias #bodyBar en el componente y lo manipulamos por codigo

	public estado_sidebar:boolean=false; //variable utilizada para ocultar o mostrar el sidebar
	public usuario_sesion: any;
	public notificaciones_totales:number=0;
	public notificaciones_administracion:number=0;        
	public notificaciones_chat:number=0;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
	public url_imagenes = environment.url_imagenes;
	public front_version:string = environment.front_version;
	public api_version:string="";
	public url_documentacion = environment.url_documentacion;
	

	@Output() cerrar_sesion = new EventEmitter<any>();

	constructor(
		private _globalService:GlobalService,
		private _notificacionesService:NotificacionesService,
		private renderer2: Renderer2,
	) {
		this.usuario_sesion=this._globalService.DatosSesion();
	}
	

	ngOnInit(): void {
		this._notificacionesService.socket.on('nuevas_notificaciones', (data:any)=>{
			
			this.notificaciones_administracion=0;
			this.notificaciones_chat=0;
			if(data.usuarios){
				this.notificaciones_administracion+=data.usuarios;
			}
			
			if(data.retos){
				this.notificaciones_administracion+=data.retos;
			}

			if(data.chat){
				this. notificaciones_chat+=data.chat;
			}
			this.notificaciones_totales = this.notificaciones_administracion+this.notificaciones_chat;
			console.log("Not: "+this.notificaciones_totales)
		})
		
		this._notificacionesService.socket.on('api_version', (data:any)=>{
			this.api_version = data;
		})
		
	}

	ngAfterViewInit(): void{
		const bodyBar = this.sideBar.nativeElement;
		/* this.renderer2.addClass(bodyBar, "d-none") */
		this.renderer2.removeClass(bodyBar, "animate__fadeOutLeft")
		this.renderer2.removeClass(bodyBar, "d-none")
		this.renderer2.addClass(bodyBar, "animate__fadeInLeft")
		this.estado_sidebar=true;
	}


	cambiarSidebar(){ 
		const bodyBar = this.sideBar.nativeElement;
		if(this.estado_sidebar){
			this.renderer2.removeClass(bodyBar, "animate__fadeInLeft")
			this.renderer2.addClass(bodyBar, "animate__fadeOutLeft")
			setTimeout(() => {
				this.renderer2.addClass(bodyBar, "d-none");
				this.estado_sidebar=false;
			  }, 800);
			
		}else{
			
			this.renderer2.removeClass(bodyBar, "animate__fadeOutLeft")
			this.renderer2.removeClass(bodyBar, "d-none")
			this.renderer2.addClass(bodyBar, "animate__fadeInLeft")
			this.estado_sidebar=true;
		}
	}

	logout(){
		this.cerrar_sesion.emit(true);
	}

}
