<div *ngIf="usuario_sesion.rol==4">
     <h1 class="titulo-componente">Mis soluciones<img class="btn_informacion" src="../../assets/info.svg"(click)="abrirInfo()"></h1>
     <div class="div-tabla">
          <table class="table">
               <thead>
                    <tr>
                         <th>Titulo</th>
                         <th>Reto</th>
                         <th>Estado</th>
                         <th>Fecha presentación</th>
                    </tr>
               </thead>
               <tbody>
                    <tr class="clicable" *ngFor="let solucion of lista_soluciones" (click)="ver_solucion(solucion._id)">
                         <td>{{solucion.resumen}}</td>
                         <td>{{solucion.id_reto?.nombre}}</td>
                         <td> 
                              <span class="label_tabla px-4 amarillo" *ngIf="solucion.estado == 3">Borrador</span>
                              <span class="label_tabla px-4 verde" *ngIf="solucion.estado == 0">Presentada</span>
                              <span class="label_tabla px-4 verde"*ngIf="solucion.estado == 1">Aprobada</span>
                              <span class="label_tabla px-4 rojo" *ngIf="solucion.estado == 2">Rechazada</span>
                         </td>
                         <td>{{pipe.transform(solucion.cdate, 'dd/MM/yyyy')}}</td>
                    </tr>
               </tbody>
          </table>
          <div class="noResultados" *ngIf="nSoluciones == 0">
               <h4>NO SE HA LOCALIZADO NINGUNA SOLUCIÓN.</h4>
          </div>
     </div>
</div>
<!--Modal para confirmar solucion presentada-->
<ng-template #modal_aviso let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
 
            <div class="modal-body aviso">
                <div class="icono-aviso">
                    <img src="../../../assets/correcto.svg"  width="100%">
                </div>
                <div class="titulo">
                    <p [innerHTML]="mensaje_error"></p>
                </div>
            </div>
          </div>
     </div>
 </ng-template>
 <!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal >
     <div class="modal-content">
          <div class="modal-content">
			<div class="modal-header">
				<img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
			</div>
               <div class="modal-body">
				<p>
                        {{informacion}}
                    </p>				
               </div> 
               <div class="modal-footer">
                    <button class="boton medium"(click)="modal.dismiss()">Aceptar</button>
               </div>            
          </div>
     </div>
</ng-template>