import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { GlobalService } from '../services/global.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from './dashboard.service';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
	public usuario_sesion:any;
	public informacion:string = "";
	public filtros:boolean = false;

	public desde:Date = new Date();
	public hasta:Date = new Date();

	//variables para referenciar los canvas y poder actualizarlos
	public graficoUsuariosNuevos:any;
	public graficoEmpresasUsuarios:any;
	public graficoRetosNuevos:any;
	public graficoSolucionesNuevas:any;
	public graficoEstadoRetos:any;

	public dataQuerys:any;

	@ViewChild('filtro') filtroClass!: ElementRef 
	@ViewChild('modal_informacion') modal_informacion:any;
	

	constructor(
		private _globalService:GlobalService,
		private modalService: NgbModal,
		private _dashboardService:DashboardService,
		private renderer: Renderer2,
	) { 
		this.usuario_sesion=this._globalService.DatosSesion();
		this.desde.setDate(this.desde.getDate() - 7); //configuramos por defecto el filtro de los últimos 7 días
	}

	ngOnInit(): void {
		this.buscar()
	}

	ngAfterViewInit(): void{
		const filtroClass = this.filtroClass.nativeElement;
		this.renderer.addClass(filtroClass, 'd-none');
	}
	
	//Abrimos el modal donde aparece información de la pagina.
	abrirInfo(){
		this.informacion = "En la ventana Dashboard, la persona administrativa podrá mediante filtros de fechas, sectores y categorías obtener distintos informes de: nuevos usuarios, retos y soluciones presentadas, estados de los retos y búsquedas realizadas. <strong>Por defecto saldrá información de los últimos 7 días</strong>"
		this.modalService.open(this.modal_informacion,{centered:true});
	}


	mostrar_filtro(){
		const filtroClass = this.filtroClass.nativeElement;

		if(this.filtros){
			this.filtros = !this.filtros;
			this.renderer.removeClass(filtroClass, 'animate__flipInX')
			this.renderer.addClass(filtroClass,'animate__flipOutX')
			setTimeout(()=>{
				this.renderer.addClass(filtroClass, 'd-none')
			}, 500)
		}else{
			this.filtros = !this.filtros;
			this.renderer.removeClass(filtroClass,'animate__flipOutX')
			this.renderer.removeClass(filtroClass,'d-none')
			this.renderer.addClass(filtroClass, 'animate__flipInX')
		}
		
	}

	
	buscar(){
		this.descargarUsuarios();
		this.descargarRetos();
		this.descargarSoluciones();
		this.descargarQuerys();
	}

	descargarUsuarios(){
		this._dashboardService.listUsuarios(this.desde, this.hasta).subscribe({
			next: (respuesta:any)=>{
				if(respuesta.error_code==1){
					
					//reseteamos el dataset
					let dataUsuariosNuevos = [];
					let labels_fechas = [];
					let listado_usuarios = respuesta.response.listado;
					let array_fechas:Array<any>=[];
					let numero_investigadores:number = 0;
					let numero_empresas:number = 0;

					//recorremos los usuarios para contabilizarlos por fechas
					for(let u=0;u<listado_usuarios.length;u++){
						let fecha = listado_usuarios[u].cdate.split('T')[0];
						if(Object.keys(array_fechas).includes(fecha)){
							array_fechas[fecha]=array_fechas[fecha]+1;
						}else{
							array_fechas[fecha]=1;
						}

						if(listado_usuarios[u].rol==3){
							numero_empresas++
						}else if(listado_usuarios[u].rol==4){
							numero_investigadores++
						}
					}

					
					for(let f=0;f<Object.keys(array_fechas).length;f++){
						let fecha:any = Object.keys(array_fechas)[f];
						dataUsuariosNuevos.push(array_fechas[fecha])
						labels_fechas.push(fecha);
					}

					if (this.graficoUsuariosNuevos) {
						this.graficoUsuariosNuevos.destroy();
					}
					this.graficoUsuariosNuevos =  new Chart("GraficoUsuariosNuevos", {
						type: 'line',
						data: {
							labels: labels_fechas, 
							datasets: [
								{
									label:"Usuarios Nuevos",
									backgroundColor: "#00DBDE",
									data: dataUsuariosNuevos
								}
								
							]
						},
						options:{
							scales:{
								x:{
									grid:{
										display: false
									}
									
								},
								y:{
									grid:{
										display: false
									},
									ticks:{
										display:false
									}
								}
							},
							plugins:{
								legend:{
									display:false
								}
							},
							
						},
						
					});

					if (this.graficoEmpresasUsuarios) {
						this.graficoEmpresasUsuarios.destroy();
					}
					this.graficoEmpresasUsuarios =  new Chart("GraficoEmpresasUsuarios", {
						type: 'pie',
						data: {
							labels: ["Investigadores", "Empresas"], 
							datasets: [
								{
									backgroundColor: ["#00DBDE", "#FC00FF"],
									data: [numero_investigadores, numero_empresas]
								}
								
							]
						},
						options:{
							plugins:{
								legend:{
									display:false
								}
							},
							
						},
						
					});
					
					//forzamos la renderización de los gráficos
					this.graficoUsuariosNuevos.update();
					this.graficoEmpresasUsuarios.update();

				}				
			},
		})
	}

	descargarRetos(){
		this._dashboardService.listRetos(this.desde, this.hasta).subscribe({
			next: (respuesta:any)=>{
				if(respuesta.error_code==1){
					let dataRetosNuevos=[];
					//reseteamos el dataset
					let listado_retos = respuesta.response.listado;
					let array_fechas:Array<any>=[];
					
					let retos_borrador:number = 0;
					let retos_revision:number = 0;
					let retos_validado:number = 0;
					let retos_activo:number = 0;
					let retos_finalizado:number = 0;

					let labels = [];
					//recorremos los retos para contabilizarlos por fechas
					for(let u=0;u<listado_retos.length;u++){
						let fecha = listado_retos[u].cdate.split('T')[0];
						
						if(Object.keys(array_fechas).includes(fecha)){
							array_fechas[fecha]=array_fechas[fecha]+1;
						}else{
							array_fechas[fecha]=1;
						}

						if(listado_retos[u].estado==0){
							retos_borrador++;
						}else if(listado_retos[u].estado==1){
							retos_revision++;
						}else if(listado_retos[u].estado==2){
							retos_validado++;
						}else if(listado_retos[u].estado==3){
							retos_activo++;
						}else if(listado_retos[u].estado==4){
							retos_finalizado++;
						}

						
					}

					
					for(let f=0;f<Object.keys(array_fechas).length;f++){
						let fecha:any = Object.keys(array_fechas)[f];
						dataRetosNuevos.push(array_fechas[fecha])
						labels.push(fecha);
					}

					if (this.graficoRetosNuevos) {
						this.graficoRetosNuevos.destroy();
					}
					this.graficoRetosNuevos =  new Chart("GraficoRetosNuevos", {
						type: 'line',
						data: {
							labels: labels, 
							datasets: [
								{
									label:"Retos Nuevos",
									backgroundColor: "#00DBDE",
									data: dataRetosNuevos
								}
								
							]
						},
						options:{
							scales:{
								x:{
									grid:{
										display: false
									}
									
								},
								y:{
									grid:{
										display: false
									},
									ticks:{
										display:false
									}
								}
							},
							plugins:{
								legend:{
									display:false
								}
							},
							
						},
						
					});
					this.graficoRetosNuevos.update();

					/*
					retos_borrador = Math.round(retos_borrador*100/listado_retos.length);
					retos_revision = Math.round(retos_revision*100/listado_retos.length);
					retos_validado = Math.round(retos_validado*100/listado_retos.length);
					retos_activo = Math.round(retos_activo*100/listado_retos.length);
					retos_finalizado = Math.round(retos_finalizado*100/listado_retos.length);
					*/
					if (this.graficoEstadoRetos) {
						this.graficoEstadoRetos.destroy();
					}
					this.graficoEstadoRetos =  new Chart("GraficoEstadoRetos", {
						type: 'pie',
						data: {
							labels: ["Borrador", "En revisión", "Validado", "Activo", "Finalizado"], 
							datasets: [
								{
									backgroundColor: ["#00DBDE", "#FC00FF", "#98E0FB", "#7F6DEF", "#050FB4"],
									data: [retos_borrador, retos_revision, retos_validado, retos_activo, retos_finalizado]
								}
								
							]
						},
						options:{
							plugins:{
								legend:{
									display:false
								}
							},
							
						},
						
					});

					this.graficoEstadoRetos.update();			
				}				
			},
		})
	}

	descargarSoluciones(){
		this._dashboardService.listSoluciones(this.desde, this.hasta).subscribe({
			next: (respuesta:any)=>{
				if(respuesta.error_code==1){
					
					//reseteamos el dataset
					let dataSolucionesNuevas=[];

					let listado_soluciones = respuesta.response.listado;
					let array_fechas:Array<any>=[];

					let labels = [];

					//quitamos las soluciones en borrador
					listado_soluciones.filter((solucion:any)=>solucion.estado!=3)

					//recorremos las soluciones para contabilizarlos por fechas
					for(let u=0;u<listado_soluciones.length;u++){
						let fecha = listado_soluciones[u].cdate.split('T')[0];

						if(Object.keys(array_fechas).includes(fecha)){
							array_fechas[fecha]=array_fechas[fecha]+1;
						}else{
							array_fechas[fecha]=1;
						}						
					}
					
					for(let f=0;f<Object.keys(array_fechas).length;f++){
						let fecha:any = Object.keys(array_fechas)[f];
						labels.push(fecha);
						dataSolucionesNuevas.push(array_fechas[fecha])
					}

					if (this.graficoSolucionesNuevas) {
						this.graficoSolucionesNuevas.destroy();
					}
					this.graficoSolucionesNuevas =  new Chart("GraficoSolucionesNuevas", {
						type: 'line',
						data: {
							labels: labels, 
							datasets: [
								{
									label:"Soluciones Nuevas",
									backgroundColor: "#00DBDE",
									data: dataSolucionesNuevas
								}
								
							]
						},
						options:{
							scales:{
								x:{
									grid:{
										display: false
									}
									
								},
								y:{
									grid:{
										display: false
									},
									ticks:{
										display:false
									}
								}
							},
							plugins:{
								legend:{
									display:false
								}
							},
							
						},
						
					});
					this.graficoSolucionesNuevas.update();
				}				
			},
		})
	}

	descargarQuerys(){
		this._dashboardService.listQuerys(this.desde, this.hasta).subscribe({
			next: (respuesta:any)=>{
				if(respuesta.error_code==1){
					
					//reseteamos el dataset
					this.dataQuerys= [];

					let lista_querys = respuesta.response.listado;
					let array_terminos:Array<any>=[];

					//recorremos las querys para agruparlas
					for(let u=0;u<lista_querys.length;u++){
						let termino = lista_querys[u].query;
						if(Object.keys(array_terminos).includes(termino)){
							array_terminos[termino]={
								'termino':termino,
								'cantidad':array_terminos[termino]['cantidad']+1
							};
						}else{
							array_terminos[termino]={
								'termino':termino,
								'cantidad':1
							};
						}						
					}

					for(let t=0;t<Object.keys(array_terminos).length;t++){
						let termino:any = Object.keys(array_terminos)[t];
						this.dataQuerys.push(array_terminos[termino])
					}

					this.dataQuerys.sort((a:any, b:any)=>{
						if(a.cantidad > b.cantidad){
							return -1;
						}
						return 1;
					})
				}				
			},
		})
	}
	
}

