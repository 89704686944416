export class Soluciones {
    constructor(
        public _id: string,
        public id_equipo: number,
        public id_usuario: number,
        public id_reto: number,
        public descripcion: string,
        public conclusion: string,
        public url_archivo: string,
        public url_video: string,
        public estado: number,  
        public cdate: Date,
        public mdate: Date,
        public borrado: string,     
        public resumen:string,
        public documentos:any,
        public fechaPagoPremio: Date,
    ){}
}