
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuarios } from './models/usuarios';
import { Empresas } from './models/empresas'
import { GlobalService } from './services/global.services';
import { UsuariosService } from './usuarios/usuarios.service';
import { SidebarComponent } from './sidebar/sidebar.component';
import { EmpresasService } from './empresas/empresas.service';
import { CondicionesComponent } from './condiciones/condiciones.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { Gtag } from 'angular-gtag';



//TODO: popup de confirmación en todos los borrados

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	providers: [GlobalService]
})

export class AppComponent {
	title = 'Nexofy';
	public errorMessage: string;
	public avisoMessage: string;
	public url = environment.url;

	public cookiesTmp: any = { aletaPoliticas: true, estadisticasPoliticas: true, marketingPoliticas: true }

	//variables para controlar la visualización del html
	public identificado: boolean = false;
	public recuperar_clave: boolean = false;
	public resetear_clave: boolean = false;
	public tipo_registro: string;
	public ver_landing: boolean = false;
	public ver_politicas: boolean = false;
	public ver_condiciones: boolean = false;
	public enviando_email: boolean = false;

	public contador_formulario: any;
	public boton_siguiente: any;
	public usuarioModelo: Usuarios;
	public empresaModelo: Empresas;
	public empresa: Empresas;
	public token: any;
	public usuario_sesion: any;
	public lista_paises: any;
	public lista_estados: any;
	public lista_estados_empresa: any;
	private imagenDefecto: string = "anonymous.png";
	private urlImagenDefecto: string = "anonymous.png";
	private imagenDefectoEmpresa: string = "anonymousEmpresa.png"
	public alertaEmail: string = "El email tiene un formato incorrecto"
	public alertaCIF: string = "El CIF es obligatorio"
	public emailValido: boolean = false;


	//variables para restablecer la contraseña
	public nueva_password: string = "";
	public nueva_password_verificacion: string = "";
	public password_hash: string = "";

	// Variable cifrar y descifrar
	public claveCompartida = 'C1FR@D0SN€EKISOF1'.toString();

	// Variables para cookies
	public year: number = new Date().getFullYear();
	public cookieAceptadas = false;
	public info: any = [false, false, false];

	//Expresiones regulares
	public nifsExp = new RegExp('^[XYZ]?[0-9]{7,8}[A-Z]$');
	public cifExp = new RegExp('^[ABCDEFGHJNPQRSUVW][0-9]{7}[0-9A-J]$');
	public movilExp = new RegExp('^[6|7]{1}[0-9]{8}$');
	public emailExp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');


	@ViewChild('modal_terminos') modal_terminos: any;
	@ViewChild('modal_cookies') modal_cookies: any;

	//Control de errores de los formularios
	public camposObligatorios = {
		'nombre': false,
		'apellidos': false,
		'email': false,
		'password': false,
		'fecha_nacimiento': false,
		'sexo': false,
		'cargo': false,
		'terminos': false,
		'telefono': false,
		'pais': false,
		'nombre_empresa': false,
		'cif_empresa': false,
		'sector_empresa': false,
		'pais_empresa': false,
		'nif': false,
		'poblacion': false,
		'provincia': false,
	};

	public erroresFormulario = {
		'nombreCorto': false,
		'apellidosCorto': false,
		'nifCorto': false,
		'emailIncorrecto': false,
		'passwordConfirmacion': false,
		'edadMinima': false,
		'telefonoIncorrecto': false,
		'nombreEmpresaCorto': false,
		'errorNumeroEmpleados': false,
		'errorFacturacion': false,
		'emailEmpresaIncorrecto': false,
	}

	public checkAvisoFormulario: boolean = false;

	public ver_login: boolean = true;

	constructor(
		private _globalService: GlobalService,
		private _usuariosService: UsuariosService,
		private _empresaService: EmpresasService,
		private router: Router,
		private modalService: NgbModal,
		private _router: ActivatedRoute,
		public gtag: Gtag
	) {
		this.tipo_registro = '';
		this.contador_formulario = 1;
		this.boton_siguiente = "Siguiente";
		this.usuarioModelo = new Usuarios('', 0, '', '', '', '', '', '', '', '', '', '', '', new Date, 0, '', '', '', '', '', '', '', '', '', '', '', 0, 0, 0, "", "", "", "", 0, new Date, new Date, 0);
		this.empresaModelo = new Empresas('', '', '', '', '', '', '', '', '', '', '', '', '', '', 0, 0, '', new Date, new Date, '', '', 0, new Date);
		this.empresa = new Empresas('', '', '', '', '', '', '', '', '', '', '', '', '', '', 0, 0, '', new Date, new Date, '', '', 0, new Date);
		this.errorMessage = '';
		this.avisoMessage = '';
	}

	ngOnInit() {
		this.ver_landing = true;
		this.ver_politicas = false;
		let aletaPoliticas = localStorage.getItem('aletaPoliticas');
		if (aletaPoliticas == "true") {
			this.cookieAceptadas = true;
		}
		if (window.location.href.includes("login")) {
			this.ver_landing = false;
			this.ver_politicas = false;
			this.ver_condiciones = false;
		} if (window.location.href.includes("recuperar_clave/")) {
			this.ver_landing = false;
			this.ver_politicas = false;
			this.recuperar_clave = false;
			this.resetear_clave = true;
			this.ver_condiciones = false;

			//el formato de la url debe ser siempre http://url_proyecto/recuperar_clave/string_hash
			this.password_hash = window.location.href.split("recuperar_clave/")[1];
		}
		if (window.location.href.includes("condiciones")) {
			this.ver_landing = false;
			this.ver_politicas = false;
			this.recuperar_clave = false;
			this.ver_condiciones = true;
		}
		if (window.location.href.includes("politicas")) {
			this.ver_landing = false;
			this.recuperar_clave = false;
			this.ver_condiciones = false;
			this.ver_politicas = true;
		}

		this._router.queryParams.subscribe((parametros: any) => {
			if (parametros.r) {
				this.ver_landing = false;
				this.ver_politicas = false;
				this.ver_login = false;
				if (parametros.r == 'e') {
					this.tipo_registro = "empresa"
				} else if (parametros.r == 'i') {
					this.tipo_registro = "investigador"
				}
				this._usuariosService.listPaises().subscribe({
					next: (respuesta) => {
						let res: any = respuesta;
						if (res.error_code == 1) {
							this.lista_paises = res.response.listado;
							this.usuarioModelo.pais = "España";
							this.empresaModelo.pais = "España";
							this.actualizar_estados();
							this.actualizar_estados_empresa();
							this.ver_login = false;
						} else {
							this.errorMessage = res.message;
						}
					},
					error: (e) => {
					}
				})
			}
		})

		this.token = this._globalService.getToken();
		if (this.token) { this.identificado = true; }


	}

	ngChange(): void {
		if (!sessionStorage.getItem('token')) {
			this.identificado = false;
			this.token = false;
		}

		this.validar_formulario();
	}

	abrirCorreo() {
		const email = 'info@nexofy.es';
		const subject = 'Problemas con el registro';
		const body = 'Describa aquí sus problemas con el registro.';

		const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

		console.log(mailtoLink);
		window.open(mailtoLink, '_self');
	}

	onSubmitLogin() {

		this.errorMessage = ''; //reseteamos el error
		//Mandamos los datos de login a la api
		//Ciframos contraseña con clave compartida DE MOMENTO LO COMENTAMOS;
		let usuario = Object.assign({}, this.usuarioModelo);
		usuario.password = CryptoJS.AES.encrypt(this.usuarioModelo.password.toString(), this.claveCompartida).toString();


		this._globalService.login(usuario).subscribe({
			next: (respuesta) => {
				//si no hay error
				if (respuesta.error_code == 1) {
					//Guardamos los datos del usuario en el localstorage
					sessionStorage.setItem('identity', JSON.stringify(respuesta.response.user));
					this.token = respuesta.response.token;
					this.identificado = true;
					//Crear elemento en el localStorage para tener el token disponible
					sessionStorage.setItem('token', this.token);
					this.usuarioModelo = respuesta.response.user;

					this.usuarioModelo.password = CryptoJS.AES.decrypt(this.usuarioModelo.password.toString(), this.claveCompartida).toString(CryptoJS.enc.Utf8);
					this.usuario_sesion = this._globalService.DatosSesion();
					if (this.usuarioModelo.rol <= 2) {
						this.router.navigate(['/administracion']);
					} else if (this.usuarioModelo.rol == 3) {
						this.router.navigate(['/retos']);
					} else if (this.usuarioModelo.rol <= 4) {
						this.router.navigate(['/home']);
					}
				} else {
					this.usuarioModelo.password = CryptoJS.AES.decrypt(this.usuarioModelo.password.toString(), this.claveCompartida).toString(CryptoJS.enc.Utf8);
					this.errorMessage = respuesta.message;
				}

			},

			error: (e) => this.errorMessage = e.error.message,

		});
	}

	public logout() {
		this.usuarioModelo = new Usuarios('', 0, '', '', '', '', '', '', '', '', '', '', '', new Date, 0, '', '', '', '', '', '', '', '', '', '', '', 0, 0, 0, "", "", "", "", 0, new Date, new Date, 0);
		this.identificado = false;
		this.token = false;
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('identity');
		window.location.href = "/login";
	}

	siguiente() {
		let valido = this.validar_formulario();
		if (valido) {
			this.contador_formulario++;
		} else {
		}
	}

	// Funcion tras aceptar politicas de Cookie y rechazar, en funcion de si se van agregando cookies, agregamos variables al locaStore con permisos.
	aceptarcookies(op: any) {
		if (op == 1) {
			this.cookieAceptadas = true;
			localStorage.setItem('aletaPoliticas', 'true');
			localStorage.setItem('estadisticasPolitica', 'true');
			localStorage.setItem('marketingPolitica', 'true');
		} else if (op == 2) {
			this.cookieAceptadas = true;
			localStorage.setItem('aletaPoliticas', 'false');
			localStorage.setItem('estadisticasPolitica', 'false');
			localStorage.setItem('marketingPolitica', 'false');
			this.modalService.dismissAll();
		} else {
			this.modalService.open(this.modal_cookies, { size: 'lg', backdrop: 'static', scrollable: true })
		}
	}

	guardarCookies() {
		this.cookieAceptadas = true;
		localStorage.setItem('aletaPoliticas', 'true');
		if (this.cookiesTmp.estadisticasPoliticas) {
			localStorage.setItem('estadisticasPoliticas', 'true');
		} else {
			localStorage.setItem('estadisticasPoliticas', 'false');
		}
		if (this.cookiesTmp.marketingPoliticas) {
			localStorage.setItem('marketingPoliticas', 'true');
		} else {
			localStorage.setItem('marketingPoliticas', 'false');
		}
		this.modalService.dismissAll();
	}

	// Abrir informacion de politicas de uso y cookies
	/* 	abrirPoliticas(){
			this.ver_politicas = true;
		}
		abrirCondicionesWeb(){
			this.ver_condiciones = true;
		} */

	irAnuevaWeb(url: string) {
		window.open(url, "_blank");
	}


	abrirCondiciones() {
		this.modalService.dismissAll();
		this.modalService.open(this.modal_terminos, { size: 'xl', backdrop: 'static', scrollable: true })
	}

	validar_formulario() {
		if (this.tipo_registro != "") {
			let n: number = this.contador_formulario;
			let formularioCorrecto
			switch (n) {
				//Datos personales del usuario
				case 1:
					this.boton_siguiente = "Siguiente";
					formularioCorrecto = true;

					//Comprobamos el nombre como obligatorio
					if (this.usuarioModelo.nombre == "") {
						this.camposObligatorios.nombre = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.nombre = false;
						//Comprobamos la longitud del nombre
						if (this.usuarioModelo.nombre.length < 3) {
							this.erroresFormulario.nombreCorto = true;
							formularioCorrecto = false;
						} else {
							this.erroresFormulario.nombreCorto = false;
						}
					}

					//Comprobamos el apellido como obligatorio
					if (this.usuarioModelo.apellidos == "") {
						this.camposObligatorios.apellidos = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.apellidos = false;
						//Comprobamos la longitud del apellidos
						if (this.usuarioModelo.apellidos.length < 3) {
							this.erroresFormulario.apellidosCorto = true;
							formularioCorrecto = false;
						} else {
							this.erroresFormulario.apellidosCorto = false;
						}
					}

					//Comprobamos el nif como obligatorio
					if (this.usuarioModelo.nif == "") {
						this.camposObligatorios.nif = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.nif = false;
						//Comprobamos la longitud del nif
						if (this.usuarioModelo.nif.length < 8 || !this.validarForm(this.nifsExp, this.usuarioModelo.nif)) {
							this.erroresFormulario.nifCorto = true;
							formularioCorrecto = false;
						} else {
							this.erroresFormulario.nifCorto = false;
						}
					}

					//Comprobamos el email como obligatorio
					if (this.usuarioModelo.email == "") {
						this.camposObligatorios.email = true;
						this.erroresFormulario.emailIncorrecto = true;
						formularioCorrecto = false;
						this.alertaEmail = "El email es obligatorio"
					} else {
						if (!this.emailValido) {
							this.camposObligatorios.email = false;
							formularioCorrecto = false;
						}
					}

					//Comprobamos el password como obligatorio
					if (this.usuarioModelo.password == "") {
						this.camposObligatorios.password = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.password = false;
						//Comprobamos la contraseña
						if (this.usuarioModelo.password === this.usuarioModelo.repetir_password) {
							this.erroresFormulario.passwordConfirmacion = false;
						} else {
							formularioCorrecto = false;
							this.erroresFormulario.passwordConfirmacion = true;
						}
					}

					//Comprobamos la fecha de nacimiento como obligatorio
					if (this.usuarioModelo.fecha_nacimiento == null) {
						this.camposObligatorios.fecha_nacimiento = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.fecha_nacimiento = false;
						const edadMinima = new Date();
						edadMinima.setFullYear(edadMinima.getFullYear() - 18);
						//Comprobamos la fecha de nacimiento
						if (new Date(this.usuarioModelo.fecha_nacimiento).getTime() > edadMinima.getTime()) {
							this.erroresFormulario.edadMinima = true;
							formularioCorrecto = false;
						} else {
							this.erroresFormulario.edadMinima = false;
						}
					}

					//Comprobamos el cargo como obligatorio
					if (this.usuarioModelo.cargo == null || this.usuarioModelo.cargo == "") {
						this.camposObligatorios.cargo = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.cargo = false;
					}

					if (this.checkAvisoFormulario) {
						this.camposObligatorios.terminos = false;
					} else {
						this.camposObligatorios.terminos = true;
						formularioCorrecto = false;
					}
					if (this.usuarioModelo.pais == "España" && this.usuarioModelo.provincia == "") {
						this.usuarioModelo.provincia = "A Coruña";
					}

					return formularioCorrecto;

				//Datos de contacto del usuario
				case 2:
					this.boton_siguiente = "Siguiente";
					formularioCorrecto = true;

					if (this.usuarioModelo.pais == "") {
						this.camposObligatorios.pais = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.pais = false;
					}
					//Validamos provincia
					if (this.lista_estados.length == 0) {
						this.usuarioModelo.provincia = this.usuarioModelo.pais;
					}

					if (this.usuarioModelo.provincia == "") {
						this.camposObligatorios.provincia = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.provincia = false;
						formularioCorrecto = true;
					}

					if (this.usuarioModelo.telefono == "") {
						this.camposObligatorios.telefono = true;
						formularioCorrecto = false;
					} else if (!this.validarForm(this.movilExp, this.usuarioModelo.telefono)) {
						this.erroresFormulario.telefonoIncorrecto = true;
						formularioCorrecto = false;
					} else {
						this.erroresFormulario.telefonoIncorrecto = false
						this.camposObligatorios.telefono = false;
					}

					//Comprobamos la población como obligatorio
					if (this.usuarioModelo.poblacion == "" || this.usuarioModelo.nombre.length < 2) {
						this.camposObligatorios.poblacion = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.poblacion = false;
					}


					if (formularioCorrecto && this.tipo_registro == "investigador" && this.usuarioModelo.personalidad == 0) {
						this.boton_siguiente = "Guardar";
					} else {
						this.boton_siguiente = "Siguiente";
					}

					return formularioCorrecto;

				//RRSS del usuario
				case 3:
					formularioCorrecto = true;
					//Si es un investigador se termina el formulario
					if (this.tipo_registro == "investigador" && this.usuarioModelo.personalidad == 0) {
						this.guardar();
						return false
					}

					return formularioCorrecto;

				//Datos básicos de la empresa
				case 4:
					this.boton_siguiente = "Siguiente";
					formularioCorrecto = true;
					if (this.empresaModelo.nombre == "") {
						this.camposObligatorios.nombre_empresa = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.nombre_empresa = false;
						if (this.empresaModelo.nombre.length < 3) {
							this.erroresFormulario.nombreEmpresaCorto = true;
						}
					}

					if (this.empresaModelo.cif == "") {
						this.camposObligatorios.cif_empresa = true;
						formularioCorrecto = false;
						this.alertaCIF = "El CIF es obligatorio";

					} else if (!this.validarForm(this.cifExp, this.empresaModelo.cif)) {
						this.alertaCIF = "El formato de CIF es incorrecto";
						this.camposObligatorios.cif_empresa = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.cif_empresa = false;
					}

					if (this.empresaModelo.email != "" && !this.validarForm(this.emailExp, this.empresaModelo.email)) {
						this.erroresFormulario.emailEmpresaIncorrecto = true;
						formularioCorrecto = false;
					}

					if (this.empresaModelo.sector == "") {
						this.camposObligatorios.sector_empresa = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.sector_empresa = false;
					}

					return formularioCorrecto;

				//Datos de contacto de la empresa
				case 5:
					formularioCorrecto = true;
					if (this.empresaModelo.pais == "España" && this.empresaModelo.provincia == "") {
						this.empresaModelo.provincia = "A Coruña";
					}

					if (this.empresaModelo.pais == "") {
						this.camposObligatorios.pais_empresa = true;
						formularioCorrecto = false;
					} else {
						this.camposObligatorios.pais_empresa = false;
					}
					if (formularioCorrecto) {
						this.boton_siguiente = "Guardar";
					}
					return formularioCorrecto;

				//RRSS de la empresa
				case 6:
					formularioCorrecto = true;
					this.guardar();
					return formularioCorrecto;

				default: return false
			}
		} else {
			return false;
		}

	}
	//Verificamos que el telefono no tenga mas de 14 caracteres y solo admina +prefijo internacional y numeros.
	verificarCaracteres(event: any) {
		if (event.keyCode != "8" && event.keyCode != "46") {
			let contador = event.target.value.length
			if (contador > 14) {
				event.preventDefault();
			}
			if (event.keyCode == "69" || event.keyCode == "109") {
				event.preventDefault();
			}
		}
	}
	//Verificamos si el email existe:
	verificarEmail() {
		//TODO: this.usuarioModelo.email;
		let test: boolean = this.validarForm(this.emailExp, this.usuarioModelo.email);
		if (test) {
			this._usuariosService.comprobarUsuario(this.usuarioModelo.email).subscribe({
				next: (respuesta) => {
					let res: any = respuesta;
					if (res.error_code == 29) {
						this.alertaEmail = res.message;
						this.erroresFormulario.emailIncorrecto = true;
						this.emailValido = false;
					} else {
						this.alertaEmail = "El email tiene un formato incorrecto";
						this.erroresFormulario.emailIncorrecto = false;
						this.emailValido = true;
					}
				}
			})
		} else {
			this.alertaEmail = "El email tiene un formato incorrecto";
			this.erroresFormulario.emailIncorrecto = true;
			this.emailValido = false;
		}

	}

	atras_formulario() {
		this.boton_siguiente = "Siguiente";
		--this.contador_formulario;
		if (this.contador_formulario < 1) {
			this.tipo_registro = "";
			this.contador_formulario = 1;
		}
	}

	async guardar() {
		this.errorMessage = "";
		this.avisoMessage = "";
		this.usuarioModelo.imagen = this.imagenDefecto;
		this.usuarioModelo.url_imagen = this.urlImagenDefecto;
		this.recuperar_clave = false;
		if (this.tipo_registro == "empresa" || this.usuarioModelo.personalidad == 1) {
			if (this.tipo_registro == "empresa") {
				this.usuarioModelo.personalidad = 1;
				this.usuarioModelo.rol = 3;
			} else {
				this.usuarioModelo.rol = 4;
			}
			this.empresaModelo.imagen = this.imagenDefectoEmpresa;
			this.empresaModelo.url_imagen = this.urlImagenDefecto;
			this._empresaService.createEmpresas(this.empresaModelo).subscribe({
				next: (respuesta) => {
					let res: any = respuesta;
					if (res.error_code == 1) {
						this.empresaModelo = res.response.stored;
						this.usuarioModelo.id_empresa = this.empresaModelo._id;

						this._usuariosService.createUsuarios(this.usuarioModelo).subscribe({
							next: (respuesta) => {
								let res: any = respuesta;
								if (res.error_code == 1) {
									//this.usuarioModelo = res.response.stored;
									this.avisoMessage = "Registro completado! Espera mientras un Administrador de la plataforma verifica tu perfil";
									this.ver_login = true;
								} else {
									this.errorMessage = res.message;
									this.ver_login = true;
								}
							},
							error: (e) => {
								this.errorMessage = "Ha habido un error creando tu usuario";
								this.ver_login = true;
							}
						})
					} else {
						this.errorMessage = res.message;
						this.ver_login = true;
					}
				},
				error: (e) => {
					this.errorMessage = "Ha habido un error creando tu empresa";
					this.ver_login = true;
				}
			})
		} else {
			this.usuarioModelo.rol = 4;
			this._usuariosService.createUsuarios(this.usuarioModelo).subscribe({
				next: (respuesta) => {
					let res: any = respuesta;
					if (res.error_code == 1) {
						//this.usuarioModelo = res.response.stored;
						this.avisoMessage = "Registro completado! Espera mientras un Administrador de la plataforma verifica tu perfil";
						this.ver_login = true;
					} else {
						this.errorMessage = res.message;
						this.ver_login = true;
					}
				},
				error: (e) => {
					this.errorMessage = "Ha habido un error creando tu usuario";
					this.ver_login = true;
				}
			})
		}
	}

	empezar_registro() {
		this.checkAvisoFormulario = false;
		this.resetEmpresa();
		this.resetUsuario();
		this.contador_formulario = 1;
		this.tipo_registro = "";

		this._usuariosService.listPaises().subscribe({
			next: (respuesta) => {
				let res: any = respuesta;
				if (res.error_code == 1) {
					this.lista_paises = res.response.listado;
					this.usuarioModelo.pais = "España";
					this.empresaModelo.pais = "España";
					this.actualizar_estados();
					this.actualizar_estados_empresa();
					this.ver_login = false;
				} else {
					this.errorMessage = res.message;
				}
			},
			error: (e) => {
			}
		})
	}

	actualizar_estados() {
		this.lista_estados = this.lista_paises.filter((pais: any) => pais.name == this.usuarioModelo.pais)[0].states
		if (this.lista_estados.length > 0) {
			this.usuarioModelo.provincia = this.lista_estados[0].name
		} else {
			this.usuarioModelo.provincia = "";
		}
	}

	actualizar_estados_empresa() {
		this.lista_estados_empresa = this.lista_paises.filter((pais: any) => pais.name == this.empresaModelo.pais)[0].states
		if (this.lista_estados_empresa.length == 0) {
			this.empresaModelo.provincia = this.empresaModelo.pais;
		} else {
			this.empresaModelo.provincia = this.lista_estados_empresa[0].name
		}
	}

	resetUsuario() {
		this.usuarioModelo = new Usuarios('', 0, '', '', '', '', '', '', '', '', '', '', '', new Date, 0, '', '', '', '', '', '', '', '', '', '', '', 0, 0, 0, "", "", "", "", 0, new Date, new Date, 0);
	}

	resetEmpresa() {
		this.empresaModelo = new Empresas('', '', '', '', '', '', '', '', '', '', '', '', '', '', 0, 0, '', new Date, new Date, '', '', 0, new Date);
	}

	recuperarClave() {
		this.errorMessage = "";
		this.avisoMessage = "";
		this.enviando_email = true;
		this._globalService.recuperar_password(this.usuarioModelo.email).subscribe({
			next: (res: any) => {
				this.enviando_email = false;
				if (res.error_code == 1) {
					this.avisoMessage = "Si el usuario existe, recibirá un correo con un enlace para que puedas restablecer tu contraseña";
				} else {
					this.errorMessage = "Ha habido un error enviando el email. " + res.message;
				}
			},
		})
	}

	restablecerPassword() {
		this.errorMessage = "";
		this.avisoMessage = "";
		if (this.nueva_password == this.nueva_password_verificacion) {
			this._globalService.restablecer_password(this.nueva_password, this.password_hash).subscribe({
				next: (res: any) => {
					if (res.error_code == 1) {
						this.resetear_clave = false;
						this.ver_login = true;
						this.avisoMessage = "Tu contraseña se ha restablecido correctamente";
					} else if (res.error_code == 10) {
						this.errorMessage = "El enlace ha expirado, vuelve a solicitar uno nuevo."
					} else {
						this.errorMessage = "Ha habido un restableciendo tu contraseña. " + res.message;
					}
				},
			})
		} else {
			this.errorMessage = "Las contraseñas no coinciden"
		}
	}

	abrirLanding() {
		window.location.href = "/";
	}

	//Aceptamos terminos y condiciones:
	aceptar_contrato(op: boolean) {
		this.modalService.dismissAll();
		this.checkAvisoFormulario = op;

	}
	// Validar expresiones regulares
	validarForm(expresion: any, campo: any) {
		if (!expresion.test(campo)) {
			return false;
		} else {
			return true;
		}
	}
}








