<div class="cabecera-ver">
     <div class="columna">
          <div class="boton-atras clicable" (click)="this.location.back()">
               <img src="../../assets/Icon feather-arrow-left-circle.svg">
          </div>
          <h1 class="titulo-componente">{{solucionModelo?.id_reto.nombre}}<img class="btn_informacion" src="../../assets/info.svg"(click)="abrirInfo()"></h1>
     </div>

     <div class="columna">
          <button class="boton secondary medium" (click)="ver_reto()">Ver reto</button>
          <button class="boton secondary medium" (click)="editar_solucion()" *ngIf="solucionModelo?.estado==3">Editar</button>
          <button class="boton large" *ngIf="solucionModelo?.estado==3" (click)="confirmarPresentacion()">Presentar</button>
     </div>
</div>


<div class="div-solucion">
     <div *ngIf="solucionModelo?.estado == 1" class="cabecera">
          <div class="columna-usuario">
               <div class="foto">
                    <img src="{{url_imagenes}}{{solucionModelo?.id_usuario.imagen}}">
               </div>
               <div class="nombre">
                    {{solucionModelo?.id_usuario.nombre}}
               </div>
          </div>
          <div class="label_tabla px-4">{{solucionModelo?.id_usuario.cargo}}</div>
     </div>
     
     <div class="cuerpo">
          <div class="titulo">Estado:</div>
          <div class="contenido">
               <span class="label_tabla px-4 amarillo" *ngIf="solucionModelo?.estado == 0">Presentada</span>
               <span class="label_tabla px-4 verde" *ngIf="solucionModelo?.estado == 1 && !solucionModelo.fechaPagoPremio">Aprobada</span>
               <div class="estadoPagado label_tabla verde"*ngIf="solucionModelo?.estado == 1 && solucionModelo.fechaPagoPremio">Aprobada,
                    Premio pagado: {{pipe.transform(solucionModelo.fechaPagoPremio, 'dd/MM/yyyy')}}
               </div>
               <span class="label_tabla px-4 rojo" *ngIf="solucionModelo?.estado == 2">Rechazada</span>
               <span class="label_tabla px-4" *ngIf="solucionModelo?.estado == 3">Borrador</span>
          </div>
          <div class="titulo">Resumen</div>
          <div class="contenido">
               <div [innerHTML]="solucionModelo?.resumen"></div>
          </div>
          
          <div class="titulo">Detalles</div>
          <div class="contenido">
               <div [innerHTML]="solucionModelo?.descripcion"></div>
          </div>

          <div class="titulo">Conclusión</div>
          <div class="contenido">
               <div [innerHTML]="solucionModelo?.conclusion"></div>
          </div>

          <div class="seccion" *ngIf="solucionModelo?.documentos">
               <div class="titulo">Documentos</div>
               <table class="table">
                    <tr *ngFor="let documento of solucionModelo.documentos">
                         <td>
                              <img class="icono" src="../../../assets/pdf.svg" *ngIf="documento.fichero.includes('.pdf')">
                              <img class="icono" src="../../../assets/txt.svg" *ngIf="documento.fichero.includes('.txt')">
                              <img class="icono" src="../../../assets/doc.svg" *ngIf="documento.fichero.includes('.doc')">
                              <img class="icono" src="../../../assets/svg.svg" *ngIf="documento.fichero.includes('.svg')">
                              <img class="icono" src="../../../assets/jpg.svg" *ngIf="documento.fichero.includes('.jpg')">
                              <img class="icono" src="../../../assets/png.svg" *ngIf="documento.fichero.includes('.png')">
                              <img class="icono" src="../../../assets/rar.svg" *ngIf="documento.fichero.includes('.rar') || documento.fichero.includes('.zip')">
                              <img class="icono" src="../../../assets/vicdeo-azul.svg" *ngIf="documento.fichero.includes('.mp4') || documento.fichero.includes('.mpeg') || documento.fichero.includes('.mpg') || documento.fichero.includes('.avi')">
                         </td>
                         <td>{{documento.nombre}}</td>
                         <td>
                              <button class="boton icono secondary" (click)="descargar_documento(documento.fichero)"><img src="../../../assets/descargar.svg"></button>
                         </td>
                    </tr>
               </table>
          </div>

          <div class="botones">
               <button class="boton secondary large icono" [ngClass]="{'disabled':usuario_sesion?.rol!=3}" *ngIf="(solucionModelo?.estado == 0 || solucionModelo?.estado == 2) && usuario_sesion?.rol==3" (click)="aprobar_solucion()">Aprobar <img src="../../../assets/check-circle-azul.svg"></button>
               <button class="boton secondary large danger" [ngClass]="{'disabled':usuario_sesion?.rol!=3}" *ngIf="(solucionModelo?.estado == 0) && usuario_sesion?.rol==3" (click)="confirmarRechazo()">Rechazar <img src="../../../assets/x (1).svg"></button>
               <button class="boton secondary large icono" *ngIf="(solucionModelo?.estado == 1)" (click)="contratos()">Contrato <img src="../../../assets/pdfAzul.svg"></button>
               <button class="boton secondary large icono" *ngIf="solucionModelo?.estado != 3 && id_conversacion != '' && id_conversacion != null " (click)="chat()">Chat <img src="../../../assets/chatAzul.svg"></button>
               <button class="boton secondary large icono" *ngIf="solucionModelo?.estado == 1 && usuario_sesion?.rol<3 && !solucionModelo.fechaPagoPremio" (click)="pagoPremio()">Premio pagado <img src="../../../assets/premio.svg"></button>
          </div>
     </div>
</div>


<!--Modal para avisos-->
<ng-template #modal_aviso let-modal>
	<div class="modal-content">
		 <div class="modal-content">
		   <div class="modal-header">
			   <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
		   </div>

		   <div class="modal-body aviso">
			   <div class="icono-aviso">
				   <img src="../../../assets/x (1).svg" *ngIf="aviso_error" width="100%">
				   <img src="../../../assets/correcto.svg" *ngIf="!aviso_error" width="100%">
			   </div>
			   <div class="titulo">
				   <h2>{{mensaje_error}}</h2>
			   </div>		
		   </div>       
		 </div>
	</div>
</ng-template> 

<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal >
     <div class="modal-content">
          <div class="modal-content">
			<div class="modal-header">
				<img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
			</div>
               <div class="modal-body">
				<p>
                        {{informacion}}
                    </p>				
               </div> 
               <div class="modal-footer">
                    <button class="boton medium"(click)="modal.dismiss()">Aceptar</button>
               </div>            
          </div>
     </div>
</ng-template>

<!--Modal confirmar la publicación de la solución-->
<ng-template #modal_presentar let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
 
               <div class="modal-body aviso">
                    <div class="titulo">
                         <h2>¿Seguro que quieres presentar la solución? Una vez que se presente no podrás hacer modificaciones</h2>
                    </div>
                    <button class="boton medium" (click)="presentar()">Sí, presentar</button>
                    <button class="boton medium secondary danger" (click)="modal.dismiss()">Cancelar</button>
               </div>
          </div>
     </div>
</ng-template>

<!--Modal confirmar rechazar la solicitud-->
<ng-template #modal_rechazar let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
 
               <div class="modal-body aviso">
                    <div class="titulo">
                         <h2>¿Seguro que quieres rechazar la solicitud? Una vez que se rechaza no podrás hacer modificaciones</h2>
                    </div>
                    <button class="boton medium" (click)="rechazar_solucion()">Sí, rechazar</button>
                    <button class="boton medium secondary danger" (click)="modal.dismiss()">Cancelar</button>
               </div>
          </div>
     </div>
</ng-template>

<!--Modal confirmar la publicación del reto-->
<ng-template #modal_rechazo_masivo let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>  
               <div class="modal-body aviso">
                    <div class="icono-aviso">
                         <img *ngIf="aviso_error" src="../../../assets/x (1).svg" width="100%">
                         <img *ngIf="!aviso_error" src="../../../assets/correcto.svg" width="100%">
                    </div>
                    <div class="titulo">
                         <h2 *ngIf="aviso_error">Ya están asignados todos los premios. ¿Desea rechazar todas las soluciones pendientes de verificar?</h2>
                         <h2 *ngIf="!aviso_error">Solucion aprobada. Ya están asignados todos los premios. ¿Desea rechazar todas las soluciones pendientes de verificar?</h2>
                    </div>
                    <button class="boton medium secondary danger" (click)="modal.dismiss()">Cancelar</button>
                    <button class="boton medium" (click)="rechazarTodas()">Sí, rechazar todas.</button>
               </div>
          </div>
     </div>
</ng-template>