import * as internal from "stream";

export class Contrato{
    constructor(
        public _id: string,
        public id_usuario: string,
        public id_reto: string,
        public estado: number,
        public cdate: Date,
        public mdate: Date,
        public borrado: string
    ){}
}