<h1 class="titulo-componente">Retos<img class="btn_informacion" src="../../assets/info.svg"(click)="abrirInfo()"></h1>

<div class="fila-busqueda">
     <div class="columna-busqueda">
          <div class="campoNombre">
               <label>Titulo</label>
               <input type="text" (keyup.enter)="descargar_retos()" placeholder="Titulo del reto..." class="form-control xl-12" [(ngModel)]="termino_busqueda">
          </div>
          <div class="campos">
               <label>Estado</label>
               <select (change)="descargar_retos()" class="form-control" [(ngModel)]="estado_busqueda">
                    <option value="0">TODOS</option>
                    <option value="3">ACTIVO</option>
                    <option value="4">FINALIZADO</option>
               </select>
          </div>
          <div class="campos">
               <label>Desde</label>
               <input type="date" (keyup.enter)="descargar_retos()" placeholder="Rango desde Inicio del reto..." class="form-control" [(ngModel)]="inicio_creado_busqueda">
          </div>
          <div class="campos">
               <label>Hasta</label>
               <input type="date" (keyup.enter)="descargar_retos()" placeholder="Rango hasta Inicio del reto..." class="form-control" [(ngModel)]="fin_creado_busqueda">
          </div>
          <div class="campos">
               <label>Sector</label>
               <select (change)="descargar_retos()" class="form-select clicable" placeholder="Sector del reto" name="sector" [(ngModel)]="sector_busqueda">
                    <option *ngFor="let op of opciones.sector">{{op}}</option>
               </select>
          </div>
          <div class="campos">
               <label>Área</label>
               <select (change)="descargar_retos()" class="form-select clicable" placeholder="Area del reto" name="area" [(ngModel)]="area_busqueda">
                    <option *ngFor="let op of opciones.area">{{op}}</option>
               </select>
          </div>
     </div>

     <div class="columna-boton">
          <button class="boton secondary medium" (click)="descargar_retos()">Buscar</button>
     </div>
</div>


<div *ngIf="numeroResultado > 0" class="div-retos">
     <div class="div-tarjeta" *ngFor="let reto of lista_retos">
          <div class="tarjeta card h-100">
               <div class="inner-card card-body">
                    <div class="titulo">
                         {{reto.nombre}}
                    </div>

                    <div class="logo">
                         <img *ngIf="reto.imagen_destacada!=''" src={{url_imagenes}}{{reto.imagen_destacada}}>
                         <img *ngIf="reto.id_empresa?.imagen && reto.imagen_destacada==''" src={{url_imagenes}}{{reto.id_empresa.imagen}}>
                    </div>

                    <div class="labels">
                         <span class="label_tabla px-4 amarillo" *ngIf="reto.estado==1 && reto.borrado==0 && reto.baneado==0">En revisión</span>
                         <span class="label_tabla px-4 verde" *ngIf="reto.estado==2 && reto.borrado==0 && reto.baneado==0">Validado</span>
                         <span class="label_tabla px-4 verde" *ngIf="reto.estado==3 && reto.borrado==0 && reto.baneado==0">Activo</span>
                         <span class="label_tabla px-4" *ngIf="reto.estado==4 && reto.borrado==0 && reto.baneado==0">Finalizado</span>
                         <span class="label_tabla px-4 rojo" *ngIf="reto.borrado==1 && reto.baneado==0">Eliminado</span>
                         <span class="label_tabla px-4 rojo" *ngIf="reto.baneado==1">Baneado</span>
                    </div>

                    <div class="contenido">
                         {{reto.descripcion}}
                    </div>

                    <div class="fechas">
                         <div *ngIf="reto.verNumero"><img src=""> Soluciones presentadas: {{reto.numero_soluciones}}</div>
                         <div><img src=""> Inicio: {{reto.cdate | date:'d/MM/y' }}</div>
                         <div><img src=""> Fin: {{reto.fecha_fin | date:'d/MM/y'}}</div>
                         
                    </div>
               </div>
               <div class="footer clicable" (click)="ver_reto(reto._id)">
                    Ver reto
               </div>
          </div>
     </div>
</div>
<div *ngIf="numeroResultado > 0" class="div-paginacion">
     <div class="atras">
          <button *ngIf="paginaActual>1"  (click)="paginaPrevia()" class="boton secondary"><img src="../../assets/arrow-left.svg">Anterior</button>
     </div>
     <div class="paginas">{{paginaActual}}/{{paginastotal}}</div>
     <div class="siguiente">
          <button class="boton secondary"*ngIf="paginaActual<paginastotal" (click)="paginaSiguiente()"><img src="../../assets/arrow-right.svg">Siguiente</button>
     </div>
</div>
<div class="noResultados" *ngIf="numeroResultado == 0">
     <h4>SIN RESULTADOS</h4>
</div>
<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal >
     <div class="modal-content">
          <div class="modal-content">
			<div class="modal-header">
				<img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
			</div>
               <div class="modal-body">
				<p>
                        {{informacion}}
                    </p>
               </div>
               <div class="modal-footer">
                    <button class="boton medium"(click)="modal.dismiss()">Aceptar</button>
               </div>
          </div>
     </div>
</ng-template>
