<div *ngIf="usuario_sesion.rol<=2">
     <h1 class="titulo-componente">Administración<img class="btn_informacion" src="../../assets/info.svg"
               (click)="abrirInfo()"></h1>
     <div class="cuerpo-componente">
          <div class="input-group">
               <select class="form-select select-tabs" (change)="cambiar_tab($event.target)">
                    <option value="usuarios">Usuarios</option>
                    <option value="empresas">Empresas</option>
                    <option value="retos">Retos</option>
                    <option value="conversaciones">Conversaciones</option>
               </select>
          </div>
          <div class="div-admin">

               <div class="columna-menu nav nav-tabs">
                    <div class="item active clicable" data-bs-toggle="tab" data-bs-target="#usuarios"
                         (click)="cambiar_tab('usuarios')">
                         Usuarios
                         <span class="circulo-notificacion clicable" *ngIf="usuarios_pendientes>0"
                              [ngStyle]="{'margin-left': '10px'}">{{usuarios_pendientes}}</span>
                    </div>
                    <div class="item clicable" data-bs-toggle="tab" data-bs-target="#empresas"
                         (click)="cambiar_tab('empresas')">
                         Empresas
                    </div>
                    <div class="item clicable" data-bs-toggle="tab" data-bs-target="#retos"
                         (click)="cambiar_tab('retos')">
                         Retos
                         <span class="circulo-notificacion" *ngIf="retos_pendientes>0"
                              [ngStyle]="{'margin-left': '10px'}">{{retos_pendientes}}</span>
                    </div>
                    <div class="item clicable" data-bs-toggle="tab" data-bs-target="#conversaciones"
                         (click)="cambiar_tab('conversaciones')">
                         Conversaciones
                    </div>
               </div>
               <div class="columna-contenido">
                    <div class="tab-pane fade" [ngClass]="{'show active':ver_tab_usuarios}" id="usuarios">
                         <button class="boton secondary" (click)="crearUsuario()">Crear administrador</button>
                         <button class="boton secondary" (click)="exportCollection()">Exportar Coleccion
                              Usuarios</button>
                         <div class="buscador">
                              <input class="form-control" (keyup.enter)="descargarUsuarios()" type="search"
                                   placeholder="Buscar..." [(ngModel)]="filtro_usuarios">
                              <button class="boton secondary" (click)="descargarUsuarios()">Buscar</button>
                         </div>
                         <div class="noResultados" *ngIf="nUsuarios == 0">
                              <h4>SIN RESULTADOS</h4>
                         </div>
                         <div *ngIf="nUsuarios > 0">
                              <table>
                                   <thead>
                                        <th>Estado</th>
                                        <th>Nombre</th>
                                        <th>Apellidos</th>
                                        <th>Email</th>
                                        <th>Rol</th>
                                        <th>Empresa</th>
                                   </thead>
                                   <tbody>
                                        <tr class="clicable" (click)="ver_usuario(usuario._id)"
                                             *ngFor="let usuario of lista_usuarios_filtrados"
                                             [ngClass]="{'borrado':usuario.borrado==1}">
                                             <td>
                                                  <span class="label_tabla px-4 rojo"
                                                       *ngIf="usuario.borrado==1">Eliminado</span>
                                                  <span class="label_tabla px-4 rojo"
                                                       *ngIf="usuario.baneado==1">Baneado</span>
                                                  <span class="label_tabla px-4 amarillo"
                                                       *ngIf="usuario.validado==0">Pendiente</span>
                                                  <span class="label_tabla px-4 verde"
                                                       *ngIf="usuario.validado==1 && usuario.baneado==0 && usuario.borrado==0">Activo</span>
                                             </td>
                                             <td>{{usuario.nombre}}</td>
                                             <td>{{usuario.apellidos}}</td>
                                             <td>{{usuario.email}}</td>
                                             <td>
                                                  <span *ngIf="usuario.rol == 1">SuperAdministrador</span>
                                                  <span *ngIf="usuario.rol == 2">Administrador</span>
                                                  <span *ngIf="usuario.rol == 3">Empresa</span>
                                                  <span *ngIf="usuario.rol == 4">Personal investigador</span>
                                             </td>
                                             <td *ngIf="usuario.id_empresa">{{usuario.id_empresa!.nombre}}</td>
                                             <td *ngIf="!usuario.id_empresa"></td>
                                        </tr>
                                   </tbody>
                              </table>
                              <div class="div-paginacion">
                                   <div class="atras">
                                        <button *ngIf="pagina_usuarios>1" (click)="anteriorPaginaUsuarios()"
                                             class="boton secondary"><img
                                                  src="../../assets/arrow-left.svg">Anterior</button>
                                   </div>
                                   <div class="paginas">{{pagina_usuarios}}/{{paginas_usuarios}}</div>
                                   <div class="siguiente">
                                        <button class="boton secondary" *ngIf="pagina_usuarios<paginas_usuarios"
                                             (click)="siguientePaginaUsuarios()"><img
                                                  src="../../assets/arrow-right.svg">Siguiente</button>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div class="tab-pane fade" [ngClass]="{'show active':ver_tab_empresas}" id="empresas">
                         <div class="buscador">
                              <input class="form-control" (keyup.enter)="descargarEmpresas()" type="search"
                                   placeholder="Buscar..." [(ngModel)]="filtro_empresas">
                              <button class="boton secondary" (click)="descargarEmpresas()">Buscar</button>
                         </div>
                         <div class="noResultados" *ngIf="nEmpresas == 0">
                              <h4>SIN RESULTADOS</h4>
                         </div>
                         <div *ngIf="nEmpresas > 0">
                              <table>
                                   <thead>
                                        <th>Nombre</th>
                                        <th>Estado</th>
                                   </thead>
                                   <tbody>
                                        <tr class="clicable" *ngFor="let empresa of lista_empresas_filtrados"
                                             (click)="ver_empresa(empresa._id)"
                                             [ngClass]="{'borrado':empresa.usuario_borrado==1}">

                                             <td>{{empresa.nombre}}</td>
                                             <td>
                                                  <span class="label_tabla px-4 rojo"
                                                       *ngIf="empresa.usuario_borrado==1">Eliminado</span>
                                                  <span class="label_tabla px-4 rojo"
                                                       *ngIf="empresa.usuario_baneado == 1">Baneado</span>
                                                  <span class="label_tabla px-4 amarillo"
                                                       *ngIf="empresa.usuario_validado == 0">Pendiente</span>
                                                  <span class="label_tabla px-4 verde"
                                                       *ngIf="(empresa.usuario_validado==1) && (empresa.usuario_baneado==0) && (empresa.usuario_borrado==0)">Activo</span>
                                             </td>


                                        </tr>
                                   </tbody>
                              </table>
                              <div class="div-paginacion">
                                   <div class="atras">
                                        <button *ngIf="pagina_empresas>1" (click)="anteriorPaginaEmpresas()"
                                             class="boton secondary"><img
                                                  src="../../assets/arrow-left.svg">Anterior</button>
                                   </div>
                                   <div class="paginas">{{pagina_empresas}}/{{paginas_empresas}}</div>
                                   <div class="siguiente">
                                        <button class="boton secondary" *ngIf="pagina_empresas<paginas_empresas"
                                             (click)="siguientePaginaEmpresas()"><img
                                                  src="../../assets/arrow-right.svg">Siguiente</button>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div class="tab-pane fade" [ngClass]="{'show active':ver_tab_retos}" id="retos">
                         <div class="buscador">
                              <input class="form-control" (keyup.enter)="descargarRetos()" type="search"
                                   placeholder="Buscar..." [(ngModel)]="filtro_retos">
                              <button class="boton secondary" (click)="descargarRetos()">Buscar</button>
                         </div>
                         <div class="noResultados" *ngIf="nRetos == 0">
                              <h4>SIN RESULTADOS</h4>
                         </div>
                         <div *ngIf="nRetos > 0">
                              <table>
                                   <thead>
                                        <th>Estado</th>
                                        <th>Nombre</th>
                                        <th>Empresa</th>
                                        <th>Fecha inicio</th>
                                        <th>Fecha fin</th>
                                        <th>Nº soluciones</th>
                                   </thead>
                                   <tbody>
                                        <tr class="clicable" *ngFor="let reto of lista_retos_filtrados"
                                             [ngClass]="{'borrado':reto.borrado==1, 'baneado':reto.baneado==1}">
                                             <td (click)="ver_reto(reto._id)">
                                                  <span class="label_tabla px-4 rojo"
                                                       *ngIf="reto.borrado==1">Eliminado</span>
                                                  <span class="label_tabla px-4 amarillo"
                                                       *ngIf="reto.estado==0 && reto.borrado!=1">Borrador</span>
                                                  <span class="label_tabla px-4 amarillo"
                                                       *ngIf="reto.estado==1 && reto.borrado!=1">En revisión</span>
                                                  <span class="label_tabla px-4 verde"
                                                       *ngIf="reto.estado==2 && reto.borrado!=1">Validado</span>
                                                  <span class="label_tabla px-4 verde"
                                                       *ngIf="reto.estado==3 && reto.borrado!=1">Activo</span>
                                                  <span class="label_tabla px-4"
                                                       *ngIf="reto.estado==4 && reto.borrado!=1">Finalizado</span>
                                             </td>
                                             <td (click)="ver_reto(reto._id)">{{reto.nombre}}</td>
                                             <td (click)="ver_reto(reto._id)">{{reto.id_empresa?.nombre}}</td>
                                             <td (click)="ver_reto(reto._id)">{{pipe.transform(reto.cdate,
                                                  'dd/MM/yyyy')}}</td>
                                             <td (click)="ver_reto(reto._id)">{{pipe.transform(reto.fecha_fin,
                                                  'dd/MM/yyyy')}}</td>
                                             <td *ngIf="!reto.verNumero"> <span
                                                       class="noVisible">{{reto.numero_soluciones}} </span> <i
                                                       (click)="verNumSoluciones(reto,1)"
                                                       class="fa fa-solid fa-eye"></i></td>
                                             <td *ngIf="reto.verNumero">{{reto.numero_soluciones}} <i
                                                       (click)="verNumSoluciones(reto,0)"
                                                       class="fa fa-solid fa-eye-slash"></i></td>
                                        </tr>


                                   </tbody>
                              </table>
                              <div class="div-paginacion">
                                   <div class="atras">
                                        <button *ngIf="pagina_retos>1" (click)="anteriorPaginaRetos()"
                                             class="boton secondary"><img
                                                  src="../../assets/arrow-left.svg">Anterior</button>
                                   </div>
                                   <div class="paginas">{{pagina_retos}}/{{paginas_retos}}</div>
                                   <div class="siguiente">
                                        <button class="boton secondary" *ngIf="pagina_retos<paginas_retos"
                                             (click)="siguientePaginaRetos()"><img
                                                  src="../../assets/arrow-right.svg">Siguiente</button>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div class="tab-pane fade" [ngClass]="{'show active':ver_tab_conversaciones}" id="conversaciones">
                         <div class="buscador">
                              <input class="form-control" (keyup.enter)="descargarConversaciones()" type="search"
                                   placeholder="Buscar..." [(ngModel)]="filtro_conversaciones">
                              <button class="boton secondary" (click)="descargarConversaciones()">Buscar</button>
                         </div>
                         <div class="noResultados" *ngIf="nConversacion == 0">
                              <h4>SIN RESULTADOS</h4>
                         </div>
                         <div *ngIf="nConversacion > 0">
                              <table>
                                   <thead>
                                        <th colspan="2">Usuarios</th>
                                        <th>Fecha inicio</th>
                                        <th>Último mensaje</th>
                                   </thead>
                                   <tbody>
                                        <tr class="clicable" *ngFor="let conversacion of lista_conversaciones"
                                             (click)="ver_conversacion(conversacion._id)"
                                             [ngClass]="{'borrado':conversacion.borrado==1}">
                                             <td>
                                                  <div class="usuario-conversacion">
                                                       <span>{{conversacion.usuarios[0].nombre}}</span>
                                                       <div class="foto">
                                                            <img
                                                                 src="{{url_imagenes}}{{conversacion.usuarios[0].imagen}}">
                                                       </div>
                                                       <span class="pieConversacion"
                                                            *ngIf="conversacion.usuarios[0].rol == 4">Persona
                                                            investigadora</span>
                                                       <span class="pieConversacion"
                                                            *ngIf="conversacion.usuarios[0].rol == 3">{{conversacion.usuarios.nombre_empresa}}</span>
                                                  </div>
                                             </td>
                                             <td>
                                                  <div class="usuario-conversacion">
                                                       <span>{{conversacion.usuarios[1].nombre}}</span>
                                                       <div class="foto">
                                                            <img
                                                                 src="{{url_imagenes}}{{conversacion.usuarios[1].imagen}}">
                                                       </div>
                                                       <span class="pieConversacion"
                                                            *ngIf="conversacion.usuarios[1].rol == 4">Persona
                                                            investigadora</span>
                                                       <span class="pieConversacion"
                                                            *ngIf="conversacion.usuarios[1].rol == 3">{{conversacion.usuarios.nombre_empresa}}</span>
                                                  </div>
                                             </td>
                                             <td>{{pipe.transform(conversacion.cdate, 'dd/MM/yyyy')}}</td>
                                             <td>{{pipe.transform(conversacion.mensajes[0].cdate, 'dd/MM/yyyy')}}</td>
                                        </tr>
                                   </tbody>
                              </table>
                              <div class="div-paginacion">
                                   <div class="atras">
                                        <button *ngIf="pagina_conversaciones>1" (click)="anteriorPaginaConversacion()"
                                             class="boton secondary"><img
                                                  src="../../assets/arrow-left.svg">Anterior</button>
                                   </div>
                                   <div class="paginas">{{pagina_conversaciones}}/{{paginas_conversaciones}}</div>
                                   <div class="siguiente">
                                        <button class="boton secondary"
                                             *ngIf="pagina_conversaciones<paginas_conversaciones"
                                             (click)="siguientePaginaConversacion()"><img
                                                  src="../../assets/arrow-right.svg">Siguiente</button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>
<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
               <div class="modal-body">
                    <p>
                         {{informacion}}
                    </p>
               </div>
               <div class="modal-footer">
                    <button class="boton medium" (click)="modal.dismiss()">Aceptar</button>
               </div>
          </div>
     </div>
</ng-template>

<!--Modal para crear un usuario nuevo-->
<ng-template #modal_usuario let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
               <div class="modal-body modal-formulario">
                    <h2>Añadir nuevo usuario administrador</h2>
                    <div class="form-group">
                         <label>Nombre</label>
                         <input type="text" placeholder="nombre" class="form-control"
                              [(ngModel)]="usuarioModelo.nombre">
                    </div>
                    <div class="form-group">
                         <label>Apellidos</label>
                         <input type="text" placeholder="apellidos" class="form-control"
                              [(ngModel)]="usuarioModelo.apellidos">
                    </div>
                    <div class="form-group">
                         <label>NIF/NIE</label>
                         <input type="text" placeholder="NIF/NIE" class="form-control" [(ngModel)]="usuarioModelo.nif">
                    </div>
                    <div class="form-group">
                         <label>Email</label>
                         <input type="email" placeholder="email" class="form-control" [(ngModel)]="usuarioModelo.email">
                    </div>
                    <div class="form-group">
                         <label>Contraseña</label>
                         <input type="password" placeholder="password" class="form-control"
                              [(ngModel)]="usuarioModelo.password">
                    </div>
                    <div class="form-group">
                         <label>Cargo</label>
                         <input type="text" placeholder="cargo" class="form-control" [(ngModel)]="usuarioModelo.cargo">
                    </div>
                    <div class="form-check">
                         <input class="form-check-input check-formulario" type="checkbox" [(ngModel)]="checkMiembroCsic"
                              (change)="usuarioModelo.miembro_csic=checkMiembroCsic">
                         <p class="form-check-label" for="flexCheckDefault">
                              Miembro del CSIC
                         </p>
                    </div>
                    <div *ngIf="usuarioModelo.miembro_csic==1">
                         <label>ID de CSIC</label>
                         <div class="input-group">
                              <input type="text" class="form-control" placeholder="ID"
                                   [(ngModel)]="usuarioModelo.id_csic">
                         </div>
                    </div>
               </div>
               <div class="modal-footer">
                    <button class="boton medium" (click)="guardarUsuario()">Aceptar</button>
               </div>
          </div>
     </div>
</ng-template>

<!--Modal para confirmar cambios guardados-->
<ng-template #modal_aviso let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>

               <div class="modal-body aviso">
                    <div class="icono-aviso">
                         <img src="../../../assets/x (1).svg" *ngIf="!aviso_error" width="100%">
                         <img src="../../../assets/correcto.svg" *ngIf="aviso_error" width="100%">
                    </div>
                    <div class="titulo">
                         <h2>{{this.mensaje_error}}</h2>
                    </div>
               </div>
          </div>
     </div>
</ng-template>