import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { NgxFileDropModule } from 'ngx-file-drop';

import { AppRoutingModule } from './app-routing';
import { AppComponent } from './app.component';
import { jsPDF } from "jspdf";

import { SidebarComponent } from './sidebar/sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EquiposComponent } from './equipos/equipos.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { ConversacionesComponent } from './conversaciones/conversaciones.component';
import { RetosComponent } from './retos/retos.component';
import { SoporteComponent } from './soporte/soporte.component';
import { PerfilComponent } from './perfil/perfil.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { AdministracionComponent } from './administracion/administracion.component';
import * as CanvasJSAngularChart from '../assets/canvasjs.angular.component';
import { VerRetoComponent } from './retos/ver-reto/ver-reto.component';
import { VerConversacionComponent } from './conversaciones/ver-conversacion/ver-conversacion.component';
import { SolucionesComponent } from './soluciones/soluciones.component';
import { PresentarSolucionComponent } from './soluciones/presentar-solucion/presentar-solucion.component';
import { VerSolucionComponent } from './soluciones/ver-solucion/ver-solucion.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditarRetoComponent } from './retos/editar-reto/editar-reto.component';
import { VerUsuarioComponent } from './usuarios/ver-usuario/ver-usuario.component';
import { GlobalService } from './services/global.services';
import { LandingComponent } from './landing/landing.component';
import { LogComponent } from './log/log.component';
import * as CryptoJS from 'crypto-js';
import { ContratosComponent } from './soluciones/ver-solucion/contratos/contratos.component';
import { PoliticasComponent } from './politicas/politicas.component';
import { CondicionesComponent } from './condiciones/condiciones.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { GtagModule } from 'angular-gtag';

var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;

const config: SocketIoConfig = {
	url: environment.url_api, // socket server url;
	options: {
		transports: ['websocket']
	}
}

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    DashboardComponent,
    UsuariosComponent,
    EquiposComponent,
    EmpresasComponent,
    ConversacionesComponent,
    RetosComponent,
    SoporteComponent,
    PerfilComponent,
    HomeComponent,
    AdministracionComponent,
    CanvasJSChart,
    VerRetoComponent,
    VerConversacionComponent,
    SolucionesComponent,
    PresentarSolucionComponent,
    VerSolucionComponent,
    EditarRetoComponent,
    VerUsuarioComponent,
    LandingComponent,
    LogComponent,
    ContratosComponent,
    PoliticasComponent,
    CondicionesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule, 
    EditorModule,
    NgbModule,
    SocketIoModule.forRoot(config), 
    NgxFileDropModule,
    ImageCropperModule,
    GtagModule.forRoot({ trackingId: 'G-72RRG34FK2', trackPageviews: true })
  ],
  providers: [
    { 
      provide: TINYMCE_SCRIPT_SRC, 
      useValue: 'tinymce/tinymce.min.js',
    },GlobalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
