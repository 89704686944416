<div class="cabecera-componente">
     <div class="boton-atras clicable" (click)="this.location.back()">
          <img src="../../assets/Icon feather-arrow-left-circle.svg">
     </div>
     <h1 class="titulo-componente"> {{retoModelo.nombre}} <img class="btn_informacion" src="../../assets/info.svg"
               (click)="abrirInfo()"></h1>
</div>
<div class="div-solucion">
     <div class="cabecera" *ngIf="retoModelo.id_empresa?.nombre">
          <div class="columna-usuario">
               <div class="foto">
                    <img *ngIf="retoModelo.id_empresa.imagen != 'ANONIMO'"
                         src="{{url_imagenes}}{{retoModelo.id_empresa.imagen}}">
                    <img *ngIf="retoModelo.id_empresa.imagen == 'ANONIMO'" src="../../assets/anonimo.png" alt="Anónimo"
                         title="Anónimo">
               </div>
               <div class="nombre">
                    {{retoModelo.id_empresa.nombre}}
               </div>
          </div>
          <div class="label_tabla px-4">{{retoModelo.id_empresa.sector}}</div>
     </div>

     <div class="formulario">
          <h3>Archivos adjuntos</h3>
          <div *ngIf="!cargando" class="div-adjuntos">
               <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                         Arrastre aquí un archivo o
                         <button class="boton medium" (click)="buscarFichero()">Seleccione un archivo</button>
                    </ng-template>
               </ngx-file-drop>

               <input type="file" style="display: none;" id="inputFichero" (change)="subir_fichero($event)">

               <table class="lista-ficheros">
                    <tr *ngFor="let documento of solucionesModelo.documentos">
                         <td>
                              <img class="icono" src="../../../assets/pdf.svg"
                                   *ngIf="documento.fichero.includes('.pdf')">
                              <img class="icono" src="../../../assets/txt.svg"
                                   *ngIf="documento.fichero.includes('.txt')">
                              <img class="icono" src="../../../assets/doc.svg"
                                   *ngIf="documento.fichero.includes('.doc')">
                              <img class="icono" src="../../../assets/svg.svg"
                                   *ngIf="documento.fichero.includes('.svg')">
                              <img class="icono" src="../../../assets/jpg.svg"
                                   *ngIf="documento.fichero.includes('.jpg')">
                              <img class="icono" src="../../../assets/png.svg"
                                   *ngIf="documento.fichero.includes('.png')">
                              <img class="icono" src="../../../assets/rar.svg"
                                   *ngIf="documento.fichero.includes('.rar') || documento.fichero.includes('.zip')">
                              <img class="icono" src="../../../assets/vicdeo-azul.svg"
                                   *ngIf="documento.fichero.includes('.mp4') || documento.fichero.includes('.mpeg') || documento.fichero.includes('.mpg') || documento.fichero.includes('.avi')">
                              <img class="icono" src="../../../assets/loading.svg" *ngIf="documento.fichero==''">
                              <img class="icono" src="../../../assets/x (1).svg"
                                   *ngIf="documento.fichero.includes('error.error')">
                         </td>
                         <td>{{documento.nombre}}</td>
                         <td><button class="boton icono secondary danger"
                                   (click)="borrar_fichero(documento.fichero)"><img
                                        src="../../../assets/trash-2-rojo.svg"></button></td>
                    </tr>
               </table>
          </div>
          <div *ngIf="cargando" class="spinner">
               <div class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
               </div>
          </div>


          <div class="input-control">
               <h3>Resumen</h3>
               <p [ngClass]="{'max-longitud':caracteres_resumen>max_caracteres_resumen}">
                    {{caracteres_resumen}}/{{max_caracteres_resumen}}
               </p>
               <p *ngIf="caracteres_resumen>max_caracteres_resumen" class="alerta_formulario">El texto es demasiado
                    largo</p>
               <textarea (keyup)="actualizarCaracteres()" class="form-control"
                    [(ngModel)]="solucionesModelo.resumen"></textarea>
               <p *ngIf="camposObligatorios.descripcion" class="alerta_formulario">El resumen es obligatorio</p>
          </div>


          <h3>Descripción</h3>
          <p [ngClass]="{'max-longitud':caracteres_descripcion>max_caracteres_descripcion}">
               {{caracteres_descripcion}}/{{max_caracteres_descripcion}}
          </p>
          <p *ngIf="caracteres_descripcion>max_caracteres_descripcion" class="alerta_formulario">El texto es demasiado
               largo</p>
          <editor [(ngModel)]="solucionesModelo.descripcion" (onKeyUp)="actualizarCaracteres()"
               apiKey="l16bkj4mgm1xi9iaeel16pfrvl5hd0234u05d9aim7jrd36w" [init]="{
                    branding: false,
                    base_url: '/tinymce',
                    suffix: '.min',
                    height: 500,
                    menubar: false,
                    plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks image code fullscreen',
                    'insertdatetime media table paste code help wordcount emoticons textcolor'
                    ],
                    toolbar:
                    'undo redo | formatselect forecolor backcolor| bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | fontfamily fontsize blocks | image code emoticons | help'
               }"></editor>
          <p class="error_formulario" *ngIf="camposObligatorios.descripcion" class="alerta_formulario">La descripcion es
               obligatoria</p>


          <div class="input-control">
               <h3>Conclusión</h3>
               <p [ngClass]="{'max-longitud':caracteres_conclusion>max_caracteres_conclusion}">
                    {{caracteres_conclusion}}/{{max_caracteres_conclusion}}
               </p>
               <p *ngIf="caracteres_conclusion>max_caracteres_conclusion" class="alerta_formulario">El texto es
                    demasiado largo</p>
               <textarea (keyup)="actualizarCaracteres()" class="form-control"
                    [(ngModel)]="solucionesModelo.conclusion"></textarea>
               <p class="error_formulario" *ngIf="camposObligatorios.conclusion" class="alerta_formulario">La conclusión
                    es obligatoria</p>
          </div>




          <button [disabled]="cargando" [ngClass]="cargando ? 'disabled boton large' : 'boton large'"
               *ngIf="solucionesModelo._id==''" (click)="guardar()">Guardar</button>
          <button [disabled]="cargando" [ngClass]="cargando ? 'disabled boton large' : 'boton large'"
               *ngIf="solucionesModelo._id!='' && solucionesModelo.estado==3" (click)="guardar()">Actualizar</button>

     </div>



</div>
<!--Modal para confirmar cambios guardados-->
<ng-template #modal_aviso let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>

               <div class="modal-body aviso">
                    <div class="icono-aviso">
                         <img src="../../../assets/x (1).svg" *ngIf="!aviso_error" width="100%">
                         <img src="../../../assets/correcto.svg" *ngIf="aviso_error" width="100%">
                    </div>
                    <div class="titulo">
                         <h2>{{this.mensaje_error}}</h2>
                    </div>
               </div>
          </div>
     </div>
</ng-template>

<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal>
     <div class="modal-content">
          <div class="modal-content">
               <div class="modal-header">
                    <img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
               </div>
               <div class="modal-body">
                    <p>
                         {{informacion}}
                    </p>
               </div>
               <div class="modal-footer">
                    <button class="boton medium" (click)="modal.dismiss()">Aceptar</button>
               </div>
          </div>
     </div>
</ng-template>