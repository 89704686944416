<h1 class="titulo-componente">Chat<img class="btn_informacion" src="../../assets/info.svg"(click)="abrirInfo()"></h1>
<div class="div-conversaciones">
     <div class="div-tarjeta" *ngFor="let conversacion of listaConversaciones">
          <div class="tarjeta" (click)="ver_conversacion(conversacion._id)">

               <div class="foto" *ngFor="let usuario of conversacion.usuarios" >
                    <img *ngIf="conversacion.estado > 0 || usuario_sesion.rol < 3" src="{{url_imagenes}}{{usuario.imagen}}">
                    <img *ngIf="conversacion.estado == 0 && usuario_sesion.rol > 2" src="../../assets/anonimo.png" alt="Anónimo" title="Anónimo">
               </div>

               <div class="columna-centro">
                    <div class="fila-encabezado">
                         <div class="nombre">
                              <span *ngFor="let usuario of conversacion.usuarios">
                                   <span *ngIf="conversacion.id_solucion == 'SOPORTE'"> - Soporte técnico Nexofy - </span>
                                   <span *ngIf="(conversacion.estado > 0 || usuario_sesion.rol < 3) && conversacion.id_solucion != 'SOPORTE'"> {{usuario.nombre}} </span>
                                   <span *ngIf="conversacion.usuarios.length>2">,</span>
                                   <span *ngIf="conversacion.estado == 0 && usuario_sesion.rol == 4">Empresa Anónima</span>
                                   <span *ngIf="conversacion.estado == 0 && usuario_sesion.rol == 3">Investigador/ra Anónimo/a</span>
                                   <span *ngIf="conversacion.estado > 0 && usuario.rol == 3">{{usuario.id_empresa!.nombre}} </span>
                              </span>
                              
                         </div>
                    </div>
                    <div [ngClass]="conversacion.mensajes[0].id_emisor == usuario_sesion._id ? 'ultimo-mensaje mio' : 'ultimo-mensaje contrario'">
                         <span *ngIf="conversacion.mensajes.length>0">
                              <!--SOLICITUD DE CONEXION-->
                              <span *ngIf="conversacion.mensajes[0].mensaje.includes('##solicitud_conexion##')">
                                   <span *ngIf="conversacion.mensajes[0].id_emisor==usuario_sesion._id">
                                        Esperando solicitud de conexión
                                   </span>

                                   <span *ngIf="conversacion.mensajes[0].id_emisor!=usuario_sesion._id">
                                        {{conversacion.usuarios[0].nombre}} quiere conectar contigo
                                   </span>
                                   
                              </span>
                              <!--SOLICITUD DE CONEXION-->


                              <!--FICHERO ADJUNTO-->
                              <span *ngIf="conversacion.mensajes[0].mensaje.includes('##fichero_subido##')">
                                   Fichero adjunto
                              </span>
                              <!--FICHERO ADJUNTO-->


                              <span *ngIf="!conversacion.mensajes[0].mensaje.includes('##')">
                                   {{conversacion.mensajes[0].mensaje}}
                              </span>
                              
                         </span>
                         
                         <span *ngIf="conversacion.mensajes.length==0">
                              Todavía no habeis iniciado la conversación
                         </span>
                    </div>
               </div>
               <div class="columna-fin" *ngIf="conversacion.mensajes.length>0">
               <div *ngIf="conversacion.pendientes>0" class="mensajes-pendientes">{{conversacion.pendientes}}</div>
                    <div class="hora">{{pipe.transform(conversacion.mensajes[0].cdate, 'dd/MM/yyyy HH:mm')}}</div>
               </div>
          </div>
     </div>
     <div class="noResultados" *ngIf="nConvesacion == 0 && loading">
          <h4>NO SE HA LOCALIZADO NINGUN REGISTRO.</h4>
     </div>
</div>
<!--Modal para mostrar informacion-->
<ng-template #modal_informacion let-modal >
     <div class="modal-content">
          <div class="modal-content">
			<div class="modal-header">
				<img src="../../assets/close.svg" class="boton-cerrar-modal clicable" (click)="modal.dismiss()">
			</div>
               <div class="modal-body">
				<p>
                        {{informacion}}
                    </p>				
               </div> 
               <div class="modal-footer">
                    <button class="boton medium"(click)="modal.dismiss()">Aceptar</button>
               </div>            
          </div>
     </div>
</ng-template>